<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row :gutter="10">
                    <a-col :span="3">
                        <a-date-picker
                            mode="year"
                            format="YYYY"
                            :allowClear="false"
                            :value="currentYear"
                            @openChange="isOpen = true"
                            :open="isOpen"
                            style="width: 100%;"
                            @panelChange="yearChange"
                        />
                    </a-col>
                    <a-col :span="4">
                        <a-tree-select
                            class="left"
                            @change="handleChange"
                            :placeholder="l('Company')"
                            style="width: 100%"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="companyTreeList"
                            tree-default-expand-all
                            v-model="comPanyId"
                            allowClear
                        />
                    </a-col>
                    <a-col :span="4">
                        <a-tree-select
                            v-model="departmentId"
                            allowClear
                            style="width: 100%"
                            show-search
                            :filterTreeNode="searchTree"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="DepTreeData"
                            :placeholder="l('Department')"
                            tree-default-expand-all
                            @select="SelectDepartmentTree"
                            @change="getData"
                        >
                        </a-tree-select>
                    </a-col>
                    <a-col span="4">
                        <a-button type="primary" @click="Export">
                            <a-icon type="import" />
                            导入
                        </a-button>
                        <a-button :type="'primary'" @click="exportToExcel()">
                            <a-icon type="download" />
                            <span>{{ l('Export') }}</span>
                        </a-button>
                    </a-col>
                    <a-col :span="9" style="text-align: right">
                        <a-input-search
                            style="max-width: 200px"
                            name="filterText"
                            :placeholder="l('SearchWithThreeDot')"
                            @search="getData"
                            enterButton
                            v-model="filterText"
                        />
                    </a-col>
                </a-row>
                <div class="my-md">
                    <!-- <a-table
                        :columns="columns"
                        :pagination="false"
                        bordered
                        size="small"
                        :scroll="{ x: 5000, y: scroll_y }"
                        :dataSource="tableData"
                    >
                    </a-table> -->
                    <div
                        class="d-flex hide-scroll"
                        style="position: sticky;top: -10px;overflow: auto;z-index: 100;"
                        ref="tableHead"
                    >
                        <div style="min-width: 250px;position: sticky;left: 0">
                            <div class="left-header right-border top-border">
                                <div class="bottom-border left-border">公司</div>
                                <div class=" bottom-border left-border">部门</div>
                            </div>
                        </div>
                        <!--表头-->
                        <div style="display:flex">
                            <div v-for="(item, index) in 12" :key="index" class="top-border">
                                <div class="right-header">
                                    <div class="bottom-border right-border">{{ currentYear.year() }}年{{ item }}月</div>
                                </div>
                                <div class="right-bottom bottom-border">
                                    <div>
                                        人数
                                    </div>
                                    <div style="line-height:20px">
                                        工作日出勤总时数
                                    </div>
                                    <div>
                                        加班总时数
                                    </div>
                                    <div>
                                        合计出勤时数
                                    </div>
                                    <div>
                                        成本
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--数据-->
                    <div
                        class="Att-Table"
                        :style="{ 'overflow-x': tableData.length > 0 ? 'auto' : 'hidden' }"
                        @scroll="scrollTableBody"
                    >
                        <div class="data-left">
                            <div
                                class="left-data-row bottom-border bg-white right-border"
                                v-for="(item, index) in tableData"
                                :key="index"
                            >
                                <div class="row-column left-border" :title="item.company">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{ item.company }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border" :title="item.department">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{ item.department }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="data-right">
                            <div v-for="(item, index) in tableData" :key="index">
                                <div style="display:flex;">
                                    <div v-for="i in 12" :key="i" style="display:flex;">
                                        <div
                                            class="data-row bottom-border right-border  data-column-text"
                                            :style="{ background: bgcolor }"
                                            :title="item['peopleNum' + i]"
                                            @click="columnClick($event, i, 'peopleNum', '人数', item)"
                                        >
                                            <div class="ellipse">
                                                {{ item['peopleNum' + i] }}
                                            </div>
                                        </div>

                                        <div
                                            class="data-row bottom-border right-border  data-column-text"
                                            :style="{ background: bgcolor }"
                                            :title="item['standardLobor' + i]"
                                            @click="columnClick($event, i, 'standardLobor', '工作日出勤总时数', item)"
                                        >
                                            <div class="ellipse">
                                                {{ item['standardLobor' + i] }}
                                            </div>
                                        </div>

                                        <div
                                            class="data-row bottom-border right-border  data-column-text"
                                            :style="{ background: bgcolor }"
                                            :title="item['qtNum' + i]"
                                            @click="columnClick($event, i, 'qtNum', '加班总时数', item)"
                                        >
                                            <div class="ellipse">
                                                {{ item['qtNum' + i] }}
                                            </div>
                                        </div>

                                        <div
                                            class="data-row bottom-border right-border  sum-data-column-text"
                                            :style="{ background: bgcolor }"
                                            :title="item['standardLoborSum' + i]"
                                        >
                                            <div class="ellipse">
                                                {{ item['standardLoborSum' + i] }}
                                            </div>
                                        </div>

                                        <div
                                            class="data-row bottom-border right-border  data-column-text"
                                            :style="{ background: bgcolor }"
                                            :title="item['cost' + i]"
                                            @click="columnClick($event, i, 'cost', '成本', item)"
                                        >
                                            <div class="ellipse">
                                                {{ item['cost' + i] }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="tableData.length <= 0">
                        <a-empty />
                    </div>
                    <a-pagination
                        class="pagination"
                        :total="totalItems"
                        v-model="pageNumber"
                        showSizeChanger
                        showQuickJumper
                        :showTotal="showTotalFun"
                        @change="onChangePage"
                        @showSizeChange="showSizeChange"
                    />
                </div>

                <div
                    class="plan-card"
                    :style="style"
                    v-if="isCrde"
                    style="position: absolute;background-color: white;z-index:10;min-height: 110px"
                >
                    <div style="text-align:center;margin-top:4px">{{ crdeTitle }}</div>
                    <a-input-number style="width:80%;margin:7px 25px 10px 25px;" v-model="updateNum" :min="0" />
                    <div style="position: absolute;right:0;margin-right:10px">
                        <a-button type="danger" @click="cancelClick">
                            取消
                        </a-button>
                        <a-button type="primary" @click="saveClick">
                            保存
                        </a-button>
                    </div>
                </div>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import * as _ from 'lodash';
import moment from 'moment';
import {
    LaborcostYearPlanServiceProxy,
    CompanyServiceProxy,
    DepartmentServiceProxy,
    CreateOrUpdateLaborcostYearPlanInput,
} from '../../../shared/service-proxies';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';
import ImportExcelModel from './import-excel-model/import-excel-model';
import ModalHelper from '../../../shared/helpers/modal/modal-helper';
import { fileDownloadService } from '../../../shared/utils';

let _this;
export default {
    name: 'year-plan',
    mixins: [AppComponentBase],
    components: { ImportExcelModel },
    data() {
        return {
            zh_CN,
            filterText: undefined,
            //表格上方间隔
            defaultTop: 60,
            companyTreeList: [], //公司
            comPanyId: undefined, //公司id
            departmentId: undefined,
            //部门树
            DepTreeData: [],
            DepTree: [],
            tableData: [],
            // 卡片样式
            style: {
                left: '0px',
                top: '0px',
            },
            isCrde: false,
            crdeTitle: undefined,
            isOpen: false,
            totalItems: 0, //总数
            // 当前页码
            pageNumber: 1,
            // 共多少页
            totalPages: 1,
            // 条数显示范围
            pagerange: [1, 1],
            // 显示条数
            pageSizeOptions: ['10', '20', '30', '40'],
            request: { maxResultCount: 10, skipCount: 0, sorting: '' },
            currentYear: undefined,
            updateNum: undefined,
            updateDepId: undefined,
            updateMonth: undefined,
            updateName: undefined,
            bgcolor: undefined,
            lastColDom: undefined,
        };
    },
    created() {
        this._fileDownloadService = fileDownloadService;
        // 公司
        this._companyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
        //部门
        this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
        this._laborcostYearPlanServiceProxy = new LaborcostYearPlanServiceProxy(this.$apiUrl, this.$api);
        this.currentYear = moment();
    },
    mounted() {
        _this = this;
        // this.currentDate = moment();
        this.companyTreeInit();
        this.refresh();
    },
    methods: {
        searchTree(inputValue, treeNode) {
            return treeNode.data.props.title.includes(inputValue);
        },
        refresh() {
            this.pageNumber = 1;
            this.request.skipCount = 0;
            this.getData();
        },
        getData() {
            this.loading = true;
            this._laborcostYearPlanServiceProxy
                .getPaged(
                    this.currentYear.year(),
                    this.comPanyId,
                    this.departmentId,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                });
        },
        companyTreeInit() {
            this._companyConfigServiceProxy
                .getPaged(undefined, '', 100, 0)
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.companyList = res.items;
                    this.companyList.forEach((item) => {
                        if (item.parentId == 0) {
                            var obj = {
                                key: item.id,
                                value: item.id,
                                title: item.fullName,
                            };
                            this.companyTreeList.push(obj);
                        }
                    });
                    this.initCompanyTree(this.companyTreeList);
                });
        },
        /**
         * 初始化树形结构数据格式
         */
        initCompanyTree(list) {
            list.forEach((v) => {
                //遍历父级数据，加载子集=
                v.children = []; //创建空的子集
                this.companyList.forEach((m) => {
                    if (m.parentId == v.value) {
                        //根据主键查子集数据
                        var obj = {
                            key: m.id,
                            value: m.id,
                            title: m.fullName,
                        };
                        v.children.push(obj); //放进父级的集合中
                        this.initCompanyTree(v.children); //递归查询子集的集合
                    }
                });
                if (v.children.length == 0) {
                    //校验如何数据没有子集，删除子集参数
                    delete v.children;
                }
            });
        },
        handleChange(value) {
            this.comPanyId = value;
            this.getData();
            this.departmentId = undefined;
            this.getDepTreeData();
        },
        /**
         * 获取部门
         */
        getDepTreeData() {
            this.DepTreeData = [];
            this._departmentServiceProxy
                .getOneDepData(this.comPanyId)
                .then((res) => {
                    this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);

                    this.DepTree.forEach((item) => {
                        if (!item.parentId) {
                            this.DepTreeData.push(item);
                        }
                    });
                    this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        /**
         * 选择部门
         */
        SelectDepartmentTree(e) {
            this.departmentId = e;
        },
        /**
         * 分页事件
         */
        showTotalFun() {
            return this.l(
                'GridFooterDisplayText',
                this.pageNumber,
                this.totalPages,
                this.totalItems,
                this.pagerange[0],
                this.pagerange[1]
            );
        },
        /**
         * 分页
         */
        onChangePage(page, pageSize) {
            this.selectedRowKeys = [];
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
        },
        showSizeChange(current, size) {
            this.request.maxResultCount = size;
            this.refresh();
        },
        //年选择器改变
        yearChange(e) {
            this.isOpen = false;
            this.currentYear = e;

            this.refresh();
        },
        /**
         * 排序
         * @param pagination
         * @param filters
         * @param sorter
         */
        sorterChange(pagination, filters, sorter) {
            if (sorter.order) {
                this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
            } else {
                this.request.sorting = undefined;
            }
            this.refresh();
        },
        scrollTableBody(e) {
            this.$refs.tableHead.scrollLeft = e.target.scrollLeft;
        },
        columnClick(event, i, enName, cnName, item) {
            if (this.lastColDom) {
                this.lastColDom.style.backgroundColor = '#ffffff';
            }
            event.target.style.backgroundColor = 'red';
            this.lastColDom = event.target;

            _this.updateDepId = item.departmentId;
            _this.updateMonth = i;
            _this.updateName = enName;

            _this.updateNum = item[enName + i];
            _this.crdeTitle = i + '月,' + cnName;
            this.style.left = event.x - 270 + 'px';
            //(基础高度+表头高度)+((行号索引+1)*行高度)+偏移量
            this.style.top = event.y - 50 + 'px';
            this.isCrde = true;
        },
        cancelClick() {
            this.isCrde = false;
        },
        saveClick() {
            this.loading = true;
            let createEn = new CreateOrUpdateLaborcostYearPlanInput();
            console.log(this.updateName);
            createEn.depId = this.updateDepId;
            createEn.year = this.currentYear.year();
            createEn.month = this.updateMonth;
            createEn.name = this.updateName;
            createEn.num = this.updateNum;

            this._laborcostYearPlanServiceProxy
                .createOrUpdate(createEn)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.isCrde = false;
                    this.getData();
                });
        },
        /**
         * 导入
         * @constructor
         */
        Export() {
            ModalHelper.create(
                ImportExcelModel,
                {
                    year: this.currentYear.year(),
                    comPanyId: this.comPanyId, //公司id
                    departmentId: this.departmentId,
                },
                {
                    width: '800px',
                }
            ).subscribe((res) => {
                this.getData();
            });
        },
        /**
         * 导出为excel
         */
        exportToExcel() {
            this.loading = true;
            this._laborcostYearPlanServiceProxy
                .getToExcelFile(
                    this.currentYear.year(),
                    this.comPanyId,
                    this.departmentId,
                    this.filterText,
                    this.request.sorting,
                    99999,
                    0
                )
                .finally(() => {
                    this.loading = false;
                })
                .then((result) => {
                    this._fileDownloadService.downloadTempFile(result);
                });
        },
    },
};
</script>

<style scoped lang="less">
.pagination {
    margin: 10px auto;
    text-align: right;
}
@import 'year-plan.less';
</style>
