<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="!hasModelFooter">查看</span>
					<span v-else-if="id">{{ l('Edit') }}</span>
					<span v-else>{{ l('Create') }}</span>
				</div>
			</div>
			<div>
				<a-row>
					<a-col :span="3">
						<q-label required label="任务项"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input placeholder="任务项" v-model="entity.item" />
					</a-col>
					<a-col :span="4">
						<q-label required label="截止完成时间"> </q-label>
					</a-col>
					<a-col :span="8" class="black">
						<a-date-picker
							:disabled="id ? true : false"
							show-time
							format="YYYY-MM-DD HH:mm"
							valueFormat="YYYY-MM-DD HH:mm"
							:showTime="{ format: 'HH:mm' }"
							placeholder="截止完成时间"
							v-model="entity.cutoffDate"
							style="width: 100%"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="3">
						<q-label label="分类"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-select
							show-search
							:placeholder="l('分类')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.classify"
						>
							<a-select-option v-for="item in classifyList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="3">
						<q-label label="创建方式"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-select
							disabled
							show-search
							class="black"
							:placeholder="l('创建方式')"
							option-filter-prop="children"
							style="width: 100%"
							v-model="entity.creatWay"
						>
							<a-select-option v-for="item in creatWayList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="3">
						<q-label required label="负责人"> </q-label>
					</a-col>
					<a-col :span="9">
						<div
							class="group-input black"
							@click="hasClick('selectPrincipal')"
							:title="id ? '' : '点击选择负责人'"
						>
							<div
								class="group-text"
								:style="{
									cursor: id ? 'not-allowed' : 'pointer',
									'background-color': id ? '#F5F5F5' : '',
								}"
							>
								{{ principal ? principal : '' }}
							</div>
							<div class="group-icon">
								<a
									:style="{
										cursor: id ? 'not-allowed' : 'pointer',
									}"
								>
									<a-icon slot="addonAfter" type="user" />
								</a>
							</div>
						</div>
					</a-col>
					<a-col :span="3">
						<q-label label="发起人"> </q-label>
					</a-col>
					<a-col :span="9">
						{{ entity.creatorUserName ? entity.creatorUserName : '' }}
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="3">
						<q-label label="抄送人"> </q-label>
					</a-col>
					<a-col :span="21">
						<div
							class="carbonCopy"
							@click="hasClick('carbonCopy')"
							:title="id ? '' : '点击选择抄送人'"
							:style="{
								cursor: id ? 'not-allowed' : 'pointer',
								'background-color': id ? '#F5F5F5' : '',
							}"
						>
							<a-tag color="#2db7f5" v-for="item in carbonCopyList" :key="item.userId">
								{{ item.name }}
							</a-tag>
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="3">
						<q-label label="描述"> </q-label>
					</a-col>
					<a-col :span="21">
						<a-textarea placeholder="描述" v-model="entity.description" />
					</a-col>
				</a-row>
				<a-row v-if="id">
					<a-col :span="3">
						<q-label label="创建时间"> </q-label>
					</a-col>
					<a-col :span="9">
						{{ creationTime }}
					</a-col>
					<a-col :span="3">
						<q-label label="接收时间"> </q-label>
					</a-col>
					<a-col :span="9">
						{{ receiveTime }}
					</a-col>
				</a-row>
				<a-row v-if="id">
					<a-col :span="3">
						<q-label label="完成时间"> </q-label>
					</a-col>
					<a-col :span="9">
						{{ completeTime }}
					</a-col>
					<a-col :span="3">
						<q-label label="关闭时间"> </q-label>
					</a-col>
					<a-col :span="9">
						{{ closeTime }}
					</a-col>
				</a-row>
				<a-row v-if="hasWorkFlow">
					<a-col :span="3">
						<q-label label="发起人图片"> </q-label>
					</a-col>
					<a-col :span="21">
						<a-upload
							accept="image/*"
							name="InitiateImgList"
							:action="uploadPictureUrl"
							listType="picture-card"
							:fileList="InitiateImgList"
							:beforeUpload="beforeUpload"
							:headers="uploadHeaders"
							:disabled="!hasCreateUser"
							@preview="handlePreview"
							@change="uploadPictureChange($event, 'InitiateImgList')"
						>
							<div v-if="hasCreateUser">
								<a-icon type="plus" />
								<!-- {{ l('UploadProfilePicture') }} -->
								<div class="ant-upload-text">{{l('Upload_an_image')}}</div>
							</div>
						</a-upload>
					</a-col>
				</a-row>
				<a-row v-if="hasWorkFlow">
					<a-col :span="3">
						<q-label label="发起人附件"> </q-label>
					</a-col>
					<a-col :span="21">
						<a-upload
							listType="picture"
							name="InitiateIFile"
							:action="uploadPictureUrl"
							:fileList="InitiateIFile"
							:beforeUpload="beforeUploadFile"
							:headers="uploadHeaders"
							:disabled="!hasCreateUser"
							@change="uploadPictureChange($event, 'InitiateIFile')"
						>
							<div v-if="hasCreateUser">
								<!-- {{ l('UploadProfilePicture') }} -->
								<a-button>
									<a-icon type="upload" />
									上传附件
								</a-button>
							</div>
						</a-upload>
					</a-col>
				</a-row>
				<a-row v-if="hasWorkFlow">
					<a-col :span="3">
						<q-label label="执行人图片"> </q-label>
					</a-col>
					<a-col :span="21">
						<a-upload
							accept="image/*"
							name="PrincipalImg"
							:action="uploadPictureUrl"
							listType="picture-card"
							:fileList="PrincipalImg"
							:beforeUpload="beforeUpload"
							:headers="uploadHeaders"
							@preview="handlePreview"
							:disabled="!hasPrincipal"
							@change="uploadPictureChange($event, 'PrincipalImg')"
						>
							<div v-if="hasPrincipal">
								<a-icon type="plus" />
								<!-- {{ l('UploadProfilePicture') }} -->
								<div class="ant-upload-text">{{l('Upload_an_image')}}</div>
							</div>
						</a-upload>
					</a-col>
				</a-row>
				<a-row v-if="hasWorkFlow">
					<a-col :span="3">
						<q-label label="执行人附件"> </q-label>
					</a-col>
					<a-col :span="21">
						<a-upload
							listType="picture"
							name="PrincipalFile"
							:action="uploadPictureUrl"
							:fileList="PrincipalFile"
							:beforeUpload="beforeUploadFile"
							:headers="uploadHeaders"
							:disabled="!hasPrincipal"
							@change="uploadPictureChange($event, 'PrincipalFile')"
						>
							<div v-if="hasPrincipal">
								<!-- {{ l('UploadProfilePicture') }} -->
								<a-button>
									<a-icon type="upload" />
									上传附件
								</a-button>
							</div>
						</a-upload>
					</a-col>
				</a-row>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer" v-if="hasModelFooter">
				<a-button :disabled="saving" @click="close()" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :loading="saving" :type="'primary'" @click="save(1)" v-if="!id && !entity.url">
					<a-icon type="save" />
					提交
				</a-button>
				<a-button
					:loading="saving"
					:type="'primary'"
					@click="save(2)"
					v-if="id && this.entity.state != '2' && !entity.url"
				>
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
				<a-button
					:loading="saving"
					:type="'danger'"
					@click="updateTaskState(3)"
					v-if="hasNullify && !entity.url"
				>
					作废
				</a-button>
				<a-button
					:loading="saving"
					:type="'primary'"
					@click="updateTaskState(4)"
					v-if="hasRefuse && !entity.url"
				>
					拒绝
				</a-button>
				<a-button
					:loading="saving"
					:type="'primary'"
					@click="updateTaskState(5)"
					v-if="hasRefuse && !entity.url"
				>
					完成
				</a-button>
				<a-button
					:loading="saving"
					:type="'primary'"
					@click="updateTaskState(6)"
					v-if="hasClose && !entity.url"
				>
					关闭
				</a-button>
				<a-button :loading="saving" :type="'primary'" @click="openurl" v-if="entity.url">
					跳转
				</a-button>
			</div>
			<!--            图片预览model-->
			<div>
				<a-modal
					:visible="previewVisible"
					:width="viewWidth"
					:bodyStyle="viewStyle"
					:footer="null"
					@cancel="previewVisible = false"
				>
					<img alt="example" style="width: 100%;height: 100%" :src="previewImage" />
				</a-modal>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import {
	CarbonCopyList,
	CreateOrUpdateTaskBaseInfoInput,
	TaskBaseInfoEditDto,
	TaskBaseInfoServiceProxy,
} from '@/shared/service-proxies';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import SelectUserTag from '@/components/select-user-tag/select-user-tag';
import modalHelper from '@/shared/helpers/modal/modal-helper';
import { AppConsts } from '../../../../abpPro/AppConsts';
import ImageUtils from '@/shared/utils/image-utils';
import { Dic } from '@/shared/utils';
import moment from 'moment';
import { abpService } from '@/shared/abp';

export default {
	name: 'cerate-or-edit-baseinfo',
	mixins: [ModalComponentBase],
	components: { SelectUserTag },
	data() {
		return {
			zh_CN,
			id: undefined,
			// 上传的接口地址
			uploadPictureUrl: AppConsts.remoteServiceBaseUrl + '/Profile/UploadFilePicture',
			uploadHeaders: undefined,
			maxAppPictureBytesValue: AppConsts.maxProFileMb,
			previewImage: undefined,
			filePath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/Task/${abp.session.tenantId}/`,
			//发起人图片
			InitiateImgList: [],
			//发起人附件
			InitiateIFile: [],
			//执行图片
			PrincipalImg: [],
			//执行附件
			PrincipalFile: [],
			previewVisible: false,
			viewStyle: undefined,
			viewWidth: 650,
			entity: new TaskBaseInfoEditDto(),
			carbonCopyList: [],
			classifyList: [],
			creatWayList: [],
			principal: undefined,
		};
	},

	created() {
		this.fullData();
		this._taskBaseInfoServiceProxy = new TaskBaseInfoServiceProxy(this.$apiUrl, this.$api);
		this.uploadHeaders = {
			Authorization: 'Bearer ' + abp.auth.getToken(),
			type: 'task',
		};
	},
	computed: {
		hasWorkFlow() {
			if (this.entity.creatWay == '2' && this.entity.classify == '01') {
				return false;
			}
			return true;
		},
		hasCreateUser() {
			if (
				this.entity.state == '4' ||
				this.entity.state == '5' ||
				this.entity.state == '6' ||
				this.entity.state == '2'
			) {
				return false;
			}
			if (!this.id) {
				return true;
			}
			if (this.entity.creatorUserId == abpService.abp.session.userId) {
				return true;
			}
			return false;
		},
		hasPrincipal() {
			if (
				this.entity.state == '4' ||
				this.entity.state == '5' ||
				this.entity.state == '6' ||
				this.entity.state == '2'
			) {
				return false;
			}
			if (this.entity.principal == abpService.abp.userInfo.empId && this.id) {
				return true;
			}
			return false;
		},
		hasModelFooter() {
			let state = this.entity.state;
			if (state == '6') {
				return false;
			}
			// if(state=="2"){
			//     return  false;
			// }
			if (state == '4') {
				return false;
			}
			if (state == '5') {
				return false;
			}
			return true;
		},
		creationTime() {
			if (this.id) {
				return moment(this.entity.creationTime).format('YYYY-MM-DD hh:mm');
			} else {
				this.entity.creationTime = moment();
				return moment().format('YYYY-MM-DD hh:mm');
			}
		},
		receiveTime() {
			if (this.entity.receiveTime) {
				return moment(this.entity.receiveTime).format('YYYY-MM-DD hh:mm');
			} else {
				return '';
			}
		},
		completeTime() {
			if (this.entity.completeTime) {
				return moment(this.entity.completeTime).format('YYYY-MM-DD hh:mm');
			} else {
				return '';
			}
		},
		closeTime() {
			if (this.entity.closeTime) {
				return moment(this.entity.closeTime).format('YYYY-MM-DD hh:mm');
			} else {
				return '';
			}
		},
		hasNullify() {
			if (
				this.id &&
				this.entity.state != '4' &&
				this.entity.state != '2' &&
				this.entity.state != '6' &&
				this.entity.creatorUserId == abpService.abp.session.userId
			) {
				return true;
			} else {
				return false;
			}
		},
		hasRefuse() {
			if (
				this.id &&
				this.entity.principal == abpService.abp.userInfo.empId &&
				this.entity.state != '4' &&
				this.entity.state != '5' &&
				this.entity.state != '6' &&
				this.entity.state != '2'
			) {
				return true;
			} else {
				return false;
			}
		},
		hasClose() {
			if (this.id && this.entity.creatorUserId == abpService.abp.session.userId && this.entity.state == '2') {
				return true;
			} else {
				return false;
			}
		},
	},
	async mounted() {
		this.classifyList = await Dic.getInstance().getDicAsync('QYB_TaskClassify');
		this.creatWayList = await Dic.getInstance().getDicAsync('QYB_TaskCreatWay');
		if (this.id) {
			this.getData();
		} else {
			this.entity.creatorUserId = abpService.abp.userInfo.userId;
			this.entity.creatorUserName = abpService.abp.userInfo.realName;
			//默认手动创建
			this.entity.creatWay = '1';
		}
	},
	methods: {
		hasClick(type) {
			if (!this.id) {
				switch (type) {
					case 'carbonCopy':
						this.carbonCopy();
						break;
					case 'selectPrincipal':
						this.selectPrincipal();
						break;
				}
			}
		},
		getData() {
			this.loading = true;
			this._taskBaseInfoServiceProxy
				.getForEdit(this.id, '2')
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res.taskBaseInfo;
					this.carbonCopyList = this.entity.carbonCopyList;
					this.principal = this.entity.principalName;
					//发起人图片
					if (this.entity.initiateImg) {
						let imgs = this.entity.initiateImg.split(',');
						imgs.forEach((item, index) => {
							this.InitiateImgList = [
								...this.InitiateImgList,
								{
									uid: index,
									name: item,
									status: 'done',
									url: this.filePath + item,
									response: {
										result: {
											fileName: item,
										},
									},
								},
							];
						});
					}
					//发起人附件
					if (this.entity.initiateIFile) {
						let files = this.entity.initiateIFile.split(',');
						files.forEach((item, index) => {
							this.InitiateIFile = [
								...this.InitiateIFile,
								{
									uid: index,
									name: item,
									status: 'done',
									url: this.filePath + item,
									response: {
										result: {
											fileName: item,
										},
									},
								},
							];
						});
					}
					//执行人图片
					if (this.entity.principalImg) {
						let pimgs = this.entity.principalImg.split(',');
						pimgs.forEach((item, index) => {
							this.PrincipalImg = [
								...this.PrincipalImg,
								{
									uid: index,
									name: item,
									status: 'done',
									url: this.filePath + item,
									response: {
										result: {
											fileName: item,
										},
									},
								},
							];
						});
					}
					if (this.entity.principalFile) {
						let pfiles = this.entity.principalFile.split(',');
						pfiles.forEach((item, index) => {
							this.PrincipalFile = [
								...this.PrincipalFile,
								{
									uid: index,
									name: item,
									status: 'done',
									url: this.filePath + item,
									response: {
										result: {
											fileName: item,
										},
									},
								},
							];
						});
					}
				});
		},
		/**
		 * 负责人
		 */
		selectPrincipal() {
			modalHelper
				.create(
					SelectUserTag,
					{
						_selectedRowKeys: [this.entity.principal],
					},
					{
						width: '900px',
						closable: false,
					}
				)
				.subscribe((res) => {
					if (res) {
						this.entity.principal = res.id;
						this.entity.principalName = res.realName;
						this.principal = res.realName;
					}
				});
		},
		/**
		 * 上传图片
		 */
		beforeUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
			if (!isJPG) {
				abp.message.error(this.l('OnlySupportPictureFile'));
			}
			const isLtXM = file.size / 1024 / 1024 < this.maxAppPictureBytesValue;
			if (!isLtXM) {
				abp.message.error(this.l('ProfilePicture_Warn_SizeLimit', this.maxAppPictureBytesValue));
			}
			return isJPG && isLtXM;
		},
		/**
		 * 上传文件
		 */
		beforeUploadFile(file) {
			const isLtXM = file.size / 1024 / 1024 < this.maxAppPictureBytesValue;
			if (!isLtXM) {
				abp.message.error(this.l('ProfilePicture_Warn_SizeLimit', this.maxAppPictureBytesValue));
			}
			return isLtXM;
		},
		/**
		 * 预览图片
		 * @param file 文件
		 * @returns {Promise<void>}
		 */
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await ImageUtils.getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
			this.viewStyle = { width: '650px', height: '500px' };
			// ImageUtils.showFullImage(this.previewImage);
		},
		/**
		 * 上传图片回调  状态 "status: "done"  status: "uploading"  status: "removed"
		 */
		uploadPictureChange({ file, fileList }, type) {
			if (file.status === 'done') {
				// this.entity.fileName = file.response.result.fileName;
			} else if (file.status === 'removed') {
				// this.entity.fileName = undefined;
			}
			if (type == 'InitiateImgList') {
				this.InitiateImgList = fileList;
			} else if (type == 'InitiateIFile') {
				this.InitiateIFile = fileList;
			} else if (type == 'PrincipalImg') {
				this.PrincipalImg = fileList;
			} else if (type == 'PrincipalFile') {
				this.PrincipalFile = fileList;
			}
		},
		/*
		 *抄送人
		 */
		carbonCopy() {
			let list = [];
			if (this.carbonCopyList.length > 0) {
				list = this.carbonCopyList.map((item) => {
					return { id: item.empId, realName: item.name };
				});
			}
			modalHelper
				.create(
					SelectUserTag,
					{
						_selectedRows: list,
						_selectedRowKeys: _.map(list, 'id'),
						isMultiple: true,
					},
					{
						width: '900px',
						closable: false,
					}
				)
				.subscribe((res) => {
					if (res) {
						this.carbonCopyList = res.map((item) => {
							let entity = new CarbonCopyList();
							entity.empId = item.id;
							entity.name = item.realName;
							return entity;
						});
					}
				});
		},
		/**
		 * 更新任务状态
		 * @param state
		 */
		updateTaskState(state) {
			// this.loading = true;
			// this._taskBaseInfoServiceProxy.updateTaskState(this.id, state)
			//     .finally(() => {
			//         this.loading = false
			//     })
			//     .then(res => {
			//         this.$notification['success']({
			//             message: this.l('SavedSuccessfully'),
			//         });
			//         this.success(true);
			//     });
			this.save(state);
		},
		openurl() {
			if (this.entity.url) {
				if (this.entity.isWrokflow) {
					if (this.entity.url.indexOf('?') != -1) {
						window.open(this.entity.url + '&&abpguid=' + abpService.abp.userInfo.ssoGuid);
					} else {
						window.open(this.entity.url + '?abpguid=' + abpService.abp.userInfo.ssoGuid);
					}
				}
			}
		},
		save(state) {
			if (!this.id && this.entity.classify == '01') {
				return abp.message.warn('不能添加分类为审批流的任务');
			}
			if (!this.entity.item) {
				return abp.message.warn('任务项不能为空');
			}
			if (!this.entity.cutoffDate) {
				return abp.message.warn('截止完成时间不能为空');
			}
			if (!this.entity.principal) {
				return abp.message.warn('负责人不能为空');
			}
			this.entity.cutoffDate = moment(this.entity.cutoffDate);
			if (this.InitiateImgList.length > 0) {
				let imgs = this.InitiateImgList.map((item) => {
					return item.response.result.fileName;
				});
				this.entity.initiateImg = _.join(imgs, ',');
			} else {
				this.entity.initiateImg = '';
			}
			if (this.InitiateIFile.length > 0) {
				let files = this.InitiateIFile.map((item) => {
					return item.response.result.fileName;
				});
				this.entity.initiateIFile = _.join(files, ',');
			} else {
				this.entity.initiateIFile = '';
			}
			if (this.PrincipalImg.length > 0) {
				let pimgs = this.PrincipalImg.map((item) => {
					return item.response.result.fileName;
				});
				this.entity.principalImg = _.join(pimgs, ',');
			} else {
				this.entity.principalImg = '';
			}

			if (this.PrincipalFile.length > 0) {
				let pfiles = this.PrincipalFile.map((item) => {
					return item.response.result.fileName;
				});
				this.entity.principalFile = _.join(pfiles, ',');
			} else {
				this.entity.principalFile = '';
			}
			this.entity.carbonCopyList = this.carbonCopyList;
			let input = new CreateOrUpdateTaskBaseInfoInput();
			input.taskBaseInfo = this.entity;
			input.state = state;
			this.loading = true;
			this._taskBaseInfoServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped lang="less">
.carbonCopy {
	border: 1px solid #d9d9d9;
	min-height: 35px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
}

.carbonCopy::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}

.carbonCopy::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.carbonCopy::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}

.ellipsis {
	width: 100%;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 1;
	overflow: hidden;
}

/deep/ .ant-modal-close-x {
	width: 30px;
	height: 45px;
	line-height: 30px;
}

.group-input {
	width: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	height: 32px;
	margin-top: 3px;
	display: flex;
}

.group-text {
	flex: 5;
	text-align: left;
	padding-left: 9px;
	line-height: 32px;
	cursor: pointer;
}

.group-icon {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.group-icon i {
	color: #808080;
	background-color: #f6f4f4;
	padding: 8px 20px;
	border-radius: 0px 4px 4px 0px;
	border-left: 1px solid #d9d9d9;
}
/deep/ .ant-calendar-picker .ant-input[disabled] {
	color: rgba(0, 0, 0, 0.65);
}
.black {
	color: rgba(0, 0, 0, 0.65);
}
</style>
