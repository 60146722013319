<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">{{l('CreateNewTenant')}}</div>
        </div>
        <a-row>
            <a-col :span="4">
                <!-- 租户名称 -->
                <a-form-item required :label="l('TenancyName')">
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-input
                        :placeholder="l('TenancyName')"
                        v-model="entity.tenancyName"
                />
            </a-col>
            <a-col :span="4">
                <!-- 租户显示名称 -->
                <a-form-item required :label="l('DisplayTenancyName')">
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-input
                        :placeholder="l('Name')"
                        v-model="entity.name"
                />
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="4">
                <!-- 管理员邮箱 -->
                <a-form-item required :label="l('AdminEmailAddress')">
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-input
                        :placeholder="l('EmailAddress')"
                        v-model="entity.adminEmailAddress"
                />
            </a-col>
            <a-col :span="4">
                <!-- 版本 -->
                <a-form-item :label="l('Edition')" has-feedback>
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <edition-combo
                        :placeholder="l('PleaseSelect')"
                        :selectedEdition="selectedEdition"
                        @selectedEditionChange="selectedEditionChange($event)"
                ></edition-combo>
            </a-col>
        </a-row>

        <a-row>
            <a-col :span="4">
                <!-- 企业帮服务地址 -->
                <a-form-item :label="l('API地址')">
                </a-form-item>
            </a-col>
            <a-col :span="20">
                <a-input placeholder="API地址" v-model="entity.lpaServicePath"/>
            </a-col>
        </a-row>
<!--        <a-row>-->
<!--            <a-col :span="5">-->
<!--                &lt;!&ndash; 力软服务器地址 &ndash;&gt;-->
<!--                <a-form-item :label="l('力软服务器地址')">-->
<!--                </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :span="19">-->
<!--                <a-input placeholder="力软服务器地址" v-model="entity.lrServicePath"/>-->
<!--            </a-col>-->
<!--        </a-row>-->
<!--        <a-row>-->
<!--            <a-col :span="5">-->
<!--                &lt;!&ndash; 力软站点地址 &ndash;&gt;-->
<!--                <a-form-item :label="l('力软站点地址')">-->
<!--                </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :span="19">-->
<!--                <a-input placeholder="力软站点地址" v-model="entity.lrSitePath"/>-->
<!--            </a-col>-->
<!--        </a-row>-->
<!--        <a-row>-->
<!--            <a-col :span="5">-->
<!--                &lt;!&ndash; 沃德服务器地址 &ndash;&gt;-->
<!--                <a-form-item :label="l('沃德服务器地址')">-->
<!--                </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :span="19">-->
<!--                <a-input placeholder="沃德服务器地址" v-model="entity.wdsfServicePath"/>-->
<!--            </a-col>-->
<!--        </a-row>-->
        <a-row>
            <a-col :span="8">
                <!-- UseHostDatabase -->
                <a-checkbox
                        @change="UseHostDatabaseChange"
                        :checked="UseHostDatabasechecked"
                >{{l("UseHostDatabase")}}
                </a-checkbox>
            </a-col>
            <a-col :span="8">
                <!-- 密码 -->
                <a-checkbox
                        @change="passwordChange"
                        :checked="passwordchecked"
                >{{l("UseDefaultPassword","123qwe")}}
                </a-checkbox>
            </a-col>
        </a-row>
        <a-row v-if="!UseHostDatabasechecked">
            <a-col :span="4">
                <a-form-item :label="l('DatabaseConnectionString')">
                </a-form-item>
            </a-col>
            <a-col :span="20">
                <a-input
                        :placeholder="l('DatabaseConnectionString')+l('Optional')"
                        v-model="entity.connectionString"
                />
            </a-col>
        </a-row>
        <a-row v-if="!passwordchecked">
            <a-col :span="4">
                <a-form-item :label="l('Password')" has-feedback>
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-input
                        :placeholder="l('Password')"
                        type="password"
                        v-model="entity.adminPassword"

                />
            </a-col>
            <a-col :span="4">
                <a-form-item :label="l('ConfirmPassword')" v-if="!passwordchecked" has-feedback>
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-input
                        :placeholder="l('ConfirmPassword')"
                        type="password"
                        v-model="confirmPwd"
                />
            </a-col>
        </a-row>
        <a-row>
            <a-col>
                <!-- IsUnlimited -->
                <a-checkbox
                        @change="checkedChange('IsUnlimitedchecked', $event)"
                        v-if="isShowIsUnlimited"
                        :checked="IsUnlimitedchecked"
                >{{l("IsUnlimited")}}
                </a-checkbox>
            </a-col>
        </a-row>
        <a-row v-if="!IsUnlimitedchecked && isShowIsUnlimited">
            <a-col :span="4">
                <a-form-item
                        :label="l('SubscriptionEndDateUtc')"
                >
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-date-picker
                        @change="subscriptionEndDateUtconChange"
                        placeholder="请选择日期"
                        v-model="entity.subscriptionEndUtc"
                />
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <!-- 是否试用 -->
                <a-checkbox
                        @change="checkedChange('isInTrialPeriod', $event)"
                        :disabled="IsInTrialPerioddisabled"
                        v-model="entity.isInTrialPeriod"
                >{{l("IsInTrialPeriod")}}
                </a-checkbox>
            </a-col>
            <a-col :span="8">
                <!-- 下次登录需要修改密码 -->
                <a-checkbox
                        @change="checkedChange('shouldChangePasswordOnNextLogin', $event)"
                        v-model="entity.shouldChangePasswordOnNextLogin"
                >{{l("ShouldChangePasswordOnNextLogin")}}
                </a-checkbox>
            </a-col>
            <a-col :span="8">
                <!-- 发送激活邮件 -->
                <a-checkbox
                        @change="checkedChange('sendActivationEmail', $event)"
                        v-model="entity.sendActivationEmail"

                >{{l("SendActivationEmail")}}
                </a-checkbox>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">
                <!-- 是否激活 -->
                <a-checkbox @change="checkedChange('isActive', $event)" v-model="entity.isActive">{{l("IsActive")}}
                </a-checkbox>
            </a-col>
            <a-col :span="8">
                <!-- 是否默认 -->
                <a-checkbox @change="checkedChange('isDefault', $event)" v-model="entity.isDefault">{{l("是否默认")}}
                </a-checkbox>
            </a-col>
        </a-row>
        <a-form-item class="btn--container">
            <a-button type="button" @click="close()">{{ l("Cancel") }}</a-button>
            <a-button type="primary" @click="save">{{ l('Save') }}</a-button>
        </a-form-item>
    </a-spin>
</template>

<script>
    import {ModalComponentBase} from "@/shared/component-base";
    import EditionCombo from "../../shared/edition-combo/edition-combo.vue";
    import {
        TenantServiceProxy,
        TenantListDto,
        SubscribableEditionComboboxItemDto,
        EntityDtoOfInt64,
        NameValueDto,
        CommonLookupServiceProxy,
        CommonLookupFindUsersInput
    } from "@/shared/service-proxies";
    import {CreateTenantInput} from "@/shared";

    export default {
        name: "create-tenant-component",
        mixins: [ModalComponentBase],
        components: {
            EditionCombo
        },
        data() {
            return {
                confirmDirty: false,
                autoCompleteResult: [],
                UseHostDatabasechecked: true,
                passwordchecked: true,
                // 版本
                selectedEdition: {
                    value: 0
                },
                // 是否在试用期
                isInTrialPeriod: false,
                IsInTrialPerioddisabled: false,
                // 下次登录需要修改密码
                shouldChangePasswordOnNextLogin: false,
                // 发送激活邮件
                sendActivationEmail: false,
                // 是否激活
                isActive: true,
                IsUnlimitedchecked: false,
                // IsUnlimited
                isShowIsUnlimited: false,
                // 版本号
                editionId: 0,
                entity: new CreateTenantInput(),
                confirmPwd: undefined,
                spinning: false
            };
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: "register"});
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this._tenantService = new TenantServiceProxy(this.$apiUrl, this.$api);
			this.entity.isActive = true;
        },
        methods: {
            /**
             * UseHostDatabaseChange
             */
            UseHostDatabaseChange(e) {
                this.UseHostDatabasechecked = eval(`${e.target.checked}`.toLowerCase());
            },
            /**
             * password
             */
            passwordChange(e) {
                this.passwordchecked = eval(`${e.target.checked}`.toLowerCase());
            },
            /**
             * 验证密码
             */
            validateToNextPassword(rule, value, callback) {
                const form = this.form;
                if (value && this.confirmDirty) {
                    form.validateFields(["confirm"], {force: true});
                }
                callback();
            },
            validateEqual(rule, value, callback) {
                const form = this.form;
                if (value && value !== form.getFieldValue("adminPassword")) {
                    callback(this.l("PasswordInconsistent"));
                } else {
                    callback();
                }
            },
            /**
             * 版本选择
             */
            selectedEditionChange(e) {
                if (e && e.value) {
                    this.IsInTrialPerioddisabled = true;
                    this.isShowIsUnlimited = true;
                    // this.editionId = parseInt(e.value);
                    this.entity.editionId = parseInt(e.value);
                } else {
                    this.IsInTrialPerioddisabled = false;
                    this.isShowIsUnlimited = false;
                    // this.editionId = null;
                    this.entity.editionId = undefined;
                }
            },
            /**
             * 是否试用
             * 下次登录需要修改密码
             * 发送激活邮
             * 是否激活
             */
            checkedChange(type, e) {
                this[`${type}`] = eval(`${e.target.checked}`.toLowerCase());
            },
            /**
             * 订阅结束日期
             */
            subscriptionEndDateUtconChange(date, dateString) {
            },
            /**
             * 提交表单
             */
            save(e) {
                if (!this.entity.tenancyName) {
                    abp.message.warn("租户名称不能为空");
                    return;
                }
                if (!this.entity.name) {
                    abp.message.warn("租户显示名称不能为空");
                    return;
                }
                if (!this.entity.adminEmailAddress) {
                    abp.message.warn("请输入管理员名称");
                    return;
                }
                if (!this.passwordchecked) {
                    if (this.entity.adminPassword != this.confirmPwd) {
                        abp.message.warn("两次密码出入不一致,请重新输入");
                        return;
                    }
                }
                this.entity.subscriptionEndUtc =
                    !this.entity.editionId || this.IsUnlimitedchecked
                        ? null
                        : this.entity.subscriptionEndUtc;
                this.spinning = true;
                this._tenantService
                    .createWithCopyMenu(this.entity)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(res => {
                        // this.notify.success(this.l('SavedSuccessfully'));
                        this.$notification["success"]({
                            message: this.l("SavedSuccessfully")
                        });
                        this.success(true);
                    })
                    .catch(err => {
                        console.log(err);
                    });
            }
        }
    };
</script>

<style lang="less" scoped>
    /deep/ .btn--container .ant-form-item-children {
        display: block;
        margin: 10px auto;
        text-align: center;
    }
</style>
