<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<span>查看分析</span>
				</div>
			</div>
			<div>
				<a-row>
					<a-col :span="4">
						<a-date-picker
							mode="year"
							format="YYYY"
							:allowClear="false"
							:value="currentYear"
							@openChange="isOpen = true"
							:open="isOpen"
							style="width: 95%"
							@panelChange="yearChange"
						/>
					</a-col>
					<a-col :span="6" offset="1">
						<a-tree-select
							@change="companyChange"
							allowClear
							:placeholder="l('Company')"
							style="width: 100%"
							tree-default-expand-all
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="companyTreeList"
							v-model="companyId"
						/>
					</a-col>
					<a-col :span="6" offset="1">
						<a-tree-select
							v-model="departmentId"
							style="width: 100%"
							show-search
							:filterTreeNode="searchTree"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="DepTreeData"
							:placeholder="l('Department')"
							tree-default-expand-all
							allow-clear
							@change="SelectDepartmentTree"
						>
						</a-tree-select>
					</a-col>
				</a-row>
				<div>
					<a-tabs default-active-key="1" v-if="analysisList">
						<a-tab-pane key="1" tab="发出完成率">
							<complete-calc :analysis="analysisList"></complete-calc>
						</a-tab-pane>
						<a-tab-pane key="2" tab="执行完成率">
							<execute-calc :analysis="analysisList"></execute-calc>
						</a-tab-pane>
						<a-tab-pane key="3" tab="主题完成率">
							<task-type-calc :analysis="analysisList"></task-type-calc>
						</a-tab-pane>
					</a-tabs>
					<a-skeleton active v-else />
				</div>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	TaskBaseInfoServiceProxy,
} from '../../../../shared/service-proxies';
import DepTreeUntils from '../../../organization/department/dep-tree-untils/dep-tree-untils';
import abpService from '../../../../shared/abp/abp.service';
import moment from 'moment';
import CompleteCalc from './calc/complete-calc';
import ExecuteCalc from './calc/execute-calc';
import TaskTypeCalc from './calc/task-type-calc';

export default {
	name: 'task-analysis',
	mixins: [ModalComponentBase],
	components: { CompleteCalc, ExecuteCalc, TaskTypeCalc },
	data() {
		return {
			zh_CN,
			isOpen: false,
			currentYear: undefined,
			companyId: undefined,
			departmentId: undefined,
			companyTreeList: [],
			DepTreeData: [],
			analysisList: undefined,
		};
	},
	created() {
		this.fullData();
		this._companyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._taskBaseInfoServiceProxy = new TaskBaseInfoServiceProxy(this.$apiUrl, this.$api);
		this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.currentYear = moment();
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.companyId = abpService.abp.userInfo.companyId;
			this.companyChange(this.companyId);
		} else {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.analysisList = undefined;
			this.loading = true;
			this._taskBaseInfoServiceProxy
				.getTaskAnalysis(
					this.currentYear ? moment(this.currentYear).year() : undefined,
					this.companyId,
					this.departmentId
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.analysisList = res;
				});
		},
		//年选择器改变
		yearChange(e) {
			this.isOpen = false;
			this.currentYear = e;
			this.getData();
		},
		companyTreeInit() {
			this._companyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.annloading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.companyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.companyId = value;
			if (!this.companyId) {
				this.departmentId = undefined;
				this.DepTreeData = [];
			} else {
				this.getDepTreeData();
			}
			if (abpService.abp.userInfo.department) {
				this.departmentId = abpService.abp.userInfo.department;
			}
			this.getData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.departmentId = undefined;
			this.DepTreeData = [];
			this._departmentServiceProxy
				.getDepTreeData(this.companyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.annloading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
			this.getData();
		},
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
	},
};
</script>

<style scoped></style>
