<template>
	<div>
		<!-- 标题 -->
		<div class="modal-header header" ref="moveModal">
			<div class="modal-title">
				<span>选择员工</span>
			</div>
			<div>
				<a-button :disabled="saving" @click="close()" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :loading="saving" :type="'primary'" @click="handleSubmit()" style="margin-right: 20px;">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</div>
		<a-row>
			<!-- <a-col :span="7">
				<a-spin :spinning="leftSpinning">
					<a-row :gutter="8">
						<a-col :span="24" style="display: flex; align-items: center; margin-top: 10px">
							<div>
								<a-icon type="share-alt" />
								{{ l('Company') }}
							</div>
							<a-tree-select
								style="flex: 1; margin:0 10px"
								allowClear
								@change="handleChange"
								placeholder="公司"
								tree-default-expand-all
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="companyTreeList"
								v-model="companyId"
							/>
						</a-col>
                        <a-col :span="24" style="margin: 10px 0;">
                            <a-checkbox v-model="isVirtual" @change="handleChange(companyId)">
                                包含虚拟部门
                            </a-checkbox>
                        </a-col>
					</a-row>
					<a-row class="tree">
						<a-tree
							:treeData="treeData"
							showIcon
							@select="onSelect"
							:expanded-keys="expandedKeys"
							@expand="onExpand"
							:selectedKeys="SelectKey"
						>
							<a-icon type="folder" slot="folder" />
							<a-icon type="file" slot="file" />
							<a-icon type="folder-open" slot="folder-open" />
							<template slot="custom" slot-scope="{ expanded }">
								<a-icon :type="expanded ? 'folder-open' : 'folder'" />
							</template>
						</a-tree>
					</a-row>
				</a-spin>
			</a-col> -->
			<a-col :span="24">
				<!-- usePagination开启分页 -->
				<!-- loading绑定引入页面的蒙层加载变量 -->
				<q-container use-pagination :loading="loading" useModal>
					<!-- 使用具名插槽 #toolbar -->
					<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
					<template #toolbar>
						<a-input-search
							name="filterText"
							:placeholder="l('SearchWithThreeDot')"
							@search="search"
							enterButton
                            style="margin-bottom: 10px"
							v-model="filterText"
						/>
                        <div style="display: flex;">
                            <div class="my-md">
                                <p v-if="mergeRows.length <= 0" class="NotR">
                                    已选人员
                                </p>
                                <a-tag
                                    :closable="false"
                                    v-for="(item) in mergeRows"
                                    :key="item.composeId"
                                    :color="item.isPluralismsDep?'red':''"
                                    v-else
                                >
                                    {{ item.realName }}
                                    {{ item.isPluralismsDep ? "(兼职)":"" }}
                                </a-tag>
                            </div>
                            <div class="counts">已选{{ mergeRows.length }}人</div>
                        </div>
					</template>
					<!-- 使用具名插槽slot="table" -->
					<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
					<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

					<!-- **************行选择*************** -->
					<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
					<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
					<template slot="table" slot-scope="scopeParam">
						<a-table
							ref="table"
							size="small"
							:pagination="false"
							:columns="columns"
							:rowKey="(tableDatas) => tableDatas.id"
							:scroll="{ y: scopeParam.scroll.y }"
							:dataSource="data"
							:customRow="customRow"
							:rowSelection="rowSelection"
						>
                            <template slot-scope="text, record" slot="isPluralismsDep">
                                <span>
                                    {{ record.isPluralismsDep ? "√" : "" }}
                                </span>
                            </template>
						</a-table>
					</template>
                    <!-- 使用具名插槽 #page -->
                    <template #page>
                        <!-- 分页组件只需复制以下代码 -->
                        <!-- 处理逻辑和变量都在 app-component-base混入文件 -->
                        <a-pagination
                            v-model="pageNumber"
                            :total="totalItems"
                            showSizeChanger
                            size="small"
                            :defaultPageSize="request.maxResultCount"
                            :pageSizeOptions="pageSizeOptions"
                            :showTotal="() => showTotal"
                            @change="onPageChange"
                            @showSizeChange="showPageSizeChange"
                        />
                    </template>
				</q-container>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import { unionBy, take } from 'lodash';
import abpService from '@/shared/abp/abp.service';
import DepTreeUntils from '@/app/organization/department/dep-tree-untils/dep-tree-untils';
import {
    CompanyServiceProxy,
    OrganizationUnitServiceProxy,
    UserDepPluralismServiceProxy,
} from "@/shared/service-proxies/service-proxies";

export default {
	name: 'select-user-by-role',
	mixins: [ModalComponentBase],
	data() {
		return {
            leftSpinning: false,
			companyId: undefined,
            isVirtual: false,
			companyList: [],
			SelectKey: [],
			expandedKeys: [],
			treeData: [],
			companyTreeList: [],
			_ouData: {},
			// 用户表格
			columns: [
                // {
                //     title: '公司',
                //     dataIndex: 'company',
                //     sorter: false,
                //     width: 100,
                //     ellipsis: true,
                //     align: 'center',
                //     scopedSlots: { customRender: 'company' },
                // },
                // {
                //     title: '部门',
                //     dataIndex: 'department',
                //     sorter: false,
                //     width: 100,
                //     ellipsis: true,
                //     align: 'center',
                //     scopedSlots: { customRender: 'department' },
                // },
                {
                    title: this.l('JobNumber'),
                    dataIndex: 'jobNumber',
                    sorter: false,
                    align: 'center',
                    scopedSlots: { customRender: 'jobNumber' },
                },
				{
					title: this.l('RealName'),
					dataIndex: 'realName',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
                // {
                //     title: '兼职部门',
                //     dataIndex: 'isPluralismsDep',
                //     align: 'center',
                //     ellipsis: true,
                //     width: 80,
                //     scopedSlots: { customRender: 'isPluralismsDep' },
                // },
			],
			//多选
			isMultiple: false,
            selectedRowField: "id",
			// 用户数据
			data: [],
			depId: undefined,
			_selectedRowKeys: [],
			_selectedRows: [],
			source:undefined
		};
	},
	computed: {
		mergeRows() {
            return [...unionBy(this.selectedRows, this._selectedRows, this.selectedRowField)];
		},
	},
	created() {
		this.fullData();
		this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
        this._userDepPluralismServiceProxy = new UserDepPluralismServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.companyTreeInit();	 
		if(this.source=='VueSendProcess')
		{ // sorce 表示从vue发起流程那边引用的弹窗 ， 选的人只能选自己相关的部门与兼职部门
			this.getData();
		}
		else
		{  // 从角色那里引用的弹窗，保持原有的写法
			if (abpService.abp.userInfo.companyId) {
				this.companyId = abpService.abp.userInfo.companyId;
				this.handleChange(this.companyId);
			} else {
				this.getData();
			}
		}
		
	},
	methods: {
        search() {
            this.getData()
        },
		companyTreeInit() {
            this.leftSpinning = true;
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.leftSpinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.companyId = value;
            if(!value) {
                this.depId = undefined;
            }
			this.getData();
			this.getCompanyData();
		},
		getCompanyData() {
			this.leftSpinning = true;
			this._organizationUnitServiceProxy
				.getAllOrganizationUnitList(this.companyId, this.isVirtual)
				.finally(() => {
					this.leftSpinning = false;
				})
				.then((result) => {
					this.treeData = [];
					this._ouData = DepTreeUntils.getInstance().InitTreeData(result.items);
					this._ouData.forEach((item) => {
						if (!item.parentId) {
							this.treeData.push(item);
						}
					});
					this.treeData = DepTreeUntils.getInstance().ArrMapTree(this._ouData, this.treeData, this.isVirtual);
					if (result.items.length > 0) {
						// this.onSelect([result.items[0].id.toString()], undefined);
					} else {
						this.data = [];
					}
				});
		},
		/**
		 * 选中item
		 */
		onSelect(selectedKeys, info) {
			this.SelectKey = selectedKeys;
			this.depId = selectedKeys.length > 0 ? parseInt(selectedKeys[0]) : undefined;
			this.getData();
		},
		/**
		 * 展开
		 */
		onExpand(expandedKeys, record) {
			this.expandedKeys = expandedKeys;
		},
		/**
		 * 拉取数据
		 */
		getData() {
			this.loading = true;
			this._userDepPluralismServiceProxy
				.getRolePagedAllUsers(
                    this.depId,
					this.companyId,
					this.source,
					this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then(({ items, totalCount }) => {
					this.data = items;
                    this.totalItems = totalCount;
					// this.initSelected();
				});
		},
        initSelected() {
			console.log(this.selectedRows);
            // 轮循key
            for (let key of this._selectedRowKeys) {
                //从本页数据中查询key
                const index = this.data.findIndex((d) => d[this.selectedRowField] === key);
                //如果存在
                if (index !== -1) {
                    //从已选中的数据中找到与本页一样ID的数据
                    const keyIndex = this._selectedRows.findIndex((r) => r[this.selectedRowField] === this.data[index][this.selectedRowField]);
                    if(keyIndex !== -1) {
                        //删除已选中的数据
                        this._selectedRows.splice(keyIndex, 1);
                        //重新选中
                        this.rowSelect(this.data[index], index);
                    }
                }
            }
        },
		// //点击标签删除
		// tagcol(record, id) {
		// 	//从本页数据中找删除的这条数据
		// 	const index = this.data.findIndex((t) => t[this.selectedRowField] === id);
		// 	//删除本页数据
		// 	if (index !== -1) {
		// 		//取消行选中 == 删除
		// 		this.rowSelect(record, index);
		// 	} //删除其他页数据
		// 	else {
		// 		//从已选中的keys中找到
		// 		const keyIndex = this._selectedRowKeys.findIndex((r) => r === record[this.selectedRowField]);
		// 		if (keyIndex !== -1) {
		// 			//删除key
        //             this._selectedRowKeys.splice(keyIndex, 1);
		// 		}
		// 		//从已选中的数据中找到
		// 		const rowIndex = this._selectedRows.findIndex((r) => r[this.selectedRowField] === record[this.selectedRowField]);
		// 		if (rowIndex !== -1) {
		// 			//删除数据
        //             this._selectedRows.splice(rowIndex, 1);
		// 		}
		// 	}
        //     this.$forceUpdate()
		// },
		handleSubmit() {
			if (this.isMultiple) {
				//多选返回全部
				this.success([...unionBy(this.selectedRows, this._selectedRows, this.selectedRowField)]);
			} else {
				//单选返回一条
                this.success(this.mergeRows[0]);
			}
			
		},
	},
};
</script>

<style scoped>
.header {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}
.counts {
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.my-md {
	flex: 1;
	border: 1px solid #d9d9d9;
	min-height: 45px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
}

.NotR {
	height: 20px;
	color: #bfbfbf;
}

.my-md::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}

.my-md::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.my-md::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}
</style>
