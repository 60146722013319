<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <div class="my-md" style="padding-left: 5px">
                <a-row>
                    <a-col :span="16">
                        <a-button
                                :type="'primary'"
                                @click="create()"
                                v-if="isGranted('JumpValidPath_add')"
                        >
                            <a-icon type="plus"/>
                            <span>{{ l("Create") }}</span>
                        </a-button>
                        <a-button
                                :type="'primary'"
                                @click="edit()"
                                v-if="isGranted('JumpValidPath_edit')"
                        >
                            <a-icon type="edit"/>
                            <span>{{ l("Edit") }}</span>
                        </a-button>
                        <a-button
                                :type="'danger'"
                                @click="BatchDelete"
                                v-if="isGranted('JumpValidPath_delete')"
                        >
                            <a-icon type="delete"/>
                            <span>{{ l("Delete") }}</span>
                        </a-button>

                    </a-col>
                    <a-col :span="8">
                        <a-input-search
                                style="width: 100%;min-width: 100px"
                                name="filterText"
                                :placeholder="l('SearchWithThreeDot')"
                                @search="refresh"
                                enterButton
                                v-model="filterText"
                        />
                    </a-col>
                </a-row>
            </div>
            <div>
                <a-table
                        @change="sorterChange"
                        :columns="columns"
                        :pagination="false"
                        :customRow="customRow"
                        :rowKey="(data) => data.id"
                        :dataSource="tableData"
                >
                    <a-checkbox slot="checkbox"
                                slot-scope="text, record"

                                :checked="record.checked">
                    </a-checkbox>
                </a-table>
                <a-pagination
                        class="pagination"
                        :total="totalItems"
                        v-model="pageNumber"
                        :showTotal="showTotalFun"
                        @change="onChangePage"
                        @showSizeChange="showSizeChange"
                />
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../../shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN"
    import {JumpValidPathServiceProxy} from "../../../../shared/service-proxies";
    import CreateOrEditJumpValidPath from "./create-or-edit-jump-valid-path/create-or-edit-jump-valid-path";
    import modalHelper from "../../../../shared/helpers/modal/modal-helper";

    let _this;
    export default {
        name: "jump-valid-path",
        mixins:[AppComponentBase],
        components:{CreateOrEditJumpValidPath},
        data(){
            return {
                zh_CN,
                filterText: undefined,
                // 用户表格
                columns: [
                    {
                        title: '',
                        dataIndex: "checkbox",
                        sorter: false,
                        fixed: 'left',
                        width: 50,
                        align: "center",
                        scopedSlots: {customRender: "checkbox"}
                    },
                    {
                        title: '类型',
                        dataIndex: 'sourceTypeName',
                        sorter: true,
                        fixed: 'left',
                        width: 100,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'sourceTypeName'},
                    },
                    {
                        title: '有效Url',
                        dataIndex: 'validPath',
                        sorter: true,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'validPath'},
                    },
                ],
                tableData: [],
                totalItems: 0, //总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ['10', '20', '30', '40'],
                request: {maxResultCount: 10, skipCount: 0, sorting: ''},
                rowId:undefined,
                jvpId:undefined
            }
        },
        created() {
            this._jumpValidPathServiceProxy=new JumpValidPathServiceProxy(this.$apiUrl,this.$api);
        },
        mounted() {
            _this=this;
            this.refresh();
        },
        methods:{
            refresh() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData(this.jvpId);
            },
            getData(jvpId){
                this.jvpId=jvpId;
                this.loading=true;
                this._jumpValidPathServiceProxy.getPaged(
                    jvpId,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(()=>{this.loading=false})
                .then(res=>{
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    if (this.tableData.length > 0) {
                        this.select(this.tableData[0]);
                    }
                })
            },
            create(){
                modalHelper.create(CreateOrEditJumpValidPath,
                    {
                        jvpId:this.jvpId
                    },
                    {
                        width:"500px"
                    })
                .subscribe(res=>{
                    if(res){
                        this.refresh();
                    }
                });
            },
            edit(){
                if(!this.rowId){
                    return abp.message.warn("请至少选择一项进行操作");
                }
                modalHelper.create(CreateOrEditJumpValidPath,
                    {
                        id:this.rowId,
                        jvpId:this.jvpId
                    },
                    {
                        width:"500px"
                    })
                    .subscribe(res=>{
                        if(res){
                            this.refresh();
                        }
                    });
            },
            BatchDelete(){
                if(!this.rowId){
                    return abp.message.warn("请至少选择一项进行操作");
                }
                this.$confirm({
                    title:"确认操作",
                    content:"你确定要删除这项数据吗？",
                    cancelText:"取消",
                    okText:"确认",
                    onOk:()=>{
                        this.loading=true;
                        this._jumpValidPathServiceProxy.delete(this.rowId)
                            .finally(()=>{this.loading=false})
                            .then(res=>{
                                this.refresh();
                            })
                    }
                })
            },
            /**
             * 刷新时的模拟选择
             */
            select(rows) {
                this.rowId = rows.id;
                rows.checked = true;
                this.$forceUpdate();
            },
            /**
             * 表格行点击
             * @param record 点击行的数据
             * @param index 点击行的下标
             */
            customRow: (record, index) => ({

                on: {
                    click: () => {
                        _this.rowId = record.id;
                        //清空选择
                        _this.tableData.map(item => item.checked = false);
                        //赋值选择
                        record.checked = !record.checked;
                        //强制更新布局
                        _this.$forceUpdate();
                    }
                }
            }),
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * 分页
             */
            onChangePage(page, pageSize) {
                this.selectedRowKeys = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.selectedRowKeys = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 排序
             * @param pagination
             * @param filters
             * @param sorter
             */
            sorterChange(pagination, filters, sorter) {
                if (sorter.order) {
                    this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
                } else {
                    this.request.sorting = undefined;
                }
                this.refresh();
            },
        }
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
