<template>
	<a-spin :spinning="loading">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('Edit') }}</span>
				<span v-else>{{ l('Create') }}</span>
			</div>
		</div>
		<div>
			<a-row>
				<a-col :span="4">
					<q-label required label="用户名称"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-input placeholder="用户名称" v-model="entity.name" />
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label required label="用户编码"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-input placeholder="用户编码" v-model="entity.code" />
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label label="图标"> </q-label>
				</a-col>
				<a-col :span="20">
					<div class="group-input" @click="selectIcon" v-if="!icon">
						<div class="group-text">
							<!--                            <icon-font :type="icon" style="font-size:30px" v-if="icon"></icon-font>-->
							<icon-font :type="icon" style="font-size:30px" v-if="icon"></icon-font>
							<!--                            <a-icon :type="icon" style="font-size: 20px" v-if="icon" />-->
						</div>
						<div class="group-icon">
							<a title="图标"><a-icon type="ellipsis"/></a>
						</div>
					</div>
					<div class="group-input" v-else>
						<div class="group-text" @click="selectIcon">
							<icon-font :type="icon" style="font-size:30px" v-if="icon"></icon-font>
							<!--                            <a-icon :type="icon" style="font-size: 20px" v-if="icon" />-->
						</div>
						<div class="group-icon">
							<a title="清空" @click="icon = undefined"><a-icon type="close-circle"/></a>
						</div>
					</div>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label label="图标大小"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-input-number placeholder="图标大小" v-model="entity.size" style="width:100%;" />
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label label="备注"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-textarea placeholder="备注" v-model="entity.description" />
				</a-col>
			</a-row>
		</div>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import {
	CreateOrUpdateSysClassifyInput,
	SysClassifyEditDto,
	SysClassifyServiceProxy,
} from '../../../../shared/service-proxies';
import modalHelper from '../../../../shared/helpers/modal/modal-helper';
import IocnModel from '../../../../components/icons/icon-model';

export default {
	name: 'create-or-edit-sysclassify',
	mixins: [ModalComponentBase],
	components: { IocnModel },
	data() {
		return {
			id: undefined,
			entity: new SysClassifyEditDto(),
			icon: undefined,
		};
	},
	created() {
		this._sysClassifyServiceProxy = new SysClassifyServiceProxy(this.$apiUrl, this.$api);
		this.fullData(); // 模态框必须,填充数据到data字段
	},
	mounted() {
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._sysClassifyServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res.sysClassify;
					this.icon = this.entity.icon;
				});
		},
		selectIcon() {
			modalHelper
				.create(
					IocnModel,
					{
						code: this.icon,
						type: 2,
					},
					{
						width: '600px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.icon = res;
					}
				});
		},
		save() {
			if (!this.entity.name) {
				return abp.message.warn('用户名称不能为空');
			}
			if (!this.entity.code) {
				return abp.message.warn('用户编码不能为空');
			}
			this.entity.icon = this.icon;
			let input = new CreateOrUpdateSysClassifyInput();
			input.sysClassify = this.entity;
			this.loading = true;
			this._sysClassifyServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.group-input {
	width: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	height: 32px;
	margin-top: 3px;
	display: flex;
}

.group-text {
	flex: 5;
	text-align: left;
	padding-left: 9px;
	line-height: 32px;
	cursor: pointer;
}

.group-icon {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.group-icon i {
	color: #808080;
	background-color: #f6f4f4;
	padding: 8px 30px;
	border-radius: 0px 4px 4px 0px;
	border-left: 1px solid #d9d9d9;
}
</style>
