<template>
    <h1>
        subscription-management
    </h1>
</template>

<script>
    export default {
        name: "subscription-management"
    }
</script>

<style scoped>

</style>
