<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id && !type">{{ l('EditDataItem') }}</span>
				<span v-else>{{ l('CreateDataItem') }}</span>
			</div>
		</div>
		<a-form :form="form" @submit="save()" autocomplete="off">
			<!--字典编码-->
			<a-row :gutter="8">
				<a-col :span="6">
					<q-label required :label="l('DataItemCode')"> </q-label>
				</a-col>
				<a-col :span="14">
					<a-input :placeholder="l('DataItemCodeInputDesc')" v-model="entity.itemCode" />
				</a-col>
			</a-row>
			<!--字典编码-->
			<a-row :gutter="8">
				<a-col :span="6">
					<q-label required :label="l('DataItemName')"> </q-label>
				</a-col>
				<a-col :span="14">
					<a-input :placeholder="l('DataItemNameInputDesc')" v-model="entity.itemName" />
				</a-col>
			</a-row>
			<!--字典编码-->
			<a-row :gutter="8">
				<a-col :span="6">
					<q-label :label="l('SortCode')"> </q-label>
				</a-col>
				<a-col :span="14">
					<a-input :placeholder="l('SortCode')" v-model="entity.sortCode" />
				</a-col>
			</a-row>
			<a-row :gutter="8">
				<a-col :span="6">
					<q-label :label="l('isDefault')"> </q-label>
				</a-col>
				<a-col :span="14">
					<a-switch v-model="isSyetem" :checkedChildren="l('Yes')" :unCheckedChildren="l('No')" />
				</a-col>
			</a-row>
		</a-form>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { ModalComponentBase } from '@/shared/component-base';
import { DataItemServiceProxy } from '@/shared/service-proxies';
import { CreateOrUpdateDataItemInput, DataItemEditDto } from '../../../../shared/service-proxies';

export default {
	name: 'create-or-edit-data-item',
	mixins: [ModalComponentBase, AppComponentBase],
	data() {
		return {
			form: this.$form.createForm(this),
			id: null,
			entity: new DataItemEditDto(),
			spinning: false,
			type: null,
			isSyetem: false,
		};
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._dataitemServiceProxy = new DataItemServiceProxy(this.$apiUrl, this.$api);
		if (this.id && !this.type) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._dataitemServiceProxy
				.getById(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res;
					this.isSyetem = res.isSyetem != null ? res.isSyetem : false;
				});
		},
		/**
		 * 提交
		 */
		save() {
			const input = new CreateOrUpdateDataItemInput();
			//添加子节点
			if (this.id && this.type) {
				this.entity.parentId = this.id;
			}
			//添加根节点
			else if (!this.id && !this.type) {
				this.entity.parentId = 0;
			} else {
				this.entity.id = this.id;
			}
			this.entity.isSyetem = this.isSyetem;
			input.dataItem = DataItemEditDto.fromJS(this.entity);
			this.spinning = true;
			this._dataitemServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped lang="less">
.modal-header {
	.anticon {
		margin-right: 10px;
	}
}
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
