<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">{{l('EditTenant')}} <span v-if="entity">:{{entity.tenancyName}}</span></div>
        </div>
        <a-row>
            <a-col :span="4">
                <!-- 租户显示名称 -->
                <a-form-item required :label="l('DisplayTenancyName')">
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-input
                        :placeholder="l('Name')"
                        v-model="entity.name"
                />
            </a-col>
            <a-col :span="4">
                <!-- 版本 -->
                <a-form-item :label="l('Edition')" has-feedback v-if="isShowEdition">
                </a-form-item>
            </a-col>
            <a-col :span="8">
                <edition-combo
                        :placeholder="l('PleaseSelect')"
                        :selectedEdition="selectedEdition"
                        @selectedEditionChange="selectedEditionChange($event)"></edition-combo>
            </a-col>
        </a-row>
        <a-row v-if="entity.connectionString">
            <a-col :span="4">
                <!-- UseHostDatabase -->
                <a-form-item :label="l('DatabaseConnectionString')">
                </a-form-item>
            </a-col>
            <a-col :span="20">
                <a-input
                        :placeholder="l('DatabaseConnectionString')+l('Optional')"
                        v-model="entity.connectionString"
                />
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="5">
                <!-- 企业帮服务地址 -->
                <a-form-item :label="l('亿迈齿轮服务地址')">
                </a-form-item>
            </a-col>
            <a-col :span="19">
                <a-input placeholder="亿迈齿轮服务地址" v-model="entity.lpaServicePath"/>
            </a-col>
        </a-row>
<!--        <a-row>-->
<!--            <a-col :span="5">-->
<!--                &lt;!&ndash; 力软服务器地址 &ndash;&gt;-->
<!--                <a-form-item :label="l('力软服务器地址')">-->
<!--                </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :span="19">-->
<!--                <a-input placeholder="力软服务器地址" v-model="entity.lrServicePath"/>-->
<!--            </a-col>-->
<!--        </a-row>-->
<!--        <a-row>-->
<!--            <a-col :span="5">-->
<!--                &lt;!&ndash; 力软站点地址 &ndash;&gt;-->
<!--                <a-form-item :label="l('力软站点地址')">-->
<!--                </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :span="19">-->
<!--                <a-input placeholder="力软站点地址" v-model="entity.lrSitePath"/>-->
<!--            </a-col>-->
<!--        </a-row>-->
<!--        <a-row>-->
<!--            <a-col :span="5">-->
<!--                &lt;!&ndash; 沃德服务器地址 &ndash;&gt;-->
<!--                <a-form-item :label="l('沃德服务器地址')">-->
<!--                </a-form-item>-->
<!--            </a-col>-->
<!--            <a-col :span="19">-->
<!--                <a-input placeholder="沃德服务器地址" v-model="entity.wdsfServicePath"/>-->
<!--            </a-col>-->
<!--        </a-row>-->
        <a-row v-if="isShowIsUnlimited">
            <a-col>
                <!-- IsUnlimited -->
                <a-checkbox @change="checkedChange('IsUnlimitedchecked', $event)"
                            :checked="IsUnlimitedchecked">{{l("IsUnlimited")}}
                </a-checkbox>
            </a-col>
        </a-row>
        <a-row>
            <a-col :span="8">

                <!-- 是否试用 -->
                <a-checkbox @change="checkedChange('isInTrialPeriod', $event)"
                            :disabled="IsInTrialPerioddisabled" v-model="entity.isInTrialPeriod">
                    {{l("IsInTrialPeriod")}}
                </a-checkbox>
            </a-col>
            <a-col :span="8">

                <!-- 是否激活 -->
                <a-checkbox @change="checkedChange('isActive', $event)" v-model="entity.isActive">{{l("IsActive")}}
                </a-checkbox>
            </a-col>
            <a-col :span="8">

                <!-- 是否默认 -->
                <a-checkbox @change="checkedChange('isDefault', $event)" v-model="entity.isDefault">{{l("是否默认")}}
                </a-checkbox>
            </a-col>
        </a-row>
        <a-row v-if="!IsUnlimitedchecked && isShowIsUnlimited">
            <a-col :span="6">
                <a-form-item :label="l('SubscriptionEndDateUtc')">

                </a-form-item>
            </a-col>
            <a-col :span="8">
                <a-date-picker
                        @change="subscriptionEndDateUtconChange"
                        placeholder="请选择日期"
                        v-model="entity.subscriptionEndUtc"
                />
            </a-col>
        </a-row>


        <a-form-item class="btn--container">
            <a-button type="button" @click="close()">
                {{ l("Cancel") }}
            </a-button>
            <a-button type="primary" @click="handleSubmit">
                {{ l('Save') }}
            </a-button>
        </a-form-item>
    </a-spin>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import EditionCombo from "../../shared/edition-combo/edition-combo.vue";
import {
    TenantServiceProxy,
    TenantListDto,
    SubscribableEditionComboboxItemDto,
    EntityDtoOfInt64,
    NameValueDto,
    CommonLookupServiceProxy,
    CommonLookupFindUsersInput
} from "@/shared/service-proxies";
import pick from "lodash.pick";
import {TenantEditDto} from "@/shared";

export default {
    mixins: [ModalComponentBase],
    name: "create-tenant-component",
    data() {
        return {
            confirmDirty: false,
            // 版本
            selectedEdition: {},
            // 是否在试用期
            isInTrialPeriod: false,
            IsInTrialPerioddisabled: false,
            // 下次登录需要修改密码
            shouldChangePasswordOnNextLogin: false,
            // 发送激活邮件
            sendActivationEmail: false,
            // 是否激活
            isActive: true,
            IsUnlimitedchecked: false,
            // IsUnlimited
            isShowIsUnlimited: false,
            // 版本号
            editionId: 0,
            spinning: false,
            isShowEdition: false,
            // 获取到的数据
            entity: new TenantEditDto(),
            entityId: 0,

        };
    },
    components: {
        EditionCombo
    },
    // watch: {
    //     entityId(val) {
    //         if (val) {
    //             console.log(val);
    //         }
    //     }
    // },
    beforeCreate() {
        // this.form = this.$form.createForm(this, { name: "register" });
    },
    created() {
        this._tenantService = new TenantServiceProxy(
            this.$apiUrl,
            this.$api
        );
        this.fullData(); // 模态框必须,填充数据到data字段
    },
    mounted() {
        this.getData();
    },
    methods: {
        /**
         * 获取数据
         */
        getData() {
            this.spinning = true;
            this._tenantService
                .getForEdit(this.entityId)
                .finally(() => {
                    this.spinning = false;
                })
                .then(res => {
                    this.entity = res;
                    this.selectedEdition.value = this.entity.editionId ? this.entity.editionId : 0;
                    this.IsUnlimitedchecked = !this.entity
                        .subscriptionEndUtc;
                    this.isShowEdition = true;
                });
        },
        /**
         * 验证密码
         */
        /**
         * 版本选择
         */
        selectedEditionChange(e) {
            if (e && e.value) {
                this.IsInTrialPerioddisabled = true;
                this.isShowIsUnlimited = true;
                this.entity.editionId = parseInt(e.value);
            } else {
                this.IsInTrialPerioddisabled = false;
                this.isShowIsUnlimited = false;
                this.entity.editionId = undefined;
            }
        },
        /**
         * 是否试用
         * 下次登录需要修改密码
         * 发送激活邮
         * 是否激活
         */
        checkedChange(type, e) {
            this[`${type}`] = eval(`${e.target.checked}`.toLowerCase());
        },
        /**
         * 订阅结束日期
         */
        subscriptionEndDateUtconChange(date, dateString) {
        },
        /**
         * 提交表单
         */
        handleSubmit(e) {
            if(!this.entity.name){
                abp.message.warn("租户显示名称不能为空");
                return;
            }
            this.spinning = true;
            this._tenantService
                .update(this.entity)
                .finally(() => {
                    this.spinning = false;
                })
                .then(res => {
                    // this.notify.success(this.l('SavedSuccessfully'));
                    this.$notification["success"]({
                        message: this.l("SavedSuccessfully")
                    });
                    this.success(true);
                })
                .catch(err => {
                    console.log(err);
                });
        }
    }
};
</script>

<style lang="less" scoped>
/deep/.btn--container .ant-form-item-children {
    display: block;
    margin: 10px auto;
    text-align: center;
}
</style>
