<template>
  <a-spin :spinning="spinning">
    <a-row :gutter="8" class="header">
                <p class="left">
                    <a-icon type="share-alt"/>
                    {{ l('OrganizationTree') }}
                </p>
        </a-row>
    <a-row style="margin-top: 5px">
      <a-tree-select class="left"  @change="handleChange"
              style="width: 220px"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="companyTreeList"
              v-model="comPanyId"
            />
    </a-row>
    <a-row class="tree">
      <a-tree
        :treeData="treeData"
        showIcon
        defaultExpandAll
        :expanded-keys="expandedKeys"
        @select="onSelect"
        @expand="onExpand"
      >
        <a-icon type="folder" slot="folder" />
        <a-icon type="file" slot="file" />
        <a-icon type="folder-open" slot="folder-open" />
        <template slot="custom" slot-scope="{ expanded }">
          <a-icon :type="expanded ? 'folder-open' : 'folder'" />
        </template>
      </a-tree>
    </a-row>
  </a-spin>
</template>

<script>
import { AppComponentBase } from "@/shared/component-base";
import { OrganizationUnitServiceProxy,CompanyServiceProxy,DepartmentServiceProxy } from "@/shared/service-proxies";
import { ModalHelper } from "@/shared/helpers";
import Bus from "@/shared/bus/bus";

export default {
  name: "userDepPluralism-tree-panel",
  mixins: [AppComponentBase],
  data() {
    return {
      spinning: false,
      _organizationUnitServiceProxy: null,
      _ouData: [],
      treeData: [],
      tmpStyle: "",
      // 选中的item
      activedNode: {},
      companyList:[],
      companyTreeList:[],
      comPanyId:undefined,
      departmentId:undefined,
        expandedKeys:[],
    };
  },
  created() {
    this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this._CompanyConfigServiceProxy = new CompanyServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this._DepartmentServiceProxy = new DepartmentServiceProxy(
                this.$apiUrl,
                this.$api
            );
    this.$nextTick(() => {
      Bus.$on("reloadOrganizationUnitTree", (data) => {
        if (data) {
          this.getData();
        }
      });
    });
    
  },
  beforeDestroy() {
    Bus.$off("reloadOrganizationUnitTree");
  },
  mounted() {
     this._CompanyConfigServiceProxy
      .getPaged(undefined, "", 100, 0)
      .finally(() => {
        this.spinning = false;
      })
      .then((res) => {
        if (res.items.length < 1) return;
        this.comPanyId= res.items[0].id;
        this.companyList = res.items;
        this.companyList.forEach((item) => {
          if (item.parentId == 0) {
            var obj = {
              key: item.id,
              value: item.id,
              title: item.fullName
            };
            this.companyTreeList.push(obj);
            this.expandedKeys.push(obj.key);
          }
        });
        this.initCompanyTree(this.companyTreeList);
        this.getData();
      });
  },
  methods: {
    getData() {
      this.spinning = true;
      this._DepartmentServiceProxy
        .getDepListByCompanyId(
            this.comPanyId
        )
        .finally(() => {
          this.spinning = false;
        })
        .then((result) => {
          this._ouData = result;
          this.treeData = this.treeDataMap();
        });
    },
    /**
     * 初始化树形结构数据格式
     */
    initCompanyTree(list) {
      list.forEach((v) => {
        //遍历父级数据，加载子集=
        v.children = []; //创建空的子集
        this.companyList.forEach((m) => {
          if (m.parentId == v.value) {
            //根据主键查子集数据
            var obj = {
              key: m.id,
              value: m.id,
              title: m.fullName
            };
            v.children.push(obj); //放进父级的集合中
            this.initCompanyTree(v.children); //递归查询子集的集合
          }
        });
        if (v.children.length == 0) {
          //校验如何数据没有子集，删除子集参数
          delete v.children;
        }
      });
    },
    handleChange(value){
        this.comPanyId=value;
        this.getData();
    },
    /**
     * 重组Tree数据
     */
    treeDataMap() {
      const ouDtataParentIsNull = _.filter(
        this._ouData,
        (item) => !item.parentId
      );
      return ouDtataParentIsNull.map((item) =>
        this._recursionGenerateTree(item)
      );
    },

    /**
     * 递归重组特性菜单为nzTree数据类型
     * @param item 组织机构项
     */
    _recursionGenerateTree(item) {
      // 叶子节点
      const childs = _.filter(
        this._ouData,
        (child) => child.parentId === item.id
      );
      // 父节点 无返回undefined
      const parentOu = _.find(this._ouData, (p) => p.id === item.parentId);
      const _treeNode = {
        title: item.displayName ,//+ "(" + item.memberCount + ")"
        key: item.id.toString(),
        isLeaf: childs && childs.length <= 0,
        slots: {
          icon: childs && childs.length > 0 ? "folder" : "file",
        },
        expanded: true,
        isMatched: true,
        code: item.code,
        memberCount: 0,
        dto: item,
        parent: parentOu,
        children: [],
      };
      if (childs && childs.length) {
        childs.forEach((itemChild) => {
          const childItem = this._recursionGenerateTree(itemChild);
          _treeNode.children.push(childItem);
        });
      }
      return _treeNode;
    },
    /**
     * 选中item
     */
    onSelect(selectedKeys, info) {
      this.activedNode = this._ouData.find(
        (item) => parseInt(item.id) == parseInt(selectedKeys[0])
      );
      if(!this.activedNode){
        this.activedNode={companyId:this.comPanyId};
      }
      this.$emit("selectedNode", this.activedNode);
      this.$nextTick(() => {
        Bus.$emit("selectedNode", this.activedNode);
      });
      // console.log("selected", this.activedNode);
    },
    /**
     * 展开
     */
    onExpand(expandedKeys,record) {
        this.expandedKeys=expandedKeys;
    },
    /**
     * 还原图标
     */
    resetExpandIcon(item) {
      for (let i in item) {
        if (!item[i].isLeaf) {
          item[i].slots.icon = "folder";
        }
        if (item[i].children.length) {
          this.resetExpandIcon(item[i].children);
        }
      }
    },
    /**
     * 设置图标
     */
    setExpandIcon(id, item) {
      for (let i in item) {
        if (item[i].key == id) {
          item[i].slots.icon = "folder-open";
        }
        if (item[i].children.length) {
          for (let j in item[i].children) {
            this.setExpandIcon(id, item[i].children);
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.header {
        height: 50px;
        border-bottom: 1px solid #e8e8e8;

        p {
            line-height: 50px;

            &.left {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 500;
                margin-left: 20px;
            }

            a {
                margin-left: 10px;
            }
        }
    }
.tree {
  margin: 20px;
}
.right-click-item {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      clear: both;
      margin: 0;
      padding: 5px 12px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;
      text-align: left;
    }
  }
}
</style>
