<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <!-- 标题 -->
            <div class="modal-header">
                <div class="modal-title">
                    <span>{{ l('导入月度计划') }}</span>
                </div>
            </div>
            <div class="my-md">
                <a-row class="text">
                    1.您选择导入的Excel表的表头(第一行)里的列名必须和系统中相应字段名称完全相同(其中姓名和工号是必须的)
                </a-row>
                <a-row class="text">
                    2.请假请填写系统对应假期规则对应编码
                </a-row>
                <a-row class="text">
                    3.员工请假<b>默认为整天</b>，请假则日总出勤时数为0
                </a-row>
                <a-row class="text">
                    4.日期顺序可不一致
                </a-row>
                <a-row class="text">
                    5.导入文件必须是xls,xlsx格式
                </a-row>
                <a-row class="text">
                    当前周期:<b>{{startTime}}</b>~<b>{{endTime}}</b>
                </a-row>
                <a-row class="text">
                    <a-col :span="5">
                        <a-button
                                @click="ExportTemplate()"
                        >
                            <a-icon type="file-excel"/>
                            <span>{{ l("下载模板") }}</span>
                        </a-button>
                    </a-col>
                    <a-col :span="19">
                        <a-upload
                                :action="uploadPictureUrl"
                                :beforeUpload="beforeUpload"
                                :headers="uploadHeaders"
                                @change="uploadPictureChange($event)">
                            <a-button type="primary"> <a-icon type="import" /> 导入 </a-button>
                        </a-upload>
                    </a-col>


                </a-row>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {AppConsts} from "../../../../abpPro/AppConsts";
    import {fileDownloadService} from "../../../../shared/utils";
    import moment from "moment";
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {MonthAttendancePlanImportInput, MonthAttendancePlanServiceProxy} from "../../../../shared/service-proxies";

    export default {
        name: "import-excel-model",
        mixins: [ModalComponentBase],
        data(){
            return{
                zh_CN,
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadExcelFile",
                uploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken()
                },
                startTime:undefined,
                endTime:undefined
            }
        },
        created() {
            this.fullData();
            this._monthAttendancePlanServiceProxy=new MonthAttendancePlanServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        mounted() {

        },
        methods:{
            beforeUpload(file) {
                let filetype;
                if(file.name){
                    filetype=file.name.substring(file.name.lastIndexOf('.'));
                }
                let isExcel=filetype===".xlsx" ||
                    filetype===".xls";
                if(!isExcel){
                    return abp.message.warn("只能上传xlsx格式和xls的文件");
                }

            },
            uploadPictureChange({file, fileList}) {
                if (file.status === 'done') {
                    let input=new MonthAttendancePlanImportInput();
                    input.startTime=moment(this.startTime);
                    input.endTime=moment(this.endTime);
                    input.fileName=file.response.result.fileName;
                    this.loading=true;
                    this._monthAttendancePlanServiceProxy.importMonthAttPlan(input)
                    .finally(()=>{this.loading=false;})
                    .then(res=>{
                        if(!res.isSuccess&&res.failedCount>0){
                            this.$notification["warn"]({
                                message: "总条数:"+res.totalCount+"  失败人数:"+res.failedCount,
                            });
                            this._fileDownloadService.downloadTempFile(res.file);
                        }else {
                            this.$notification["success"]({
                                message: this.l("导入成功"),
                            });
                        }
                    })
                }
            },
            ExportTemplate(){
                this.loading=true;
                this._monthAttendancePlanServiceProxy.exportTemplate(moment(this.startTime),moment(this.endTime))
                .finally(()=>{this.loading=false;})
                .then(res=>{
                    this._fileDownloadService.downloadTempFile(res);
                })

            },
        }
    }
</script>

<style scoped>
    .text{
        padding: 5px 0px;
    }
</style>
