<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8">
				<a-col :span="24">
					<a-button :type="'primary'" @click="createOrEdit()" v-if="isGranted('notice_add')">
						<a-icon type="plus" />
						<span>{{ l('Create') }}</span>
					</a-button>
					<a-input-search
						style="width: 200px; margin: 0 10px"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
						v-decorator="['filterText']"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: 1500, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<!--                    类型-->
				<span slot="noticeType" slot-scope="text, record">
					<span class="message" v-if="record.noticeType.itemDetailName == '通知'">
						通知
					</span>
					<span class="notice" v-else-if="record.noticeType.itemDetailName == '公告'">
						公告
					</span>
					<span class="false" v-else>
						无效
					</span>
				</span>
				<!--                    阅读数-->
				<span slot="pv" slot-scope="text, record">
					{{ record.pv ? record.pv : 0 }}
				</span>
				<!--                    有效标识-->
				<span slot="enabledMark" slot-scope="text, record">
					<span class="true" v-if="record.enabledMark">
						已发布
					</span>
					<span class="false" v-else>
						无效
					</span>
				</span>
				<span slot="actions" slot-scope="text, record">
					<a-space>
						<!-- 修改 -->
						<a class="table-edit" @click="createOrEdit(record.id)" v-if="isGranted('notice_edit')">
							<a-icon type="edit" />{{ l('Edit') }}</a
						>
						<!-- 删除 -->
						<a-popconfirm
							placement="top"
							:okText="l('Ok')"
							:cancelText="l('Cancel')"
							@confirm="deleteNotice(record)"
							v-if="isGranted('notice_delete')"
						>
							<template slot="title">
								{{ l('ConfirmDeleteWarningMessage') }}
							</template>
							<a class="table-delete"> <a-icon type="delete" />{{ l('Delete') }}</a>
						</a-popconfirm>
					</a-space>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { NewsServiceProxy } from '../../../shared/service-proxies';
import moment from 'moment';
import { ModalHelper } from '@/shared/helpers';
import CreateEditNotice from '../../oa/notice/create-edit-notice/create-edit-notice';

export default {
	name: 'notice',
	mixins: [AppComponentBase],
	components: {
		CreateEditNotice,
	},
	data() {
		return {
			// 表格
			columns: [
				{
					title: this.l(''),
					dataIndex: 'id',
					width: 50,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'id' },
				},
				{
					title: this.l('Type'),
					dataIndex: 'noticeType',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'noticeType' },
				},
				{
					title: this.l('FullHead'),
					dataIndex: 'fullHead',
					sorter: false,
					width: 150,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'fullHead' },
				},
				{
					title: this.l('Subtitle'),
					dataIndex: 'subtitle',
					width: 200,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'Subtitle' },
				},
				{
					title: this.l('NoticeType'),
					dataIndex: 'category.itemDetailName',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'category.itemDetailName' },
				},

				{
					title: this.l('ReleaseTime'),
					dataIndex: 'releaseTime',
					align: 'center',
					width: 130,
					ellipsis: true,
					scopedSlots: { customRender: 'releaseTime' },
				},
				{
					title: this.l('AuthorName'),
					dataIndex: 'authorName',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'authorName' },
				},
				{
					title: this.l('PV'),
					dataIndex: 'pv',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'pv' },
				},
				{
					title: this.l('EnabledMark'),
					dataIndex: 'enabledMark',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'enabledMark' },
				},
				{
					title: this.l('Actions'),
					dataIndex: 'actions',
					align: 'center',
					fixed: 'right',
					scopedSlots: { customRender: 'actions' },
					width: 150,
				},
			],
			tableData: [],
		};
	},
	created() {
		this._NewsServiceProxy = new NewsServiceProxy(this.$apiUrl, this.$api);
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this.selectedRowobj = {};
			this._NewsServiceProxy
				.getPagedByNotice(
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.releaseTime = item.releaseTime ? moment(item.releaseTime).format('YYYY-MM-DD HH:mm') : ' ';
					});
					this.totalItems = res.totalCount;
				});
		},

		createOrEdit(record) {
			ModalHelper.create(
				CreateEditNotice,
				{ Id: record },
				{
					width: '900px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
		deleteNotice(record) {
			this.loading = true;
			this._NewsServiceProxy
				.delete(record.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.request.skipCount = 0;
					this.pageNumber = 1;
					this.$notification['success']({
						message: this.l('SuccessfullyDeleted'),
					});
					this.getData();
				});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}

.message {
	margin: auto;
	width: 40px;
	height: 20px;
	font-weight: bold;
	border-radius: 0.25em;
	padding: 5px;
	font-size: 10px;
	color: white;
	background-color: #5cb85c;
	display: flex;
	align-items: center;
	justify-content: center;
}

.notice {
	margin: auto;
	width: 40px;
	height: 20px;
	font-weight: bold;
	border-radius: 0.25em;
	padding: 5px;
	font-size: 10px;
	color: white;
	background-color: #337ab7;
	display: flex;
	align-items: center;
	justify-content: center;
}

.true {
	margin: auto;
	width: 50px;
	height: 20px;
	font-weight: bold;
	border-radius: 0.25em;
	padding: 5px;
	font-size: 10px;
	color: white;
	background-color: #5cb85c;
	display: flex;
	align-items: center;
	justify-content: center;
}

.false {
	margin: auto;
	width: 50px;
	height: 20px;
	font-weight: bold;
	border-radius: 0.25em;
	padding: 5px;
	font-size: 10px;
	color: white;
	background-color: #777777;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
