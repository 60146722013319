<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 8]">
				<a-col :span="4">
					<a-tree-select
						style="width: 100%"
						tree-default-expand-all
						multiple
						allowClear
						:placeholder="l('Company')"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="CompanyId"
						@change="companyChange"
					/>
				</a-col>
				<a-col :span="4">
					<a-tree-select
						style="width: 100%"
						tree-default-expand-all
						multiple
						allowClear
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						v-model="DepartmentId"
						@change="SelectDepartmentTree"
					>
					</a-tree-select>
				</a-col>

				<a-col :span="4">
					<a-select
						mode="tags"
						show-search
						showArrow
						:placeholder="l('EmpState')"
						option-filter-prop="children"
						style="width: 100%"
						aria-required="true"
						allowClear
						v-model="empStateCode"
						@change="search"
					>
						<a-select-option v-for="item in EmpStateList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>

				<a-col :span="4">
					<a-select
						mode="tags"
						show-search
						showArrow
						:placeholder="l('EmpType')"
						option-filter-prop="children"
						style="width: 100%"
						aria-required="true"
						allowClear
						v-model="empTypeCode"
						@change="search"
					>
						<a-select-option v-for="item in EmpTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>

				<a-col :span="4">
					<a-select
						show-search
						:placeholder="l('MaritalStatus')"
						option-filter-prop="children"
						style="width: 100%"
						aria-required="true"
						:allowClear="true"
						v-model="maritalStatusCode"
						@change="search"
					>
						<a-select-option v-for="item in MaritalStatusList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
				<a-col :span="4">
					<a-input-search
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</a-col>
			</a-row>
			<a-row :gutter="[8, 8]">
				<a-col :span="12">
					<a-button :type="'primary'" @click="exportToExcel()">
						<a-icon type="download" />
						<span>{{ l('Export') }}</span>
					</a-button>
					<a-button :type="'primary'" v-if="isGranted('roster_exe')">
						<a href="QYBClient:123-MonacoGP-19279-Senna">{{ l('证件入职制卡') }}</a>
					</a-button>
				</a-col>
				<a-col :span="10" offset="2" style="text-align: right">
					<a-checkbox v-model="isChildrenOrg" @change="search">
						包含子部门
					</a-checkbox>
					<a-checkbox v-model="IdCardOverTime" @change="search">
						证件近两个月到期人员
					</a-checkbox>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
			>
				<a @click="viewUser(record)" slot="jobNumber" slot-scope="text, record">
					{{ text }}
				</a>

				<span slot="isOverTime" slot-scope="text, record">
					{{ getIsOverTime(record) }}
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import moment from 'moment';
import { Dic, fileDownloadService } from '../../../shared/utils';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EmployeeServiceProxy,
	UserServiceProxy,
} from '../../../shared/service-proxies';
import CreateOrEditLoginUser from '../../organization/login-user/create-or-edit-login-user/create-or-edit-login-user.vue';
import ModalHelper from '../../../shared/helpers/modal/modal-helper';
import { abpService } from '../../../shared/abp';
import DepTreeUntils from '../department/dep-tree-untils/dep-tree-untils';
import ViewUserComponent from '../../admin/users/view-user/view-user';

export default {
	name: 'roster',
	mixins: [AppComponentBase],
	components: { CreateOrEditLoginUser, ViewUserComponent },
	data() {
		return {
			// 用户表格
			columns: [
				{
					title: '工号',
					dataIndex: 'jobNumber',
					ellipsis: true,
					sorter: false,
					align: 'center',
					fixed: 'left',
					width: 100,
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: '姓名',
					dataIndex: 'realName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					fixed: 'left',
					width: 100,
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: '公司',
					dataIndex: 'companyName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'companyName' },
				},
				{
					title: '一级部门',
					dataIndex: 'displayName',
					ellipsis: true,
					width: 150,
					align: 'center',
					scopedSlots: { customRender: 'displayName' },
				},
				{
					title: '二级部门',
					dataIndex: 'twoDepartment',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 100,
				},
				{
					title: '三级部门',
					dataIndex: 'threeDepartment',
					sorter: false,
					ellipsis: true,
					align: 'center',
					width: 100,
				},
				{
					title: '岗位',
					dataIndex: 'orgName',
					ellipsis: true,
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'orgName' },
				},
				{
					title: '学历',
					dataIndex: 'educationsName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'educationsName' },
				},
				{
					title: '专业',
					dataIndex: 'professional',
					ellipsis: true,
					align: 'center',
					width: 120,
					scopedSlots: { customRender: 'professional' },
				},
				{
					title: '毕业时间',
					dataIndex: 'schoolEndDate',
					ellipsis: true,
					align: 'center',
					width: 110,
					scopedSlots: { customRender: 'schoolEndDate' },
				},
				{
					title: '毕业院校',
					dataIndex: 'schoolName',
					ellipsis: true,
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'schoolName' },
				},
				{
					title: '婚姻状况',
					dataIndex: 'maritalStatusName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'maritalStatusName' },
				},
				{
					title: '政治面貌',
					dataIndex: 'politicsStatusName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'politicsStatusName' },
				},
				{
					title: '身份证号',
					dataIndex: 'identityCard',
					ellipsis: true,
					align: 'center',
					width: 250,
					scopedSlots: { customRender: 'identityCard' },
				},
				{
					title: '身份证有效期',
					dataIndex: 'identityValidity',
					ellipsis: true,
					width: 130,
					align: 'center',
					scopedSlots: { customRender: 'identityValidity' },
				},
				{
					title: '出生日期',
					dataIndex: 'birthday',
					ellipsis: true,
					width: 110,
					align: 'center',
					scopedSlots: { customRender: 'birthday' },
				},
				// {
				// 	title: '出生月份',
				// 	dataIndex: 'birthdayMonth',
				// 	ellipsis: true,
				// 	width: 110,
				// 	align: 'center',
				// 	scopedSlots: { customRender: 'birthdayMonth' },
				// },
				{
					title: '民族',
					dataIndex: 'nationName',
					ellipsis: true,
					width: 80,
					align: 'center',
					scopedSlots: { customRender: 'nationName' },
				},
				{
					title: '籍贯',
					dataIndex: 'nativePlace',
					ellipsis: true,
					width: 200,
					align: 'center',
					scopedSlots: { customRender: 'nativePlace' },
				},
				{
					title: '年龄',
					dataIndex: 'age',
					ellipsis: true,
					width: 80,
					align: 'center',
					scopedSlots: { customRender: 'age' },
				},
				{
					title: '性别',
					dataIndex: 'genderName',
					ellipsis: true,
					sorter: false,
					width: 100,
					align: 'center',
					scopedSlots: { customRender: 'genderName' },
				},
				{
					title: '户籍地址',
					dataIndex: 'identityAddress',
					ellipsis: true,
					width: 200,
					align: 'center',
					scopedSlots: { customRender: 'identityAddress' },
				},
				{
					title: '常住地址',
					dataIndex: 'presentAddress',
					ellipsis: true,
					width: 200,
					align: 'center',
					scopedSlots: { customRender: 'presentAddress' },
				},
				{
					title: '手机号',
					dataIndex: 'mobile',
					ellipsis: true,
					width: 120,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'mobile' },
				},
				{
					title: '紧急联系人',
					dataIndex: 'contactName',
					ellipsis: true,
					width: 120,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'contactName' },
				},
				{
					title: '紧急联系人手机号',
					dataIndex: 'contactPhone',
					ellipsis: true,
					width: 150,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'contactPhone' },
				},
				{
					title: '开户行名称',
					dataIndex: 'bankName',
					ellipsis: true,
					width: 150,
					align: 'center',
					scopedSlots: { customRender: 'bankName' },
				},
				{
					title: '银行卡号',
					dataIndex: 'cardNo',
					ellipsis: true,
					width: 150,
					align: 'center',
					scopedSlots: { customRender: 'cardNo' },
				},
				{
					title: '保险类型',
					dataIndex: 'insuranceType',
					ellipsis: true,
					width: 100,
					align: 'center',
					scopedSlots: { customRender: 'insuranceType' },
				},
				{
					title: '社保编号',
					dataIndex: 'socialSecurity',
					ellipsis: true,
					width: 150,
					align: 'center',
					scopedSlots: { customRender: 'socialSecurity' },
				},
				{
					title: '公积金编号',
					dataIndex: 'reservedFunds',
					ellipsis: true,
					width: 150,
					align: 'center',
					scopedSlots: { customRender: 'reservedFunds' },
				},
				{
					title: '员工种类一',
					dataIndex: 'empKindOne',
					ellipsis: true,
					width: 110,
					align: 'center',
					scopedSlots: { customRender: 'empKindOne' },
				},
				{
					title: '员工种类二',
					dataIndex: 'empKindTwo',
					ellipsis: true,
					width: 110,
					align: 'center',
					scopedSlots: { customRender: 'empKindTwo' },
				},
				{
					title: '员工种类三',
					dataIndex: 'empKindThree',
					ellipsis: true,
					width: 110,
					align: 'center',
					scopedSlots: { customRender: 'empKindThree' },
				},
				{
					title: '员工状态',
					dataIndex: 'empStateName',
					ellipsis: true,
					width: 100,
					align: 'center',
					scopedSlots: { customRender: 'empStateName' },
				},
				{
					title: '入职日期',
					dataIndex: 'hireDate',
					ellipsis: true,
					width: 110,
					align: 'center',
					scopedSlots: { customRender: 'hireDate' },
				},
				{
					title: '试用期',
					dataIndex: 'probationMonth',
					ellipsis: true,
					align: 'center',
					width: 80,
					scopedSlots: { customRender: 'probationMonth' },
				},
				{
					title: '转正日期',
					width: 110,
					dataIndex: 'regularDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'regularDate' },
				},
				{
					title: '司龄',
					dataIndex: 'companyAge',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyAge' },
					width: 80,
				},
				{
					title: '合同类型',
					dataIndex: 'pcTypeName',
					ellipsis: true,
					width: 110,
					align: 'center',
					scopedSlots: { customRender: 'pcTypeName' },
				},
				{
					title: '合同开始日期',
					dataIndex: 'pcStartDate',
					ellipsis: true,
					width: 130,
					align: 'center',
					scopedSlots: { customRender: 'pcStartDate' },
				},
				{
					title: '合同期限',
					dataIndex: 'dueTerm',
					ellipsis: true,
					width: 110,
					align: 'center',
					scopedSlots: { customRender: 'dueTerm' },
				},
				{
					title: '合同结束日期',
					dataIndex: 'pcEndDate',
					ellipsis: true,
					width: 130,
					align: 'center',
					scopedSlots: { customRender: 'pcEndDate' },
				},
				{
					title: '合同是否到期',
					dataIndex: 'isOverTime',
					ellipsis: true,
					width: 150,
					align: 'center',
					scopedSlots: { customRender: 'isOverTime' },
				},
				{
					title: '招聘来源',
					dataIndex: 'recruitmentSourceName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'recruitmentSourceName' },
				},
				{
					title: '内部推荐人',
					dataIndex: 'recommender',
					ellipsis: true,
					align: 'center',
					width: 150,
					scopedSlots: { customRender: 'recommender' },
				},
				{
					title: '离职日期',
					dataIndex: 'termDate',
					ellipsis: true,
					width: 150,
					align: 'center',
					scopedSlots: { customRender: 'termDate' },
				},
				{
					title: '离职原因',
					dataIndex: 'termReason',
					ellipsis: true,
					width: 150,
					align: 'center',
					scopedSlots: { customRender: 'termReason' },
				},
				// {
				//     title: '邮箱',
				//     dataIndex: 'personalEmail',
				//     ellipsis: true,
				//     align: 'center',
				//     scopedSlots: { customRender: 'personalEmail' },
				//     width: 250,
				// },
				//
				//
				// {
				//     title: '试用期职等',
				//     dataIndex: 'probationGradeName',
				//     ellipsis: true,
				//     width: 110,
				//     align: 'center',
				//     scopedSlots: { customRender: 'probationGradeName' },
				// },
				// {
				//     title: '转正后职等',
				//     dataIndex: 'regularGradeName',
				//     ellipsis: true,
				//     width: 110,
				//     align: 'center',
				//     scopedSlots: { customRender: 'regularGradeName' },
				// },
				//
				// {
				//     title: '首次工作日期',
				//     dataIndex: 'firstWorkDate',
				//     ellipsis: true,
				//     width: 130,
				//     align: 'center',
				//     scopedSlots: { customRender: 'firstWorkDate' },
				// },
				//
				//
				// {
				//     title: '户口性质',
				//     dataIndex: 'residenceName',
				//     ellipsis: true,
				//     align: 'center',
				//     scopedSlots: { customRender: 'residenceName' },
				// },
				//
				//
				// {
				//     title: '常住地址邮编',
				//     dataIndex: 'postalCode',
				//     ellipsis: true,
				//     align: 'center',
				//     width: 150,
				//     scopedSlots: { customRender: 'postalCode' },
				// },
				//
				// {
				//     title: '证件开始日期',
				//     dataIndex: 'identityStartDate',
				//     ellipsis: true,
				//     width: 130,
				//     align: 'center',
				//     scopedSlots: { customRender: 'identityStartDate' },
				// },
				// {
				//     title: '身份证有效期',
				//     dataIndex: 'identityValidity',
				//     ellipsis: true,
				//     width: 130,
				//     align: 'center',
				//     scopedSlots: { customRender: 'identityValidity' },
				// },
				//
				// {
				//     title: '员工类型',
				//     dataIndex: 'empTypeName',
				//     ellipsis: true,
				//     align: 'center',
				//     scopedSlots: { customRender: 'empTypeName' },
				// },
				// {
				//     title: '员工状态',
				//     dataIndex: 'empStateName',
				//     ellipsis: true,
				//     align: 'center',
				//     scopedSlots: { customRender: 'empStateName' },
				// },
				//
				//
				//
				//
				// {
				//     title: '劳务公司',
				//     dataIndex: 'personalServiceName',
				//     ellipsis: true,
				//     align: 'center',
				//     scopedSlots: { customRender: 'personalServiceName' },
				// },
				//
				//
				//
				// {
				//     title: '离职日期',
				//     dataIndex: 'termDate',
				//     ellipsis: true,
				//     width: 150,
				//     align: 'center',
				//     scopedSlots: { customRender: 'termDate' },
				// },
			],

			tableData: [],
			companyTreeList: [],
			DepTreeData: [],
			CompanyId: undefined,
			DepartmentId: undefined,
			empStateCode: '01',
			empTypeCode: undefined,
			maritalStatusCode: undefined,
			//导出用
			_fileDownloadService: '',
			//CodeList查询用
			EmpStateList: [],
			EmpTypeList: [],
			MaritalStatusList: [],
			IdCardOverTime: false,
			isChildrenOrg: true,
		};
	},

	created() {
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._EmployeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
		this.columns = this.getColumn('花名册', this.columns);
	},
	async mounted() {
		this.EmpStateList = await Dic.getInstance().getDicAsync('EmpState');
		this.EmpTypeList = await Dic.getInstance().getDicAsync('EmpType');
		this.MaritalStatusList = await Dic.getInstance().getDicAsync('MaritalStatus');

		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.CompanyId = abpService.abp.userInfo.companyId;
		}
		this.search();
	},

	methods: {
		getIsOverTime(record) {
			if (record.pcEndData) {
				if (record.pcEndData > moment()) {
					return '是';
				}
				return '否';
			}
		},
		getData() {
			this.loading = true;
			this._EmployeeServiceProxy
				.getRoster(
					this.CompanyId,
					this.DepartmentId,
					this.empTypeCode,
					this.empStateCode,
					this.maritalStatusCode,
					this.IdCardOverTime,
					!this.isChildrenOrg,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
					this.tableData.map((item) => {
						item.birthday = item.birthday ? moment(item.birthday).format('YYYY-MM-DD') : '';
						item.creationTime = item.creationTime ? moment(item.creationTime).format('YYYY-MM-DD') : '';
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : '';
						item.regularDate = item.regularDate ? moment(item.regularDate).format('YYYY-MM-DD') : '';
						item.termDate = item.termDate ? moment(item.termDate).format('YYYY-MM-DD') : '';
						item.firstWorkDate = item.firstWorkDate ? moment(item.firstWorkDate).format('YYYY-MM-DD') : '';
						item.identityValidity = item.identityValidity
							? moment(item.identityValidity).format('YYYY-MM-DD')
							: '';
						item.identityStartDate = item.identityStartDate
							? moment(item.identityStartDate).format('YYYY-MM-DD')
							: '';
						item.schoolEndDate = item.schoolEndDate ? moment(item.schoolEndDate).format('YYYY-MM-DD') : '';
						item.pcStartDate = item.pcStartDate ? moment(item.pcStartDate).format('YYYY-MM-DD') : '';
						item.pcEndDate = item.pcEndDate ? moment(item.pcEndDate).format('YYYY-MM-DD') : '';
					});
				});
		},

		exe() {
			window.href = 'QYBClient:123-MonacoGP-19279-Senna';
		},
		/**
		 * 查看用户详情
		 */
		viewUser(record) {
			ModalHelper.create(
				ViewUserComponent,
				{ EmpId: record.id, companyId: this.companyId, depId: this.DepartmentId },
				{
					width: '1000px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy.getPaged(undefined, '', 100, 0).then((res) => {
				this.companyList = res.items;
				this.companyList.forEach((item) => {
					if (item.parentId == 0) {
						var obj = {
							key: item.id,
							value: item.id,
							title: item.fullName,
						};
						this.companyTreeList.push(obj);
					}
				});
				this.initCompanyTree(this.companyTreeList);
				// if (this.companyTreeList.length > 0) {
				//     this.companyChange(this.companyTreeList[0].key);
				// }
			});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.CompanyId = value;
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getDepTreeData();
			this.getData();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepartmentId = undefined;
			this.DepTreeData = [];
			this.loading = true;
			this._departmentServiceProxy
				.getDepTreeData(this.CompanyId)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			console.log('e', e);
			this.DepartmentId = e;
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},
		/**
		 * 导出为excel
		 */
		exportToExcel() {
			this.loading = true;
			this._EmployeeServiceProxy
				.exportRoster(
					this.CompanyId,
					this.DepartmentId,
					this.empTypeCode,
					this.empStateCode,
					this.maritalStatusCode,
					this.IdCardOverTime,
					!this.isChildrenOrg,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((result) => {
					this._fileDownloadService.downloadTempFile(result);
				});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
