<template>
    <a-spin :spinning="spinning" :span="24" @click="clearMenu">
        <div v-if="isGranted('emp_isVirtual')">
            <a-checkbox v-model="isVirtual" @change="handleChange(comPanyId)">
                包含虚拟部门
            </a-checkbox>
        </div>
        <div style="display: flex; flex-direction: column;">
            <a-tree-select
                    class="left"
                    @change="handleChange"
                    :placeholder="l('Company')"
                    style="width: 100%"
                    allowClear
                    tree-default-expand-all
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :tree-data="companyTreeList"
                    v-model="comPanyId"
            />
            <a-tree
                    :style="{'overflow-y': 'auto','max-height':scroll_y+'px','overflow-x': 'none',}"
                    :treeData="treeData"
                    showIcon
                    @select="onSelect"
                    @rightClick="onRightClick"
                    v-if="treeData.length"
                    :expanded-keys="expandedKeys"
                    @expand="onExpand"
                    :selectedKeys="SelectKey"
            >
                <a-icon type="folder" slot="folder"/>
                <a-icon type="file" slot="file"/>
                <a-icon type="folder-open" slot="folder-open"/>
                <template slot="custom" slot-scope="{ expanded }">
                    <a-icon :type="expanded ? 'folder-open' : 'folder'"/>
                </template>
            </a-tree>

            <div
                    v-else-if="treeData.length <= 0 && comPanyId"
                    style="width: 100%;text-align: center;"
            >
                <a
                        style="text-decoration: underline"
                        type="primary"
                        @click.prevent="CreateDapertment"
                >暂无部门，请点击添加</a
                >
            </div>
            <!--右键菜单-->
            <div
                    :style="tmpStyle"
                    class="right-click-item"
                    style="z-index: 999"
                    v-if="NodeTreeItem"
            >
                <ul
                        v-if="
                        isGrantedAny(
                            'EditDepartment,AddDepartment,DeleteDepartment,AddRoot'
                        )
                    "
                >
                    <li>
                        <a
                                v-if="isGranted('AddRoot')"
                                @click="CreateDapertment"
                        >
                            <a-icon type="plus"/>
                            <!--                        {{ l('AddRoot') }} -->
                            添加根部门
                        </a>
                    </li>
                    <li
                            v-if="isGranted('AddDepartment')"
                            @click="addChildren()"
                    >
                        <a-icon type="plus"/>
                        <span>
                            {{ l("添加下级部门") }}
                        </span>
                    </li>
                    <li
                            v-if="isGranted('EditDepartment')"
                            @click="editDataItem()"
                    >
                        <a-icon type="edit"/>
                        <span>
                            {{ l("Edit") }}
                        </span>
                    </li>

                    <li
                            v-if="isGranted('DeleteDepartment')"
                            @click.stop="deleteDataitem()"
                    >
                        <a-popconfirm
                                placement="right"
                                :title="l('ConfirmDeleteWarningMessage')"
                                @confirm="deleteConfirm"
                                @cancel="clearMenu"
                                :okText="l('Ok')"
                                :cancelText="l('Cancel')"
                        >
                            <a-icon type="delete"/>
                            <span>
                                {{ l("Delete") }}
                            </span>
                        </a-popconfirm>
                    </li>

                </ul>
            </div>
        </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {OrganizationUnitServiceProxy} from "@/shared/service-proxies";
    import CreateOrEditOrganiaztionUnit from "../create-or-edit-organiaztion-unit/create-or-edit-organiaztion-unit";
    import {ModalHelper} from "@/shared/helpers";
    import Bus from "@/shared/bus/bus";
    import UserDepPluralismTreePanel
        from "../../../organization/userDepPluralism/userDepPluralism-tree-panel/userDepPluralism-tree-panel";
    import {
        CompanyServiceProxy,
        DepartmentServiceProxy,
    } from "../../../../shared/service-proxies";
    import CreateEditDepartment from "../../../organization/department/create-edit-department/create-edit-department";
    import CreateOrEditDataItem from "../../../commonbll/data-item/create-or-edit-data-item/create-or-edit-data-item";
    import {abpService} from "../../../../shared/abp";

    export default {
        name: "organization-unit-tree-panel",
        mixins: [AppComponentBase],
        components: {UserDepPluralismTreePanel, CreateEditDepartment},
        props: {
            depuserCount: {
                type: Number,
                default: 0,
            },
            isNotChoices: {
                type: Boolean,
                default() {
                    return true;
                },
            },
        },
        data() {
            return {
                //表格上方间隔
                defaultTop: 20,
                spinning: false,
                _organizationUnitServiceProxy: null,
                _ouData: [],
                treeData: [],
                NodeTreeItem: null, // 右键菜单
                tmpStyle: "",
                // 选中的item
                activedNode: {},
                selectedTree: {},
                companyTreeList: [],
                companyList: [],
                comPanyId: undefined,
                SelectKey: [],
                expandedKeys: [],
                _DepartmentServiceProxy: "",
                treeIsSelected: false, //是否是已经选过节点了，默认没有
                isTheSameNode: false, //两次选的是不是同一个节点
                addOrDelete: false, //是否是添加或删除操作
                depId: "", //选择的部门ID
                isVirtual: false,
                type: 0,
            };
        },
        created() {
            this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(
                this.$apiUrl,
                this.$api
            );
            this._CompanyConfigServiceProxy = new CompanyServiceProxy(
                this.$apiUrl,
                this.$api
            );
            this._DepartmentServiceProxy = new DepartmentServiceProxy(
                this.$apiUrl,
                this.$api
            );

            this.$nextTick(() => {
                Bus.$on("reloadOrganizationUnitTree", (data) => {
                    this.type = 0;
                    this.companyTreeInit();
                    if (data) {
                        this.addOrDelete = true;
                        this.depId = data.depId;
                        this.comPanyId = data.companyId;
                    }
                    //添加和编辑
                    this.type = data.type;
                    if (data.depId) {
                        this.getData();
                        this.onSelect([this.depId.toString()], this.type);
                    } else {
                        this.handleChange(this.comPanyId, this.type);
                    }
                });
            });
            this.companyTreeInit();
        },
        beforeDestroy() {
            Bus.$off("reloadOrganizationUnitTree");
        },
        mounted() {
            if (abpService.abp.userInfo.companyId) {
                this.comPanyId = abpService.abp.userInfo.companyId;
                this.handleChange(this.comPanyId);
            }
        },
        methods: {
            companyTreeInit() {
                this._CompanyConfigServiceProxy
                    .getPaged(undefined, "", 100, 0)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then((res) => {
                        this.companyTreeList = [];
                        this.companyList = res.items;
                        this.companyList.forEach((item) => {
                            if (item.parentId == 0) {
                                var obj = {
                                    key: item.id,
                                    value: item.id,
                                    title: item.fullName,
                                };
                                this.companyTreeList.push(obj);
                            }
                        });
                        this.initCompanyTree(this.companyTreeList);
                        // if (this.companyTreeList.length > 0) {
                        //     if (this.companyTreeList[0].children !== undefined) {
                        //         this.handleChange(this.companyTreeList[0].children[0].key);
                        //
                        //     } else {
                        //         this.handleChange(this.companyTreeList[0].key);
                        //
                        //     }
                        // }
                    });
            },
            /**
             * 初始化树形结构数据格式
             */
            initCompanyTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.companyList.forEach((m) => {
                        if (m.parentId == v.value) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.id,
                                value: m.id,
                                title: m.fullName,
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initCompanyTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },
            handleChange(value, type = 0) {
                this.comPanyId = value;
                this.SelectKey = undefined;
                this.depId = undefined;
                this.getData();
                this.$emit("selectTree", {companyId: this.comPanyId, type: type});
                this.$nextTick(() => {
                    Bus.$emit("selectTree", this.comPanyId);
                });
            },
            getData() {
                if (!this.comPanyId) {
                    this.treeData = [];
                    this.activedNode = undefined;
                    return;
                }
                this.spinning = true;
                this._organizationUnitServiceProxy
                    .getAllOrganizationUnitList(this.comPanyId, true)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then((result) => {
                        if (result.items.length <= 0) {
                            this.$emit("selectedNode", undefined);
                        }
                        this.treeData = [];
                        this._ouData = result.items.map((item) => {
                            return {
                                title:
                                    item.displayName + "(" + item.memberCount + ")",
                                key: item.id.toString(),
                                id: item.id,
                                isLeaf: true,
                                slots: {
                                    icon: "",
                                },
                                expanded: true,
                                isMatched: true,
                                isVirtual: item.isVirtual,
                                code: item.code,
                                memberCount: item.memberCount,
                                dto: item,
                                parentId: item.parentId,
                                children: [],
                                sortCode: item.sortCode
                            };
                        });
                        this._ouData.forEach((item) => {
                            if (!item.parentId) {
                                this.treeData.push(item);
                            }
                        });
                        this.treeData = this.depInit(this.treeData);
                        //增加或者删除
                        if (this.addOrDelete) {
                            //重新触发选择事件
                            this.onSelect([this.depId + ""], this.type);
                        } else {
                            this.$emit("selectedNode", {
                                companyId: this.comPanyId,
                                depId: {id: undefined},
                                type: this.type
                            });
                        }
                    });
            },
            /**
             * 部门结构初始化话
             */
            depInit(data) {
                data.forEach((d) => {
                    let c = this._ouData.filter((item) => item.parentId == d.key);
                    if (c.length > 0) {
                        d.isLeaf = false;
                        if (this.isVirtual) {
                            d.children = this.depInit(c);
                        } else {
                            if (d.isVirtual) {
                                let e = this.depInit(c);
                                //子查询结束后移除本身，子级占用父级位置
                                data = data.filter((item) => item.key != d.key);
                                data = [...data, ...e];
                            } else {
                                let j = this.depInit(c);
                                if (j.length > 0) {
                                    d.children = this.depInit(c);
                                }
                            }
                        }
                    } else {
                        if (!this.isVirtual) {
                            if (d.isVirtual) {
                                let e = this.depInit(c);
                                //子查询结束后移除本身，子级占用父级位置
                                data = data.filter((item) => item.key != d.key);
                                data = [...data, ...e];
                            }
                        }
                    }
                });
                return data.sort((a, b) => a.sortCode - b.sortCode);
            },
            /**
             * 重写选中的逻辑，不可删除
             */
            onSelect(selectedKeys, type = 0) {
                this.SelectKey = selectedKeys;
                if (selectedKeys.length > 0) {
                    this.activedNode = this._ouData
                        ? {
                            id: this._ouData.find(
                                (item) =>
                                    parseInt(item.id) == parseInt(selectedKeys[0])
                            )
                                ? this._ouData.find(
                                    (item) =>
                                        parseInt(item.id) ==
                                        parseInt(selectedKeys[0])
                                ).id
                                : undefined,
                        }
                        : {id: this.SelectKey[0]};
                    this.$emit("selectedNode", {
                        companyId: this.comPanyId,
                        depId: this.activedNode,
                        type: type
                    });
                    this.$emit(
                        "selectDepTree",
                        this._ouData
                            ? this._ouData.find(
                            (item) =>
                                parseInt(item.id) == parseInt(selectedKeys[0])
                            )
                            : undefined
                    );
                } else {
                    this.$emit("selectedNode", {
                        companyId: this.comPanyId,
                        depId: {id: undefined},
                    });
                    this.$emit("selectDepTree", {isVirtual: false});
                }
            },
            /**
             * 展开
             */
            onExpand(expandedKeys, record) {
                this.expandedKeys = expandedKeys;
            },
            /**
             * 右键事件
             */
            onRightClick({event, node}) {
                this.dataItemId = node.eventKey;
                const x =
                    event.currentTarget.offsetLeft +
                    event.currentTarget.clientWidth;
                const y = event.currentTarget.offsetTop;
                this.NodeTreeItem = true;
                this.tmpStyle = {
                    position: "absolute",
                    maxHeight: 40,
                    textAlign: "center",
                    left: `${x + 10 - 0}px`,
                    top: `${y + 6 - 0}px`,
                    display: "flex",
                    flexDirection: "row",
                };
            },
            /**
             * 编辑节点
             */
            editDataItem() {
                let companyId = this.comPanyId;
                ModalHelper.create(
                    CreateEditDepartment,
                    {id: this.dataItemId * 1, companyId},
                    {
                        width: "900px",
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.getData();
                    }
                });
            },
            /**
             * 添加子节点
             */
            addChildren() {
                ModalHelper.create(
                    CreateEditDepartment,
                    {id: null, companyId: this.comPanyId, pid: this.dataItemId},
                    {
                        width: "900px",
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.getData();
                    }
                });
            },
            /**
             * 删除节点
             */
            deleteConfirm() {
                this._DepartmentServiceProxy.delete(this.dataItemId).then((res) => {
                    this.$notification["success"]({
                        message: this.l("SavedSuccessfully"),
                    });
                    this.getData();
                    this.clearMenu();
                });
            },
            deleteDataitem() {
                return;
            },
            /**
             * 用于点击空白处隐藏增删改菜单
             */
            clearMenu() {
                this.NodeTreeItem = null;
            },
            /**
             *添加部门
             */
            CreateDapertment() {
                if (!this.comPanyId) {
                    abp.message.warn(this.l("请选择公司"));
                    return;
                }
                let pid = undefined; //根组织无需pid
                // if(this.selectedRowKeys.length<=0||this.selectedRowKeys.length>1){
                //     pid=undefined;
                // }
                // else {
                //     pid=this.selectedRowKeys[0];
                // }
                let companyId = this.comPanyId;
                ModalHelper.create(
                    CreateEditDepartment,
                    {id: null, companyId, pid: pid},
                    {
                        width: "900px",
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.getData();
                        // this.selectedRowKeys = [];
                        // this.selectedRows=[];
                        // this.getDepartmentList();
                    }
                });
            },
        },
    };
</script>

<style scoped lang="less">
    .header {
        height: 50px;
        border-bottom: 1px solid #e8e8e8;

        p {
            line-height: 50px;

            &.left {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 500;
                margin-left: 20px;
            }

            a {
                margin-left: 10px;
            }
        }
    }

    .tree {
        margin: 0 20px 20px;
    }

    .right-click-item {
        position: relative;
        margin: 0;
        padding: 4px 0;
        text-align: left;
        list-style-type: none;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 4px;
        outline: none;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        -webkit-transform: translate3d(0, 0, 0);

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                clear: both;
                margin: 0;
                padding: 5px 12px;
                color: rgba(0, 0, 0, 0.65);
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                white-space: nowrap;
                cursor: pointer;
                transition: all 0.3s;
                text-align: left;
            }
        }
    }
</style>
