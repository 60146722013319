<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8" style="margin-bottom: 8px;">
				<a-col :md="12" :sm="12">
					<!-- 添加岗位等级 -->
					<a-button :type="'primary'" @click="createPostGrade()" v-if="isGranted('add_post_grade')">
						<a-icon type="plus" />
						<span>{{ l('Create') }}</span>
					</a-button>
					<!-- 编辑岗位等级 -->
					<a-button :type="'primary'" v-if="isGranted('edit_post_grade')" @click="editPostGrade()">
						<a-icon type="edit" />
						<span>{{ l('Edit') }}</span>
					</a-button>
					<!-- 批量删除岗位等级 -->
					<a-button :type="'danger'" v-if="isGranted('delete_post_grade')" @click="batchDelete()">
						<a-icon type="delete" />
						<span>{{ l('Delete') }}</span>
					</a-button>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(row) => row.id"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
			>
				<p class="subscriptionEndUtc" slot="subscriptionEndUtc" slot-scope="record">
					{{ record }}
				</p>
				<a class="isActive" slot="isActive" slot-scope="record">
					<a-icon v-if="record" type="check" />
					<a-icon v-if="!record" type="close" />
				</a>
				<p class="creationTime" slot="creationTime" slot-scope="record">
					{{ record | moment('YYYY-MM-DD') }}
				</p>
			</a-table>
		</template>
	</q-container>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import { OrgPostGradeServiceProxy } from '../../../../shared/service-proxies';
import * as _ from 'lodash';
import AddPostGradeComponent from '../add-grade/add-grade';
import { ModalHelper } from '@/shared/helpers';

export default {
	name: 'post-grade-view',
	mixins: [ModalComponentBase],
	data() {
		return {
			// 表格
			columns: [
				{
					title: this.l('GradeName'),
					dataIndex: 'gradeName',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'gradeName' },
					width: '150px',
					fixed: 'left',
				},
				{
					title: this.l('BasePay'),
					dataIndex: 'basePay',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'basePay' },
					width: '100px',
				},
				{
					title: this.l('ManagerAdd'),
					dataIndex: 'managerAdd',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'managerAdd' },
					width: '150px',
				},
				{
					title: this.l('PsotSubsidy'),
					dataIndex: 'psotSubsidy',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'psotSubsidy' },
					width: '150px',
				},
				{
					title: this.l('SkillSubsidy'),
					dataIndex: 'skillSubsidy',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'skillSubsidy' },
					width: '150px',
				},

				{
					title: this.l('OverTimeSubsidy'),
					dataIndex: 'overTimeSubsidy',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'overTimeSubsidy' },
					width: '150px',
				},
				{
					title: this.l('OtherSubsidy'),
					dataIndex: 'otherSubsidy',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'otherSubsidy' },
					width: '150px',
				},
				{
					title: this.l('SalaryIncome'),
					dataIndex: 'salaryIncome',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'salaryIncome' },
					width: '150px',
				},
				{
					title: this.l('MonthlyBonus'),
					dataIndex: 'monthlyBonus',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'monthlyBonus' },
					width: '150px',
				},
				{
					title: this.l('SynthesisIncome'),
					dataIndex: 'synthesisIncome',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'synthesisIncome' },
					width: '150px',
				},
			],

			tableData: [],
			postId: undefined,
		};
	},
	created() {
		this._orgPostGradeServiceProxy = new OrgPostGradeServiceProxy(this.$apiUrl, this.$api);
	},
	methods: {
		/**
		 * 获取岗位等级列表
		 */
		getData(postId) {
			this.postId = postId;
			this.selectedRowObj = {};
			if (!postId) {
				this.tableData = [];
				return;
			}

			this.loading = true;
			this._orgPostGradeServiceProxy
				.getPostGradeListByPostId(postId)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = [];
					this.tableData = res;
					this.tableData.map((item) => {
						item.checked = false;
					});
				});
		},
		/**
		 *编辑岗位等级
		 */
		editPostGrade() {
			if (!this.postId) {
				abp.message.warn(this.l('PleaseSelectAXPlaceHolder', this.l('OrgPost')));
				return;
			}
			if (this.selectedRowKeys.length <= 0 || this.selectedRowKeys.length > 1) {
				return abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
			}
			ModalHelper.create(
				AddPostGradeComponent,
				{ id: this.selectedRowKeys[0], postId: this.postId },
				{
					width: '900px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData(this.postId);
				}
			});
		},
		/**
		 *添加岗位等级
		 */
		createPostGrade() {
			if (!this.postId) {
				abp.message.warn(this.l('PleaseSelectAXPlaceHolder', this.l('OrgPost')));
				return;
			}
			ModalHelper.create(
				AddPostGradeComponent,
				{ id: null, postId: this.postId },
				{
					width: '900px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData(this.postId);
				}
			});
		},
		/**
		 * 批量删除岗位等级
		 */
		batchDelete() {
			const selectCount = this.selectedRowKeys.length;
			if (selectCount <= 0) {
				abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
				return;
			}
			this.$confirm({
				title: this.l('ConfirmInformation'),
				content: this.l('ConfirmDeleteXItemsWarningMessage', selectCount),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					const ids = _.map(this.selectedRowKeys);
					this.spinning = true;
					this._orgPostGradeServiceProxy
						.batchDelete(ids)
						.finally(() => {
							this.spinning = false;
						})
						.then(() => {
							this.getData(this.postId);
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
						});
				},
			});
		},
	},
};
</script>

<style scoped></style>
