<template>
	<a-row :gutter="8" style="height: 100%">
		<!--业务字典树-->
		<a-col :span="6">
			<a-card :bordered="false" @click="clearMenu">
				<a-row :gutter="8">
					<a-col :span="10">
						<p class="left">
							<a-icon type="share-alt" />
							{{ l('字典列表') }}
						</p>
					</a-col>
					<a-col :span="14">
						<p v-if="isGranted('add_root')">
							<a @click.stop="addDataItem()">
								<a-icon type="plus" />
								{{ l('AddRoot') }}
							</a>
							<a-divider type="vertical"></a-divider>
							<a :title="l('Refresh')" @click.stop="getData()">
								<a-icon type="reload" />
							</a>
						</p>
					</a-col>
				</a-row>
				<a-spin :spinning="treespinning">
					<div class="tree-container">
						<a-tree :tree-data="treeData" @select="onSelect" @rightClick="onRightClick">
							<a-icon type="folder" slot="folder" />
							<a-icon type="file" slot="file" />
							<a-icon type="folder-open" slot="folder-open" />
							<template slot="custom" slot-scope="{ expanded }">
								<a-icon :type="expanded ? 'folder-open' : 'folder'" />
							</template>
						</a-tree>
					</div>
					<!--右键菜单-->
					<div :style="tmpStyle" class="right-click-item" v-if="NodeTreeItem">
						<ul>
							<li @click="editDataItem()" v-if="isGranted('data_item_edit')">
								<a-icon type="edit" />
								<span>
									{{ l('Edit') }}
								</span>
							</li>
							<li @click="addchildren()" v-if="isGranted('data_item_add')">
								<a-icon type="plus" />
								<span>
									{{ l('AddSubUnit') }}
								</span>
							</li>
							<li @click.stop="deleteDataitem()" v-if="isGranted('data_item_delete')">
								<a-popconfirm
									placement="right"
									:title="l('ConfirmDeleteWarningMessage')"
									@confirm="deleteconfirm"
									@cancel="clearMenu"
									:okText="l('Ok')"
									:cancelText="l('Cancel')"
								>
									<a-icon type="delete" />
									<span>
										{{ l('Delete') }}
									</span>
								</a-popconfirm>
							</li>
						</ul>
					</div>
				</a-spin>
			</a-card>
		</a-col>

		<a-col :span="18">
			<a-card
				:title="l('DataItemHeader', title, parentName)"
				:bordered="false"
				@click="clearMenu"
				style="height: 100%"
			>
				<a-spin :spinning="spinning">
					<!--字典明细操作部分-->
					<a-row :gutter="[8, 32]">
						<a-col :md="20" :sm="12">
							<!-- 添加字典明细 -->
							<a-button
								:type="'primary'"
								v-if="isGranted('data_item_detail_add')"
								@click="CreateDataItemDetail()"
							>
								<a-icon type="plus" />
								<span>{{ l('CreateDataItemDetail') }}</span>
							</a-button>
							<!-- 批量删除 -->

                            <a-divider style="margin:0 30px;" type="vertical" />
                            <label>源语言：<a-tag style="font-weight: 700; font-size: 0.8rem;" color="blue">{{  localelanguage.displayName}}</a-tag></label>
                            <!-- 批量删除 -->
                            目标语言：
                            <a-select
                                show-search
                                placeholder="请选择目标语言"
                                option-filter-prop="children"
                                style="width: 150px; margin-left: 10px"
                                v-model="languageId"
                                @change="languageChange"
                            >
                                <a-select-option
                                    v-for="item in selectlanguagelist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    {{ item.displayName }}
                                </a-select-option>
                            </a-select>

						</a-col>
					</a-row>

					<a-row>
						<a-table
							class="list-table"
							@change="getDataItemDtailList"
							:pagination="false"
							:columns="columns"
							:rowKey="(tableDatas) => tableDatas.id"
							:scroll="{ y: scroll_y }"
							:dataSource="tableData"
						>

                        <template
                        slot="language"
                        slot-scope="text, record, index"
                    >
                        <div
                            v-if="record.edit"
                            class="editable-cell-input-wrapper"
                        >
                            <a-input
                                v-model="record.language"
                                style="color: #1890ff;width: 150px;"
                                
                            />
                            <a-icon
                                type="check"
                                class="editable-cell-icon-check"
                                @click="check(record.language, index)"
                            />
                        </div>
                        <div
                            v-else
                            class="editable-cell-text-wrapper"
                            style="color: #1890ff"
                        >
                            {{ text }}
                            <a-icon
                                type="edit"
                                class="editable-cell-icon"
                                @click="edit(record, index)"
                            />
                        </div>
                    </template>

							<p class="subscriptionEndUtc" slot="subscriptionEndUtc" slot-scope="record">{{ record }}</p>
							<a class="isActive" slot="isActive" slot-scope="record">
								<a-icon v-if="record" type="check" />
								<a-icon v-if="!record" type="close" />
							</a>
							<p class="creationTime" slot="creationTime" slot-scope="record">
								{{ record | moment('YYYY-MM-DD') }}
							</p>
							<template slot-scope="text, record" slot="Actions">
								<!-- 修改 -->
								<a
									@click="editDataItemDetail(record.id, record.isSyetem,record)"
									v-if="isGranted('data_item_detail_edit')"
								>
									<a-icon type="edit" />
									<span>{{ l('Edit') }}</span>
									<a-divider type="vertical" />
								</a>
								<!-- 删除 -->
								<a-popconfirm
									placement="top"
									:okText="l('Ok')"
									:cancelText="l('Cancel')"
									@confirm="deleteDataItemDetail(record.id, record.isSyetem)"
									v-if="isGranted('data_item_detail_delete')"
								>
									<template slot="title">{{ l('ConfirmDeleteWarningMessage') }}</template>
									<a>
										<a-icon type="delete" />
										<span>{{ l('Delete') }}</span>
									</a>
								</a-popconfirm>
							</template>
						</a-table>
					</a-row>
				</a-spin>
			</a-card>
		</a-col>
	</a-row>
</template>

<script>
import { ModalComponentBase } from '@/shared/component-base';
import { ModalHelper } from '@/shared/helpers';
import { DataItemServiceProxy, DataItemDetailServiceProxy,QYBLanguageServiceProxy, } from '@/shared/service-proxies';
import CreateOrEditDataItem from '../../commonbll/data-item/create-or-edit-data-item/create-or-edit-data-item';
import CreateOrEditDataItemDetail from '../../commonbll/data-item/creata-or-edit-data-item-detail/creata-or-edit-data-item-detail';
import { abpService } from '@/shared';

export default {
	name: 'data-item',
	mixins: [ModalComponentBase],
	components: {
		CreateOrEditDataItem,
		CreateOrEditDataItemDetail,
	},
	data() {
		return {
			NodeTreeItem: null,
			tmpStyle: '',
			//字典ID
			dataitem: 0,
			//字典树
			treeData: [],
			// 表格
			columns: [
				{
					title: this.l('Code'),
					dataIndex: 'itemDetailCode',
					align: 'center',
					scopedSlots: { customRender: 'itemDetailCode' },
				},
				{
					title: this.l('Name'),
					dataIndex: 'itemDetailName',
					align: 'center',
					scopedSlots: { customRender: 'itemDetailName' },
				},
                {
                    title: this.l("language"),
                    dataIndex: "language",
                    align: "center",
                    width:'200',
                    scopedSlots: { customRender: "language" },
                },
				{
					title: this.l('isDefault'),
					dataIndex: 'isSyetem',
					align: 'center',
					scopedSlots: { customRender: 'isSyetem' },
					customRender: (text, row, index) => {
						var message = this.getText(text);
						return {
							children: message,
							attrs: {},
						};
					},
				},
				{
					title: this.l('Sort'),
					dataIndex: 'sortCode',
					align: 'center',
					scopedSlots: { customRender: 'sortCode' },
				},
				{
					title: this.l('Actions'),
					dataIndex: 'Actions',
					align: 'center',
					scopedSlots: { customRender: 'Actions' },
				},
			],

			tableData: [],
			tree: [],
			//选中的树节点ID
			selectDataItemId: null,
			treespinning: false,
			spinning: false,
			title: '',
			parentName: '',
			mainIsDefault: undefined,
			detailedIsDefault: undefined,
            localelanguage: {},
            languageId: undefined,
            selectlanguagelist:undefined,
            multilingualTypes: [],
		};
	},
	created() {
		this._dataitemServiceProxy = new DataItemServiceProxy(this.$apiUrl, this.$api);
		this._dataitemdeatailServiceProxy = new DataItemDetailServiceProxy(this.$apiUrl, this.$api);
        this._qybLanguageServiceProxy = new QYBLanguageServiceProxy(
            this.$apiUrl,
            this.$api
        );
		this.getData();
	},
    async mounted() {
        this.multilingualTypes =
            await this._qybLanguageServiceProxy.getLanguages();
        
        
        this.Headers();
    },
	methods: {

        ISchangge(){
            var locale = localStorage.getItem("qyb-language");
            this.selectlanguagelist=  this.multilingualTypes.filter((item,index,arry)=>{
                if(item.name!=locale){
                    return item;
                }
            })
            this.languageId= this.selectlanguagelist[0].id;
            this.columns[2].title=this.selectlanguagelist[0].displayName;
        },
        check(value, index) {
          
if(value){


           
            this.tableData[index].language = value;
            this.tableData[index].edit = false;
            this.$set(this.tableData, index, this.tableData[index]);
            this._qybLanguageServiceProxy
            .updateKeyValue(this.languageId,this.tableData[index].id,value)
            .finally((res)=>{

            })
            .then((res)=>{
                // this.getLanguageCode();
                this.$notification['success']({
						message: this.l('SavedSuccessfully'),
				});
            })
        }
        
        },

        edit(row, index) {
            row.edit = true;
            this.$set(this.tableData, index, row);
        },
        languageChange() {
          var entity=  this.selectlanguagelist.find(n=>{
                if(n.id==this.languageId){
                    return n;
                }
            });
            this.columns[2].title=entity.displayName;
            this.getLanguageCode();
            
        },
        resLisScroll() {
            let toTop = this.$refs.resListRef.scrollTop;
            this.divTop = toTop;
        },
        Headers() {
            var locale = localStorage.getItem("qyb-language");
            this.localelanguage = this.multilingualTypes.find((n) => {
                if (n.name == locale) {
                    return n;
                }
            });
            this.columns[2].title=this.localelanguage.displayName;
            this.columns[1].title=this.localelanguage.displayName;
          
        },
        getLanguageCode(list) {
            if(Array.isArray(list)){
               
            }
            else{
                list=this.tableData;
            }
            if (this.selectDataItemId > 0) {
                var id = this.languageId;
                this.spinning = true;
                this._qybLanguageServiceProxy
                    .getItemDetailKeyValue(this.selectDataItemId, id)
                    .finally((res) => {
                        this.spinning = false;
                    })
                    .then((res) => {
                        debugger;
                        if (res.length > 0) {
                           
                            for (var item of list) {
                                var entity = res.find((n) => {
                                    if (n.key == item.itemDetailCode) {
                                        return n;
                                    }
                                });
                                var index = list.findIndex(
                                        (n) => n.id == item.id
                                    );
                                if (entity) {
                                    item.language = entity.value;
                                
                                    item.edit = false;

                                    this.$set(list, index, item);
                                }
                                else{
                                    item.language="";

                                    this.$set(list, index, item);
                                }
                     
                            }
                        
                        } else {
                    
                            for (var item of list) {
                                item.language = "";
                                var index = list.findIndex(
                                    (n) => n.id == item.id
                                );
                                this.$set(list, index, item);
                            }
                        }

                        this.tableData = list;

                    });
            }
        },
        getLocaLanguageCode(list) {
            if (this.selectDataItemId > 0) {
                var id = this.languageId;
                this.spinning = true;
                this._qybLanguageServiceProxy
                    .getItemDetailKeyValue(this.selectDataItemId, this.localelanguage.id)
                    .finally((res) => {
                        this.spinning = false;
                    })
                    .then((res) => {
                        if (res.length > 0) {
                            for (var item of list) {
                                var entity = res.find((n) => {
                                    if (n.key == item.itemDetailCode) {
                                        return n;
                                    }
                                });
                                if (entity) {
                                    if(entity.value){

                                   
                                    item.itemDetailName = entity.value;
                                    var index = list.findIndex(
                                        (n) => n.id == item.id
                                    );

                                    item.edit = false;
                                }

                                    // this.$set(list, index, item);
                                }
                            }
                        } 
                        this.tableData = list;
                    });
            }
        },

		/**
		 * 获取字典数据
		 */
		getData() {
			this.treespinning = true;
			this._dataitemServiceProxy
				.getTreeList()
				.finally(() => {
					this.treespinning = false;
				})
				.then((res) => {
					this.tree = [...res];
					this.treeData = [];
					this.tree.forEach((item) => {
						if (item.parentId == 0) {
							this.treeData.push(item);
						}
					});
					this.initTree(this.treeData);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集
				var pid = '';
				if (v.parentId != 0) {
					//校验是否为父级数据
					pid = v.parentId;
				}
				var keyid = v.key; //拼接父级ID和子集ID产生Key
				v.key = ''; //创建Key
				v.key = keyid;
				v.children = []; //创建空的子集
				this.tree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var mid = '';
						if (m.parentId != 0) {
							mid = v.parentId;
						}
						var mkeyid = m.key; //拼接子集ID和其子集ID产生Key
						m.key = '';
						m.key = mkeyid;
						v.children.push(m); //放进父级的集合中
						this.initTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 添加字典
		 */
		addDataItem() {
			ModalHelper.create(
				CreateOrEditDataItem,
				{ id: null, type: null },
				{
					width: '500px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
		/**
		 * 点击数
		 */
		onSelect(keys, node, event) {
			this.selectDataItemId = keys[0];

            this.languageId = this.localelanguage.id;
            this.ISchangge();

			this.getDataItemDtailList();
			if (keys[0]) {
				this.title = '-' + node.node.title;
			} else {
				this.title = '';
			}
		},
		/**
		 * 右键事件
		 */
		onRightClick({ event, node }) {
			this.mainIsDefault = node._props.dataRef.isSyetem;
			this.dataItemId = node.eventKey;
			const x = event.currentTarget.offsetLeft + event.currentTarget.clientWidth;
			const y = event.currentTarget.offsetTop;
			this.NodeTreeItem = true;
			this.tmpStyle = {
				position: 'absolute',
				maxHeight: 40,
				textAlign: 'center',
				left: `${x + 10 - 0}px`,
				top: `${y + 6 - 0}px`,
				display: 'flex',
				flexDirection: 'row',
			};
		},
		/**
		 * 用于点击空白处隐藏增删改菜单
		 */
		clearMenu() {
			this.NodeTreeItem = null;
		},
		/**
		 * 删除字典
		 */
		deleteconfirm() {
			var iscorrect = this.isCreatorDelete();
			if (iscorrect) {
				this._dataitemServiceProxy.delete(this.dataItemId).then((res) => {
					this.NodeTreeItem = null;
					this.notify.success(this.l('SavedSuccessfully'));
					this.getData();
				});
			}
		},
		/**
		 * 获取字典明细列表
		 */
		getDataItemDtailList() {
			this.spinning = true;
			this._dataitemdeatailServiceProxy
				.getListByByDataItmeCode(
					this.selectDataItemId == undefined ? undefined : this.selectDataItemId,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
                    this.getLanguageCode(res);
                    this.getLocaLanguageCode(res);
					// this.tableData = res;
					if (res.length > 0) {
						this.parentName = '(' + res[0].itemCode + ')';
					} else {
						this.parentName = '';
					}
				});
		},
		/**
		 * 单个删除字典明细
		 */
		deleteDataItemDetail(id, isSyetem) {
			this.detailedIsDefault = isSyetem;
			if (this.isTableDelete(this.detailedIsDefault)) {
				this._dataitemdeatailServiceProxy.delete(id).then((res) => {
					this.notify.success(this.l('SavedSuccessfully'));
					this.getDataItemDtailList();
				});
			}
		},
		/**
		 *编辑字典明细
		 */
		editDataItemDetail(id, isSyetem,record) {
			this.detailedIsDefault = isSyetem;
			if (this.isTableDelete(this.detailedIsDefault)) {
				ModalHelper.create(
					CreateOrEditDataItemDetail,
                    { id: id, localelanguage: this.localelanguage,Name:record.itemDetailName },
					{
						width: '500px',
					}
				).subscribe((res) => {
					if (res) {
						this.getDataItemDtailList();
					}
				});
			}
		},

		/**
		 * 编辑字典
		 */
		editDataItem() {
			var iscorrect = this.isCreatorDelete();
			if (iscorrect) {
				ModalHelper.create(
					CreateOrEditDataItem,
					{ id: this.dataItemId, type: null },
					{
						width: '500px',
					}
				).subscribe((res) => {
					if (res) {
						this.getData();
					}
				});
			}
		},
		/**
		 * 添加子节点
		 */
		addchildren() {
			ModalHelper.create(
				CreateOrEditDataItem,
				{ id: this.dataItemId, type: 1 },
				{
					width: '500px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
		/**
		 *添加字典明细
		 */
		CreateDataItemDetail() {
			if (!this.selectDataItemId) {
				abp.message.warn(this.l('请选择你要添加类型'));
				return;
			}
			ModalHelper.create(
				CreateOrEditDataItemDetail,
				{ id: null, itemId: this.selectDataItemId,
                    localelanguage: this.localelanguage, },
				{
					width: '500px',
				}
			).subscribe((res) => {
				if (res) {
					this.getDataItemDtailList();
				}
			});
		},

		/**
		 * 判断树是否可删除或修改
		 */
		isCreatorDelete(e) {
			var admin = abpService.abp.userInfo.isTenantAdmin;
			if (admin == 1) {
				var iscorrect = true;
				return iscorrect;
			} else {
				if (this.mainIsDefault) {
					abp.message.warn('非管理员账号不可操作！');
					var iscorrect = false;
					return iscorrect;
				} else {
					var iscorrect = true;
					return iscorrect;
				}
				return iscorrect;
			}
			return iscorrect;
		},

		/**
		 * 判断表是否可删除
		 */
		isTableDelete(e) {
			var admin = abpService.abp.userInfo.isTenantAdmin;
			if (admin == 1) {
				var iscorrect = true;
				return iscorrect;
			} else {
				if (this.detailedIsDefault) {
					abp.message.warn('非管理员账号不可操作！');
					var iscorrect = false;
					return iscorrect;
				} else {
					var iscorrect = true;
					return iscorrect;
				}
				return iscorrect;
			}
			return iscorrect;
		},

		/**
		 * 表格字段转换
		 */
		getText(text) {
			let isText = '';
			if (text) {
				isText = '是';
			} else {
				isText = '否';
			}
			return isText;
		},
		//
		deleteDataitem() {},
	},
};
</script>

<style scoped lang="less">
.tree-container {
	max-height: calc(100vh - 204px);
	overflow-y: scroll;
}
.tree-container::-webkit-scrollbar {
	width: 5px;
	height: 10px;
}
/* 滚动槽 */
.tree-container::-webkit-scrollbar-track {
	-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
	border-radius: 10px;
}
/* 滚动条滑块 */
.tree-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
.right-click-item {
	position: relative;
	margin: 0;
	padding: 4px 0;
	text-align: left;
	list-style-type: none;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 4px;
	outline: none;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
	-webkit-transform: translate3d(0, 0, 0);

	ul {
		list-style: none;
		margin: 0;
		padding: 0;

		li {
			clear: both;
			margin: 0;
			padding: 5px 12px;
			color: rgba(0, 0, 0, 0.65);
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			white-space: nowrap;
			cursor: pointer;
			transition: all 0.3s;
			text-align: left;
		}
	}
}

.header {
	height: 50px;
	border-bottom: 1px solid #e8e8e8;

	p {
		line-height: 50px;

		&.left {
			font-size: 16px;
			color: rgba(0, 0, 0, 0.85);
			font-weight: 500;
			margin-left: 20px;
		}

		a {
			margin-left: 10px;
		}
	}
}

.tree {
	margin: 20px;
}

.organization-unit-tree-panel {
	background-color: #fff;
	border: 1px solid #e8e8e8;
}

.units-header {
	height: 50px;
	border-bottom: 1px solid #e8e8e8;

	> .title {
		line-height: 50px;
		font-size: 16px;
		color: rgba(0, 0, 0, 0.85);
		font-weight: 500;
		margin-left: 20px;
	}

	p {
		line-height: 50px;

		&.left {
			font-size: 16px;
			color: rgba(0, 0, 0, 0.85);
			font-weight: 500;
			margin-left: 20px;
		}

		a {
			margin-left: 10px;
		}
	}
}

/deep/ .ant-tabs-bar {
	margin: 0 20px;
}

// 暂无数据
.no-data {
	border: 1px solid #e8e8e8;
	margin: 20px;

	p {
		text-align: center;
		margin-bottom: 0;
		line-height: 120px;
	}
}
</style>
