<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row>
				<a-col :span="12">
					<a-button :type="'primary'" v-if="isGranted('sysclassify_add')" @click="create()">
						<a-icon type="plus" />
						<span>{{ l('Create') }}</span>
					</a-button>
					<a-button :type="'primary'" v-if="isGranted('sysclassify_edit')" @click="edit()">
						<a-icon type="edit" />
						<span>{{ l('Edit') }}</span>
					</a-button>
					<a-button :type="'danger'" v-if="isGranted('sysclassify_delete')" @click="BatchDelete">
						<a-icon type="delete" />
						<span>{{ l('Delete') }}</span>
					</a-button>
				</a-col>
				<a-col :span="12" style="text-align: right">
					<a-input-search
						style="margin-top: 4px;width: 200px"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="refresh"
						enterButton
						v-model="filterText"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<span slot="icon" slot-scope="text, record">
					<icon-font :type="record.icon" style="font-size:40px" v-if="record.icon"></icon-font>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import CreateOrEditSysclassify from './create-or-edit-sysclassify/create-or-edit-sysclassify';
import modalHelper from '../../../shared/helpers/modal/modal-helper';
import { SysClassifyServiceProxy } from '../../../shared/service-proxies';
import moment from 'moment';
import * as _ from 'lodash';

export default {
	name: 'sysclassify',
	mixins: [AppComponentBase],
	components: { CreateOrEditSysclassify },
	data() {
		return {
			// 用户表格
			columns: [
				{
					title: '用户名称',
					dataIndex: 'name',
					sorter: true,
					fixed: 'left',
					width: 100,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'name' },
				},
				{
					title: '用户编码',
					dataIndex: 'code',
					sorter: true,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'code' },
				},
				{
					title: '图标',
					dataIndex: 'icon',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'icon' },
				},
				{
					title: '创建时间',
					dataIndex: 'creationTime',
					sorter: true,
					ellipsis: true,
					align: 'center',
					width: 180,
					scopedSlots: { customRender: 'creationTime' },
				},
				{
					title: '备注',
					dataIndex: 'description',
					sorter: true,
					ellipsis: true,
					align: 'center',
					width: 180,
					scopedSlots: { customRender: 'description' },
				},
			],
			tableData: [],
		};
	},
	created() {
		this._sysClassifyServiceProxy = new SysClassifyServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.refresh();
	},
	methods: {
		getData() {
			this.loading = true;
			this._sysClassifyServiceProxy
				.getPaged(this.filterText, this.request.sorting, this.request.maxResultCount, this.request.skipCount)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.creationTime = item.creationTime
							? moment(item.creationTime).format('YYYY-MM-DD HH:mm')
							: ' ';
					});
					this.totalItems = res.totalCount;
				});
		},
		create() {
			modalHelper
				.create(
					CreateOrEditSysclassify,
					{},
					{
						width: '600px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.refresh();
					}
				});
		},
		edit() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			if (this.selectedRowKeys.length > 1) {
				return abp.message.warn('只能选择一项进行操作');
			}
			modalHelper
				.create(
					CreateOrEditSysclassify,
					{
						id: this.selectedRowKeys[0],
					},
					{
						width: '600px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.refresh();
					}
				});
		},
		BatchDelete() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			this.$confirm({
				title: '确认操作',
				content: '你确定要删除这' + this.selectedRowKeys.length + '项数据吗？',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;
					this._sysClassifyServiceProxy
						.batchDelete(this.selectedRowKeys)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.refresh();
						});
				},
			});
		},
	},
};
</script>

<style scoped></style>
