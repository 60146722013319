<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8" type="flex" justify="end">
				<!-- 错误状态 -->
				<a-col :span="4">
					<a-select v-model="hasException" style="width: 100%" @change="search">
						<a-select-option value="">{{ l('All') }}</a-select-option>
						<a-select-option value="false">{{ l('Success') }}</a-select-option>
						<a-select-option value="true">{{ l('HasError') }}</a-select-option>
					</a-select>
				</a-col>
				<!--日期范围 -->
				<a-col :span="6">
					<a-range-picker
						:placeholder="[l('StartDateTime'), l('EndDateTime')]"
						@change="search"
						v-model="startToEndDate"
						style="width: 100%"
					/>
				</a-col>
				<!-- 用户名 -->
				<a-col :span="5">
					<a-input-search
						name="username"
						:placeholder="l('UserName')"
						style="margin-bottom: 3px"
						@search="search"
						enterButton
						v-model="username"
						v-decorator="['username']"
					/>
				</a-col>

				<!-- -->
			</a-row>
			<a-row type="flex" justify="end">
				<!-- 服务 -->
				<a-col :span="4" v-if="advancedFiltersVisible">
					<a-input
						:placeholder="l('Service')"
						v-model="serviceName"
						style="width: 95%"
						v-decorator="['serviceName']"
					/>
				</a-col>
				<!-- 持续时间 -->
				<a-col :span="6" v-if="advancedFiltersVisible">
					<a-row>
						<a-col :span="11">
							<a-input-number
								id="MinExecutionDuration"
								:min="0"
								:placeholder="l('MinExecutionDuration')"
								style="text-align: center; width: 100%;"
								:max="86400000"
								:step="1"
								v-model="minExecutionDuration"
							/>
						</a-col>
						<a-col :span="1"> ～</a-col>
						<a-col :span="11">
							<a-input-number
								id="MaxExecutionDuration"
								:min="0"
								:placeholder="l('MaxExecutionDuration')"
								style="text-align: center; width: 100%;"
								:max="86400000"
								:step="1"
								v-model="maxExecutionDuration"
							/>
						</a-col>
					</a-row>
				</a-col>
				<!-- 方法名 -->
				<a-col :span="6" v-if="advancedFiltersVisible">
					<a-input
						:placeholder="l('MethodName')"
						v-model="methodName"
						style="width: 95%"
						v-decorator="['methodName']"
					/>
				</a-col>
				<!-- 浏览器 -->
				<a-col :span="6" v-if="advancedFiltersVisible">
					<a-input
						:placeholder="l('Browser')"
						v-model="browserInfo"
						style="width: 95%"
						v-decorator="['browserInfo']"
					/>
				</a-col>
			</a-row>
			<a-row :gutter="8">
				<a-col :md="20" :sm="12"> </a-col>
				<!-- 显示暴击过滤 -->
				<a-col :md="4" :sm="12" :offset="20" class="text-right">
					<a @click="advancedFiltersVisible = !advancedFiltersVisible">
						{{ advancedFiltersVisible ? l('HideAdvancedFilters') : l('ShowAdvancedFilters') }}
						<a-icon :type="advancedFiltersVisible ? 'up' : 'down'" />
					</a>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: 1200, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<!-- 异常 -->
				<p class="roles" slot="exceptionType" slot-scope="text, record">
					<a-icon v-if="record.exception" class="text-warning" type="exclamation-circle" />
					<a-icon v-if="!record.exception" class="text-green" type="check-circle" />
				</p>
				<span slot="actions" slot-scope="text, record">
					<!-- 查看详情 -->
					<a class="table-edit" @click="getDetails(record)">
						<a-icon type="search" />{{ l('ViewDetails') }}</a
					>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { ModalHelper } from '@/shared/helpers';
import { AuditLogServiceProxy } from '@/shared/service-proxies';
import AuditLogsDetails from './audit-logs-detail/audit-logs-detail';
import moment from 'moment';

export default {
	mixins: [AppComponentBase],
	name: 'audit-logs',
	components: {
		AuditLogsDetails,
	},
	data() {
		return {
			// 是否显示高级过滤
			advancedFiltersVisible: false,
			// 表格
			columns: [
				{
					title: this.l('Time'),
					dataIndex: 'executionTimeStr',
					align: 'center',
					width: 170,
					scopedSlots: { customRender: 'executionTimeStr' },
					ellipsis: true,
				},
				{
					title: this.l('UserName'),
					dataIndex: 'userName',
					align: 'center',
					scopedSlots: { customRender: 'userName' },
					ellipsis: true,
				},
				{
					title: this.l('Service'),
					dataIndex: 'serviceName',
					align: 'center',
					scopedSlots: { customRender: 'serviceName' },
					ellipsis: true,
				},
				{
					title: this.l('Action'),
					dataIndex: 'methodName',
					align: 'center',
					scopedSlots: { customRender: 'methodName' },
					ellipsis: true,
				},
				{
					title: this.l('Duration'),
					dataIndex: 'executionDuration',
					align: 'center',
					scopedSlots: { customRender: 'executionDuration' },
					ellipsis: true,
				},
				{
					title: this.l('IpAddress'),
					dataIndex: 'clientIpAddress',
					align: 'center',
					scopedSlots: { customRender: 'clientIpAddress' },
					ellipsis: true,
				},
				{
					title: this.l('Client'),
					dataIndex: 'clientName',
					align: 'center',
					scopedSlots: { customRender: 'clientName' },
					ellipsis: true,
				},
				{
					title: this.l('Browser'),
					dataIndex: 'browserInfo',
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'browserInfo' },
					ellipsis: true,
				},
				{
					title: this.l('ExceptionType'),
					dataIndex: 'exceptionType',
					align: 'center',
					scopedSlots: { customRender: 'exceptionType' },
					ellipsis: true,
				},
				{
					title: this.l('Actions'),
					dataIndex: 'actions',
					align: 'center',
					width: 150,
					fixed: 'right',
					scopedSlots: { customRender: 'actions' },
				},
			],

			tableData: [],

			// 用户名
			username: '',
			// 错误状态
			hasException: '',
			// 日期范围
			startToEndDate: [],
			// 服务
			serviceName: '',
			// 持续时间
			minExecutionDuration: '',
			maxExecutionDuration: '',
			// 方法名
			methodName: '',
			// 浏览器
			browserInfo: '',
			_auditLogServiceProxy: '',
		};
	},
	created() {
		this._auditLogServiceProxy = new AuditLogServiceProxy(this.$apiUrl, this.$api);
		this.getData();
	},
	methods: {
		/**
		 * 获取数据
		 */
		getData() {
			this.loading = true;
			this._auditLogServiceProxy
				.getPagedAuditLogs(
					this.startToEndDate[0] === null ? undefined : this.startToEndDate[0],
					this.startToEndDate[1] === null ? undefined : this.startToEndDate[1],
					this.username,
					this.serviceName,
					this.methodName,
					this.browserInfo,
					this.hasException,
					this.minExecutionDuration,
					this.maxExecutionDuration,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.executionTimeStr = item.executionTime
							? moment(item.executionTime).format('YYYY-MM-DD HH:mm:ss')
							: '-';
					});
					this.totalItems = res.totalCount;
				});
		},

		/**
		 * 查看详情
		 */
		getDetails(item) {
			ModalHelper.create(
				AuditLogsDetails,
				{ auditLog: item },
				{
					width: '900px',
				}
			).subscribe((res) => {
				if (res) {
					this.getData();
				}
			});
		},
	},
};
</script>

<style scoped lang="less">
.subscriptionEndUtc {
	text-align: center;
}

// table
.list-table {
	i {
		margin-right: 10px;
	}
}

.pagination {
	margin: 10px auto;
	text-align: right;
}

.table-edit {
	.anticon {
		margin-right: 10px;
	}
}

.table-delete,
.ant-dropdown-link {
	margin-left: 20px;
}

.table--container {
	margin-top: 20px;
}
</style>
