var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.spinning}},[_c('div',{staticClass:"modal-header"},[(!_vm.language.id)?_c('div',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.l('CreateNewLanguage')))]):_vm._e(),(_vm.language.id)?_c('div',{staticClass:"modal-title"},[_vm._v(" "+_vm._s(_vm.l('EditLanguage'))+": "),_c('span',[_vm._v(_vm._s(_vm.language.name))])]):_vm._e()]),_c('a-form',{attrs:{"form":_vm.form,"autocomplete":"off"},on:{"submit":function($event){return _vm.save()}}},[_c('a-form-item',{attrs:{"label":_vm.l('LanguageName')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                    rules: [
                        { required: true, message: _vm.l('ThisFieldIsRequired') },
                    ]
                }]),expression:"['name', {\n                    rules: [\n                        { required: true, message: l('ThisFieldIsRequired') },\n                    ]\n                }]"}]},_vm._l((_vm.languageNames),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.displayText)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":_vm.l('LanguageIcon')}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['icon', {
                    rules: [
                        { required: true, message: _vm.l('ThisFieldIsRequired') },
                    ]
                }]),expression:"['icon', {\n                    rules: [\n                        { required: true, message: l('ThisFieldIsRequired') },\n                    ]\n                }]"}]},[_vm._l((_vm.flags),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(item.displayText)+" ")])}),_vm._v("> ")],2)],1),_c('a-form-item',{attrs:{"label":_vm.l('Enabled')}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['isEnabled', {valuePropName: 'checked'} ]),expression:"['isEnabled', {valuePropName: 'checked'} ]"}],attrs:{"checkedChildren":_vm.l('Yes'),"unCheckedChildren":_vm.l('No')}})],1)],1),_c('div',{staticClass:"modal-footer"},[_c('a-button',{attrs:{"disabled":_vm.saving,"type":"button"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.l("Cancel"))+" ")]),_c('a-button',{attrs:{"loading":_vm.saving,"type":'primary'},on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"acticon acticon-save"}),_c('span',[_vm._v(_vm._s(_vm.l("Save")))])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }