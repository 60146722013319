<template>
	<a-spin :spinning="spinning">
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="entity && entity.id">{{ l('EditCompany') }}</span>
				<span v-else>{{ l('CreateCompany') }}</span>
			</div>
		</div>
		<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 24 }">
			<a-row type="flex" justify="space-around" align="middle">
				<a-col :span="12">
					<a-form-item :label="l('Name')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
						<a-input
							:placeholder="l('CompanyFullName')"
							v-decorator="['fullName', { rules: [{ required: true, message: l('CanNotNull') }] }]"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item :label="l('Code')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
						<a-input
							:placeholder="l('CompanyEnCode')"
							v-decorator="['enCode', { rules: [{ required: true, message: l('CanNotNull') }] }]"
						/>
					</a-form-item>
				</a-col>
			</a-row>

			<a-row justify="center">
				<a-col :span="12">
					<a-form-item :label="l('ShortName')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
						<a-input :placeholder="l('CompanyShortName')" v-decorator="['shortName']" />
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item :label="l('CompanyNature')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
						<a-select :placeholder="l('CompanyNature')" v-decorator="['nature']">
							<a-select-option v-for="item in natureList" :key="item.id.toString()">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
			</a-row>

			<a-row justify="center">
				<a-col :span="12">
					<a-form-item :label="l('CompanyParentId')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
						<a-tree-select
							style="width: 100%"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="companyTreeList"
							tree-default-expand-all
							:placeholder="l('CompanyParentId')"
							v-decorator="['parentId', { rules: [] }]"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item :label="l('CompanyInnerPhone')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
						<a-input
							:placeholder="l('CompanyInnerPhone')"
							v-decorator="['innerPhone', { rules: [{ message: l('CanNotNull') }] }]"
						/>
					</a-form-item>
				</a-col>
			</a-row>

			<a-row justify="center">
				<a-col :span="12">
					<a-form-item :label="l('FoundedTime')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
						<a-date-picker
							style="width:100%;"
							:placeholder="l('FoundedTime')"
							v-decorator="['foundedTime', { rules: [] }]"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item :label="l('ManagerUserId')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
						<div class="group-input" @click="selectUser">
							<div class="group-text">
								{{ userName ? userName : '' }}
							</div>
							<div class="group-icon">
								<a><a-icon slot="addonAfter" type="user"/></a>
							</div>
						</div>
					</a-form-item>
				</a-col>
			</a-row>

			<a-row justify="center">
				<a-col :span="12">
					<a-form-item
						:label="l('CompanyBusinessScope')"
						:label-col="{ span: 5 }"
						:wrapper-col="{ span: 18 }"
					>
						<a-textarea
							:placeholder="l('CompanyBusinessScope')"
							v-decorator="['businessScope', { rules: [] }]"
							:rows="4"
						/>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item :label="l('CompanyDescription')" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
						<a-textarea
							:placeholder="l('CompanyDescription')"
							v-decorator="['description', { rules: [] }]"
							:rows="4"
					/></a-form-item>
				</a-col>
			</a-row>
		</a-form>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="handleSubmit">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { ModalComponentBase } from '@/shared/component-base';
import { CompanyServiceProxy, DataItemDetailServiceProxy, UserServiceProxy } from '@/shared/service-proxies';
import { ModalHelper } from '@/shared/helpers';
import SelectUserTag from '@/components/select-user-tag/select-user-tag.vue';

export default {
	name: 'companyedit',
	mixins: [ModalComponentBase],
	data() {
		return {
			spinning: false,
			name: '',
			_CompanyConfigServiceProxy: '',
			formLayout: 'horizontal',
			form: this.$form.createForm(this, { name: 'coordinated' }),
			companyList: [],
			natureList: [],
			entity: {},
			userName: '',
			companyTreeList: [],
		};
	},
	created() {
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._UserServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
		this.fullData(); // 模态框必须,填充数据到data字段
		this._dataitemDetailServiceProxy = new DataItemDetailServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		if (this.entity && this.entity.id) {
			this.getData();
		} else {
			this.entity = { managerUserId: 0 };
		}

		this._CompanyConfigServiceProxy
			.getPaged(undefined, '', 100, 0)
			.finally(() => {
				this.spinning = false;
			})
			.then((res) => {
				this.companyList = res.items;
				this.companyList.forEach((item) => {
					if (item.parentId == 0) {
						var obj = {
							key: item.id,
							value: item.id.toString(),
							title: item.fullName,
						};
						this.companyTreeList.push(obj);
					}
				});
				this.initCompanyTree(this.companyTreeList);
			});
		this._UserServiceProxy
			.getUserById(this.entity ? this.entity.managerUserId : 0)
			.finally(() => {})
			.then((res) => {
				this.userName = res.realName;
			});
		this.getCompanyNatureList();
	},
	methods: {
		/**
		 * 获取数据
		 */
		getData() {
			this.spinning = true;
			this._CompanyConfigServiceProxy
				.getForEdit(this.entity ? this.entity.id : '')
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.company;
					this.form.setFieldsValue({
						fullName: res.company.fullName,
						enCode: res.company.enCode,
						shortName: res.company.shortName,
						nature: res.company.nature,
						foundedTime: res.company.foundedTime,
						//managerUserId: res.company.managerUserId,
						businessScope: res.company.businessScope,
						description: res.company.description,
						parentId: res.company.parentId != 0 ? res.company.parentId.toString() : '',
						innerPhone: res.company.innerPhone,
					});
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id.toString(),
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		getCompanyNatureList() {
			this._dataitemDetailServiceProxy.getListByDataItmeCode('CompanyNature').then((res) => {
				this.natureList = res;
			});
		},
		/**
		 * 提交数据
		 */
		handleSubmit(e) {
			e.preventDefault();

			this.form.validateFields((err, values) => {
				if (!err) {
					let parmas = Object.assign(values, {
						id: this.entity ? this.entity.id : '',
						managerUserId: this.entity ? this.entity.managerUserId : 0,
					});
					this.spinning = true;
					this._CompanyConfigServiceProxy
						.createOrUpdate({ Company: parmas })
						.finally(() => {
							this.spinning = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: this.l('SavedSuccessfully'),
							});
							this.success(true);
						});
				}
			});
		},

		/**
		 * 选择人员
		 */
		selectUser() {
			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRowKeys: [this.entity.managerUserId],
				},
				{ closable: false }
			).subscribe((res) => {
				if (res) {
					this.entity.managerUserId = res.id;
					this.userName = res.realName;
				}
			});
		},
	},
};
</script>

<style lang="less">
#username {
	background-color: white;
	color: #808080;
}
.group-input {
	width: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	height: 32px;
	margin-top: 3px;
	display: flex;
}

.group-text {
	flex: 5;
	text-align: left;
	padding-left: 9px;
	line-height: 32px;
	cursor: pointer;
}
.group-icon {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.group-icon i {
	color: #808080;
	background-color: #f6f4f4;
	padding: 8px 20px;
	border-radius: 0px 4px 4px 0px;
	border-left: 1px solid #d9d9d9;
}
</style>
