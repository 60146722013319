<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="spinning">
            <div class="modal-header">
                <div class="modal-title">
                    <a-icon type="team"/>
                    {{ l('SelectUsers') }}
                </div>
            </div>
            <!-- 搜索框 -->
            <a-input-search
                    name="filterText"
                    @search="filteronchange"
                    :placeholder="l('SearchWithThreeDot')"
                    enterButton
                    v-model="filterText"/>
            <a-table
                    :pagination="false"
                    :customRow="customRow"
                    :rowSelection="{ selectedRowKeys: selectedRowKeys, onSelectAll: tableSelectAll,onSelect:tableonSelect }"
                    :columns="columns"
                    :rowKey="data => data.id"
                    :dataSource="TableData">
            </a-table>
            <!-- 分页 -->
            <a-pagination
                    class="pagination"
                    size="small"
                    :total="totalItems"
                    showSizeChanger
                    showQuickJumper
                    :showTotal="showTotalFun"
                    @change="onChange"
                    @showSizeChange="showSizeChange"/>
            <!-- 按钮 -->
            <div class="modal-footer">
                <a-button :disabled="saving" @click="close()" type="button">
                    <a-icon type="close-circle"/>
                    {{ l('Cancel') }}
                </a-button>
                <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
                    <a-icon type="save"/>
                    {{ l('Save') }}
                </a-button>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {ModalComponentBase} from "@/shared/component-base";
    import {OrganizationUnitServiceProxy} from "@/shared/service-proxies";
    import {UserServiceProxy} from "@/shared";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import * as _ from "lodash";

    let _this;
    export default {
        name: "select-user-success-user",
        mixins: [ModalComponentBase],
        data() {
            return {
                spinning: false,
                zh_CN,
                // 搜索
                filterText: "",
                // 选中item
                selectedRowKeys: [],
                // 分页
                request: {maxResultCount: 10, skipCount: 0},
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                _organizationUnitServiceProxy: null,
                // 用户表格
                columns: [
                    {
                        title: this.l("Department"),
                        dataIndex: "departmentName",
                        align: "center",
                        scopedSlots: {customRender: "departmentName"}
                    },
                    {
                        title: this.l("OrgPost"),
                        dataIndex: "orgName",
                        align: "center",
                        scopedSlots: {customRender: "orgName"}
                    },
                    {
                        title: this.l("JobNumber"),
                        dataIndex: "jobNumber",
                        align: "center",
                        scopedSlots: {customRender: "jobNumber"}
                    },
                    {
                        title: this.l("Name"),
                        dataIndex: "realName",
                        align: "center",
                        scopedSlots: {customRender: "realName"}
                    }
                ],
                // 用户数据
                TableData: [],
                selectRows: [],
                companyId: undefined,
                organizationUnitId:undefined,
            };
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(
                this.$apiUrl,
                this.$api
            );
            this._UserServiceProxy = new UserServiceProxy(
                this.$apiUrl,
                this.$api
            );
            this.getData();
        },
        mounted() {
          _this=this;
        },
        methods: {
            /**
             * 获取数据
             */
            getData() {
                this.selectRows=[];
                this.selectedRowKeys=[];
                this.spinning = true;
                this._UserServiceProxy
                    .getUserPageListByDep(
                        this.companyId,
                        this.organizationUnitId,
                        this.filterText,
                        this.request.sorting,
                        this.request.maxResultCount,
                        this.request.skipCount
                    )
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then(res => {
                        this.TableData = res.items;
                        this.TableData.map(item=>{item.checked=false;});
                        this.totalItems = res.totalCount;
                        this.totalPages = Math.ceil(
                            res.totalCount / this.request.maxResultCount
                        );
                        this.pagerange = [
                            (this.pageNumber - 1) * this.request.maxResultCount + 1,
                            this.pageNumber * this.request.maxResultCount
                        ];
                    });
            },
            /**
             * 提交表单
             */
            handleSubmit() {
                const selectCount = this.selectRows.length;
                if (selectCount <= 0) {
                    abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
                    return;
                }
                this.success(this.selectRows);
            },

            filteronchange(){
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
            /**
             * 表格行点击
             * @param record 点击行的数据
             * @param index 点击行的下标
             */
            customRow: (record, index) => ({
                on: {
                    click: () => {
                        record.checked = !record.checked;
                        if (record.checked) {
                            //判断是否存在
                            let u = _this.selectRows.find(item => item.id == record.id);
                            if (!u) {
                                _this.selectedRowKeys.push(record.id);
                                _this.selectRows.push(record);
                            }
                        } else {
                            _this.selectedRowKeys = [..._this.selectedRowKeys.filter(item => item != record.id)];
                            _this.selectRows = [..._this.selectRows.filter(item => item.id != record.id)];
                        }
                    }
                }
            }),
            tableSelectAll(record, rows, crows) {
                if (record) {
                    //更改表格数据勾选状态
                    this.TableData.map(item => {
                        item.checked = true;
                    });
                    rows.forEach((item, index) => {
                        //判断是否存在
                        let u = this.selectRows.find(t => t.id == item.id);
                        if (!u) {
                            this.selectRows=[...this.selectRows,item];
                            this.selectedRowKeys=[...this.selectedRowKeys,item.id];
                        }
                    });
                } else {
                    this.TableData.forEach((item, index) => {
                        item.checked = false;
                        this.selectRows = this.selectRows.filter(t => t.id != item.id);
                        this.selectedRowKeys = _.difference(this.selectedRowKeys, [item.id]);
                    });
                }
            },
            /**
             * 重写复选框点击事件(不可删除)
             */
            tableonSelect(record) {
                record.checked = !record.checked;
                if (record.checked) {
                    this.selectedRowKeys.push(record.id);
                    this.selectRows.push(record);
                } else {
                    this.selectRows = [...this.selectRows.filter(item => item.id != record.id)];
                    this.selectedRowKeys = [...this.selectedRowKeys.filter(item => item != record.id)];
                }
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * 分页
             */
            onChange(page, pageSize) {

                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            }
        }
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
