<template>
	<a-row :gutter="8" style="height: 100%">
		<!--业务字典树-->
		<a-col :span="6">
			<q-tree-container>
				<template #select>公司</template>
				<template #tree>
					<a-tree
						:tree-data="treeData"
						@select="onSelect"
						:expanded-keys="expandedKeys"
						@expand="onExpand"
						:selectedKeys="TreeKey"
					>
						<a-icon type="folder" slot="folder" />
						<a-icon type="file" slot="file" />
						<a-icon type="folder-open" slot="folder-open" />
						<template slot="custom" slot-scope="{ expanded }">
							<a-icon :type="expanded ? 'folder-open' : 'folder'" />
						</template>
					</a-tree>
				</template>
			</q-tree-container>
		</a-col>

		<a-col :span="18">
			<!-- usePagination开启分页 -->
			<!-- loading绑定引入页面的蒙层加载变量 -->
			<q-container usePagination :loading="loading">
				<!-- 使用具名插槽 #toolbar -->
				<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
				<template #toolbar>
					<!--字典明细操作部分-->
					<a-row :gutter="[8, 8]">
						<a-col :span="14">
							<!-- 添加部门 -->
							<a-button :type="'primary'" v-if="isGranted('department_add')" @click="CreateDapertment()">
								<a-icon type="plus" />
								<span>{{ l('CreateDepartment') }}</span>
							</a-button>
							<!-- 编辑部门 -->
							<a-button :type="'primary'" v-if="isGranted('department_edit')" @click="editDepartment()">
								<a-icon type="edit" />
								<span>{{ l('Edit') }}</span>
							</a-button>
							<!-- 批量删除岗位 -->
							<a-button :type="'danger'" v-if="isGranted('department_delete')" @click="DepbatchDelete()">
								<a-icon type="delete" />
								<span>{{ l('Delete') }}</span>
							</a-button>
						</a-col>
						<a-col :span="8" style="text-align: right" v-if="isGranted('department_isvirtual')">
							<a-checkbox v-model="isVirtual" @change="getData">
								包含虚拟部门
							</a-checkbox>
						</a-col>
					</a-row>
				</template>
				<!-- 使用具名插槽slot="table" -->
				<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
				<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->
				<template slot="table" slot-scope="scopeParam">
					<a-table
						ref="table"
						size="small"
						:pagination="false"
						:columns="columns"
						:rowKey="(tableDatas) => tableDatas.id"
						:scroll="{ y: scopeParam.scroll.y }"
						:dataSource="tableData"
						:customRow="customRow"
						:rowClassName="rowClassName"
					>
					</a-table>
				</template>
				<!-- 使用具名插槽 #page -->
				<template #page>
					<!-- 分页组件只需复制以下代码 -->
					<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
					<a-pagination
						v-model="pageNumber"
						:total="totalItems"
						showSizeChanger
						size="small"
						:defaultPageSize="request.maxResultCount"
						:pageSizeOptions="pageSizeOptions"
						:showTotal="() => showTotal"
						@change="onPageChange"
						@showSizeChange="showPageSizeChange"
					/>
				</template>
			</q-container>
		</a-col>
	</a-row>
</template>

<script>
import { ModalComponentBase } from '@/shared/component-base';
import { ModalHelper } from '@/shared/helpers';
import { CompanyServiceProxy } from '@/shared/service-proxies';
import CreateEditDepartment from '../../organization/department/create-edit-department/create-edit-department';
import { DepartmentServiceProxy } from '../../../shared/service-proxies';
import * as _ from 'lodash';
import DepTreeUntils from './dep-tree-untils/dep-tree-untils';

export default {
	name: 'department',
	mixins: [ModalComponentBase],
	components: { CreateEditDepartment },
	data() {
		return {
			prefixClass: 'rc-pagination',

			NodeTreeItem: null,

			tmpStyle: '',
			//字典ID
			dataitem: 0,
			//字典树
			treeData: [],
			// 表格
			columns: [
				{
					title: this.l('Name'),
					dataIndex: 'displayName',
					align: 'left',
					scopedSlots: { customRender: 'fullName' },
				},
				{
					title: this.l('编码'),
					dataIndex: 'code',
					align: 'left',
					scopedSlots: { customRender: 'code' },
				},
				{
					title: this.l('Manager'),
					dataIndex: 'manangerName',
					align: 'left',
					scopedSlots: { customRender: 'manangerName' },
				},
				{
					title: this.l('UserCount'),
					dataIndex: 'userCount',
					align: 'left',
					scopedSlots: { customRender: 'userCount' },
				},
			],

			tableData: [],
			tree: [],
			//选中的树节点ID
			selectCompanyId: null,
			tableTree: [],
			TreeKey: undefined,
			expandedKeys: [],
			isVirtual: false,
		};
	},
	created() {
		this._companyServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);

		this.getCompanyData();
	},
	methods: {
		/**
		 * 获取公司
		 */
		getCompanyData() {
			this.treeData = [];
			this._companyServiceProxy
				.getTreeList()
				.finally(() => {})
				.then((res) => {
					this.tree = res;
					this.tree.forEach((item) => {
						if (item.parentId == 0) {
							this.treeData.push(item);
							this.expandedKeys.push(item.key);
						}
					});
					this.initTree(this.treeData);
					if (this.treeData.length > 0) {
						this.onSelect([this.treeData[0].key], undefined);
					}
				});
		},
		/**
		 * 初始化公司树形结构数据格式
		 */
		initTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集
				var pid = '';
				if (v.parentId != 0) {
					//校验是否为父级数据
					pid = v.parentId;
				}
				var keyid = v.key; //拼接父级ID和子集ID产生Key
				v.key = ''; //创建Key
				v.key = keyid;
				v.children = []; //创建空的子集
				this.tree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var mid = '';
						if (m.parentId != 0) {
							mid = v.parentId;
						}
						var mkeyid = m.key; //拼接子集ID和其子集ID产生Key
						m.key = '';
						m.key = mkeyid;
						v.children.push(m); //放进父级的集合中
						this.initTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 点击树
		 */
		onSelect(keys, event) {
			this.selectCompanyId = keys[0];
			this.TreeKey = keys;

			this.getData();
		},
		onExpand(expandedKeys, record) {
			this.expandedKeys = expandedKeys;
		},
		/**
		 * 用于点击空白处隐藏增删改菜单
		 */
		clearMenu() {
			this.NodeTreeItem = null;
		},
		/**
		 * 获取部门列表
		 */
		getData() {
			this.loading = true;
			this._departmentServiceProxy
				.getPaged(
					this.selectCompanyId == undefined ? undefined : this.selectCompanyId,
					true,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = [];
					this.tableTree = res.items;
					this.tableTree.forEach((item) => {
						if (!item.parentId) {
							this.tableData.push(item);
						}
					});
					this.tableData = DepTreeUntils.getInstance().ArrToTree(
						this.tableTree,
						this.tableData,
						this.isVirtual
					);
				});
		},

		/**
		 * 单个删除部门
		 */
		/**
		 *编辑部门
		 */
		editDepartment() {
			if (!this.selectCompanyId) {
				abp.message.warn(this.l('请选择公司'));
				return;
			}
			if (this.selectedRowKeys.length > 1) {
				return abp.message.warn('只能选择一项进行操作');
			}
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
			}
			ModalHelper.create(
				CreateEditDepartment,
				{ id: this.selectedRowKeys[0], companyId: this.selectCompanyId },
				{
					width: '900px',
				}
			).subscribe((res) => {
				if (res) {
					this.search();
				}
			});
		},

		/**
		 *添加部门
		 */
		CreateDapertment() {
			if (!this.selectCompanyId) {
				abp.message.warn(this.l('请选择公司'));
				return;
			}
			let pid;
			if (this.selectedRowKeys.length <= 0 || this.selectedRowKeys.length > 1) {
				pid = undefined;
			} else {
				pid = this.selectedRowKeys[0];
			}
			ModalHelper.create(
				CreateEditDepartment,
				{ id: null, companyId: this.selectCompanyId, pid: pid },
				{
					width: '900px',
				}
			).subscribe((res) => {
				if (res) {
					this.search();
				}
			});
		},
		/**
		 * 批量删除部门
		 */
		DepbatchDelete() {
			const selectCount = this.selectedRowKeys.length;
			if (selectCount <= 0) {
				abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
				return;
			}
			this.$confirm({
				title: this.l('ConfirmInformation'),
				content: this.l('ConfirmDeleteXItemsWarningMessage', selectCount),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					const ids = _.map(this.selectedRowKeys);
					this.spinning = true;
					this._departmentServiceProxy
						.batchDelete(ids)
						.finally(() => {
							this.spinning = false;
						})
						.then(() => {
							this.search();
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
						});
				},
			});
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .ant-tree li .ant-tree-node-content-wrapper {
	width: 100%;
}

/deep/ .ant-tree-title {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	display: block;
}

/deep/ .ant-table-thead > tr > th .ant-table-header-column .ant-checkbox {
	display: none;
}
</style>
