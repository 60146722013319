<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="spinning">
            <div class="modal-header">
                <div class="modal-title">
                    <span v-if="!headerTitle">{{ l('功能授权') }}</span>
                    <span v-else>{{l('功能授权') }}({{headerTitle}})</span>
                </div>
            </div>
            <!--            步骤条-->
            <div class="step">
                <!--                步骤条-->
                <a-row>
                    <a-steps :current="current" size="small">
                        <a-step key=0 title="功能权限"/>
                        <a-step key="1" title="按钮信息"/>
                        <a-step key="2" title="字段权限"/>
                        <a-step key="3" title="表单权限"/>
                    </a-steps>
                </a-row>
            </div>
            <!--            模块-->
            <div style="margin-top: 40px">
                <!--                功能权限-->
                <div v-show="current==0">
                    <div style="height: 500px;overflow-y: auto">
                        <a-tree
                                checkable
                                v-model="selectModuleKeys"
                                :tree-data="mModuleTree"
                                @select="moduleSelect"
                                @check="moduleCheck"
                                @expand="moduleExpand"
                                :expanded-keys="expandedKeys"
                        />
                    </div>
                </div>
                <!--                按钮权限-->
                <div v-if="current==1">
                    <div style="height: 500px;overflow-y: auto">
                        <a-tree
                                checkable
                                v-model="selectBtnKeys"
                                :tree-data="btnTree"
                                @check="mBtnCheck"
                                @expand="mBtnExpand"
                                :expanded-keys="mBtnExpandedKeys"
                        />
                    </div>
                </div>
                <!--                字段权限-->
                <div v-if="current==2">
                    <div style="height: 500px;overflow-y: auto">
                        <column-authorize :select-module-keys="selectModuleKeys"
                                          :module-data="columnData"
                                          @checkChange="columnCheckChange"
                                          ref="column"
                        ></column-authorize>
                    </div>
                </div>
                <!--                表单权限-->
                <div v-show="current==3">
                    <div class="result">
                        暂未开发
                    </div>
                </div>
            </div>
            <!-- 按钮 -->
            <div class="modal-footer">
                <a-button :type="'button'" :disabled="prevsaving" @click="prev()">
                    {{ l('上一步') }}
                </a-button>
                <a-button @click="next()" :disabled="nextsaving" type="button">
                    {{ l('下一步') }}
                </a-button>
                <a-button :type="'primary'" @click="save()" :disabled="current <= 0">
                    <a-icon type="save"/>
                    {{ l('Save') }}
                </a-button>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import {AppComponentBase, ModalComponentBase} from "../../../../shared/component-base";
    import {
        AuthorizeEditDto,
        AuthorizeServiceProxy,
        CreateOrUpdateAuthorizeInput
    } from "../../../../shared/service-proxies";
    import _ from 'lodash'
    import ColumnAuthorize from './column-authorize/column-authorize'
    import {deepCloneAsync} from "../../../../shared/utils/CloneUtils";

    export default {
        name: "authorize",
        mixins: [ModalComponentBase],
        components:{ColumnAuthorize},
        data() {
            return {
                zh_CN,
                spinning: false,
                nextsaving: false,
                prevsaving: true,
                headerTitle:undefined,
                current: 0,
                Id: undefined,
                //类型 1角色，2用户，3部门（父级组件传入参数）
                type:undefined,
                //展开的key
                expandedKeys: [],
                //选中功能授权的key
                selectModuleKeys: [],
                //预选择的按钮key
                selectBtnKeys: [],
                //用于提交的实体集合
                authorizeList: [],

                mBtnExpandedKeys:[],
                btnTree:[],
                //是否新增
                isAdd: true,
                //所有父级key
                parentKey: [],
                //需要提交的父级key
                submitParentKey: [],

                //接口数据
                //菜单权限列表
                mModuleTree: [],
                mSelectKeyList: [],
                mModuleKeyDic: undefined,
                mButtonKeyDic: undefined,
                mAllDic: undefined,
                mModuleButtonTree: [],
                //以选择的所有按钮key
                mModuleButtonAllKey:[],
                //列权限
                selectColumnKeys:[],
                columnData:[],
                columnKeyDic:undefined,
                IsApp:undefined,
            }
        },
        watch: {
            current(res) {
                if (this.current >= 3) {
                    this.nextsaving = true;
                } else {
                    this.nextsaving = false;
                }
                if (this.current <= 0) {
                    this.prevsaving = true;
                } else {
                    this.prevsaving = false;
                }
            }
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this._authorizeServiceProxy = new AuthorizeServiceProxy(this.$apiUrl, this.$api);
            if (this.Id) {
                this.getData();
            }
        },
        methods: {
            getData() {
                this.spinning = true;
                this._authorizeServiceProxy.getUserAuthorize(this.Id,this.type,this.IsApp)
                    .finally(() => {
                        this.spinning = false
                    })
                    .then(async ({
                                     moduleTree,
                                     selectKeyList,
                                     moduleKeyDic,
                                     buttonKeyDic,
                                     allDic,
                                     moduleButtonTree,
                                     moduleColumnTree,
                                     columnKeyDic
                    }) => {
                        this.mModuleTree = moduleTree;
                        this.mSelectKeyList = selectKeyList;
                        this.mModuleKeyDic = moduleKeyDic;
                        this.mButtonKeyDic = buttonKeyDic;
                        this.mAllDic = allDic;
                        this.mModuleButtonTree = moduleButtonTree;
                        this.columnData=moduleColumnTree;
                        this.columnKeyDic=columnKeyDic;

                        //获取所有父级Key
                        await this.getAllParentKey([...this.mModuleTree]);

                        if(this.mSelectKeyList.length > 0){
                            //有选中就不是新增
                            this.isAdd = false;
                            //筛选出来功能授权的key
                            this.selectModuleKeys = [...this.mSelectKeyList.filter(item=>item.indexOf('Module') !== -1 && allDic[item])];
                            //筛选拥有的列的权限
                            this.selectColumnKeys= [...this.mSelectKeyList.filter(item=>item.indexOf('Column') !== -1 && allDic[item])];
                            this.selectColumnKeys.map(item=>{
                                item="Column"+item;
                            });
                            this.selectBtnKeys=[...this.mSelectKeyList.filter(item=>item.indexOf('Button') !== -1 && allDic[item])];
                            //去掉后台返回的父级key
                            this.selectModuleKeys = _.difference([...this.selectModuleKeys], this.parentKey);
                        }

                        //拿到第一级的key，用来展开树
                        this.expandedKeys = _.map(this.mModuleTree, "key");

                    })
            },
            //递归获取所有父级Key
            getAllParentKey(mModuleTree){
                return new Promise(async resolve => {
                    for(let module of mModuleTree){
                        if(module.children.length > 0){
                            this.parentKey = [...this.parentKey, module.value];
                            await this.getAllParentKey(module.children);
                        }
                    }
                    resolve();
                })
            },
            /**
             * 下一步
             */
            async next() {
                this.current++;
                this.tabSwitch();
            },
            /**
             * tab选择后对应回调
             */
            tabSwitch(){
                //数据验证
                switch (this.current) {
                    case 0:

                        break;
                    //按钮权限
                    case 1:

                        this.mModuleButtonAllKey=[];

                        //清空需要展开的key
                        this.mBtnExpandedKeys = [];
                        //克隆源数据
                        let data=_.cloneDeepWith(this.mModuleButtonTree);
                        this.btnTree = this.mbthInit(data,{});
                        //只需要以选择的模块下的按钮
                        this.selectBtnKeys=[...this.selectBtnKeys.filter(item=>this.mModuleButtonAllKey.includes(item))];
                        break;
                    case 2:
                        this.$nextTick(async ()=>{
                            let data=await deepCloneAsync(this.columnData);
                            this.$refs.column.refresh(data);
                            this.$refs.column.selectColumnKeys=this.selectColumnKeys;
                        });
                        break;
                }
            },
            /**
             * 上一步
             */
            prev() {
                this.current--;
                this.tabSwitch();
            },
            /**
             * 按钮树初始化
             */
            mbthInit(data, obj){
                data.forEach( item => {

                    this.mBtnExpandedKeys=[...this.mBtnExpandedKeys,item.key];
                    if (item.children.length > 0) {
                        //有子级就先找子级(权限都在最后一级)
                        item.children = this.mbthInit(item.children, obj);
                        //判断是否存在选择的模块Key
                        let k = this.selectModuleKeys.filter(t => t == item.key);
                        if (k.length <= 0) {
                            //移除不被需要的
                            if (!obj.ishave) {
                                data = [...data.filter(t => t.key != item.key)];
                            }
                            //不在选择的模块中且不存在子级的也要删除
                            if(item.children.length<=0){
                                data = [...data.filter(t => t.key != item.key)];
                            }
                        }
                    } else {
                        let k = this.selectModuleKeys.filter(t => t == item.key);
                        //存在按钮的的页面
                        if(item.key.indexOf('Button')!=-1){

                            //判断页面是否被选择
                            let bk=this.selectModuleKeys.filter(t => t == item.moduleId)
                            if(bk.length>0){
                                this.mModuleButtonAllKey.push(item.key);
                                //被需要
                                obj.ishave = true;
                            }
                            else {
                                //移除未选择的
                                data = [...data.filter(t => t.key != item.key)];
                            }
                        }
                        else {
                            if (k.length <= 0) {
                                data = [...data.filter(t => t.key != item.key)];
                            } else {
                                //被需要
                                obj.ishave = true;
                            }
                        }
                    }
                    //默认不被需要
                    if (item.parentId == "Module0") {
                        obj.ishave = false;
                    }
                });
                return data;
            },
            /**
             * 功能权限选择事件
             * @param key
             * @param info
             */
            moduleSelect(key, info) {

                // if(info.selected){
                //     this.selectModuleKeys=[...this.selectModuleKeys,...key]
                // }
                // else {
                //     this.selectModuleKeys=[...this.selectModuleKeys.filter(item=>item!=info.node.$vnode.key)]
                // }
                // console.log(this.selectModuleKeys)
                // console.log(info.selected)
                // console.log(info.node.$vnode.key)
            },
            /**
             * 功能权限勾选事件
             * @param key
             * @param info
             */
            moduleCheck(key) {
                this.selectModuleKeys = [...key];
            },
            /**
             * 按钮权限勾选事件
             * @param key
             */
            mBtnCheck(key) {
                this.selectBtnKeys = _.difference([...key], [...key.filter(item=>item.indexOf("Module")!=-1)]);
            },
            /**
             * 功能权限展开收起事件
             * @param key
             * @param info
             */
            moduleExpand(key,info){
                this.expandedKeys = key;
            },
            /**
             * 按钮权限展开收起事件
             * @param key
             */
            mBtnExpand(key){
                this.mBtnExpandedKeys=key;
            },
            /**
             * 列权限选中事件
             */
            columnCheckChange(key){
                this.selectColumnKeys=[...key];
            },
            /**
             * 获取已选择的父级的Id
             * @param data
             * @param keys
             * @return {*}
             */
            getParentIdKey(data,keys){
                    data.forEach(item=>{
                        if(item.children&&item.children.length>0){
                            let key=this.getParentIdKey(item.children,keys);
                            if(key.length>0){
                                if(keys.includes(item.key)){
                                    keys.push(item.parentId);
                                }
                            }
                        }
                        else {
                            if(this.selectModuleKeys.filter(t=>t==item.key).length>0){
                                if(keys.filter(t=>t==item.parentId).length<=0){
                                    keys.push(item.parentId);
                                }
                            }
                        }
                    });
                    return keys;
            },
            async save() {
                let input=new CreateOrUpdateAuthorizeInput();
                let authorizeList=[];

                let key=this.getParentIdKey(this.mModuleTree,[]);
                let keys=[...new Set(key)].filter(item=>item!="Module0");
                //菜单实体初始化
                [...new Set([...keys,...this.selectModuleKeys])].forEach(item=>{
                    let dto=new AuthorizeEditDto();
                    dto.objectType=this.type;
                    dto.objectId=this.Id;
                    dto.itemType=1;
                    dto.itemId=this.mModuleKeyDic[item];
                    authorizeList.push(dto);
                });
                //按钮实体初始化
                this.selectBtnKeys.forEach(item=>{
                    let dto=new AuthorizeEditDto();
                    dto.objectType=this.type;
                    dto.objectId=this.Id;
                    dto.itemType=2;
                    dto.itemId=this.mButtonKeyDic[item];
                    authorizeList.push(dto);
                });
                //列权限初始化
                this.selectColumnKeys.filter(item=>item.indexOf('Column')!=-1).forEach(item=>{
                    let dto=new AuthorizeEditDto();
                    dto.objectType=this.type;
                    dto.objectId=this.Id;
                    dto.itemType=3;
                    dto.itemId=this.columnKeyDic[item];
                    authorizeList.push(dto);
                });
                input.objectId=this.Id;
                input.authorizeList=authorizeList;
                input.isApp=this.IsApp;
                this.spinning=true;
                this._authorizeServiceProxy.createOrUpdate(input).finally(()=>{this.spinning=false})
                .then(res=>{
                    this.$notification["success"]({
                        message: this.l("SavedSuccessfully")
                    });
                    this.success(true);
                });
            }
        }
    }
</script>

<style scoped>
    .step {
        margin: -24px;
        padding: 5px 10px;
        border-bottom: 1px solid #E8E8E8;
    }

    .result {
        height: 500px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(0, 0, 0, 0.07);
        font-size: 40px;
    }
</style>
