<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row :gutter="10">
                    <a-col :span="3">
                        <a-month-picker placeholder="选择月份"
                                        v-model="date"
                                        :allowClear="false"
                                        style="width: 100%"
                                        @change="monthChange">
                        </a-month-picker>
                    </a-col>
                    <a-col :span="4">
                        <a-tree-select class="left"
                                       @change="handleChange"
                                       :placeholder="l('Company')"
                                       style="width: 100%"
                                       allowClear
                                       multiple
                                       :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                       :tree-data="companyTreeList"
                                       tree-default-expand-all
                                       v-model="comPanyId"
                        />
                    </a-col>
                    <a-col :span="4">
                        <a-tree-select
                                v-model="departmentId"
                                allowClear
                                style="width: 100%"
                                show-search
                                multiple
                                :filterTreeNode="searchTree"
                                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                :tree-data="DepTreeData"
                                :placeholder="l('Department')"
                                tree-default-expand-all
                                @select="SelectDepartmentTree"
                                @change="getData">
                        </a-tree-select>
                    </a-col>
                    <a-col :span="4">
                        <a-select
                                mode="tags"
                                show-search
                                :placeholder="l('EmpType')"
                                option-filter-prop="children"
                                style="width: 100%"
                                allowClear
                                v-model="empTypeCode"
                                @change="getData"
                        >
                            <a-select-option v-for="item in EmpTypeList" :key="item.itemDetailCode">
                                {{ item.itemDetailName }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col :span="9" style="text-align: right">
                        <a-input-search
                                style="max-width: 200px"
                                name="filterText"
                                :placeholder="l('SearchWithThreeDot')"
                                @search="getData"
                                enterButton
                                v-model="filterText"
                        />
                    </a-col>
                </a-row>
                <!--                    出勤周期提示-->
                <a-row style="">
                    <p style="font-size: 10px;color:#BEBEBE ">考勤周期:{{startTime}}~{{endTime}}</p>
                </a-row>
                <a-row>
                    <a-button type="primary" @click="importExcel" v-if="isGranted('lc_import_month_att_plan')">
                        <a-icon type="import"/>
                        导入月度计划
                    </a-button>
                    <a-button type="primary" @click="exportExcel" v-if="isGranted('lc_excel_month_att_plan')">
                        <a-icon type="download"/>
                        <span>导出</span>
                    </a-button>
                    <a-button type="primary" @click="syncPlan" v-if="isGranted('lc_sync_plan')">
                        <a-icon type="sync"/>
                        <span>同步排班</span>
                    </a-button>
                </a-row>
                <div class="my-md">

                    <div class="d-flex hide-scroll" style="position: sticky;top: -10px;overflow: auto;z-index: 100;"
                         ref="tableHead">
                        <div style="min-width: 450px;position: sticky;left: 0">

                            <div class="left-header right-border top-border">
                                <!--                                <div class="bottom-border left-border ">部门</div>-->

                                <div class="bottom-border left-border">工号</div>
                                <div class=" bottom-border left-border">姓名</div>
                                <div class="bottom-border left-border">部门</div>
                                <div class=" bottom-border left-border">岗位</div>
                                <div class=" bottom-border left-border" style="flex: 2">日期</div>
                            </div>
                        </div>
                        <!--                            表头-->
                        <div style="flex: 1;">
                            <div class="right-header top-border">
                                <div class="bottom-border right-border"
                                     style="display: flex;flex-direction: column;max-width: 50px"
                                     v-for="(item,index) in dayheaderList" :key="index"
                                >
                                    <div class="header-column-text" :style="{color:item.color}" :title="item.title">
                                        <div>
                                            {{item.day}}
                                        </div>
                                        <div>
                                            {{item.week}}
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-border text-center right-border   width-80">正常出勤时数(H)</div>
                                <div class="bottom-border text-center right-border  width-80">工作日加班时数</div>
                                <div class="bottom-border text-center right-border  width-80">周末加班时数</div>
                                <div class="bottom-border text-center right-border  width-80">夜班天数</div>
                                <div class="bottom-border text-center right-border  width-80">事假时数</div>
                                <div class="bottom-border text-center right-border  width-80">病假时数</div>
                                <div class="bottom-border text-center right-border  width-80">有薪假时数</div>
                                <div class="bottom-border text-center right-border  width-80">缺勤时数</div>
                            </div>
                        </div>

                    </div>
                    <!--                数据-->
                    <div class="Att-Table" :style="{'overflow-x':empPlanList.length>0?'auto':'hidden',}"
                         ref="tableBody" @scroll="scrollTableBody">
                        <div class="data-left">
                            <!--                            <div class="left-header right-border top-border">-->
                            <!--                                <div class=" bottom-border left-border">姓名</div>-->
                            <!--                                <div class="bottom-border left-border">工号</div>-->
                            <!--                            </div>-->
                            <div class="left-data-row bottom-border bg-white right-border"
                                 style="max-width: 450px"
                                 v-for="(item,index) in empPlanList">

                                <!--                                <div class="row-column left-border" @click="columnClick($event,index,1,item)" :title="item.departmentName">-->
                                <!--                                    <div class="column-text">{{item.departmentName}}</div>-->
                                <!--                                </div>-->
                                <div class="row-column left-border"
                                     :title="item.jobNumber">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{item.jobNumber}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border"
                                     :title="item.userName">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{item.userName}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border"
                                     :title="item.departmentName">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{item.departmentName}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border"
                                     :title="item.post">
                                    <div class="column-text">
                                        <div class="ellipse">
                                            {{item.post}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border" style="flex: 2">
                                    <div class="type-column">
                                        <div class="ellipse bottom-border" style="min-height: 30px">
                                            班次
                                        </div>
                                        <div class="ellipse bottom-border" style="min-height: 31px">
                                            日总出勤时数
                                        </div>
                                        <div class="ellipse bottom-border" style="min-height: 31px">
                                            加班时数
                                        </div>
                                        <div class="ellipse" >
                                            请假
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="data-right ">
                            <div v-for="(e,j) in empPlanList">
                                <div class="data-row bottom-border color-white">
                                    <div class="row-column right-border" style="max-width: 50px"
                                         v-for="(item,index) in empPlanList[j].planList" :key="index"
                                         @click="columnClick($event,j,empPlanList[j],item,1)">
                                        <div class="data-column-text " :style="{'background':item.bgcolor}"
                                             :title="item.title">
                                            <div class="ellipse">
                                                {{item.shiftName}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="data-row bottom-border ">
                                    <div class="row-column right-border" style="max-width: 50px"
                                         v-for="(item,index) in empPlanList[j].standardLoborList" :key="index"
                                         @click="columnClick($event,j,empPlanList[j],item,2)">
                                        <div class="data-column-text "
                                             :title="item.dateStr+(' 日总出勤时数:'+item.value)">
                                            <div class="ellipse">
                                                {{item.value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="data-row bottom-border ">
                                    <div class="row-column right-border" style="max-width: 50px"
                                         v-for="(item,index) in empPlanList[j].qtNumList" :key="index"
                                         @click="columnClick($event,j,empPlanList[j],item,3)">
                                        <div class="data-column-text "
                                             :title="item.dateStr+(' 加班时数:'+item.value)">
                                            <div class="ellipse">
                                                {{item.value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="data-row bottom-border ">
                                    <div class="row-column right-border" style="max-width: 50px"
                                         v-for="(item,index) in empPlanList[j].leaveNumList" :key="index"
                                         @click="columnClick($event,j,empPlanList[j],item,4)">
                                        <div class="data-column-text "
                                             :title="item.dateStr+(item.value?' 请假类型:'+item.value:' 未请假')">
                                            <div class="ellipse">
                                                {{item.value}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--                        汇总-->
                        <div class="data-count">
                            <div class="left-data-row bottom-border  bg-white right-border"
                                 v-for="(item,index) in empPlanList">
                                <div class="row-column "
                                     style="min-width: 79px">
                                    <div class="column-text ">
                                        <div class="ellipse">
                                            {{getEmpmonthAttSum(item.userId).attNumber}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border width-80"
                                >
                                    <div class="column-text ">
                                        <div class="ellipse">
                                            {{getEmpmonthAttSum(item.userId).workQTNumber}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border width-80"
                                >
                                    <div class="column-text ">
                                        <div class="ellipse">
                                            {{getEmpmonthAttSum(item.userId).weekQTNumber}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border width-80"
                                >
                                    <div class="column-text ">
                                        <div class="ellipse">
                                            {{getEmpmonthAttSum(item.userId).nightDays}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border width-80"
                                >
                                    <div class="column-text ">
                                        <div class="ellipse">
                                            {{getEmpmonthAttSum(item.userId).sjHours}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border width-80"
                                >
                                    <div class="column-text ">
                                        <div class="ellipse">
                                            {{getEmpmonthAttSum(item.userId).bjHours}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border width-80"
                                >
                                    <div class="column-text ">
                                        <div class="ellipse">
                                            {{getEmpmonthAttSum(item.userId).salaryHours}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row-column left-border width-80"
                                >
                                    <div class="column-text  ">
                                        <div class="ellipse">
                                            {{getEmpmonthAttSum(item.userId).absenceHours}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="empPlanList.length<=0">
                    <a-empty/>
                </div>
                <div style="text-align: right">
                    <a-pagination
                            class="pagination"
                            v-model="pageNumber"
                            :page-size-options="pageSizeOptions"
                            :total="totalItems"
                            show-size-changer
                            @change="onChange"
                            :page-size="pageSize"
                            @showSizeChange="showSizeChange"
                            :showTotal="showTotalFun"
                    >
                    </a-pagination>
                </div>
                <!--                卡片-->
                <div class="plan-card" :style="style" v-if="isCrde"
                     style="position: absolute;background-color: white;z-index:10;">
                    <!--                    班次-->
                    <div v-if="columnType==1" class="edit-card">
                        <a-row :gutter="[0,16]">
                            <a-col style="text-align: center;color: #9c9c9c;font-size: 10px;margin-top: 10px">
                                {{tabsTip}}
                            </a-col>
                        </a-row>
                        <a-row v-for="(item,index) in shiftInfoCodeList" :gutter="[0,16]" :key="index">
                            <a-col :span="20" offset="2">
                                <a-button block v-if="item.code=='Rest'" style="color:green;"
                                          @click="editClick(item.code)">
                                    {{item.name}}
                                </a-button>
                                <a-button block
                                          v-else-if="item.code=='Holiday'"
                                          style="color:deeppink;"
                                          @click="editClick(item.code)">
                                    {{item.name}}
                                </a-button>
                                <a-button block v-else-if="item.code=='Empty'" style="color:red;"
                                          @click="editClick(item.code)">
                                    {{item.name}}
                                </a-button>

                                <a-button block type="primary" v-else @click="editClick(item.code)">
                                    {{item.name}}
                                </a-button>
                            </a-col>
                        </a-row>
                    </div>
                    <!--                日总出勤时数    -->
                    <div v-if="columnType==2">
                        <a-row :gutter="[0,16]">
                            <a-col style="text-align: center;color: #9c9c9c;font-size: 10px;margin-top: 10px">
                                {{tabsTip}}
                            </a-col>
                        </a-row>
                        <a-row :gutter="[0,16]" style="text-align: center;margin: 10px 0px;">
                            日总出勤时数:
                            <a-input-number v-model="standardLobor" :min="0"/>
                        </a-row>
                        <a-row :gutter="[0,16]" style="width: 90%;margin:10px auto">
                            <a-button type="primary" block @click="editClick()">
                                保存
                            </a-button>
                        </a-row>
                    </div>
                    <!--                加班时数    -->
                    <div v-if="columnType==3">
                        <a-row :gutter="[0,16]">
                            <a-col style="text-align: center;color: #9c9c9c;font-size: 10px;margin-top: 10px">
                                {{tabsTip}}
                            </a-col>
                        </a-row>
                        <a-row :gutter="[0,16]" style="text-align: center;margin: 10px 0px;">
                            加班时数:
                            <a-input-number v-model="qTNum" :min="0"/>
                        </a-row>
                        <a-row :gutter="[0,16]" style="width: 90%;margin:10px auto">
                            <a-button type="primary" block @click="editClick()">
                                保存
                            </a-button>
                        </a-row>
                    </div>
                    <!--                请假    -->
                    <div v-if="columnType==4">
                        <a-row :gutter="[0,16]">
                            <a-col style="text-align: center;color: #9c9c9c;font-size: 10px;margin-top: 10px">
                                {{tabsTip}}
                            </a-col>
                        </a-row>
                        <a-row :gutter="[0,16]" style="text-align: center;margin: 10px 0px;">
                           <span>
                                请假类型:
                           </span>
                            <span>
                                <a-select
                                        show-search
                                        :placeholder="l('EmpType')"
                                        option-filter-prop="children"
                                        style="width: 120px"
                                        allowClear
                                        v-model="leaveType"
                                >
                                <a-select-option v-for="item in holidayRuleList" :key="item.code">
                                    {{ item.name }}
                                </a-select-option>
                            </a-select>
                            </span>
                        </a-row>
                        <a-row :gutter="[0,16]" style="width: 90%;margin:10px auto">
                            <a-button type="primary" block @click="editClick()">
                                保存
                            </a-button>
                        </a-row>
                    </div>
                </div>
            </a-card>
            <!--                遮罩-->
            <div class="card-loading" :style="cardloadStyle" v-if="isCrde" @click="cardClick">

            </div>
        </a-spin>

    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN"
    import {abpService} from "../../../shared/abp";
    import moment from "moment";
    import {
        AttendanceGroupServiceProxy,
        CompanyServiceProxy,
        DepartmentServiceProxy,
        EnterpriseAttendCycleServiceProxy, MonthAttendancePlanServiceProxy, MonthAttPlanEditDto
    } from "../../../shared/service-proxies";
    import DepTreeUntils from "../../organization/department/dep-tree-untils/dep-tree-untils";
    import ImportExcelModel from "./import-excel-model/import-excel-model"
    import ModalHelper from "../../../shared/helpers/modal/modal-helper";
    import {Dic, fileDownloadService} from "../../../shared/utils";

    export default {
        name: "month-att-plan",
        mixins: [AppComponentBase],
        components: {ImportExcelModel},
        data() {
            return {
                zh_CN,
                initialValue: undefined, //出勤日期初始值
                startTime: undefined,
                endTime: undefined,
                companyTreeList: [],
                comPanyId: undefined,
                departmentId: undefined,
                //部门树
                DepTreeData: [],
                DepTree: [],
                date: undefined,
                Year: undefined,
                Month: undefined,
                //日期表头
                dayheaderList: [],
                empPlanList: [],
                filterText: undefined,
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                //默认条数
                pageSize: 10,
                //开始索引
                startindex: 0,
                //结束索引
                endindex: 5,
                // 共多少页
                totalPages: 1,
                // 显示条数
                pageSizeOptions: ["5", "10", "20", "30", "40", "50"],
                tableData: [],
                // 卡片遮罩样式
                cardloadStyle: {
                    width: "0px",
                    height: "0px",
                    left: "0px",
                    top: "0px"
                },
                // 卡片样式
                style: {
                    left: "0px",
                    top: "0px"
                },
                //表格左测选中的dom
                thisDom: undefined,
                isCrde: false,
                columnType: 1,
                shiftInfoCodeList: [],
                tabsTip: "",
                standardLobor: undefined,
                qTNum: undefined,
                leaveNum: undefined,
                id: undefined,
                attDate: undefined,
                EmpTypeList: [],
                empTypeCode: undefined,
                holidayRuleList: [],
                leaveType: undefined,
                monthAttSumList: [],
                empList:[],
                monthAttPlan:[]
            }
        },
        created() {
            // 出勤日期
            this.attenanceDateApi = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
            this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
            this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
            this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
            this._monthAttendancePlanServiceProxy = new MonthAttendancePlanServiceProxy(this.$apiUrl, this.$api);
            this._attendanceGroupAppService = new AttendanceGroupServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        async mounted() {
            this.cardloadStyle.width = document.body.height - 220 + "px";
            this.cardloadStyle.height = document.body.height - 130 + "px";
            this.EmpTypeList = await Dic.getInstance().getDicAsync('EmpType');
            await this.getCycle();
            this.companyTreeInit();
            // this.getData();
        },
        methods: {
            searchTree(inputValue, treeNode) {
                return treeNode.data.props.title.includes(inputValue)
            },
            getData() {
                this.loading = true;
                this._monthAttendancePlanServiceProxy.getPaged(
                    this.filterText,
                    moment(this.startTime),
                    moment(this.endTime),
                    this.comPanyId,
                    this.departmentId,
                    this.empTypeCode
                )
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(async res => {
                        this.monthAttSumList = res.monthAttSumList;
                        this.empList=res.empList;
                        this.monthAttPlan=res.monthAttPlan;
                        this.pageNumber = 1;
                        this.startindex = 0;
                        this.endindex = 10;
                        this.totalItems = res.empList.length ;
                        await this.pageData();
                    })
            },
            getEmpmonthAttSum(empId) {
                let sum = this.monthAttSumList.filter(item => item.empId == empId);
                if (sum.length > 0) {
                    return sum[0];
                } else {
                    return {};
                }
            },
            /**
             * 数据分页
             */
            async pageData() {
                this.loading = true;
                let emplist=_.slice(this.empList, this.startindex, this.endindex);
                let monthAttPlan=this.monthAttPlan.filter(item=>emplist.includes(item.empId));
                this.tableData = await this.PlanInit(emplist, monthAttPlan);
                this.totalPages = Math.ceil(
                    this.totalItems / this.pageSize
                );
                this.empPlanList = this.tableData;
                this.loading = false;
            },
            /**
             * 每页条数事件
             */
            showSizeChange(current, pageSize) {
                this.pageSize = pageSize;
                this.pageNumber = current;
                this.startindex = (this.pageNumber - 1) * this.pageSize;
                this.endindex = this.startindex + this.pageSize;
                this.pageData();

            },
            /**
             * 页码事件
             */
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.pageSize = pageSize;
                this.startindex = (page - 1) * this.pageSize;
                this.endindex = this.startindex + this.pageSize;
                this.pageData();
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.startindex + 1,
                    this.endindex > this.totalItems ? this.totalItems : this.endindex
                );
            },
            /**
             * 初始化表格数据
             */
            PlanInit(users, planList) {

                return new Promise((resolve, reject) => {
                    let shifts = [];
                    users.forEach((user, i) => {
                        let p = {};
                        p.userId = user;

                        let plans = planList.filter(item => item.empId == user);
                        p.standardLoborList = [];
                        p.leaveNumList = [];
                        p.qtNumList = [];

                        plans.forEach((item, index) => {
                            let s = {};
                            p.standardLoborList = [...p.standardLoborList,
                                {
                                    value: item.standardLobor ? item.standardLobor : 0,
                                    key: item.id,
                                    date: item.attendDate,
                                    userName: item.realName,
                                    dateStr: moment(item.attendDate).format('YYYY-MM-DD')
                                }
                            ];
                            p.leaveNumList = [...p.leaveNumList, {
                                value: item.leaveType ? item.leaveType : '',
                                key: item.id,
                                date: item.attendDate,
                                userName: item.realName,
                                code: item.leaveTypeCode,
                                dateStr: moment(item.attendDate).format('YYYY-MM-DD')
                            }];
                            p.qtNumList = [...p.qtNumList, {
                                value: item.qtNum ? item.qtNum : 0,
                                key: item.id,
                                date: item.attendDate,
                                userName: item.realName,
                                dateStr: moment(item.attendDate).format('YYYY-MM-DD')
                            }];
                            p.date = item.attendDate;
                            if (index == 0) {
                                p.departmentName = item.department;
                                p.userName = item.realName;
                                p.attGroupId = item.attGroupId;
                                p.post = item.post;
                                p.jobNumber = item.jobNumber;
                                s.key = item.id;
                                s.shiftCode = item.attendShiftCode;
                                s.userId = user;
                                s.date = item.attendDate;
                                // s.title=moment(item.date).format("YYYY-MM-DD")+(item.shiftName?" 班次:"+item.shiftName:" 未排班")
                                if (item.attendShiftCode) {
                                    //休息
                                    if (item.attendShiftCode == "Rest") {
                                        s.shiftName = "休息";
                                        s.userName = item.realName;
                                        s.attGroupId = item.attGroupId;
                                        s.bgcolor = "#87d068";
                                        s.title = moment(item.attendDate).format("YYYY-MM-DD") + " 休息"
                                    } else if (item.attendShiftCode == "Holiday") {
                                        s.shiftName = "法定节假日";
                                        s.bgcolor = "pink";
                                        s.userName = item.realName;
                                        s.attGroupId = item.attGroupId;
                                        s.title = moment(item.attendDate).format("YYYY-MM-DD") + " 法定节假日"
                                    } else {
                                        s.shiftName = item.attendShift;
                                        s.attGroupId = item.attGroupId;
                                        s.userName = item.realName;
                                        s.title = moment(item.attendDate).format("YYYY-MM-DD") + " 班次:" + item.attendShift;
                                        s.bgcolor = "#108ee9"
                                    }
                                } else {
                                    s.title = moment(item.attendDate).format("YYYY-MM-DD") + " 未排班"
                                    s.attGroupId = item.attGroupId;
                                    s.userName = item.realName;
                                    s.bgcolor = '';
                                }
                                p.planList = [s];

                            } else {
                                s.key = item.id;
                                s.shiftCode = item.attendShiftCode;
                                s.userId = user;
                                // s.title=moment(item.date).format("YYYY-MM-DD")+(item.shiftCode?" 班次:"+item.shiftName:" 未排班")
                                if (item.attendShiftCode) {
                                    if (item.attendShiftCode == "Rest") {
                                        s.shiftName = "休息";
                                        s.userName = item.realName;
                                        s.attGroupId = item.attGroupId;
                                        s.title = moment(item.attendDate).format("YYYY-MM-DD") + " 休息"
                                        s.bgcolor = "#87d068";
                                    } else if (item.attendShiftCode == "Holiday") {
                                        s.title = moment(item.attendDate).format("YYYY-MM-DD") + " 法定节假日"
                                        s.shiftName = "法定节假日";
                                        s.userName = item.realName;
                                        s.attGroupId = item.attGroupId;
                                        s.bgcolor = "pink";
                                    } else {
                                        s.title = moment(item.attendDate).format("YYYY-MM-DD") + " 班次:" + item.attendShift;
                                        s.shiftName = item.attendShift;
                                        s.attGroupId = item.attGroupId;
                                        s.userName = item.realName;
                                        s.bgcolor = "#108ee9"
                                    }
                                } else {
                                    s.title = moment(item.attendDate).format("YYYY-MM-DD") + " 未排班"
                                    s.attGroupId = item.attGroupId;
                                    s.userName = item.realName;
                                    s.bgcolor = '';
                                }
                                s.date = item.attendDate;
                                p.planList = [...p.planList, s];
                            }
                        });
                        shifts = [...shifts, p];
                    });
                    resolve(shifts);
                })

            },
            /**
             * 单元格点击事件
             * @param record
             * @param index
             */
            async columnClick(record, index, data, row, t) {
                this.columnType = t;
                this.attDate = row.date;
                this.thisDom = record.target.parentElement;
                this.bgColor = this.thisDom.style.backgroundColor;
                this.thisDom.style.backgroundColor = "#FB6370";
                this.thisDom.style.border = "1px solid #ffffff";
                this.thisDom.style.color = "#ffffff";
                let rowindex = index;
                switch (t) {
                    case 1:
                        this.id = row.key;
                        this.tabsTip = "修改员工" + row.userName + moment(row.date).format("yyyy-MM-DD") + "班次";
                        this.shiftInfoCodeList = await this.shiftInfoList(row.attGroupId);
                        if (this.shiftInfoCodeList.length > 0) {
                            this.shiftInfoCodeList = [...this.shiftInfoCodeList,
                                ...[
                                    {
                                        code: "Rest",
                                        name: "休息"
                                    }
                                    , {
                                        code: "Holiday",
                                        name: "法定节假日"
                                    }
                                    , {
                                        code: "Empty",
                                        name: "清空"
                                    }
                                ]
                            ]
                        }
                        rowindex = index * 4;
                        break;
                    case 2:
                        this.id = row.key;
                        this.tabsTip = "修改员工" + row.userName + moment(row.date).format("yyyy-MM-DD") + "总出勤时数";
                        this.standardLobor = row.value;
                        rowindex = (index * 4) + 1;
                        break;
                    case 3:
                        this.id = row.key;
                        this.tabsTip = "修改员工" + row.userName + moment(row.date).format("yyyy-MM-DD") + "加班时数";
                        this.qTNum = row.value;
                        rowindex = (index * 4) + 2;
                        break;
                    case 4:
                        this.id = row.key;
                        this.tabsTip = "修改员工" + row.userName + moment(row.date).format("yyyy-MM-DD") + "请假类型";
                        this.holidayRuleList = await this.getHolidayRuleList();
                        this.leaveType = row.code;
                        rowindex = (index * 4) + 3;
                        break;
                }
                this.isCrde = true;
                this.style.left = (record.x - 270) + "px";
                //(基础高度+表头高度)+((行号索引+1)*行高度)+偏移量
                this.style.top = (175 + 0) + ((rowindex + 1) * 31) + 3 + "px";

            },
            /**
             * 卡片遮罩
             * @param e
             * @param a
             */
            cardClick(e, a) {
                //日期
                this.thisDom.style.backgroundColor = this.bgColor;
                this.thisDom.style.border = "";
                this.thisDom.style.color = "";

                //显示整体的滚动体
                // $('.layout-content').css("overflow", "auto");
                // this.shiftCodeIndex = undefined;
                this.isCrde = false;
            },
            async getCycle() {
                let cycle = await this.initCycle();
                this.startTime = cycle.startTime;
                this.endTime = cycle.endTime;
                this.date = cycle.yearOrMonth;
                if (this.startTime && this.endTime) {
                    this.dayheaderList = [];
                    this.headerDaysInit(this.startTime, this.endTime);
                }
            },
            /**
             * 获取周期设置
             */
            async initCycle() {
                return new Promise(((resolve, reject) => {
                    this.spinning = true;
                    this._enterpriseAttendCycleServiceProxy.getCycleByNow()
                        .finally(() => {
                            this.spinning = false
                        })
                        .then(res => {
                            resolve(res);
                        });
                }));

            },
            /**
             * 获取考勤组班次
             */
            async shiftInfoList(AttGroupId) {
                return new Promise((resolve, reject) => {
                    if (!AttGroupId) {
                        AttGroupId = undefined;
                    }
                    this.loading = true;
                    this._attendanceGroupAppService.getShiftInfoCodeByAttGroupId(AttGroupId)
                        .finally(() => {
                            this.loading = false;
                        })
                        .then(res => {
                            resolve(res);
                        })
                });
            },
            // 初始化出勤日期
            initAttDate() {
                return new Promise((resolve, reject) => {
                    this.attenanceDateApi.getCycleByNow().then((res) => {
                        this.initialValue = [moment(res.startTime), moment(res.endTime)];
                        this.currentPeriod = res;
                        resolve(res);
                    });
                });
            },
            companyTreeInit() {
                this.loading = true;
                this._CompanyConfigServiceProxy
                    .getPaged(undefined, "", 100, 0)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then((res) => {
                        this.companyList = res.items;
                        this.companyList.forEach((item) => {
                            if (item.parentId == 0) {
                                var obj = {
                                    key: item.id,
                                    value: item.id,
                                    title: item.fullName
                                };
                                this.companyTreeList.push(obj);

                            }
                        });
                        this.initCompanyTree(this.companyTreeList);
                        if (this.companyTreeList.length > 0 && !this.comPanyId) {
                            this.handleChange(this.companyTreeList[0].key);
                        } else {
                            this.getData();
                        }

                    });
            },
            /**
             * 初始化树形结构数据格式
             */
            initCompanyTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.companyList.forEach((m) => {
                        if (m.parentId == v.value) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.id,
                                value: m.id,
                                title: m.fullName
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initCompanyTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },
            handleChange(value) {
                this.comPanyId = value;
                this.departmentId = undefined;
                this.getData();
                this.getDepTreeData();
            },
            /**
             * 获取部门
             */
            getDepTreeData() {
                this.DepTreeData = [];
                this._DepartmentServiceProxy.getDepTreeData(this.comPanyId).then(res => {
                    this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
                    this.DepTree.forEach(item => {
                        if (!item.parentId) {
                            this.DepTreeData.push(item);
                        }
                    });
                    this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);

                }).finally(() => {
                    // this.loading = false;
                })
            },
            /**
             * 选择部门
             */
            SelectDepartmentTree(e) {
                this.departmentId = e;
            },
            /**
             * 月份选择change
             */
            async monthChange(date) {
                this.Year = moment(date).year();
                this.Month = moment(date).month() + 1;
                let cycle = await this.getCycleBydate();
                this.startTime = cycle.startTime;
                this.endTime = cycle.endTime;
                if (this.startTime && this.endTime) {
                    this.dayheaderList = [];
                    this.headerDaysInit(this.startTime, this.endTime);
                }
                this.getData();
            },
            /**
             * 获取指定年月的周期
             */
            async getCycleBydate() {
                return new Promise((resolve, reject) => {
                    this.spinning = true;
                    this._enterpriseAttendCycleServiceProxy.getByTenant(this.Year, this.Month)
                        .finally(() => {
                            this.spinning = false
                        })
                        .then(res => {
                            resolve(res);
                        })
                })
            },
            /**
             * 表头数据初始化
             */
            headerDaysInit(startTime, endTime) {
                let days = moment(endTime).diff(startTime, "day") + 1;
                let week;
                for (let i = 0; i < days; i++) {
                    week = moment(startTime).add(i, 'day').day();
                    this.dayheaderList = [...this.dayheaderList, {
                        day: moment(startTime).add(i, 'day').format('DD'),
                        dayTime: moment(startTime).add(i, 'day').format('YYYY-MM-DD'),
                        week: this.weekInit(week),
                        color: week == 0 || week == 6 ? 'red' : '',
                        title: moment(startTime).add(i, 'day').format('YYYY-MM-DD') + " 周" + this.weekInit(week)
                    }];
                }
            },
            /**
             * 星期数据格式化
             */
            weekInit(number) {
                switch (number) {
                    case 0:
                        return "日"
                        break;
                    case 1:
                        return "一"
                        break;
                    case 2:
                        return "二"
                        break;
                    case 3:
                        return "三"
                        break;
                    case 4:
                        return "四"
                        break;
                    case 5:
                        return "五"
                        break;
                    case 6:
                        return "六"
                        break;
                }
            },
            /**
             * 获取假期类型
             */
            getHolidayRuleList() {
                return new Promise((resolve, reject) => {
                    this.loading = true;
                    this._monthAttendancePlanServiceProxy.getHolidayRuleList()
                        .finally(() => {
                            this.loading = false
                        })
                        .then(res => {
                            resolve(res);
                        })
                })
            },
            /**
             * 导入排班
             * @constructor
             */
            importExcel() {
                ModalHelper.create(
                    ImportExcelModel,
                    {
                        startTime: this.startTime,
                        endTime: this.endTime
                    },
                    {
                        width: "800px"
                    }
                ).subscribe(res => {
                    this.getData();
                });
            },
            /**
             * 导出
             */
            exportExcel() {
                if (this.empPlanList.length <= 0) {
                    return abp.message.warn(this.l('NoData'))
                }
                this.loading = true;
                this._monthAttendancePlanServiceProxy.getToExcelFile(
                    this.filterText,
                    moment(this.startTime),
                    moment(this.endTime),
                    this.comPanyId,
                    this.departmentId,
                    this.empTypeCode
                )
                    .finally(() => {
                        this.loading = false
                    })
                    .then(res => {
                        this._fileDownloadService.downloadTempFile(res);
                    });
            },
            /**
             * 同步排班
             */
            syncPlan() {
                this.$confirm({
                    title: '操作确认',
                    content: '同步排班将按照当前周期覆盖员工现有排班，并且只同步负责人员的班次，确认要执行该操作吗？',
                    cancelText: '取消',
                    okText: '确认',
                    onOk: () => {
                        this.loading = true;
                        this._monthAttendancePlanServiceProxy.syncEmpShiftPlan(
                            moment(this.startTime),
                            moment(this.endTime)
                        )
                            .finally(() => {
                                this.loading = false
                            })
                            .then(res => {
                                this.$notification["success"]({
                                    message: this.l("同步成功"),
                                });
                            })
                    }
                });

            },
            editClick(shiftcode) {
                switch (this.columnType) {
                    case 1:
                        this.editMonthAttPlan(1, this.id, shiftcode, undefined);
                        break;
                    case 2:
                        if (!this.standardLobor) {
                            this.standardLobor = 0;
                        }
                        this.editMonthAttPlan(2, this.id, undefined, this.standardLobor);
                        break;
                    case 3:
                        if (!this.qTNum) {
                            this.qTNum = 0;
                        }
                        this.editMonthAttPlan(3, this.id, undefined, this.qTNum);
                        break;
                    case 4:
                        this.editMonthAttPlan(4, this.id, undefined, undefined);
                        break;
                }
            },
            /**
             * 编辑月度考勤计划
             * @param type
             * @param id
             * @param shiftcode
             * @param number
             */
            editMonthAttPlan(type, id, shiftcode, number) {
                let input = new MonthAttPlanEditDto();
                input.id = id;
                input.number = number;
                input.type = type;
                input.shiftCode = shiftcode;
                input.attendDate = this.attDate;
                input.leaveType = this.leaveType;
                this.loading = true;
                this._monthAttendancePlanServiceProxy.editMonthAttPlan(input)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(res => {
                        this.$notification["success"]({
                            message: this.l("保存成功"),
                        });
                        this.cardClick();
                        this.getData();
                    });
            },
            scrollTableBody(e) {
                this.$refs.tableHead.scrollLeft = e.target.scrollLeft;
            },
        }
    }
</script>

<style scoped lang="less">
    @import "month-att-plan.less";
</style>
