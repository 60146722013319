<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row :gutter="10">
                    <a-col :span="2">
                        <a-date-picker
                            mode="year"
                            format="YYYY"
                            :allowClear="false"
                            :value="currentYear"
                            @openChange="isOpen = true"
                            :open="isOpen"
                            style="width: 100%;"
                            @panelChange="yearChange"
                        />
                    </a-col>
                    <a-col :span="4">
                        <a-tree-select
                            class="left"
                            @change="handleChange"
                            :placeholder="l('Company')"
                            style="width: 100%"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="companyTreeList"
                            tree-default-expand-all
                            v-model="comPanyId"
                        />
                    </a-col>
                    <a-col :span="4">
                        <a-tree-select
                            v-model="departmentId"
                            allowClear
                            style="width: 100%"
                            show-search
                            :filterTreeNode="searchTree"
                            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                            :tree-data="DepTreeData"
                            :placeholder="l('Department')"
                            tree-default-expand-all
                            @change="getData"
                        >
                        </a-tree-select>
                    </a-col>
                    <a-col :span="4">
                        <a-select
                            show-search
                            placeholder="对比项"
                            option-filter-prop="children"
                            style="width: 100%"
                            v-model="diffItem"
                            @change="reportInit"
                        >
                            <a-select-option v-for="item in diffItemList" :key="item.type">
                                {{ item.name }}
                            </a-select-option>
                        </a-select>
                    </a-col>
                    <a-col span="4">
                        <a-button :type="'primary'" @click="exportToExcel()" v-if="isGranted('lc_month-rate-excel')">
                            <a-icon type="download" />
                            <span>{{ l('Export') }}</span>
                        </a-button>
                    </a-col>
                </a-row>
                <div class="my-md">
                    <div id="report" style="height: 350px"></div>
                </div>
                <div class="my-md" :style="{ overflow: 'auto', height: scroll_y + 'px' }">
                    <!--                    表头-->
                    <div
                        class="lc-header top-border bottom-border"
                        style="position: sticky;top: 0px;overflow: auto;z-index: 100;"
                    >
                        <div class="lc-left-header">
                            <div class="lc-header-column left-border">
                                月份
                            </div>
                            <div class="lc-header-column left-border">
                                数据类型
                            </div>
                        </div>
                        <div class="lc-right-header">
                            <div class="lc-header-column left-border">
                                实际数值
                            </div>
                            <div class="lc-header-column flex-direction-column left-border flex-2">
                                <div class="lc-header-children bottom-border">年度计划差异率</div>
                                <div class="lc-header-children flex-direction-row">
                                    <div class="lc-header-children-column right-border">计划数值</div>
                                    <div class="lc-header-children-column">差异率</div>
                                </div>
                            </div>
                            <div class="lc-header-column flex-direction-column left-border right-border flex-2">
                                <div class="lc-header-children bottom-border">月度计划差异率</div>
                                <div class="lc-header-children flex-direction-row">
                                    <div class="lc-header-children-column right-border">计划数值</div>
                                    <div class="lc-header-children-column">差异率</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="lc-body">
                        <div class="lc-body-left flex-direction-column">
                            <div class="lc-data-row" v-for="item in tableData">
                                <div class="lc-row-column left-border height-155 bottom-border">
                                    <div class="lc-column-text">
                                        <div class="lc-ellipse">
                                            {{ item.yearMonth }}
                                        </div>
                                    </div>
                                </div>
                                <div class="lc-row-column left-border flex-direction-column ">
                                    <div class="lc-column-text bottom-border height-31">
                                        <div class="lc-ellipse">
                                            人数(人)
                                        </div>
                                    </div>
                                    <div class="lc-column-text height-31 bottom-border">
                                        <div class="lc-ellipse">
                                            出勤时数(小时)
                                        </div>
                                    </div>
                                    <div class="lc-column-text height-31 bottom-border">
                                        <div class="lc-ellipse ">
                                            加班时数(小时)
                                        </div>
                                    </div>
                                    <div class="lc-column-text height-31 bottom-border">
                                        <div class="lc-ellipse">
                                            请假时数(小时)
                                        </div>
                                    </div>
                                    <div class="lc-column-text height-31 bottom-border">
                                        <div class="lc-ellipse">
                                            成本(万元)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="lc-body-right flex-direction-column">
                            <div class="lc-body-data flex-direction-column" v-for="item in tableData">
                                <div class="lc-data-row" v-for="data in item.monthLCRateData">
                                    <div class="lc-row-column left-border bottom-border">
                                        <div class="lc-column-text">
                                            <div class="lc-ellipse">
                                                {{ data.actualNumber }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lc-row-column left-border bottom-border">
                                        <div class="lc-column-text">
                                            <div class="lc-ellipse">
                                                {{ data.yearPlanNumber }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lc-row-column left-border bottom-border">
                                        <div class="lc-column-text">
                                            <div class="lc-ellipse">{{ data.yearRate * 100 }}%</div>
                                        </div>
                                    </div>
                                    <div class="lc-row-column left-border bottom-border">
                                        <div class="lc-column-text">
                                            <div class="lc-ellipse">
                                                {{ data.monthPlanNumber }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="lc-row-column left-border right-border bottom-border">
                                        <div class="lc-column-text">
                                            <div class="lc-ellipse">{{ data.monthRate * 100 }}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="tableData.length <= 0">
                        <a-empty />
                    </div>
                </div>
                <!--                <div>-->
                <!--                    <a-pagination-->
                <!--                            class="pagination"-->
                <!--                            v-model="pageNumber"-->
                <!--                            :page-size-options="pageSizeOptions"-->
                <!--                            :total="totalItems"-->
                <!--                            show-size-changer-->
                <!--                            @change="onChange"-->
                <!--                            :page-size="pageSize"-->
                <!--                            @showSizeChange="showSizeChange"-->
                <!--                            :showTotal="showTotalFun"-->
                <!--                    >-->
                <!--                    </a-pagination>-->
                <!--                </div>-->
            </a-card>
            <div
                v-if="isOpen"
                :style="{
                    height: windowHeight + 'px',
                    position: 'absolute',
                    top: '0px',
                    width: '100%',
                }"
                @click="isOpen = false"
            ></div>
        </a-spin>
    </a-config-provider>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
import moment from 'moment';
import * as echarts from 'echarts';
import {
    CompanyServiceProxy,
    DepartmentServiceProxy,
    LaborcostYearPlanServiceProxy,
} from '../../../shared/service-proxies';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';
import { fileDownloadService } from '../../../shared/utils';

export default {
    name: 'dep-diff-rate',
    mixins: [AppComponentBase],
    data() {
        return {
            zh_CN,
            //表格上方间隔
            defaultTop: 300,
            isOpen: false,
            currentYear: undefined,
            companyTreeList: [], //公司
            comPanyId: undefined, //公司id
            departmentId: undefined,
            //部门树
            DepTreeData: [],
            DepTree: [],
            // 总数
            totalItems: 0,
            // 当前页码
            pageNumber: 1,
            //默认条数
            pageSize: 5,
            //开始索引
            startindex: 0,
            //结束索引
            endindex: 5,
            // 共多少页
            totalPages: 1,
            // 显示条数
            pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
            tableData: [],
            diffData: [],
            diffItemList: [
                {
                    type: 1,
                    name: '人数差异率',
                },
                {
                    type: 2,
                    name: '出勤差异率',
                },
                {
                    type: 3,
                    name: '加班差异率',
                },
                {
                    type: 4,
                    name: '请假差异率',
                },
                {
                    type: 5,
                    name: '成本差异率',
                },
            ],
            diffItem: 1,
        };
    },
    created() {
        this._fileDownloadService = fileDownloadService;
        // 公司
        this._companyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
        //部门
        this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
        this._laborcostYearPlanServiceProxy = new LaborcostYearPlanServiceProxy(this.$apiUrl, this.$api);
    },
    computed: {
        windowHeight() {
            return window.innerHeight - 150;
        },
    },
    mounted() {
        this.currentYear = moment();
        this.companyTreeInit();
    },
    methods: {
        refresh() {
            // this.pageNumber = 1;
            // this.request.skipCount = 0;
            this.getData();
        },
        getData() {
            this.loading = true;
            this._laborcostYearPlanServiceProxy
                .getMonthLCRate(this.currentYear.year(), this.comPanyId, this.departmentId, undefined)
                .finally(() => {
                    this.loading = false;
                })
                .then((res) => {
                    this.diffData = res;
                    // this.pageNumber = 1;
                    // this.startindex = 0;
                    // this.endindex = 5;
                    // this.totalItems = this.diffData.length;
                    this.pageData();
                    this.reportInit();
                });
        },
        reportDataInit() {
            let eData = {
                xAxis: [],
                yearDiff: [],
                monthDiff: [],
            };
            this.diffData.forEach((item) => {
                eData.xAxis.push(item.month + '月');
                let diff = item.monthLCRateData.filter((t) => t.type == this.diffItem);
                if (diff.length > 0) {
                    eData.yearDiff.push(diff[0].yearRate);
                    eData.monthDiff.push(diff[0].monthRate);
                } else {
                    eData.yearDiff.push(0);
                    eData.monthDiff.push(0);
                }
            });
            return eData;
        },
        /**
         * Eachart dom渲染
         */
        reportInit() {
            let data = this.reportDataInit();
            let chartDom = document.getElementById('report');
            let myChart = echarts.init(chartDom);
            let option;
            let seriesArr = [
                {
                    name: '年度计划差异率',
                    type: 'bar',
                    // label: labelOption,
                    data: data.yearDiff.map((item) => {
                        return {
                            value: item,
                            label: {
                                show: true,
                                position: item >= 0 ? 'top' : 'bottom',
                                distance: 15,
                                align: 'center',
                                verticalAlign: 'middle',
                                textStyle: {
                                    fontSize: 10,
                                    // color:"#252525"
                                },
                                formatter: function(record) {
                                    return (record.value * 1000000000000000) / 10000000000000 + '%';
                                },
                            },
                        };
                    }),
                },
                {
                    name: '月度计划差异率',
                    type: 'bar',
                    // label: labelOption,
                    data: data.monthDiff.map((item) => {
                        return {
                            value: item,
                            label: {
                                show: true,
                                position: item >= 0 ? 'top' : 'bottom',
                                distance: 15,
                                align: 'center',
                                verticalAlign: 'middle',
                                textStyle: {
                                    fontSize: 10,
                                    // color:"#252525"
                                },
                                formatter: function(record) {
                                    return (record.value * 1000000000000000) / 10000000000000 + '%';
                                },
                            },
                        };
                    }),
                },
            ];
            let legendArr = [
                {
                    name: '年度计划差异率',
                },
                {
                    name: '月度计划差异率',
                },
            ];
            switch (this.diffItem) {
                case 4:
                    seriesArr = [
                        {
                            name: '月度计划差异率',
                            type: 'bar',
                            // label: labelOption,
                            data: data.monthDiff.map((item) => {
                                return {
                                    value: item,
                                    label: {
                                        show: true,
                                        position: item >= 0 ? 'top' : 'bottom',
                                        distance: 15,
                                        align: 'center',
                                        verticalAlign: 'middle',
                                        textStyle: {
                                            fontSize: 10,
                                            // color:"#252525"
                                        },
                                        formatter: function(record) {
                                            return (record.value * 1000000000000000) / 10000000000000 + '%';
                                        },
                                    },
                                };
                            }),
                        },
                    ];
                    legendArr = [
                        {
                            name: '月度计划差异率',
                        },
                    ];
                    break;
                case 5:
                    seriesArr = [
                        {
                            name: '年度计划差异率',
                            type: 'bar',
                            // label: labelOption,
                            data: data.yearDiff.map((item) => {
                                return {
                                    value: item,
                                    label: {
                                        show: true,
                                        position: item >= 0 ? 'top' : 'bottom',
                                        distance: 15,
                                        align: 'center',
                                        verticalAlign: 'middle',
                                        textStyle: {
                                            fontSize: 10,
                                            // color:"#252525"
                                        },
                                        formatter: function(record) {
                                            return (record.value * 1000000000000000) / 10000000000000 + '%';
                                        },
                                    },
                                };
                            }),
                        },
                    ];
                    legendArr = [
                        {
                            name: '年度计划差异率',
                        },
                    ];
                    break;
            }

            let sdata = [...data.monthDiff, ...data.yearDiff];
            let min = Math.min(...sdata);
            if (min > 0) {
                min = 0;
            }
            option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                    formatter: function(record) {
                        let htm = '';
                        record.forEach((item, index) => {
                            if (index == 0) {
                                htm += '<b>' + item.name + '</b>' + '<br>';
                            }
                            htm +=
                                item.seriesName +
                                ': ' +
                                (item.value * 1000000000000000) / 10000000000000 +
                                '%' +
                                '<br>';
                        });
                        return htm;
                    },
                },
                legend: {
                    data: legendArr,
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: { show: false },
                        data: data.xAxis,
                        axisLabel: {
                            formatter: function(value) {
                                let valueTxt = '';
                                if (value.length > 5) {
                                    valueTxt = value.substring(0, 5) + '...';
                                } else {
                                    valueTxt = value;
                                }
                                return valueTxt;
                            },
                            // color:"#252525",
                            rotate: -40,
                        },
                    },
                ],
                yAxis: [
                    {
                        axisLabel: {
                            formatter: function(value) {
                                return value * 100 + '%';
                            },
                        },
                        min: min * 1.2,
                    },
                ],
                series: seriesArr,
            };
            myChart.setOption(option, true);
            window.addEventListener('resize', () => {
                myChart.resize();
            });
        },
        searchTree(inputValue, treeNode) {
            return treeNode.data.props.title.includes(inputValue);
        },
        companyTreeInit() {
            this._companyConfigServiceProxy
                .getPaged(undefined, '', 100, 0)
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this.companyList = res.items;
                    this.companyList.forEach((item) => {
                        if (item.parentId == 0) {
                            var obj = {
                                key: item.id,
                                value: item.id,
                                title: item.fullName,
                            };
                            this.companyTreeList.push(obj);
                        }
                    });
                    this.initCompanyTree(this.companyTreeList);
                    if (this.companyTreeList.length > 0 && !this.comPanyId) {
                        this.handleChange(this.companyTreeList[0].key);
                    } else {
                        this.refresh();
                    }
                });
        },
        /**
         * 初始化树形结构数据格式
         */
        initCompanyTree(list) {
            list.forEach((v) => {
                //遍历父级数据，加载子集=
                v.children = []; //创建空的子集
                this.companyList.forEach((m) => {
                    if (m.parentId == v.value) {
                        //根据主键查子集数据
                        var obj = {
                            key: m.id,
                            value: m.id,
                            title: m.fullName,
                        };
                        v.children.push(obj); //放进父级的集合中
                        this.initCompanyTree(v.children); //递归查询子集的集合
                    }
                });
                if (v.children.length == 0) {
                    //校验如何数据没有子集，删除子集参数
                    delete v.children;
                }
            });
        },
        handleChange(value) {
            this.comPanyId = value;
            this.departmentId = undefined;
            this.refresh();
            this.getDepTreeData();
        },
        /**
         * 获取部门
         */
        getDepTreeData() {
            this.DepTreeData = [];
            this._departmentServiceProxy
                .getOneDepData(this.comPanyId)
                .then((res) => {
                    this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
                    this.DepTree.forEach((item) => {
                        if (!item.parentId) {
                            this.DepTreeData.push(item);
                        }
                    });
                    this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        //年选择器改变
        yearChange(e) {
            this.isOpen = false;
            this.currentYear = e;
            this.refresh();
        },
        /**
         * 数据分页
         */
        pageData() {
            this.loading = true;
            // this.totalPages = Math.ceil(
            //     this.totalItems / this.pageSize
            // );
            // this.tableData = _.slice(this.diffData, this.startindex, this.endindex);
            this.tableData = this.diffData;
            this.loading = false;
        },
        /**
         * 每页条数事件
         */
        showSizeChange(current, pageSize) {
            this.pageSize = pageSize;
            this.pageNumber = current;
            this.startindex = (this.pageNumber - 1) * this.pageSize;
            this.endindex = this.startindex + this.pageSize;
            this.pageData();
        },
        /**
         * 页码事件
         */
        onChange(page, pageSize) {
            this.pageNumber = page;
            this.pageSize = pageSize;
            this.startindex = (page - 1) * this.pageSize;
            this.endindex = this.startindex + this.pageSize;
            this.pageData();
        },
        /**
         * 分页事件
         */
        showTotalFun() {
            return this.l(
                'GridFooterDisplayText',
                this.pageNumber,
                this.totalPages,
                this.totalItems,
                this.startindex + 1,
                this.endindex > this.totalItems ? this.totalItems : this.endindex
            );
        },
        /**
         * 导出为excel
         */
        exportToExcel() {
            if (this.diffData.length <= 0) {
                return abp.message.warn(this.l('NoData'));
            }
            this.loading = true;
            this._laborcostYearPlanServiceProxy
                .getMonthLCRateToExcelFile(this.currentYear.year(), this.comPanyId, this.departmentId, undefined)
                .finally(() => {
                    this.loading = false;
                })
                .then((result) => {
                    this._fileDownloadService.downloadTempFile(result);
                });
        },
    },
};
</script>

<style scoped lang="less">
.pagination {
    margin: 10px auto;
    text-align: right;
}

@import 'month-lc-rate';
</style>
