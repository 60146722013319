<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="[8, 8]">
				<a-col :span="10">
					<a-button :type="'primary'" v-if="isGranted('AddDepUser')" @click="addUser()">
						<a-icon type="plus" />
						<span>{{ l('AddUser') }}</span>
					</a-button>
					<a-button :type="'danger'" v-if="isGranted('DeleteDepUser')" @click="batchDepUserDelete()">
						<a-icon type="delete" />
						<span>{{ l('Delete') }}</span>
					</a-button>
				</a-col>
				<a-col :span="14" style="text-align: right">
					<a-input-search
						style="width: 200px;"
						name="filterText"
						@search="search"
						:placeholder="l('SearchWithThreeDot')"
						enterButton
						v-model="filterText"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				@change="handleChange"
				:columns="columns"
				size="small"
				:pagination="false"
				:rowKey="(data) => data.id"
				:dataSource="tableData"
			>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { AddDepUsersDto, UserDepPluralismServiceProxy } from '../../../../shared/service-proxies';
import UserSelect from '../select-user-success-user/select-user-success-user.vue';
import { ModalHelper } from '@/shared/helpers';
import Bus from '@/shared/bus/bus';
import * as _ from 'lodash';

export default {
	name: 'user-dep-pluralism-panel',
	mixins: [AppComponentBase],
	components: { UserSelect },
	props: {
		treeData: {
			type: Object,
			default() {
				return {};
			},
		},
		companyId: {
			type: Number,
			default: undefined,
		},
	},
	data() {
		return {
			_userDepPluralismServiceProxy: null,
			filteredInfo: null,
			sortedInfo: null,
			// 用户表格
			columns: [
				{
					title: this.l('RealName'),
					dataIndex: 'userName',
					align: 'center',
					scopedSlots: { customRender: 'userName' },
					ellipsis: true,
				},
				{
					title: this.l('JobNumber'),
					dataIndex: 'jobNumber',
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
					ellipsis: true,
				},
				{
					title: '部门',
					dataIndex: 'departmentName',
					align: 'center',
					scopedSlots: { customRender: 'departmentName' },
					ellipsis: true,
				},
				{
					title: this.l('EmailAddress'),
					dataIndex: 'email',
					align: 'center',
					scopedSlots: { customRender: 'email' },
					ellipsis: true,
				},
				{
					title: this.l('Mobile'),
					dataIndex: 'mobile',
					align: 'center',
					scopedSlots: { customRender: 'mobile' },
					ellipsis: true,
				},
			],
			tableData: [],
		};
	},
	created() {
		this._userDepPluralismServiceProxy = new UserDepPluralismServiceProxy(this.$apiUrl, this.$api);
		this.columns = this.getColumn('兼职员工', this.columns);
		// 接受树结构传过来的选中item
		this.getData();
	},
	methods: {
		getData() {
			if (this.isNotChoices) {
				this.treeData = null;
			}
			this.loading = true;
			this._userDepPluralismServiceProxy
				.getPagedDepUsers(
					this.treeData ? this.treeData.id : undefined,
					this.companyId,
					this.filterText,
					'id',
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((result) => {
					this.tableData = result.items;
					this.tableData.map((item) => {
						item.checked = false;
					});
					this.totalItems = result.totalCount;
					this.$emit('GetDepUserCount', result.totalCount);
				});
		},
		/**
		 * 选中树结构
		 */
		getTree(data) {
			this.treeData = data;
			this.getData();
		},

		handleChange(pagination, filters, sorter) {
			this.filteredInfo = filters;
			this.sortedInfo = sorter;
		},
		/**
		 * 添加
		 */
		addUser() {
			if (!this.companyId) {
				return abp.message.warn('请先选择公司');
			}
			if (!this.treeData.id) {
				return abp.message.warn('请先选择部门');
			}
			ModalHelper.create(UserSelect, {
				organizationUnitId: this.treeData ? this.treeData.id : undefined,
				companyId: this.companyId,
			}).subscribe((res) => {
				if (res) {
					this.loading = true;
					const input = new AddDepUsersDto();
					var ids = _.map(res, 'id');
					input.departmentId = this.treeData.id;
					input.depUserIds = ids;
					this._userDepPluralismServiceProxy
						.batchCreateDepUsers(input)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: this.l('SavedSuccessfully'),
							});

							Bus.$emit('reloadOrganizationUnitTree', {
								depId: this.treeData ? this.treeData.id : undefined,
								companyId: this.companyId,
							});
						});
				}
			});
		},
		/**
		 * 批量删除
		 */
		batchDepUserDelete() {
			if (this.selectedRowKeys.length <= 0) {
				abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
				return;
			}
			this.$confirm({
				content: this.l('ConfirmDeleteXItemsWarningMessage', this.selectedRowKeys.length),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					this.loading = true;
					var ids = _.map(this.selectedRowKeys);
					this._userDepPluralismServiceProxy
						.batchDelete(ids)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.request.skipCount = 0;
							this.pageNumber = 1;
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
							Bus.$emit('reloadOrganizationUnitTree', {
								depId: this.treeData ? this.treeData.id : undefined,
								companyId: this.companyId,
							});
						});
				},
			});
		},
	},
};
</script>

<style scoped lang="less">
.user-container {
	border: 1px solid #e8e8e8;
	margin: 20px;
	padding: 20px;

	.table-delete {
		i {
			margin-right: 10px;
		}
	}
}

.opeattion-container {
	margin: 20px 0;
}

.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
