<template>
	<a-row :gutter="8">
		<a-col :span="5">
			<q-tree-container>
				<template #select>
					<a-tree-select
						@change="handleChange"
						style="width: 100%"
						tree-default-expand-all
						:tree-data="companyTreeList"
						v-model="CompanyId"
					/>
				</template>

				<template #tree>
					<a-tree
						v-if="PostTreeData.length > 0"
						:treeData="PostTreeData"
						:selectedKeys="[postId]"
						showIcon
						defaultExpandAll
						@select="onSelect"
					>
						<a-icon type="folder" slot="folder" />
						<a-icon type="file" slot="file" />
						<a-icon type="folder-open" slot="folder-open" />
						<template slot="custom" slot-scope="{ expanded }">
							<a-icon :type="expanded ? 'folder-open' : 'folder'" />
						</template>
					</a-tree>
				</template>
			</q-tree-container>
		</a-col>

		<a-col :span="19"><userrelation-view ref="relation"></userrelation-view> </a-col>
	</a-row>
</template>

<script>
import { CompanyServiceProxy } from '@/shared/service-proxies';
import { OrgPostServiceProxy, OrgPostGradeServiceProxy } from '../../../shared/service-proxies';
import * as _ from 'lodash';
import UserrelationView from './userrelation-view/userrelation-view';
import { AppComponentBase } from '../../../shared/component-base';
import { abpService } from '../../../shared/abp';
export default {
	name: 'concurrent-post',
	mixins: [AppComponentBase],
	components: { UserrelationView },
	data() {
		return {
			//选中的树节点ID
			CompanyId: undefined,
			treespinning: false,
			tableTree: [],
			tablespinning: false,
			//部门树
			PostTreeData: [],
			PostTree: [],
			companyTreeList: [],
			companyList: [],
			postId: undefined,
		};
	},
	created() {
		this._companyServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._OrgPostServiceProxy = new OrgPostServiceProxy(this.$apiUrl, this.$api);
		this._orgPostGradeServiceProxy = new OrgPostGradeServiceProxy(this.$apiUrl, this.$api);

		this.GetCompany();
		if (abpService.abp.userInfo.companyId) {
			this.CompanyId = abpService.abp.userInfo.companyId;
			this.handleChange(this.CompanyId);
		}
	},
	methods: {
		/**
		 * 获取公司列表
		 */
		GetCompany() {
			this.treespinning = true;
			this._companyServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.treespinning = false;
				})
				.then((res) => {
					if (res.items.length < 1) return;
					if (res.items.length > 0 && !this.CompanyId) {
						this.CompanyId = res.items[0].id;
						this.getPostTreeData();
					}

					this.companyList = res.items;
					this.companyTreeList = [];
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 公司初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.CompanyId = value;
			this.getPostTreeData();
		},
		/**
		 * 点击树
		 */
		onSelect(keys) {
			this.postId = keys[0];
			this.$refs.relation.request.skipCount = 0;
			this.$refs.relation.pageNumber = 1;
			this.$refs.relation.getData(this.postId);
		},
		/**
		 * 岗位表格树初始化
		 */
		postgradeinitTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.tableTree.forEach((m) => {
					if (m.parentId == v.id) {
						v.children.push(m); //放进父级的集合中
						this.postgradeinitTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 设置岗位树数据
		 */
		getPostTreeData() {
			this.PostTreeData = [];
			this._OrgPostServiceProxy
				.getPagedDepPosts(this.CompanyId == undefined ? 999 : this.CompanyId, undefined, '', undefined, 999, 0)
				.then((res) => {
					// if (res.items.length < 1) {
					//     this.postId = undefined;
					//     return;
					// }
					// this.postId = res.items[0].id;
					this.PostTree = res.items;
					this.PostTree.forEach((item) => {
						if (item.parentId == 0) {
							let obj = {
								key: item.id,
								value: item.id.toString(),
								title: item.name,
							};
							this.PostTreeData.push(obj);
						}
					});
					this.initPostTree(this.PostTreeData);
					this.$refs.relation.getData(this.postId);
				});
		},
		/**
		 * 岗位初始化部门树形结构
		 */
		initPostTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.PostTree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id.toString(),
							title: m.name,
						};
						v.children.push(obj); //放进父级的集合中
						this.initPostTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
	},
};
</script>

<style scoped></style>
