<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('EditNews') }}</span>
				<span v-else>{{ l('CreateNews') }}</span>
			</div>
		</div>
		<a-config-provider :locale="zhCN">
			<a-form :form="form" @submit="save()" autocomplete="off">
				<a-row :gutter="8">
					<a-row>
						<a-col :span="3">
							<q-label required :label="l('FullHead')"> </q-label>
						</a-col>
						<a-col :span="21">
							<a-input :placeholder="l('FullHead')" v-model="entity.fullHead" />
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="3">
							<q-label :label="l('Subtitle')"> </q-label>
						</a-col>
						<a-col :span="21">
							<a-input :placeholder="l('Subtitle')" v-model="entity.subtitle" />
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="3">
							<q-label required :label="l('CategoryType')"> </q-label>
						</a-col>
						<a-col :span="9">
							<a-select
								show-search
								:placeholder="l('CategoryType')"
								option-filter-prop="children"
								v-model="entity.categoryCode"
							>
								<a-select-option v-for="item in CategoryTypeList" :key="item.itemDetailCode">
									{{ item.itemDetailName }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col :span="3">
							<q-label required :label="l('ReleaseTime')"> </q-label>
						</a-col>
						<a-col :span="9">
							<a-date-picker
								:show-time="timeOp"
								v-model="releaseTime"
								:open="IsOpen"
								@focus="onfocus"
								:placeholder="l('ReleaseTime')"
								@change="onChange"
								@ok="onOk"
								format="YYYY-MM-DD HH:mm"
								style="width: 100%"
							>
							</a-date-picker>
						</a-col>
					</a-row>

					<a-row>
						<a-col :span="3">
							<q-label :label="l('TagWord')"> </q-label>
						</a-col>
						<a-col :span="9">
							<a-input :placeholder="l('TagWord')" v-model="entity.tagWord" />
						</a-col>
						<a-col :span="3">
							<q-label :label="l('KeyWord')"> </q-label>
						</a-col>
						<a-col :span="9">
							<a-input :placeholder="l('KeyWord')" v-model="entity.keyWord" />
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="3">
							<q-label required :label="l('AuthorName')"> </q-label>
						</a-col>
						<a-col :span="9">
							<a-input :placeholder="l('AuthorName')" v-model="entity.authorName" />
						</a-col>
						<!--                        <a-col :span="3">-->
						<!--                            <q-label required :label="l('CompileName')">-->
						<!--                            </q-label>-->
						<!--                        </a-col>-->
						<!--                        <a-col :span="9">-->
						<!--                            <a-input :placeholder="l('CompileName')" v-model="entity.compileName"/>-->
						<!--                        </a-col>-->
					</a-row>
					<a-row>
						<a-col :span="3">
							<q-label :label="l('NewsContent')"> </q-label>
						</a-col>
						<a-col :span="21" class="editor">
							<editor @onChange="editorOnChang" :value="entity.newsContent"></editor>
						</a-col>
					</a-row>
				</a-row>
			</a-form>
		</a-config-provider>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import { CreateOrUpdateNewsInput, NewsEditDto, NewsServiceProxy } from '../../../../shared/service-proxies';
import Dic from '../../../../shared/utils/dic';
import moment from 'moment';
import Editor from '../../../../components/editor/editor';
import $ from 'jquery';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'create-edit-news',
	mixins: [ModalComponentBase],
	components: {
		Editor,
	},
	data() {
		return {
			spinning: false,
			form: this.$form.createForm(this),
			Id: undefined,
			entity: new NewsEditDto(),
			CategoryTypeList: [],
			releaseTime: undefined,
			zhCN,
			IsOpen: false,
			timeOp: {
				format: 'HH:mm',
			},
		};
	},
	async created() {
		this.fullData(); // 模态框必须,填充数据到data字段

		this._NewsServiceProxy = new NewsServiceProxy(this.$apiUrl, this.$api);
		this.releaseTime = moment(moment().format('YYYY-MM-DD HH:mm'));
		this.entity.releaseTime = this.releaseTime;
		this.CategoryTypeList = await Dic.getInstance().getDicAsync('CategoryType');
		if (this.id) {
			this.getData();
		}
	},
	mounted() {},
	methods: {
		getData() {
			this.spinning = true;
			this._NewsServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.news;
					this.releaseTime = this.entity.releaseTime;
				});
		},
		onChange(e) {
			this.entity.releaseTime = e;
			this.IsOpen = false;
		},
		onOk(value) {
			this.entity.releaseTime = value;
			this.IsOpen = false;
		},
		onfocus(e) {
			this.IsOpen = true;
		},
		editorOnChang(v) {
			//this.newsContent=v;
			this.entity.newsContent = v;
		},
		save() {
			if (!this.entity.fullHead) {
				return abp.message.warn('请输入标题');
			}
			if (!this.entity.categoryCode) {
				return abp.message.warn('请选择新闻栏目');
			}
			if (!this.entity.releaseTime) {
				return abp.message.warn('请指定发布时间');
			}
			if (!this.entity.authorName) {
				return abp.message.warn('请输入作者');
			}
			let input = new CreateOrUpdateNewsInput();
			input.news = NewsEditDto.fromJS(this.entity);
			input.departmentIds = '';
			this.spinning = true;
			this._NewsServiceProxy
				.createOrUpdateNews(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.editor {
	line-height: 22px;
}
</style>
