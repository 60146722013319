<template>
	<div>
		<!-- 标题 -->
		<div class="modal-header" style="display: flex; justify-content: space-between; margin-bottom: 5px">
			<div class="modal-title">
				<span>选择员工</span>
			</div>
			<div>
				<a-button :disabled="saving" @click="close()" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</div>
		<a-row>
			<a-col :span="7">
				<a-spin :spinning="leftspinning">
					<a-row :gutter="8">
						<a-col :span="24" style="display: flex; align-items: center;">
							<div>
								<a-icon type="share-alt" />
								{{ l('Company') }}
							</div>
							<a-tree-select
								style="flex: 1; margin:0 10px"
								allowClear
								@change="handleChange"
								placeholder="公司"
								tree-default-expand-all
								:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								:tree-data="companyTreeList"
								v-model="companyId"
							/>
						</a-col>
					</a-row>
					<a-row class="tree">
						<a-tree
							:treeData="treeData"
							showIcon
							@select="onSelect"
							:expanded-keys="expandedKeys"
							@expand="onExpand"
							:selectedKeys="SelectKey"
						>
							<a-icon type="folder" slot="folder" />
							<a-icon type="file" slot="file" />
							<a-icon type="folder-open" slot="folder-open" />
							<template slot="custom" slot-scope="{ expanded }">
								<a-icon :type="expanded ? 'folder-open' : 'folder'" />
							</template>
						</a-tree>
					</a-row>
				</a-spin>
			</a-col>
			<a-col :span="17">
				<!-- usePagination开启分页 -->
				<!-- loading绑定引入页面的蒙层加载变量 -->
				<q-container usePagination :loading="loading" useModal>
					<!-- 使用具名插槽 #toolbar -->
					<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
					<template #toolbar>
						<a-input-search
							name="filterText"
							:placeholder="l('SearchWithThreeDot')"
							@search="getData"
							enterButton
							v-model="filterText"
						/>
						<div style="display: flex;">
							<div class="my-md">
								<p v-if="mergeRows.length <= 0" class="NotR">
									已选人员
								</p>
								<a-tag
									closable
									@close="tagcol(item, item.id)"
									v-for="(item, index) in mergeRows"
									:key="index"
									v-else
								>
									{{ item.realName }}
								</a-tag>
							</div>
							<div class="counts">已选{{ mergeRows.length }}人</div>
						</div>
					</template>
					<!-- 使用具名插槽slot="table" -->
					<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
					<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

					<!-- **************行选择*************** -->
					<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
					<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
					<template slot="table" slot-scope="scopeParam">
						<a-table
							ref="table"
							size="small"
							:pagination="false"
							:columns="columns"
							:rowKey="(tableDatas) => tableDatas.id"
							:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
							:dataSource="data"
							:customRow="customRow"
							:rowSelection="rowSelection"
						>
						</a-table>
					</template>
					<!-- 使用具名插槽 #page -->
					<template #page>
						<!-- 分页组件只需复制以下代码 -->
						<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
						<a-pagination
							v-model="pageNumber"
							:total="totalItems"
							showSizeChanger
							size="small"
							:defaultPageSize="request.maxResultCount"
							:pageSizeOptions="pageSizeOptions"
							:showTotal="() => showTotal"
							@change="onPageChange"
							@showSizeChange="showPageSizeChange"
						/>
					</template>
				</q-container>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import { CompanyServiceProxy, OrganizationUnitServiceProxy, UserServiceProxy } from '@/shared';
import _ from 'lodash';
import abpService from '@/shared/abp/abp.service';
import DepTreeUntils from '@/app/organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'select-user-tag',
	mixins: [ModalComponentBase],
	data() {
		return {
			leftspinning: false,

			companyId: undefined,
			companyList: [],
			SelectKey: [],
			expandedKeys: [],
			treeData: [],
			companyTreeList: [],
			_ouData: {},
			// 用户表格
			columns: [
				{
					title: this.l('RealName'),
					dataIndex: 'realName',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('JobNumber'),
					dataIndex: 'jobNumber',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: this.l('Mobile'),
					dataIndex: 'mobile',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'mobile' },
				},
			],
			//多选
			isMultiple: false,
			// 用户数据
			data: [],
			depId: undefined,
			_selectedRowKeys: [],
			_selectedRows: [],
		};
	},
	computed: {
		mergeRows() {
			//根据ID得并集
			return [..._.unionBy(this._selectedRows, this.selectedRows, 'id')];
		},
	},
	created() {
		this.fullData();
		this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._UserServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.companyId = abpService.abp.userInfo.companyId;
			this.handleChange(this.companyId);
		} else {
			this.getData();
		}
	},
	methods: {
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.pageNumber = 1;
			this.request.skipCount = (this.pageNumber - 1) * this.request.maxResultCount;

			this.companyId = value;
			this.getData();
			this.getCompanyData();
		},
		getCompanyData() {
			this.leftspinnin = true;
			this._organizationUnitServiceProxy
				.getAllOrganizationUnitList(this.companyId, false)
				.finally(() => {
					this.leftspinnin = false;
				})
				.then((result) => {
					this.treeData = [];
					this._ouData = DepTreeUntils.getInstance().InitTreeData(result.items);
					this._ouData.forEach((item) => {
						if (!item.parentId) {
							this.treeData.push(item);
						}
					});
					this.treeData = DepTreeUntils.getInstance().ArrMapTree(this._ouData, this.treeData, false);
					if (result.items.length > 0) {
						// this.onSelect([result.items[0].id.toString()], undefined);
					} else {
						this.data = [];
					}
				});
		},
		/**
		 * 选中item
		 */
		onSelect(selectedKeys, info) {
			this.pageNumber = 1;
			this.request.skipCount = (this.pageNumber - 1) * this.request.maxResultCount;

			this.SelectKey = selectedKeys;
			this.depId = selectedKeys.length > 0 ? parseInt(selectedKeys[0]) : undefined;
			this.getData();
		},
		/**
		 * 展开
		 */
		onExpand(expandedKeys, record) {
			this.expandedKeys = expandedKeys;
		},
		/**
		 * 拉取数据
		 */
		getData() {
			this.loading = true;
			this._UserServiceProxy
				.getPageListByDepId(
					this.companyId,
					this.depId,
					undefined,
					undefined,
					this.filterText,
					'',
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((result) => {
					this.data = result.items;
					this.totalItems = result.totalCount;
					//轮循key
					for (let key of this._selectedRowKeys) {
						//从本页数据中查询key
						const index = this.data.findIndex((d) => d.id == key);
						//如果存在
						if (index != -1) {
							//从已选中的数据中找到与本页一样ID的数据
							const keyIndex = this._selectedRows.findIndex((r) => r.id == this.data[index].id);
							//删除已选中的数据
							this._selectedRows.splice(keyIndex, 1);
							//重新选中
							this.rowSelect(this.data[index], index);
						}
					}
				});
		},
		//点击标签删除
		tagcol(record, id) {
			//从本页数据中找删除的这条数据
			const index = this.data.findIndex((t) => t.id == id);
			//删除本页数据
			if (index != -1) {
				//取消行选中 == 删除
				this.rowSelect(record, index);
			} //删除其他页数据
			else {
				//从已选中的keys中找到
				const keyIndex = this._selectedRowKeys.findIndex((r) => r == record.id);
				if (keyIndex != -1) {
					//删除key
					this._selectedRowKeys.splice(keyIndex, 1);
				}
				//从已选中的数据中找到
				const rowIndex = this._selectedRows.findIndex((r) => r.id == record.id);
				if (rowIndex != -1) {
					//删除数据
					this._selectedRows.splice(rowIndex, 1);
				}
			}
		},
		handleSubmit() {
			if (this.isMultiple) {
				//多选返回全部
				this.success([..._.unionBy(this._selectedRows, this.selectedRows, 'id')]);
			} else {
				//单选返回一条
				this.success(this.mergeRows[0]);
			}
		},
		//重写混入文件内的方法
		onPageChange(page) {
			this.pageNumber = page;
			this.request.skipCount = (page - 1) * this.request.maxResultCount;
			this.getData();
		},
		//重写混入文件内的方法
		showPageSizeChange(current, size) {
			this.pageNumber = 1;
			this.request.maxResultCount = size;
			this.getData();
		},
	},
};
</script>

<style scoped>
.counts {
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.my-md {
	flex: 1;
	border: 1px solid #d9d9d9;
	min-height: 45px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
}

.NotR {
	height: 20px;
	color: #bfbfbf;
}

.my-md::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}

.my-md::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.my-md::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}
</style>
