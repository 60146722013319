<template>
    <a-spin :spinning="loading" >
            <div id="tree" style="height: 100%"></div>
    </a-spin>
</template>

<script>

    import OrgChart from "../../../../libs/OrgChart/OrgChart";
    import {AppComponentBase, OrganizationChartServiceProxy, UserServiceProxy} from "@/shared";


    export default {
        name: "orgchart",
        mixins: [AppComponentBase],
        data() {
            return {
                option: {
                    template: "luba",
                    lazyLoading:true,
                    layout: OrgChart.mixed,
                    // nodeMenu: {
                    //     png: {text: "导出PNG图片"},
                    // },
                    nodeMouseClick: null,
                    nodeBinding: {
                        field_3: "name",
                        field_2: "postName",
                        field_1: "orgName",
                        field_0: "num",
                        img_0: "image"
                    },
                    nodes: [], //{"pid":"d12","id":"d13","ORGName":"财务分析","PostName":null,"Sex":null,"Image":null,"Num":"人数:2","Name":null,"JobNumber":null,"userId":null}
                    collapse: {
                        level: 3,
                        allChildren: true
                    }
                }
            }
        },
        mounted() {
            this._organizationChartServiceProxy = new OrganizationChartServiceProxy(this.$apiUrl, this.$api);
            this.loading = true;
            this._organizationChartServiceProxy.getOrgChart()
                .finally(() => {
                    this.loading = false;
                })
                .then(res => {
                            this.option.nodes = res;
                            new OrgChart(document.getElementById("tree"), this.option);
                })

        },
        methods: {
        }
    };
</script>
<style scoped>
    >>> .ant-spin-container {
        height: 100%;
    }
</style>
