<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row>
				<a-col :span="12">
					<a-button :type="'primary'" v-if="isGranted('task_add')" @click="create()">
						<a-icon type="plus" />
						<span>{{ l('Create') }}</span>
					</a-button>
					<a-button :type="'primary'" v-if="isGranted('task_edit')" @click="edit()">
						<a-icon type="edit" />
						<span>{{ l('Edit') }}</span>
					</a-button>
					<a-button :type="'danger'" v-if="isGranted('task_delete')" @click="BatchDelete">
						<a-icon type="delete" />
						<span>{{ l('Delete') }}</span>
					</a-button>
					<a-button :type="'primary'" v-if="isGranted('task_analysis')" @click="analysis">
						<a-icon type="edit" />
						<span>查看分析</span>
					</a-button>
					<a-button :type="'primary'" v-if="isGranted('task_export')" @click="exporting">
						<a-icon type="export" />
						<span>导出</span>
					</a-button>
				</a-col>

				<a-col :span="12" style="text-align: right">
					<span>
						<a-select
							show-search
							:placeholder="l('状态')"
							option-filter-prop="children"
							style="max-width: 150px;width: 100%"
							aria-required="true"
							:allowClear="true"
							v-model="state"
							@change="refresh()"
						>
							<a-select-option v-for="item in stateList" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</span>
					<span>
						<a-input-search
							style="max-width: 200px;padding-left: 10px"
							name="filterText"
							:placeholder="l('SearchWithThreeDot')"
							@search="refresh"
							enterButton
							v-model="filterText"
						/>
					</span>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: 1800, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<span slot="cutoffDate" slot-scope="text, record">
					<span v-if="hasday(record).state">
						{{ hasday(record).time
						}}<b style="color: rgba(255,0,0,0.68)">(逾期{{ hasday(record).day }}天)</b>
					</span>
					<span v-else>
						{{ hasday(record).time }}
					</span>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import * as _ from 'lodash';
import { TaskBaseInfoServiceProxy } from '../../../shared/service-proxies';
import moment from 'moment';
import CreateOrEditBaseInfo from './cerate-or-edit-baseinfo/cerate-or-edit-baseinfo';
import modalHelper from '../../../shared/helpers/modal/modal-helper';
import { Dic, fileDownloadService } from '../../../shared/utils';
import TaskAnalysis from './task-analysis/task-analysis';

export default {
	name: 'baseinfo',
	mixins: [AppComponentBase],
	components: { CreateOrEditBaseInfo, TaskAnalysis },
	data() {
		return {
			// 用户表格
			columns: [
				{
					title: '发起人',
					dataIndex: 'creatorUserName',
					sorter: true,
					width: 80,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'creatorUserName' },
				},
				{
					title: '任务项',
					dataIndex: 'item',
					sorter: true,
					ellipsis: true,
					align: 'center',
					width: 180,
					scopedSlots: { customRender: 'item' },
				},
				{
					title: '负责人',
					dataIndex: 'principalName',
					sorter: false,
					ellipsis: true,
					width: 100,
					align: 'center',
					scopedSlots: { customRender: 'principalName' },
				},
				{
					title: '任务状态',
					dataIndex: 'stateName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'stateName' },
				},
				{
					title: '分类',
					dataIndex: 'classifyName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'classifyName' },
				},
				{
					title: '创建方式',
					dataIndex: 'creatWayName',
					sorter: true,
					ellipsis: true,
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'creatWayName' },
				},
				{
					title: '创建时间',
					dataIndex: 'creationTime',
					sorter: true,
					ellipsis: true,
					align: 'center',
					width: 120,
					scopedSlots: { customRender: 'creationTime' },
				},
				{
					title: '截止完成时间',
					dataIndex: 'cutoffDate',
					sorter: true,
					ellipsis: true,
					align: 'left',
					width: 180,
					scopedSlots: { customRender: 'cutoffDate' },
				},
				{
					title: '接收时间',
					dataIndex: 'receiveTime',
					sorter: true,
					ellipsis: true,
					align: 'center',
					width: 120,
					scopedSlots: { customRender: 'receiveTime' },
				},
				{
					title: '完成时间',
					dataIndex: 'completeTime',
					sorter: true,
					ellipsis: true,
					align: 'center',
					width: 120,
					scopedSlots: { customRender: 'completeTime' },
				},
			],
			tableData: [],
			stateList: [],
			state: undefined,
		};
	},
	created() {
		this._taskBaseInfoServiceProxy = new TaskBaseInfoServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
	},
	computed: {},
	async mounted() {
		this.refresh();
		this.stateList = await Dic.getInstance().getDicAsync('QYB_TaskState');
	},
	methods: {
		/**
		 *截止完成时间格式化
		 */
		hasday(record) {
			if (record.cutoffDate) {
				let day = moment().diff(moment(record.cutoffDate), 'day');
				//执行中状态
				if (day > 0 && record.state == '1') {
					return {
						state: true,
						time: moment(record.cutoffDate).format('YYYY-MM-DD hh:mm'),
						day: day,
					};
				} else {
					return {
						state: false,
						time: moment(record.cutoffDate).format('YYYY-MM-DD hh:mm'),
						day: 0,
					};
				}
			} else {
				return {
					state: false,
					time: '',
					day: 0,
				};
			}
		},
		getData() {
			this.loading = true;
			this._taskBaseInfoServiceProxy
				.getPaged(
					this.state,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
						item.creationTime = item.creationTime
							? moment(item.creationTime).format('YYYY-MM-DD hh:mm')
							: '';
						item.completeTime = item.completeTime
							? moment(item.completeTime).format('YYYY-MM-DD hh:mm')
							: '';
						item.receiveTime = item.receiveTime ? moment(item.receiveTime).format('YYYY-MM-DD hh:mm') : '';
					});
					this.totalItems = res.totalCount;
				});
		},
		create() {
			modalHelper
				.create(
					CreateOrEditBaseInfo,
					{},
					{
						width: '800px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.refresh();
					}
				});
		},
		edit() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			if (this.selectedRowKeys.length > 1) {
				return abp.message.warn('只能选择一项进行操作');
			}
			modalHelper
				.create(
					CreateOrEditBaseInfo,
					{
						id: this.selectedRowKeys[0],
					},
					{
						width: '800px',
					}
				)
				.subscribe((res) => {
					if (res) {
						this.refresh();
					}
				});
		},
		BatchDelete() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			this.$confirm({
				title: '确认操作',
				content: '你确定要删除这' + this.selectedRowKeys.length + '项数据吗？',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;
					this._taskBaseInfoServiceProxy
						.batchDelete(this.selectedRowKeys)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.refresh();
						});
				},
			});
		},
		analysis() {
			modalHelper
				.create(
					TaskAnalysis,
					{},
					{
						width: '800px',
					}
				)
				.subscribe((res) => {});
		},
		/**
		 * 导出
		 */
		exporting() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.loading = true;
			this._taskBaseInfoServiceProxy
				.getToExcelFile(
					this.state,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
	},
};
</script>

<style scoped></style>
