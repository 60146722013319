<template>
	<a-spin :spinning="loading">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('Edit') }}</span>
				<span v-else>{{ l('Create') }}</span>
			</div>
		</div>
		<a-form @submit="save()" autocomplete="off">
			<a-row :gutter="8">
				<a-row>
					<a-col :span="3">
						<q-label required :label="l('Name')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input :placeholder="l('CourseCategoryNameInputDesc')" v-model="entity.name" />
					</a-col>
					<a-col :span="3">
						<q-label required :label="l('Code')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input :placeholder="l('CourseCategoryCodeInputDesc')" v-model="entity.enCode" />
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="3">
						<q-label :label="l('ParentPost')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-tree-select
							v-model="pId"
							style="width: 100%"
							show-search
							:filterTreeNode="searchTree"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="PostTreeData"
							:placeholder="l('ParentPost')"
							@select="SelectTree"
						>
						</a-tree-select>
					</a-col>
					<a-col :span="3">
						<q-label :label="l('ParentDepartment')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-tree-select
							v-model="entity.departmentId"
							style="width: 100%"
							show-search
							:filterTreeNode="searchTree"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="DepTreeData"
							:placeholder="l('ParentDepartment')"
							tree-default-expand-all
							@select="SelectTree"
						>
						</a-tree-select>
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="3">
						<q-label :label="l('EmpTypeKindOne')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-select
							show-search
							:placeholder="l('EmpTypeKindOne')"
							option-filter-prop="children"
							v-model="entity.empKindOne"
						>
							<a-select-option v-for="item in empTypeKindOne" :key="item.id">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="3">
						<q-label :label="l('EmpTypeKindTwo')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-select
							show-search
							:placeholder="l('EmpTypeKindTwo')"
							option-filter-prop="children"
							v-model="entity.empKindTwo"
						>
							<a-select-option v-for="item in empTypeKindTwo" :key="item.id">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="3">
						<q-label :label="l('EmpTypeKindThree')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-select
							show-search
							:placeholder="l('EmpTypeKindThree')"
							option-filter-prop="children"
							v-model="entity.empKindThree"
						>
							<a-select-option v-for="item in empTypeKindThree" :key="item.id">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
					<a-col :span="3">
						<q-label :label="l('排序码')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input-number v-model="entity.sortCode" :min="0" style="width: 100%;" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="3">
						<q-label :label="l('DepartmentDescription')"> </q-label>
					</a-col>
					<a-col :span="21">
						<textarea
							class="ant-input"
							:Placeholder="l('DepartmentDescriptionInputDesc')"
							style="width: 100%"
							AllowClear="true"
							v-model="entity.description"
						/>
					</a-col>
				</a-row>
			</a-row>
		</a-form>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import {
	DataItemDetailServiceProxy,
	OrgPostServiceProxy,
	DepartmentServiceProxy,
	DepartmentEditDto,
	DataItemEditDto,
	CreateOrUpdateOrgPostInput,
	OrgPostEditDto,
} from '../../../../shared/service-proxies';
import { ModalHelper } from '@/shared/helpers';
import ObjectUtils from '../../../../shared/utils/ObjectUtils';
import { Dic } from '../../../../shared/utils';
import DepTreeUntils from '../../department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'add-Post',
	mixins: [ModalComponentBase],
	components: {},
	data() {
		return {
			id: undefined,
			pId: undefined,
			companyId: undefined,
			empTypeKindOne: [],
			empTypeKindTwo: [],
			empTypeKindThree: [],
			entity: new OrgPostEditDto(),
			postList: [],
			PostTreeData: [],
			tree: [],
			DepTreeData: [],
			deptree: [],
			depId: undefined,
		};
	},
	async mounted() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._orgPostServiceProxy = new OrgPostServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);

		this.empTypeKindOne = await Dic.getInstance().getDicAsync('EmpTypeKind1');
		this.empTypeKindTwo = await Dic.getInstance().getDicAsync('EmpTypeKind2');
		this.empTypeKindThree = await Dic.getInstance().getDicAsync('EmpTypeKind3');

		this.gettreedata();
		this.getdeptreedata();

		if (this.id) {
			this.getData();
		} else {
			// this.entity.parentId = this.pId;
			this.entity.departmentId = this.depId;
		}
	},
	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		getData() {
			this.loading = true;
			this._orgPostServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res.orgPost;
					if (this.entity.parentId == 0) {
						this.pId = undefined;
					} else {
						this.pId = this.entity.parentId;
					}
					if (this.entity.departmentId === 0) {
						this.entity.departmentId = undefined;
					}
				});
		},

		gettreedata() {
			this.loading = true;
			this._orgPostServiceProxy
				.getPagedDepPosts(this.companyId, undefined, '', 'id', 1000, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tree = res.items;
					this.tree.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id.toString(),
								value: item.id.toString(),
								title: item.name,
							};
							this.PostTreeData.push(obj);
						}
					});
					this.initTree(this.PostTreeData);
				});
		},
		getdeptreedata() {
			this.loading = true;
			this._DepartmentServiceProxy
				.getDepTreeData(this.companyId)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.deptree = DepTreeUntils.getInstance().InitSelectData(res);
					this.deptree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.deptree, this.DepTreeData, false);
				});
		},
		initTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.tree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.id.toString(),
							value: m.id.toString(),
							title: m.name,
						};
						v.children.push(obj); //放进父级的集合中
						this.initTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		SelectTree(value, node, extra) {},
		save() {
			if (!this.entity.enCode) {
				return abp.message.warn('请输入编码');
			}
			if (!this.entity.name) {
				return abp.message.warn('请输入名称');
			}
			if (!this.pId) {
				this.entity.parentId = 0;
			} else {
				this.entity.parentId = this.pId;
			}
			const input = new CreateOrUpdateOrgPostInput();
			this.entity.companyId = this.companyId;
			input.orgPost = this.entity;

			this.loading = true;
			this._orgPostServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
