<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <div class="calc_container">
                <div id="user">

                </div>
                <div id="dep">

                </div>
            </div>
            <div class="calc_container">
                <div id="com">

                </div>
                <div>

                </div>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../../../shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN";
    import * as echarts from "echarts"

    export default {
        name: "complete-calc",
        mixins:[AppComponentBase],
        props:{
            analysis:{
                type:Object,
                default:()=>{}
            }
        },
        data(){
            return {
                zh_CN
            }
        },
        created() {

        },
        mounted() {
            this.userInit();
            this.depInit();
            this.comInit();
        },
        methods:{
            userInit(){
                let chartDom = document.getElementById('user');
                let myChart = echarts.init(chartDom);
                let option;
                option = {
                    title: {
                        text: '个人发出任务完成率',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter:'{b}:{c}({d}%)'
                    },
                    legend: {
                        top: '80%',
                        y: 'center'
                    },
                    series: [
                        {
                            name: '个人发出任务完成率',
                            type: 'pie',
                            radius: ['30%', '50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.analysis.userCompleteData
                        }
                    ]
                };
                myChart.setOption(option);
                window.addEventListener('resize', () => {
                    myChart.resize();
                });
            },
            depInit(){
                let chartDom = document.getElementById('dep');
                let myChart = echarts.init(chartDom);
                let option;
                option = {
                    title: {
                        text: '部门发出任务完成率',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter:'{b}:{c}({d}%)'
                    },
                    legend: {
                        top: '80%',
                        y: 'center'
                    },
                    series: [
                        {
                            name: '部门发出任务完成率',
                            type: 'pie',
                            radius: ['30%', '50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.analysis.depCompleteData
                        }
                    ]
                };
                myChart.setOption(option);
                window.addEventListener('resize', () => {
                    myChart.resize();
                });
            },
            comInit(){
                let chartDom = document.getElementById('com');
                let myChart = echarts.init(chartDom);
                let option;
                option = {
                    title: {
                        text: '公司发出任务完成率',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'item',
                        formatter:'{b}:{c}({d}%)'
                    },
                    legend: {
                        top: '80%',
                        y: 'center'
                    },
                    series: [
                        {
                            name: '公司发出任务完成率',
                            type: 'pie',
                            radius: ['30%', '50%'],
                            avoidLabelOverlap: false,
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '16',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: this.analysis.comCompleteData
                        }
                    ]
                };
                myChart.setOption(option);
                window.addEventListener('resize', () => {
                    myChart.resize();
                });
            }
        }
    }
</script>

<style scoped>
    .calc_container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .calc_container div{
        flex: 1;
        display: flex;
        height: 300px;
        justify-content: center;
        align-items: center;
    }
</style>
