<template>
    <a-row :gutter="2" style="height: 100%">
        <a-col :span="24" style="height: 100%">
            <a-button @click="jumpSpecifylink()" type="primary">
                <i class="anticon anticon-save"></i>
                {{l("跳转")}}
            </a-button>
        </a-col>
    </a-row>
</template>

<script>
    import {ModalComponentBase} from "@/shared/component-base";
    import { abpService } from '@/shared/abp';
    import {AppConsts} from "../../../abpPro/AppConsts";
    export default {
        name: "designs",
        mixins: [ModalComponentBase],
        components: {},
        data() {
            return {
                 getPageUrl: '/iframe/'
                //getPageUrl: '/iframe/'
            };
        },
        created() {
            
        },
        methods: {
            jumpSpecifylink(){
                var guid=abpService.abp.utils.getCookieValue('SSOGuid');
                var url=AppConsts.LR_Url+'/LR_NewWorkFlow/NWFScheme/Index'+'?abpguid='+guid;
                window.open(url);
            }
        }
    }
</script>

<style scoped>

</style>
