<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row>
				<a-col :span="6">
					<a-button :type="'primary'" v-if="isGranted('task_complete_export')" @click="exporting">
						<a-icon type="export" />
						<span>导出</span>
					</a-button>
				</a-col>
				<a-col :span="18" style="text-align: right">
					<a-date-picker
						mode="year"
						format="YYYY"
						:allowClear="false"
						:value="currentYear"
						@openChange="isOpen = true"
						:open="isOpen"
						style="width: 100%;max-width: 150px"
						@panelChange="yearChange"
					/>
					<a-tree-select
						style="width: 100%;max-width: 180px"
						class="margin-left-10"
						tree-default-expand-all
						multiple
						allowClear
						:placeholder="l('Company')"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="companyId"
						@change="companyChange"
					/>
					<a-tree-select
						style="width: 100%;max-width: 180px"
						class="margin-left-10"
						tree-default-expand-all
						multiple
						allowClear
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="DepTreeData"
						:placeholder="l('Department')"
						v-model="departmentId"
						@change="SelectDepartmentTree"
					>
					</a-tree-select>
					<a-input-search
						style="width: 200px"
						class="margin-left-10"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				@change="sorterChange"
				:scroll="{ x: 1200, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
			<div
				v-if="isOpen"
				:style="{
					height: windowHeight + 'px',
					position: 'absolute',
					top: '0px',
					width: '100%',
				}"
				@click="isOpen = false"
			></div>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { CompanyServiceProxy, DepartmentServiceProxy, TaskBaseInfoServiceProxy } from '@/shared/service-proxies';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';
import moment from 'moment';
import { fileDownloadService } from '@/shared/utils';
import * as $ from 'jquery';

export default {
	name: 'task-complete-calc',
	mixins: [AppComponentBase],
	data() {
		return {
			companyTreeList: [],
			DepTreeData: [],
			companyId: undefined,
			departmentId: undefined,
			tableData: [],
			// 用户表格
			columns: [
				{
					title: '工号',
					dataIndex: 'jobNumber',
					sorter: false,
					ellipsis: true,
					width: 100,
					fixed: 'left',
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: '姓名',
					dataIndex: 'realName',
					sorter: false,
					width: 100,
					fixed: 'left',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: '发起完成数量',
					dataIndex: 'issueCompletionNum',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'issueCompletionNum' },
				},
				{
					title: '发起总数量',
					dataIndex: 'issueTotalNum',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'issueTotalNum' },
				},
				{
					title: '发起完成率',
					dataIndex: 'issueCompletionRate',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'issueCompletionRate' },
				},
				{
					title: '执行完成数量',
					dataIndex: 'executeCompletionNum',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'executeCompletionNum' },
				},
				{
					title: '执行总数量',
					dataIndex: 'executeTotalNum',
					sorter: false,
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'executeTotalNum' },
				},
				{
					title: '执行完成率',
					dataIndex: 'executeCompletionRate',
					sorter: false,
					ellipsis: true,
					align: 'left',
					scopedSlots: { customRender: 'executeCompletionRate' },
				},
			],

			isOpen: false,
			currentYear: undefined,
		};
	},
	computed: {
		windowHeight() {
			return window.innerHeight - 150;
		},
	},
	created() {
		this._companyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._taskBaseInfoServiceProxy = new TaskBaseInfoServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
	},
	mounted() {
		this.currentYear = moment();
		this.companyTreeInit();
		this.refresh();
	},
	methods: {
		getData() {
			this.loading = true;
			this._taskBaseInfoServiceProxy
				.getTaskCompletionRate(
					this.companyId ? _.join(this.companyId, ',') : '',
					this.departmentId ? _.join(this.departmentId, ',') : '',
					this.currentYear ? moment(this.currentYear).year() : undefined,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
				});
		},
		/**
		 * 导出
		 */
		exporting() {
			if (this.tableData.length <= 0) {
				return abp.message.warn(this.l('NoData'));
			}
			this.loading = true;
			this._taskBaseInfoServiceProxy
				.exportTaskCompletionRate(
					this.companyId ? _.join(this.companyId, ',') : '',
					this.departmentId ? _.join(this.departmentId, ',') : '',
					this.currentYear ? moment(this.currentYear).year() : undefined,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		//年选择器改变
		yearChange(e) {
			this.isOpen = false;
			this.currentYear = e;
			this.getData();
		},
		companyTreeInit() {
			this.loading = true;
			this._companyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.companyId = value;
			this.getDepTreeData();
			this.refresh();
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.departmentId = undefined;
			this.DepTreeData = [];
			this.loading = true;
			this._departmentServiceProxy
				.getDepTreeData(this.companyId)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.departmentId = e;
			this.refresh();
		},
	},
};
</script>

<style scoped>
.margin-left-10 {
	margin-left: 10px;
}
</style>
