<template>
    <div>
        <div class="modal-header">
            <div class="modal-title">
                <span>{{ title }}</span>
            </div>
        </div>
        <div>
            <!-- usePagination开启分页 -->
            <!-- loading绑定引入页面的蒙层加载变量 -->
            <q-container usePagination use-modal :loading="loading">
                <!-- 使用具名插槽 #toolbar -->
                <!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
                <template #toolbar>
                    <a-row>
                        <a-col :span="12">
                            <a-button type="primary" @click="add">添加</a-button>
                            <a-button type="danger" @click="del">删除</a-button>
                        </a-col>
                        <a-col :span="8" offset="4">
                            <a-input-search
                                style="margin: 0 0 10px 0;"
                                name="filterRoleText"
                                :placeholder="l('SearchWithThreeDot')"
                                @search="search"
                                enterButton
                                v-model="filterText"
                            />
                        </a-col>
                    </a-row>
                </template>
                <!-- 使用具名插槽slot="table" -->
                <!-- 插槽作用域的变量 slot-scope="scopeParam" -->
                <!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

                <!-- **************行选择*************** -->
                <!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
                <!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
                <template slot="table" slot-scope="scopeParam">
                    <a-table
                        ref="table"
                        size="small"
                        :pagination="false"
                        :scroll="{ y: scopeParam.scroll.y }"
                        :columns="columns"
                        :rowKey="(tableDatas) => tableDatas.id"
                        :dataSource="roleTableDataList"
                        :customRow="customRow"
                        :rowSelection="rowSelection"
                    >
                        <!-- <template slot-scope="text, record" slot="company">
                            <span v-if="record.isPluralismsDep">
                                {{ record.pCompanyName || ""}}
                            </span>
                            <span v-else>
                                {{ record.company || ""}}
                            </span>
                        </template> -->
                        <!-- <template slot-scope="text, record" slot="department">
                            <span v-if="record.isPluralismsDep">
                                {{ record.pDepartmentName || ""}}
                                {{ record.isVirtual ? '（虚拟）' : '' }}
                            </span>
                            <span v-else>
                                {{ record.department || ""}}
                            </span>
                        </template> -->
                        <!-- <template slot-scope="text, record" slot="isPluralismsDep">
                            <span>
                                {{ record.isPluralismsDep ? "√" : "" }}
                            </span>
                        </template> -->
                    </a-table>
                </template>
            </q-container>
        </div>
    </div>
</template>

<script>
import ModalComponentBase from '@/shared/component-base/modal-component-base';
import { RoleServiceProxy } from "@/shared/service-proxies/service-proxies";
import ModalHelper from "@/shared/helpers/modal/modal-helper";
import SelectUserByRole from "@/components/select-user-tag/select-user-by-role";
import { map, cloneDeep } from 'lodash';
import {UserRoleInput} from "@/shared/service-proxies";

export default {
    name: "view-role",
    mixins: [ModalComponentBase],
    data() {
        return {
            roleId: undefined,
            title: undefined,

            _roleServiceProxy: undefined,
            //已有角色的用户列表
            roleTableDataList: [],

            roleTableDataListRaw:[],

            searchData: [],
            columns: [
                // {
                //     title: '公司',
                //     dataIndex: 'company',
                //     sorter: false,
                //     ellipsis: true,
                //     align: 'center',
                //     scopedSlots: { customRender: 'company' },
                // },
                // {
                //     title: '部门',
                //     dataIndex: 'department',
                //     sorter: false,
                //     ellipsis: true,
                //     align: 'center',
                //     scopedSlots: { customRender: 'department' },
                // },
                {
                    title: '工号',
                    dataIndex: 'jobNumber',
                    sorter: false,
                    ellipsis: true,
                    align: 'center',
                    scopedSlots: { customRender: 'jobNumber' },
                },
                {
                    title: '姓名',
                    dataIndex: 'realName',
                    align: 'center',
                    ellipsis: true,
                    scopedSlots: { customRender: 'realName' },
                },
                // {
                //     title: '兼职部门',
                //     dataIndex: 'isPluralismsDep',
                //     align: 'center',
                //     width: 80,
                //     ellipsis: true,
                //     scopedSlots: { customRender: 'isPluralismsDep' },
                // },
            ],
            isMultiple: true,

            selectedRowField: "id",
        }
    },
    created() {
        this.fullData();
        this._roleServiceProxy = new RoleServiceProxy(this.$apiUrl, this.$api);
        this.search();
    },
    methods: {
        add(){
            const original = cloneDeep(this.roleTableDataList);
            ModalHelper.create(
                SelectUserByRole,
                {
                    isMultiple: true,
                },
                {
                    width: '900px',
                    closable: false,
                }
            ).subscribe((res) => {
                if (res) {
                    let dto = {}
                    dto.roleId = this.roleId;
                    // 添加用户使用userid，不是empid
                    dto.userList = res.map(item => {
                        return {
                            userId: item.userId,
                            pDepartmentId: item.isPluralismsDep ? item.departmentId : undefined
                        }
                    });
                    this.loading = true;
                    this._roleServiceProxy
                        .batchAddUser(dto)
                        .then((res) => {
                            this.$notification['success']({
                                message: this.l('SavedSuccessfully'),
                            });
                            this.getUserByRole();
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            });
        },
        del(){
            // 得到删除的userid
            const delUserIds = this.selectedRows.map(row => row[this.selectedRowField]);
            if(delUserIds.length === 0) {
                abp.message.warn('请至少选择一个员工');
                return;
            }

            console.log(delUserIds);
            
            // 得到要保存的userid
            const saveUser = this.roleTableDataListRaw.filter(table => !delUserIds.includes(table[this.selectedRowField]));
            let dto = {};
            dto.roleId = this.roleId;
            // 添加用户使用userid，不是empid
            dto.userList = saveUser.map(item => {
                return {
                    userId: item.userId,
                    pDepartmentId: item.isPluralismsDep ? item.pDepartmentId : undefined
                }
            });
            this.$confirm({
                content: "确认删除？",
                okText: "确认",
                cancelText: "取消",
                onOk: () => {
                    this.loading = true;
                    this._roleServiceProxy
                        .batchCreateUser(dto)
                        .then((res) => {
                            this.$notification['success']({
                                message: this.l('SavedSuccessfully'),
                            });
                            this.rowClearAllSelected();
                            this.getUserByRole();
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            })
        },
        search(){
          //改前端查询
            //this.getUserByRole();
          if(!this.filterText  || this.filterText.length === 0){
              this.getUserByRole();
          }
          this.roleTableDataList = this.roleTableDataList.filter(c=>c.realName.includes(this.filterText))
        },
        /**
         * 获取拥有该角色的所有人员
         */
        getUserByRole() {
            this.loading = true;
            this._roleServiceProxy
                .getUserByRole(this.roleId, '')
                .finally(() => {
                    this.loading = false;
                })
                .then(async (res) => {
                    this.roleTableDataList = res;
                    this.roleTableDataListRaw =res.map(c=>(c))
                });
        },
    }
}
</script>

<style scoped>
.tag-list {
    display: flex; align-items: center; overflow-y: scroll;
}
/* 设置全局滚动条的样式 */
.tag-list::-webkit-scrollbar {
    width: 2px;
    height: 8px;
}
/* 滚动槽 */
.tag-list::-webkit-scrollbar-track {
    -webkit-box-shadow: 0 0 0 #ccc;
    border-radius: 8px;
}
/* 滚动条滑块 */
.tag-list::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 0 0 #ccc;
}
</style>
