<template>
    <a-spin :spinning="spinning">
        <div :style="{ borderBottom: '1px solid #E9E9E9' }">
            <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
                全选
            </a-checkbox>
        </div>
        <br />
        <a-checkbox-group v-model="checkedList" :options="plainOptions" @change="cheOnChange" />

        <!-- 按钮 -->
        <div class="modal-footer">
            <a-button :disabled="saving" @click="close()" type="button">
                <a-icon type="close-circle" />
                {{ l('Cancel') }}
            </a-button>
            <a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
                <a-icon type="save" />
                {{ l('Save') }}
            </a-button>
        </div>
    </a-spin>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { ModalComponentBase } from '@/shared/component-base';
import { AuthorizeServiceProxy } from '@/shared/service-proxies';
import Bus from '@/shared/bus/bus';

export default {
    name: 'create-or-edit-role',
    mixins: [AppComponentBase, ModalComponentBase],
    data() {
        return {
            _authorizeServiceProxy: null,
            spinning: false,
            checkedList: [],
            indeterminate: true,
            checkAll: false,
            plainOptions: [],
            //类型1、角色 2、用户
            type: undefined,
            //对应的角色或用户Id
            ObjectId: undefined,
        };
    },
    components: {},
    created() {
        this.fullData(); // 模态框必须,填充数据到data字段
        this._authorizeServiceProxy = new AuthorizeServiceProxy(this.$apiUrl, this.$api);
        this.getData();
    },
    methods: {
        /**
         * 获取数据
         */
        getData() {
            this.spinning = true;
            this._authorizeServiceProxy
                .getObjRestrictClientAuthorize(this.ObjectId, this.type)
                .finally(() => {
                    this.spinning = false;
                })
                .then((result) => {
                    result.map((item) => {
                        this.plainOptions.push({
                            label: item.clientName,
                            value: item.clientId,
                        });
                        if (item.isCheck) {
                            this.checkedList.push(item.clientId);
                        }
                    });
                });
        },
        /**
         * 提交表单
         */
        handleSubmit() {
            this.saving = true;
            this.spinning = true;
            this._authorizeServiceProxy
                .setObjectRestrictClientType(this.type, this.ObjectId, this.checkedList)
                .finally(() => {
                    this.saving = false;
                    this.spinning = false;
                })
                .then(() => {
                    this.$notification.success(this.l('SavedSuccessfully'));
                    this.success(true);
                });
        },
        cheOnChange(checkedList) {
            this.indeterminate = !!checkedList.length && checkedList.length < this.plainOptions.length;

            this.checkAll = this.checkedList.length === this.plainOptions.length;
        },
        onCheckAllChange(e) {
            console.log(this.plainOptions);
            Object.assign(this, {
                checkedList: e.target.checked ? this.plainOptions : [],
                indeterminate: false,
                checkAll: e.target.checked,
            });
        },
    },
};
</script>

<style scoped lang="less">
.modal-header {
    .anticon {
        margin-right: 10px;
    }
}
.pagination {
    margin: 10px auto;
    text-align: right;
}
</style>
