<template>
	<a-card>
		<a-row>
			<a-col :span="5">
				<organization-unit-tree-panel
					@selectedNode="selectedNodeFunc"
					@selectTree="selectTreefunc"
					@selectDepTree="selectTree"
					:depuser-count="DepuserCount"
				>
				</organization-unit-tree-panel>
			</a-col>
			<a-col :span="19" style="padding-left: 10px;">
				<a-tabs v-model="ActiveKey" @change="callback" size="small">
					<!-- 用户 -->
					<a-tab-pane key="1" v-if="!isVirtual">
						<span slot="tab">
							员工
							<a-badge
								:count="UserCount"
								:overflowCount="1000000"
								:showZero="false"
								:number-style="{
									backgroundColor: '#1890FF',
									marginBottom: '10px',
									fontSize: '10px',
								}"
							/>
						</span>
						<organization-unit-members-panel
							ref="members"
							:isNotChoices="isNotChoices"
							:tree-data="selectedTree"
							:companyId="CompanyId"
							@GetUserCount="GetUserCount"
						>
						</organization-unit-members-panel>
					</a-tab-pane>
					<!--部门兼职人员-->
					<a-tab-pane key="3" forceRender>
						<span slot="tab">
							兼职员工
							<a-badge
								:count="DepuserCount"
								:overflowCount="1000000"
								:showZero="false"
								:number-style="{
									backgroundColor: '#1890FF',
									marginBottom: '10px',
									fontSize: '10px',
								}"
							/>
						</span>
						<user-dep-pluralism-panel
							:isNotChoices="isNotChoices"
							:tree-data="selectedTree"
							@GetDepUserCount="GetDepUserCount"
							:company-id="CompanyId"
							ref="userDep"
						></user-dep-pluralism-panel>
					</a-tab-pane>
				</a-tabs>
			</a-col>
		</a-row>
	</a-card>
</template>
<!--部门员工页面-->
<script>
import OrganizationUnitTreePanel from './organization-unit-tree-panel/organization-unit-tree-panel';
import OrganizationUnitMembersPanel from './organization-unit-members-panel/organization-unit-members-panel';
import OrganizationUnitRolePanel from './organization-unit-role-panel/organization-unit-role-panel';
import UserDepPluralismPanel from './user-dep-pluralism-panel/user-dep-pluralism-panel.vue';
import bus from '@/shared/bus/bus';

export default {
	name: 'organization-units',
	components: {
		OrganizationUnitTreePanel,
		OrganizationUnitMembersPanel,
		OrganizationUnitRolePanel,
		UserDepPluralismPanel,
	},
	data() {
		return {
			// 选择的树结构
			selectedTree: {},
			key: 1,
			UserCount: 0,
			DepuserCount: 0,
			CompanyId: undefined,
			isNotChoices: true,
			isVirtual: false,
			ActiveKey: '1',
		};
	},
	mounted() {
		this.selectedTree = {};
		this.$refs.members.getData();
		this.$refs.userDep.getData();
	},
	methods: {
		selectTree(data) {
			this.isVirtual = data ? data.isVirtual : false;
			if (this.isVirtual) {
				this.ActiveKey = '3';
			} else {
				this.ActiveKey = '1';
			}
		},
		/**
		 * 选择部门
		 */
		selectedNodeFunc(data) {
			this.$nextTick(() => {
				if (data) {
					if (!this.isVirtual) {
						if (data.depId) {
							this.isNotChoices = false;
							this.selectedTree = data.depId;
							if (data.type != 1) {
								this.$refs.members.totalItems = 0;
								this.$refs.members.pageNumber = 1;
								this.$refs.members.request.skipCount = 0;
								this.$refs.members.pagerange = [1, 10];
							}
							this.$nextTick(() => {
								this.$refs.members.getData(this.CompanyId);
								this.$refs.userDep.getData();
							});
						} else {
							this.isNotChoices = true;
							this.selectedTree = data.depId ? { id: data.depId } : {};
							this.companyId = data.companyId;
							this.$nextTick(() => {
								this.$refs.members.getData(this.companyId);
								this.$refs.userDep.getData();
							});
						}
					} else {
						this.selectedTree = data.depId.id ? data.depId : { id: data.depId };
						// console.log(this.selectedTree)
						this.$nextTick(() => {
							this.$refs.userDep.getData();
						});
					}
				}
			});
		},
		/**
		 * 选择公司
		 * @param data
		 */
		selectTreefunc(data) {
			this.ActiveKey = '1';
			this.isVirtual = false;
			this.CompanyId = data.companyId;
			this.selectedTree = {};
			this.$nextTick(() => {
				if (data.type != 1) {
					this.$refs.members.pageNumber = 1;
					this.$refs.members.request.skipCount = 0;
					this.$refs.members.totalItems = 0;
					this.$refs.members.pagerange = [1, 10];
				}
				this.$refs.members.getData(this.CompanyId);
				this.$refs.userDep.getData();
			});
		},
		/**
		 * 获取人员总数
		 * @param data
		 * @constructor
		 */
		GetUserCount(data) {
			this.UserCount = data ? data : 0;
		},
		/**
		 * 获取兼职人员总数
		 * @param data
		 * @constructor
		 */
		GetDepUserCount(data) {
			this.DepuserCount = data ? data : 0;
		},
		callback(key) {
			bus.$emit('calcHeight');
			this.ActiveKey = key;
		},
	},
};
</script>

<style scoped lang="less"></style>
