<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8" style="margin-bottom: 8px">
				<a-col :md="12" :sm="12">
					<a-button :type="'primary'" v-if="isGranted('add_concurrent_post')" @click="addUser()">
						<a-icon type="plus" />
						<span>{{ l('Create') }}</span>
					</a-button>
					<a-button :type="'danger'" v-if="isGranted('delete_concurrent_post')" @click="batchDepUserDelete()">
						<a-icon type="delete" />
						<span>{{ l('Delete') }}</span>
					</a-button>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				::customRow="customRow"
				:rowClassName="rowClassName"
			>
				<p class="subscriptionEndUtc" slot="subscriptionEndUtc" slot-scope="record">
					{{ record }}
				</p>
				<a class="isActive" slot="isActive" slot-scope="record">
					<a-icon v-if="record" type="check" />
					<a-icon v-if="!record" type="close" />
				</a>
				<p class="creationTime" slot="creationTime" slot-scope="record">
					{{ record | moment('YYYY-MM-DD') }}
				</p>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { ModalComponentBase } from '@/shared/component-base';
import Adduserrelation from '../../userrelation/userrelation-add-user-panel/add-userrelation';
import * as _ from 'lodash';
import { ModalHelper } from '@/shared/helpers';
import { AddPostUsersDot, UserRelationServiceProxy } from '@/shared/service-proxies';

export default {
	name: 'userrelation-view',
	mixins: [ModalComponentBase],
	data() {
		return {
			// 表格
			columns: [
				{
					title: this.l('RealName'),
					dataIndex: 'realName',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('JobNumber'),
					dataIndex: 'jobNumber',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: this.l('Mobile'),
					dataIndex: 'mobile',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'mobile' },
				},
				{
					title: this.l('EmailAddress'),
					dataIndex: 'emailAddress',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'emailAddress' },
				},
			],
			tableData: [],
			postId: undefined,
		};
	},
	created() {
		this._userRelationServiceProxy = new UserRelationServiceProxy(this.$apiUrl, this.$api);
	},
	methods: {
		/**
		 * 获取岗位等级列表
		 */
		getData(postId) {
			this.postId = postId;
			if (!postId) {
				this.tableData = [];
				return;
			}
			this.loading = true;
			this.selectedRowObj = {};
			this._userRelationServiceProxy
				.getPagedOrganizationUnitUsers(
					postId,
					this.filterText,
					'id',
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = [];
					this.tableData = res.items;
					this.tableData.map((item) => {
						item.checked = false;
					});
					this.totalItems = res.totalCount;
				});
		},
		/**
		 * 添加
		 */
		addUser() {
			if (!this.postId) {
				return abp.message.warn('请选择岗位');
			}
			ModalHelper.create(Adduserrelation, {}).subscribe((res) => {
				if (res) {
					this.loading = true;
					const input = new AddPostUsersDot();
					var ids = _.map(res, 'id');
					input.orgPostId = this.postId;
					input.depUserIds = ids;
					this._userRelationServiceProxy
						.addPostUsers(input)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: this.l('SavedSuccessfully'),
							});
							this.getData(this.postId);
						});
				}
			});
		},

		/**
		 * 批量删除
		 */
		batchDepUserDelete() {
			if (this.selectedRowKeys.length <= 0) {
				abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
				return;
			}
			this.$confirm({
				content: this.l('ConfirmDeleteXItemsWarningMessage', this.selectedRowKeys.length),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					this.loading = true;
					var ids = _.map(this.selectedRowKeys);
					this._userRelationServiceProxy
						.batchDeleteUsers(ids)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.pageNumber = 1;
							this.request.skipCount = 0;
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
							this.getData(this.postId);
						});
				},
			});
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
