<template>
    <div>
        <a-tree
                checkable
                v-model="selectColumnKeys"
                :tree-data="data"
                @check="columnCheck"
                @expand="columnExpand"
                :expanded-keys="columnExpandedKeys"
        />
    </div>
</template>

<script>
    import {ModalComponentBase} from "../../../../../shared/component-base";
    import _ from "lodash";
    import {deepCloneAsync} from "../../../../../shared/utils/CloneUtils";

    export default {
        name: "column-authorize",
        mixins: [ModalComponentBase],
        props: {
            /**
             * 模块数据源
             */
            moduleData: {
                type: Array,
                default: ()=>[]
            },
            /**
             * 选择的模块
             */
            selectModuleKeys: {
                type: Array,
                default: ()=>[]
            }
        },
        data() {
            return {
                columnExpandedKeys: [],
                data: [],
                //当前选中的key
                selectColumnKeys:[],
                columnAllKey:[]
            }
        },
        created() {

        },
        mounted() {
            // this.refresh();
        },
        methods: {
            refresh(record) {
                this.$nextTick( ()=>{
                    this.data = this.treeInit(record, {});
                    //只需要当前以选择页面下的列
                    this.selectColumnKeys=[...this.selectColumnKeys.filter(item=>this.columnAllKey.includes(item))];
                });
            },

            /**
             * 树初始化
             * 数据源
             * 对象(是否被需要)
             */
            treeInit(data, obj) {
                    data.forEach( item => {

                        if (item.children.length > 0) {
                            //有子级就先找子级(权限都在最后一级)
                            item.children = this.treeInit(item.children, obj);
                            //判断是否存在选择的模块Key
                            let k = this.selectModuleKeys.filter(t => t == item.key);
                            if (k.length <= 0) {
                                //移除不被需要的
                                if (!obj.ishave) {
                                    data = [...data.filter(t => t.key != item.key)];
                                }
                                //不在选择的模块中且不存在子级的也要删除
                                if(item.children.length<=0){
                                    data = [...data.filter(t => t.key != item.key)];
                                }
                            }
                        } else {
                            let k = this.selectModuleKeys.filter(t => t == item.key);
                            //存在表格列的页面
                            if(item.key.indexOf('Column')!=-1){
                                //判断表格所在页面Id是否被选择
                                let ck=this.selectModuleKeys.filter(t => t == item.moduleId)
                                if(ck.length>0){
                                    this.columnAllKey.push(item.key);
                                    //被需要
                                    obj.ishave = true;
                                }
                                else {
                                    //移除模块未选择的
                                    data = [...data.filter(t => t.key != item.key)];
                                }
                            }
                            else {
                                if (k.length <= 0) {
                                    data = [...data.filter(t => t.key != item.key)];
                                } else {
                                    //被需要
                                    obj.ishave = true;
                                }
                            }
                        }
                        //默认不被需要
                        if (item.parentId == "Module0") {
                            this.columnExpandedKeys=[...this.columnExpandedKeys,item.key];
                            obj.ishave = false;
                        }
                    });
                   return data;

            },
            /**
             * 按钮权限勾选事件
             * @param key
             */
            columnCheck(key) {
                this.$emit("checkChange", key);
            },
            /**
             * 功能权限展开收起事件
             * @param key
             * @param info
             */
            columnExpand(key, info) {
                this.columnExpandedKeys = key;
            },
        }
    }
</script>

<style scoped>

</style>
