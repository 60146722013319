<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="spinning">
            <!-- 标题 -->
            <div class="modal-header">
                <div class="modal-title">
                    <span>{{ l('导入') }}</span>
                </div>
            </div>
            <div class="my-md">
                <a-row class="text">
                    1.您选择导入的Excel表必须安照模板内要求填写
                </a-row>
                <a-row class="text">
                    2.导入文件必须是xsl,xlsx格式
                </a-row>
                <!-- <a-row class="text">
                    当前周期:<b>{{startTime}}</b>~<b>{{endTime}}</b>
                </a-row> -->
                <a-row class="text">
                    <a-col :span="5">
                        <a-button
                                @click="ExportTemplate()"
                        >
                            <a-icon type="file-excel"/>
                            <span>{{ l("下载模板") }}</span>
                        </a-button>
                    </a-col>
                    <a-col :span="19">
                        <a-upload
                                :action="uploadPictureUrl"
                                :beforeUpload="beforeUpload"
                                :headers="uploadHeaders"
                                @change="uploadPictureChange($event)">
                            <a-button type="primary">
                                <a-icon type="import"/>
                                导入
                            </a-button>
                        </a-upload>
                    </a-col>


                </a-row>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {AppConsts} from "../../../../abpPro/AppConsts";
    import {
        UserPlanImportInput,UserServiceProxy
    } from "../../../../shared/service-proxies";
    import moment from "moment";
    import {fileDownloadService} from "../../../../shared/utils";

    export default {
        name: "import-excel-model",
        mixins: [ModalComponentBase],
        data() {
            return {
                zh_CN,
                spinning: false,
                uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadExcelFile",
                uploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken()
                },
                startTime: undefined,
                endTime: undefined
            }
        },
        created() {
            this.fullData();
			this.UserServiceProxy=new UserServiceProxy(this.$apiUrl, this.$api);
            this._fileDownloadService = fileDownloadService;
        },
        mounted() {

        },
        methods: {
            beforeUpload(file) {
                let filetype;
                if (file.name) {
                    filetype = file.name.substring(file.name.lastIndexOf('.'));
                }
                let isExcel = filetype === ".xlsx" ||
                    filetype === ".xsl";
                if (!isExcel) {
                    return abp.message.warn("只能上传xlsx格式和xsl的文件");
                }

            },
            uploadPictureChange({file, fileList}) {
                if (file.status === 'done') {
                    let input = new UserPlanImportInput();
                    input = file.response.result.fileName;
                    this.spinning = true;
                    this.UserServiceProxy
                        .importExcel(input)
                        .finally(() => {
                            this.spinning = false
                        })
                        .then(res => {
                            if (!res.isSuccess && res.failedCount > 0) {
                                this.$notification["warn"]({
                                    message: "总条数:" + res.totalCount + "  失败行数:" + res.failedCount,
                                });
                                this._fileDownloadService.downloadTempFile(res.file);
                            } else {
                                this.$notification["success"]({
                                    message: this.l("导入成功"),
                                });
                            }
                        })
                }
            },
            ExportTemplate() {
                this.UserServiceProxy.getToImportTempFile()
                    .finally(
                        () => {
                        }
                    ).then(
                    res => {
                        this._fileDownloadService.downloadTempFile(res);
                    }
                );
            },
        }
    }
</script>

<style scoped>
    .text {
        padding: 5px 0px;
    }
</style>
