// 判断arr是否为一个数组，返回一个bool值
function isArray (arr) {
    return Object.prototype.toString.call(arr) === '[object Array]';
}

const deepCloneAsync = (obj) => {
	return new Promise(async resolve => {
		// 对常见的“非”值，直接返回原来值
		if([null, undefined, NaN, false].includes(obj)) resolve(obj);
		if(typeof obj !== "object" && typeof obj !== 'function') {
			//原始类型直接返回
		    resolve(obj);
		}
		var o = isArray(obj) ? [] : {};
		for(let i in obj) {
		    if(obj.hasOwnProperty(i)){
		        o[i] = typeof obj[i] === "object" ? await deepCloneAsync(obj[i]) : obj[i];
		    }
		}
		resolve(o);
	})
}


export {
	deepCloneAsync
}
