<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('EditAppDTImg') }}</span>
				<span v-else>{{ l('CreateAppDTImg') }}</span>
			</div>
		</div>
		<a-form :form="form" @submit="save()" autocomplete="off">
			<a-row :gutter="8">
				<a-row>
					<a-col :span="5">
						<q-label required label="说明"> </q-label>
					</a-col>
					<a-col :span="19">
						<a-input placeholder="说明" v-model="entity.imgName" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label required :label="l('SortCode')"> </q-label>
					</a-col>
					<a-col :span="19">
						<a-input-number
							:placeholder="l('SortCode')"
							v-model="entity.sortCode"
							:min="0"
							style="width: 100%"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label :label="l('关联模块')"> </q-label>
					</a-col>
					<a-col :span="19">
						<a-input placeholder="关联模块" v-model="entity.relatedModule" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="5">
						<q-label :label="l('ImgFileName')"> </q-label>
					</a-col>
					<a-col :span="19">
						<a-upload
							name="profilePictureFile"
							:action="uploadPictureUrl"
							listType="picture-card"
							:fileList="fileList"
							:beforeUpload="beforeUpload"
							accept="image/*"
							:headers="uploadHeaders"
							@preview="handlePreview"
							@change="uploadPictureChange($event)"
						>
							<div v-if="fileList.length < 1">
								<a-icon type="plus" />
								<!-- {{ l('UploadProfilePicture') }} -->
								<div class="ant-upload-text">上传图片</div>
							</div>
						</a-upload>
					</a-col>
				</a-row>
			</a-row>
		</a-form>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import { AppDTImgEditDto, AppDTImgServiceProxy, CreateOrUpdateAppDTImgInput } from '../../../../shared/service-proxies';
import { AppConsts } from '../../../../abpPro/AppConsts';
import ImageUtils from '../../../../shared/utils/image-utils';

export default {
	name: 'create-edit-manage',
	mixins: [ModalComponentBase],
	data() {
		return {
			form: this.$form.createForm(this),
			spinning: false,
			id: undefined,
			entity: new AppDTImgEditDto(),
			// 上传的接口地址
			uploadPictureUrl: AppConsts.remoteServiceBaseUrl + '/Profile/UploadAppPicture',
			maxAppPictureBytesValue: AppConsts.maxProfilPictureMb,
			fileList: [],
			uploadHeaders: undefined,
			appDTImgPath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/App/${abp.session.tenantId}/`,
		};
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._AppDTImgServiceProxy = new AppDTImgServiceProxy(this.$apiUrl, this.$api);
		this.uploadHeaders = {
			Authorization: 'Bearer ' + abp.auth.getToken(),
		};
		this.entity.sortCode = 0;
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._AppDTImgServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.appDTImg;
					if (this.entity.fileName) {
						this.fileList = [
							{
								uid: -1,
								name: this.entity.fileName,
								status: 'done',
								url: this.appDTImgPath + this.entity.fileName,
							},
						];
					}
				});
		},
		/**
		 * 上传图片
		 */
		beforeUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
			if (!isJPG) {
				abp.message.error(this.l('OnlySupportPictureFile'));
			}
			const isLtXM = file.size / 1024 / 1024 < this.maxAppPictureBytesValue;
			if (!isLtXM) {
				abp.message.error(this.l('ProfilePicture_Warn_SizeLimit', this.maxAppPictureBytesValue));
			}
			return isJPG && isLtXM;
		},
		/**
		 * 预览图片
		 * @param file 文件
		 * @returns {Promise<void>}
		 */
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await ImageUtils.getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			ImageUtils.showFullImage(this.previewImage);
		},
		/**
		 * 上传图片回调  状态 "status: "done"  status: "uploading"  status: "removed"
		 */
		uploadPictureChange({ file, fileList }) {
			if (file.status === 'done') {
				this.entity.fileName = file.response.result.fileName;
			} else if (file.status === 'removed') {
				this.entity.fileName = undefined;
			}
			this.fileList = fileList;
		},
		save() {
			if (!this.entity.imgName) {
				return abp.message.warn('请输入说明');
			}
			if (this.entity.sortCode != 0 && !this.entity.sortCode) {
				return abp.message.warn('请输入排序码');
			}
			if (!this.entity.fileName) {
				return abp.message.warn('请上传图片');
			}
			let input = new CreateOrUpdateAppDTImgInput();
			input.appDTImg = AppDTImgEditDto.fromJS(this.entity);
			this.spinning = true;
			this._AppDTImgServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .ant-upload-list-picture-card-container {
	width: 100%;
	height: 180px;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item {
	width: 100%;
	height: 180px;
}
/deep/ .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	object-fit: fill;
}
/deep/ .ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
	opacity: 1;
}
</style>
