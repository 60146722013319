<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <a-card>
                <a-row>
                    <a-col :span="12">
                        <div class="my-md">
                            <a-row>
                                <a-col :span="16">
                                    <a-button
                                            :type="'primary'"
                                            v-if="isGranted('JumpProtocol_add')"
                                            @click="createJumpProtocol()"
                                    >
                                        <a-icon type="plus"/>
                                        <span>{{ l("Create") }}</span>
                                    </a-button>
                                    <a-button
                                            :type="'primary'"
                                            v-if="isGranted('JumpProtocol_edit')"
                                            @click="editJumpProtocol()"
                                    >
                                        <a-icon type="edit"/>
                                        <span>{{ l("Edit") }}</span>
                                    </a-button>
                                    <a-button
                                            :type="'danger'"
                                            v-if="isGranted('JumpProtocol_delete')"
                                            @click="BatchDeleteJumpProtocol"
                                    >
                                        <a-icon type="delete"/>
                                        <span>{{ l("Delete") }}</span>
                                    </a-button>

                                </a-col>
                                <a-col :span="8">
                                    <a-input-search
                                            style="width: 100%;min-width: 100px"
                                            name="filterText"
                                            :placeholder="l('SearchWithThreeDot')"
                                            @search="refresh"
                                            enterButton
                                            v-model="filterText"
                                    />
                                </a-col>
                            </a-row>
                        </div>

                        <div>
                            <a-table
                                    @change="sorterChange"
                                    :columns="columns"
                                    :pagination="false"
                                    :customRow="customRow"
                                    :rowKey="(data) => data.id"
                                    :dataSource="tableData"
                            >
                                <a-checkbox slot="checkbox"
                                            slot-scope="text, record"

                                            :checked="record.checked">
                                </a-checkbox>
                            </a-table>
                            <a-pagination
                                    class="pagination"
                                    :total="totalItems"
                                    v-model="pageNumber"
                                    :showTotal="showTotalFun"
                                    @change="onChangePage"
                                    @showSizeChange="showSizeChange"
                            />
                        </div>
                    </a-col>
                    <a-col :span="11" offset="1">
                        <jump-valid-path :jvp-id="jvpId" ref="jvp"></jump-valid-path>
                    </a-col>
                </a-row>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN"
    import * as _ from "lodash";
    import {JumpProtocolServiceProxy} from "../../../shared/service-proxies";
    import CreateOrEditJumpProtocol from "./create-or-edit-jump-protocol/create-or-edit-jump-protocol";
    import modalHelper from "../../../shared/helpers/modal/modal-helper";
    import JumpValidPath from "./jump-valid-path/jump-valid-path"

    let _this;
    export default {
        name: "jump-protocol",
        mixins: [AppComponentBase],
        components:{CreateOrEditJumpProtocol,JumpValidPath},
        data() {
            return {
                zh_CN,
                filterText: undefined,
                // 用户表格
                columns: [
                    {
                        title: '',
                        dataIndex: "checkbox",
                        sorter: false,
                        fixed: 'left',
                        width: 50,
                        align: "center",
                        scopedSlots: {customRender: "checkbox"}
                    },
                    {
                        title: '名称',
                        dataIndex: 'name',
                        sorter: true,
                        fixed: 'left',
                        width: 100,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'name'},
                    },
                    {
                        title: '编码',
                        dataIndex: 'code',
                        sorter: true,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'code'},
                    },
                    {
                        title: '协议Url',
                        dataIndex: 'jumpUrl',
                        sorter: true,
                        ellipsis: true,
                        align: 'center',
                        scopedSlots: {customRender: 'jumpUrl'},
                    },
                ],
                tableData: [],
                totalItems: 0, //总数
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ['10', '20', '30', '40'],
                request: {maxResultCount: 10, skipCount: 0, sorting: ''},
                jvpId:undefined
            }
        },
        created() {
            this._jumpProtocolServiceProxy=new JumpProtocolServiceProxy(this.$apiUrl,this.$api);
        },
        mounted() {
            _this=this;
            this.refresh();
        },
        methods: {
            refresh() {
                this.pageNumber = 1;
                this.request.skipCount = 0;
                this.getData();
            },
            getData() {
                this.loading=true;
                this._jumpProtocolServiceProxy.getPaged(
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(()=>{this.loading=false;})
                .then(res=>{
                    this.tableData = res.items;
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    if (this.tableData.length > 0) {
                        this.select(this.tableData[0]);
                    }
                })
            },
            createJumpProtocol() {
                modalHelper.create(CreateOrEditJumpProtocol,{},{
                    width:"600px"
                }).subscribe(res=>{
                    if(res){
                        this.refresh();
                    }
                })
            },
            editJumpProtocol() {
                if(!this.jvpId){
                    return abp.message.warn("请至少选择一项");
                }
                modalHelper.create(CreateOrEditJumpProtocol,{
                    id:this.jvpId
                },{
                    width:"600px"
                }).subscribe(res=>{
                    if(res){
                        this.refresh();
                    }
                })
            },
            BatchDeleteJumpProtocol() {
                if(!this.jvpId){
                    return abp.message.warn("请至少选择一项");
                }
                this.$confirm({
                    title:"确认操作",
                    content:"你确定要删除这项数据吗？",
                    cancelText:"取消",
                    okText:"确认",
                    onOk:()=>{
                        this.loading=true;
                        this._jumpProtocolServiceProxy.delete(this.jvpId)
                            .finally(()=>{this.loading=false})
                            .then(res=>{
                                this.refresh();
                            })
                    }
                })
            },
            /**
             * 刷新时的模拟选择
             */
            select(rows) {
                this.jvpId = rows.id;
                this.$refs.jvp.getData(this.jvpId);
                rows.checked = true;
                _this.$forceUpdate();
            },
            /**
             * 表格行点击
             * @param record 点击行的数据
             * @param index 点击行的下标
             */
            customRow: (record, index) => ({

                on: {
                    click: () => {
                        _this.jvpId = record.id;
                        _this.$refs.jvp.getData(_this.jvpId);
                        //清空选择
                        _this.tableData.map(item => item.checked = false);
                        //赋值选择
                        record.checked = !record.checked;
                        //强制更新布局
                        _this.$forceUpdate();
                    }
                }
            }),
            /**
             * 分页事件
             */
            showTotalFun() {
                return `共有${this.totalItems}条`;
            },
            /**
             * 分页
             */
            onChangePage(page, pageSize) {
                this.selectedRowKeys = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            showSizeChange(current, size) {
                this.selectedRowKeys = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            /**
             * 排序
             * @param pagination
             * @param filters
             * @param sorter
             */
            sorterChange(pagination, filters, sorter) {
                if (sorter.order) {
                    this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
                } else {
                    this.request.sorting = undefined;
                }
                this.refresh();
            },
        }
    }
</script>

<style scoped>
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
</style>
