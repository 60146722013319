<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('EditDataItemDetail') }}</span>
				<span v-else>{{ l('CreateDataItemDetail') }}</span>
			</div>
		</div>
		<a-form :form="form" @submit="save()" autocomplete="off">
			<!--字典编码-->
			<a-row :gutter="8">
				<a-col :span="6">
					<q-label required :label="l('Code')" prop="Code"> </q-label>
				</a-col>
				<a-col :span="14">
					<a-input
						:placeholder="l('DataItemDetailItemDetailCodeInputDesc')"
						v-model="entity.itemDetailCode"
					/>
				</a-col>
			</a-row>
			<!--字典编码-->
			<a-row :gutter="8">
				<a-col :span="6">
					<q-label required :label="l('Name')"> </q-label>
				</a-col>
				<a-col :span="14">
					<a-input :placeholder="l('DataItemNameInputDesc')" v-model="entity.itemDetailName" />
				</a-col>
			</a-row>
			<!--字典编码-->
			<a-row :gutter="8">
				<a-col :span="6">
					<q-label :label="l('Sort')"> </q-label>
				</a-col>
				<a-col :span="14">
					<a-input :placeholder="l('SortCode')" v-model="entity.sortCode" />
				</a-col>
			</a-row>
			<a-row :gutter="8">
				<a-col :span="6">
					<q-label :label="l('isDefault')"> </q-label>
				</a-col>
				<a-col :span="14">
					<a-switch v-model="isSyetem" :checkedChildren="l('Yes')" :unCheckedChildren="l('No')" />
				</a-col>
			</a-row>
		</a-form>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { ModalComponentBase } from '@/shared/component-base';
import { DataItemDetailServiceProxy } from '@/shared/service-proxies';
import { CreateOrUpdateDataItemDetailInput, DataItemDetailEditDto } from '../../../../shared/service-proxies';

export default {
	name: 'creata-or-edit-data-item-detail',
	mixins: [ModalComponentBase, AppComponentBase],
	data() {
		return {
			form: this.$form.createForm(this),
			id: null,
			entity: new DataItemDetailEditDto(),
			spinning: false,
			itemId: null,
			dataItemCode: null,
			isSyetem: false,
		};
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._dataitemdeatailServiceProxy = new DataItemDetailServiceProxy(this.$apiUrl, this.$api);
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this._dataitemdeatailServiceProxy.getById(this.id).then((res) => {
				this.entity = res;
				this.itemId = res.dataItemId;
				this.isSyetem = res.isSyetem != null ? res.isSyetem : false;
			});
		},
		/**
		 * 提交
		 */
		save() {
			const input = new CreateOrUpdateDataItemDetailInput();
			//新增
			if (!this.id && !this.itemId) {
				return abp.message.warn('未选择分类');
			}
			if (!this.entity.itemDetailCode) {
				return abp.message.warn('请输入编码');
			}
			if (!this.entity.itemDetailName) {
				return abp.message.warn('请输入名称');
			}
			this.entity.id = this.id;
			this.entity.dataItemId = this.itemId;
			this.entity.isSyetem = this.isSyetem;
			input.dataItemDetail = DataItemDetailEditDto.fromJS(this.entity);
			this.spinning = true;
			this._dataitemdeatailServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
