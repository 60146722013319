<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('Edit') }}</span>
				<span v-else>{{ l('Create') }}</span>
			</div>
		</div>
		<a-form :form="form" @submit="save()" autocomplete="off">
			<a-row :gutter="8">
				<a-row>
					<a-col :span="3">
						<q-label required :label="l('GradeName')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input
							:placeholder="l('CourseCategoryInputDesc', l('GradeName'))"
							v-model="entity.gradeName"
						/>
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="3">
						<q-label :label="l('DepartmentDescription')"> </q-label>
					</a-col>
					<a-col :span="18">
						<textarea
							class="ant-input"
							:Placeholder="l('DepartmentDescriptionInputDesc')"
							style="width: 100%"
							AllowClear="true"
							v-model="entity.description"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-tabs default-active-key="1" @change="callback">
						<a-tab-pane key="1" tab="薪资">
							<a-row>
								<a-col :span="3">
									<q-label :label="l('MonthlyBonus')"> </q-label>
								</a-col>
								<a-col :span="9">
									<a-input-number
										style="width: 100%;"
										:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
										:placeholder="l('CourseCategoryInputDesc', l('MonthlyBonus'))"
										v-model="entity.monthlyBonus"
									/>
								</a-col>
								<a-col :span="3">
									<q-label :label="l('SynthesisIncome')"> </q-label>
								</a-col>
								<a-col :span="9">
									<a-input-number
										style="width: 100%;"
										:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
										:placeholder="l('CourseCategoryInputDesc', l('SynthesisIncome'))"
										v-model="entity.synthesisIncome"
									/>
								</a-col>
							</a-row>

							<a-row>
								<a-col :span="3">
									<q-label :label="l('OtherSubsidy')"> </q-label>
								</a-col>
								<a-col :span="9">
									<a-input-number
										style="width: 100%;"
										:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
										:placeholder="l('CourseCategoryInputDesc', l('OtherSubsidy'))"
										v-model="entity.otherSubsidy"
									/>
								</a-col>
								<a-col :span="3">
									<q-label :label="l('SalaryIncome')"> </q-label>
								</a-col>
								<a-col :span="9">
									<a-input-number
										style="width: 100%;"
										:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
										:placeholder="l('CourseCategoryInputDesc', l('SalaryIncome'))"
										v-model="entity.salaryIncome"
									/>
								</a-col>
							</a-row>

							<a-row>
								<a-col :span="3">
									<q-label :label="l('SkillSubsidy')"> </q-label>
								</a-col>
								<a-col :span="9">
									<a-input-number
										style="width: 100%;"
										:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
										:placeholder="l('CourseCategoryInputDesc', l('SkillSubsidy'))"
										v-model="entity.skillSubsidy"
									/>
								</a-col>
								<a-col :span="3">
									<q-label :label="l('OverTimeSubsidy')"> </q-label>
								</a-col>
								<a-col :span="9">
									<a-input-number
										style="width: 100%;"
										:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
										:placeholder="l('CourseCategoryInputDesc', l('OverTimeSubsidy'))"
										v-model="entity.overTimeSubsidy"
									/>
								</a-col>
							</a-row>

							<a-row>
								<a-col :span="3">
									<q-label :label="l('ManagerAdd')"> </q-label>
								</a-col>
								<a-col :span="9">
									<a-input-number
										style="width: 100%;"
										:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
										:placeholder="l('CourseCategoryInputDesc', l('ManagerAdd'))"
										v-model="entity.managerAdd"
									/>
								</a-col>
								<a-col :span="3">
									<q-label :label="l('PsotSubsidy')"> </q-label>
								</a-col>
								<a-col :span="9">
									<a-input-number
										style="width: 100%;"
										:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
										:placeholder="l('CourseCategoryInputDesc', l('PsotSubsidy'))"
										v-model="entity.psotSubsidy"
									/>
								</a-col>
							</a-row>

							<a-row>
								<a-col :span="3">
									<q-label :label="l('BasePay')"> </q-label>
								</a-col>
								<a-col :span="9">
									<a-input-number
										style="width: 100%;"
										:parser="(value) => value.replace(/\$\s?|(,*)/g, '')"
										:placeholder="l('CourseCategoryInputDesc', l('BasePay'))"
										v-model="entity.basePay"
									/>
								</a-col>
							</a-row>
						</a-tab-pane>
					</a-tabs>
				</a-row>
			</a-row>
		</a-form>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import {
	OrgPostGradeServiceProxy,
	CreateOrUpdateOrgPostGradeInput,
	OrgPostGradeEditDto,
} from '../../../../shared/service-proxies';
import { ModalHelper } from '@/shared/helpers';

export default {
	name: 'add-grade',
	mixins: [ModalComponentBase],
	components: {},
	data() {
		return {
			form: this.$form.createForm(this),
			id: null,
			entity: new OrgPostGradeEditDto(),
			spinning: false,
			postId: undefined,
			_orgPostGradeServiceProxy: null,
		};
	},
	mounted() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._orgPostGradeServiceProxy = new OrgPostGradeServiceProxy(this.$apiUrl, this.$api);
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		callback(key) {},
		getData() {
			this.spinning = true;
			this._orgPostGradeServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.orgPostGrade;
				});
		},
		save() {
			if (!this.entity.gradeName) {
				return abp.message.warn(this.l('CourseCategoryInputDesc', this.l('GradeName')));
			}
			const input = new CreateOrUpdateOrgPostGradeInput();
			this.entity.orgPostId = this.postId != undefined ? parseFloat(this.postId) : undefined;
			input.orgPostGrade = OrgPostGradeEditDto.fromJS(this.entity);
			this.spinning = true;
			this._orgPostGradeServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
