<template>
	<a-spin :spinning="loading">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('Edit') }}</span>
				<span v-else>{{ l('Create') }}</span>
			</div>
		</div>
		<div>
			<a-row>
				<a-col :span="4">
					<q-label required label="名称"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-input placeholder="名称" v-model="entity.name" />
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label required label="编码"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-input placeholder="编码" v-model="entity.code" />
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label label="协议Url"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-input placeholder="协议Url" v-model="entity.jumpUrl" />
				</a-col>
			</a-row>
		</div>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import {
	CreateOrUpdateJumpProtocolInput,
	JumpProtocolEditDto,
	JumpProtocolServiceProxy,
} from '../../../../shared/service-proxies';

export default {
	name: 'create-or-edit-jump-protocol',
	mixins: [ModalComponentBase],
	data() {
		return {
			id: undefined,
			entity: new JumpProtocolEditDto(),
		};
	},
	created() {
		this.fullData();
		this._jumpProtocolServiceProxy = new JumpProtocolServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._jumpProtocolServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res.jumpProtocol;
				});
		},
		save() {
			if (!this.entity.name) {
				return abp.message.warn('名称不能为空');
			}
			if (!this.entity.code) {
				return abp.message.warn('编码不能为空');
			}
			let input = new CreateOrUpdateJumpProtocolInput();
			input.jumpProtocol = this.entity;
			this.loading = true;
			this._jumpProtocolServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
