<template>
	<div>
		<!-- 标题 -->
		<div class="modal-header" style="display: flex; justify-content: space-between; margin-bottom: 5px">
			<div class="modal-title">
				<span>{{ l('选择部门') }}</span>
			</div>
			<div>
				<a-button :disabled="saving" @click="close()" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
					<a-icon type="save" />
					{{ l('Save') }}
				</a-button>
			</div>
		</div>
		<a-row>
			<a-col :span="6">
				<a-row :gutter="8">
					<a-col :span="16">
						<p class="left">
							<a-icon type="share-alt" />
							{{ l('Company') }}
						</p>
					</a-col>
				</a-row>
				<a-spin :spinning="treespinning">
					<a-tree
						:tree-data="treeData"
						@select="onSelect"
						:expanded-keys="expandedKeys"
						@expand="onExpand"
						:selectedKeys="TreeKey"
					>
						<a-icon type="folder" slot="folder" />
						<a-icon type="file" slot="file" />
						<a-icon type="folder-open" slot="folder-open" />
						<template slot="custom" slot-scope="{ expanded }">
							<a-icon :type="expanded ? 'folder-open' : 'folder'" />
						</template>
					</a-tree>
				</a-spin>
			</a-col>
			<a-col :span="18">
				<a-card :bordered="false">
					<!-- usePagination开启分页 -->
					<!-- loading绑定引入页面的蒙层加载变量 -->
					<q-container useModal :loading="loading">
						<!-- 使用具名插槽 #toolbar -->
						<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
						<template #toolbar>
							<div class="my-md">
								<p v-if="mergeRows.length <= 0" class="NotR">
									已选部门
								</p>
								<a-tag
									closable
									@close="tagcol(item.id, index)"
									v-for="(item, index) in mergeRows"
									:key="item.id"
									v-else
								>
									{{ item.displayName }}
								</a-tag>
							</div>
						</template>
						<!-- 使用具名插槽slot="table" -->
						<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
						<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

						<!-- **************行选择*************** -->
						<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
						<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
						<template slot="table">
							<a-table
								ref="table"
								size="small"
								:pagination="false"
								:columns="columns"
								:rowKey="(tableDatas) => tableDatas.id"
								:dataSource="tableData"
								:customRow="customRow"
								:rowClassName="rowClassName"
								defaultExpandAllRows
							>
							</a-table>
						</template>
					</q-container>
				</a-card>
			</a-col>
		</a-row>
	</div>
</template>

<script>
import { CompanyServiceProxy, DepartmentServiceProxy } from '../../../../shared/service-proxies';
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import DepTreeUntils from '../../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	mixins: [ModalComponentBase],
	name: 'select-dep-tag',
	data() {
		return {
			treespinning: false,
			//公司树
			treeData: [],
			expandedKeys: [],
			TreeKey: undefined,
			// 表格
			columns: [
				{
					title: this.l('Name'),
					dataIndex: 'displayName',
					sorter: false,
					align: 'left',
					scopedSlots: { customRender: 'fullName' },
				},
				{
					title: this.l('Manager'),
					dataIndex: 'manangerName',
					align: 'left',
					scopedSlots: { customRender: 'manangerName' },
				},
				{
					title: this.l('UserCount'),
					dataIndex: 'userCount',
					align: 'left',
					scopedSlots: { customRender: 'userCount' },
				},
			],
			//多选
			isMultiple: true,
			tableData: [],
			_selectedRowKeys: [],
			_selectedRows: [],
		};
	},
	computed: {
		mergeRows() {
			//  _.differenceBy(this.selectRows, this.selectedRows, 'id')
			//比较差异，返回第一个数组唯一值
			//再直接拼接第二个数组，这样就得到了并集
			return [..._.differenceBy(this._selectedRows, this.selectedRows, 'id'), ...this.selectedRows];
		},
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段

		this._companyServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getCompanyData();
	},
	methods: {
		/**
		 * 获取公司
		 */
		getCompanyData() {
			this.treespinning = true;
			this.treeData = [];
			this._companyServiceProxy
				.getTreeList()
				.finally(() => {
					this.treespinning = false;
				})
				.then((res) => {
					this.tree = res;
					this.tree.forEach((item) => {
						if (item.parentId == 0) {
							this.treeData.push(item);
							this.expandedKeys.push(item.key);
						}
					});
					this.initTree(this.treeData);
					if (this.treeData.length > 0) {
						this.onSelect([this.treeData[0].key], undefined);
					}
				});
		},
		/**
		 * 初始化公司树形结构数据格式
		 */
		initTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集
				var pid = '';
				if (v.parentId != 0) {
					//校验是否为父级数据
					pid = v.parentId;
				}
				var keyid = v.key; //拼接父级ID和子集ID产生Key
				v.key = ''; //创建Key
				v.key = keyid;
				v.children = []; //创建空的子集
				this.tree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var mid = '';
						if (m.parentId != 0) {
							mid = v.parentId;
						}
						var mkeyid = m.key; //拼接子集ID和其子集ID产生Key
						m.key = '';
						m.key = mkeyid;
						v.children.push(m); //放进父级的集合中
						this.initTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 点击树
		 */
		onSelect(keys, event) {
			this.selectCompanyId = keys[0];
			this.TreeKey = keys;
			this.getDepartmentList();
		},
		onExpand(expandedKeys, record) {
			this.expandedKeys = expandedKeys;
		},
		/**
		 * 获取部门集合
		 */
		getDepartmentList() {
			this.loading = true;
			this._departmentServiceProxy
				.getPaged(
					this.selectCompanyId == undefined ? undefined : this.selectCompanyId,
					false,
					this.filterText,
					this.request.sorting,
					999,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = [];
					this.tableTree = res.items;

					this.tableTree.forEach((item, index) => {
						if (!item.parentId) {
							this.tableData.push(item);
						}
					});
					this.tableData = DepTreeUntils.getInstance().ArrToTree(this.tableTree, this.tableData, false);
				});
		},

		tagcol(record, index) {
			this.$delete(this.selectedRowObj, index + 'r' + record.id);
		},
		handleSubmit() {
			this.success(this.mergeRows);
		},
	},
};
</script>

<style scoped lang="less">
.my-md {
	border: 1px solid #d9d9d9;
	min-height: 45px;
	border-radius: 3px;
	display: flex;
	align-items: center;
	padding-left: 5px;
	line-height: 35px;
	overflow-x: auto;
}

.NotR {
	height: 20px;
	color: #bfbfbf;
}

.my-md::-webkit-scrollbar {
	/*滚动条整体样式*/
	width: 1px; /*高宽分别对应横竖滚动条的尺寸*/
	height: 6px;
}

.my-md::-webkit-scrollbar-thumb {
	border-radius: 10px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: #808080;
}

.my-md::-webkit-scrollbar-track {
	/*滚动条里面轨道*/
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 10px;
	background: #ededed;
}

/deep/ .ant-table-thead > tr > th .ant-table-header-column .ant-checkbox {
	display: none;
}
</style>
