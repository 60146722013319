<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <div class="type_container">
                <!--                <div>-->
                <!--                    1111-->
                <!--                </div>-->
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../../../shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale/zh_CN";
    import * as echarts from "echarts";
    import * as $ from "jquery";

    export default {
        name: "task-type-calc",
        mixins: [AppComponentBase],
        props:{
            analysis:{
                type:Object,
                default:()=>{}
            }
        },
        data() {
            return {
                zh_CN
            }
        },
        created() {

        },
        mounted() {
            this.init();
            this.dataInit();
        },
        methods: {
            init() {
                $(".type_container").html('');
                this.analysis.taskTypeData.forEach((item, index) => {
                    $(".type_container").append(`<div id='echart${index + 1}' style="flex: 1;
                                                                                display: flex;
                                                                                height: 300px;
                                                                                min-width: 375px;
                                                                                justify-content: center;
                                                                                align-items: center;"
                                                   ></div>`);
                });
            },
            dataInit() {
                this.analysis.taskTypeData.forEach((item, index) => {
                    let chartDom = document.getElementById("echart" + (index + 1));
                    let myChart = echarts.init(chartDom);
                    let option;
                    option = {
                        title: {
                            text: item.type,
                            left: 'center'
                        },
                        tooltip: {
                            trigger: 'item',
                            formatter: '{b}:{c}({d}%)'
                        },
                        legend: {
                            top: '80%',
                            y: 'center'
                        },
                        series: [
                            {
                                name: item.type,
                                type: 'pie',
                                radius: ['30%', '50%'],
                                avoidLabelOverlap: false,
                                label: {
                                    show: false,
                                    position: 'center'
                                },
                                emphasis: {
                                    label: {
                                        show: true,
                                        fontSize: '16',
                                        fontWeight: 'bold'
                                    }
                                },
                                labelLine: {
                                    show: false
                                },
                                data: item.data
                            }
                        ]
                    };
                    myChart.setOption(option);
                    window.addEventListener('resize', () => {
                        myChart.resize();
                    });
                });
            }
        }
    }
</script>

<style scoped>
    .type_container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: row;
    }

    .chart {
        flex: 1;
        display: flex;
        height: 300px;
        min-width: 375px;
        justify-content: center;
        align-items: center;
    }
</style>
