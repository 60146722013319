<template>
	<a-spin :spinning="loading">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('Edit') }}</span>
				<span v-else>{{ l('Create') }}</span>
			</div>
		</div>
		<div>
			<a-row>
				<a-col :span="4">
					<q-label required label="类型"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-select
						show-search
						:placeholder="l('类型')"
						option-filter-prop="children"
						v-model="entity.sourceType"
						style="width: 100%"
					>
						<a-select-option v-for="item in SourceTypeList" :key="item.itemDetailCode">
							{{ item.itemDetailName }}
						</a-select-option>
					</a-select>
				</a-col>
			</a-row>
			<a-row>
				<a-col :span="4">
					<q-label required label="有效Url"> </q-label>
				</a-col>
				<a-col :span="20">
					<a-input placeholder="有效Url" v-model="entity.validPath" />
				</a-col>
			</a-row>
		</div>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import ModalComponentBase from '../../../../../shared/component-base/modal-component-base';
import {
	CreateOrUpdateJumpValidPathInput,
	JumpValidPathEditDto,
	JumpValidPathServiceProxy,
} from '../../../../../shared/service-proxies';
import { Dic } from '../../../../../shared/utils';

export default {
	name: 'create-or-edit-jump-valid-path',
	mixins: [ModalComponentBase],
	data() {
		return {
			id: undefined,
			entity: new JumpValidPathEditDto(),
			SourceTypeList: [],
			jvpId: undefined,
		};
	},
	created() {
		this.fullData();
		this._jumpValidPathServiceProxy = new JumpValidPathServiceProxy(this.$apiUrl, this.$api);
	},
	async mounted() {
		this.SourceTypeList = await Dic.getInstance().getDicAsync('QYB_Mes_SourceType');
		if (this.id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.loading = true;
			this._jumpValidPathServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.entity = res.jumpValidPath;
				});
		},
		save() {
			if (!this.entity.sourceType) {
				return abp.message.warn('请选择类型');
			}
			if (!this.entity.validPath) {
				return abp.message.warn('请输入有效Url');
			}
			this.entity.jvpId = this.jvpId;
			let input = new CreateOrUpdateJumpValidPathInput();
			input.jumpValidPath = this.entity;
			this.loading = true;
			this._jumpValidPathServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
