<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8">
				<a-col :span="24">
					<a-button :type="'primary'" v-if="isGranted('company_add')" @click="createOrEdit()">
						<a-icon type="plus" />
						<span>{{ l('Create') }}</span>
					</a-button>
					<a-input-search
						style="width: 200px; margin: 0 10px;"
						name="filterText"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
						v-decorator="['filterText']"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ y: scopeParam.scroll.y }"
				:dataSource="treeData"
			>
				<span slot="actions" slot-scope="text, record">
					<!-- 修改 -->
					<a class="table-edit" @click="createOrEdit(record)" v-if="isGranted('company_edit')">
						<a-icon type="edit" />{{ l('Edit') }}</a
					>
					<!-- 删除 -->
					<a-popconfirm
						placement="top"
						v-if="isGranted('company_delete')"
						:okText="l('Ok')"
						:cancelText="l('Cancel')"
						@confirm="deleteItem(record)"
					>
						<template slot="title">
							{{ l('ConfirmDeleteWarningMessage') }}
						</template>
						<a class="table-delete"> <a-icon type="delete" />{{ l('Delete') }}</a>
					</a-popconfirm>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { ModalHelper } from '@/shared/helpers';
import { CompanyServiceProxy } from '@/shared/service-proxies';
import CreateOrEditCompanyConfig from './companyedit';

export default {
	mixins: [AppComponentBase],
	name: 'company',
	components: {
		CreateOrEditCompanyConfig,
	},
	data() {
		return {
			_CompanyConfigServiceProxy: null,

			// 表格
			columns: [
				{
					title: this.l('Name'),
					dataIndex: 'fullName',
					align: 'left',
					scopedSlots: { customRender: 'fullName' },
				},
				{
					title: this.l('Code'),
					dataIndex: 'enCode',
					align: 'center',
					scopedSlots: { customRender: 'enCode' },
				},
				{
					title: this.l('ShortName'),
					dataIndex: 'shortName',
					align: 'center',
					scopedSlots: { customRender: 'shortName' },
				},
				{
					title: this.l('ManagerUserId'),
					dataIndex: 'managerName',
					align: 'center',
					scopedSlots: { customRender: 'managerName' },
				},
				{
					title: this.l('Actions'),
					dataIndex: 'actions',
					align: 'center',
					scopedSlots: { customRender: 'actions' },
					width: '200px',
				},
			],

			tableData: [],
			treeData: [],
			defaultExpandedRowKeys: [],
		};
	},
	async created() {
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.getData();
	},
	methods: {
		/**
		 * 获取数据
		 */
		getData() {
			this.loading = true;
			this._CompanyConfigServiceProxy
				.getPaged(this.filterText, this.request.sorting, this.request.maxResultCount, this.request.skipCount)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.treeData = [];
					this.tableData.forEach((item) => {
						if (!item.parentId) {
							this.treeData.push(item);
							this.defaultExpandedRowKeys.push(item.id);
						}
					});

					this.initTree(this.treeData);
					this.totalItems = res.totalCount;
				});
		},

		/**
		 * 初始化树形结构数据格式
		 */
		initTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集
				var pid = '';
				if (v.parentId != 0) {
					//校验是否为父级数据
					pid = v.parentId;
				}
				var keyid = pid + '' + v.id; //拼接父级ID和子集ID产生Key
				v.key = ''; //创建Key
				v.key = keyid;
				v.children = []; //创建空的子集
				this.tableData.forEach((m) => {
					if (m.parentId == v.id) {
						//根据主键查子集数据
						var mid = '';
						if (m.parentId != 0) {
							mid = v.parentId;
						}
						var mkeyid = mid + '' + m.id; //拼接子集ID和其子集ID产生Key
						m.key = '';
						m.key = mkeyid;
						v.children.push(m); //放进父级的集合中
						this.initTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 创建编辑
		 */
		createOrEdit(item) {
			ModalHelper.create(
				CreateOrEditCompanyConfig,
				{ entity: item },
				{
					width: '900px',
				}
			).subscribe((res) => {
				if (res) {
					this.search();
				}
			});
		},

		/**
		 * 批量删除
		 */
		batchDelete(e) {
			const selectCount = this.selectedRowKeys.length;
			if (selectCount <= 0) {
				abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
				return;
			}
			this.message.confirm(this.l('ConfirmDeleteXItemsWarningMessage', selectCount), (res) => {
				if (res) {
					const ids = _.map(this.selectedRowKeys);
					this.loading = true;
					this._CompanyConfigServiceProxy
						.batchDelete(ids)
						.finally(() => {
							this.loading = false;
						})
						.then(() => {
							this.search();
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
						});
				}
			});
		},
		/**
		 * 单个删除
		 */
		deleteItem(item) {
			this.loading = true;
			this._CompanyConfigServiceProxy
				.delete(item.id)
				.finally(() => {
					this.loading = false;
				})
				.then(() => {
					this.search();
					this.$notification['success']({
						message: this.l('SuccessfullyDeleted'),
					});
				});
		},
	},
};
</script>

<style scoped lang="less">
.subscriptionEndUtc {
	text-align: center;
}

// table
.list-table {
	i {
		margin-right: 10px;
	}
}

.pagination {
	margin: 10px auto;
	text-align: right;
}

.table-edit {
	.anticon {
		margin-right: 10px;
	}
}

.table-delete,
.ant-dropdown-link {
	margin-left: 20px;
}
</style>
