<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="Id">{{ l('EditNotice') }}</span>
				<span v-else>{{ l('CreateNotice') }}</span>
			</div>
		</div>
		<a-config-provider :locale="zhCN">
			<div class="my-md">
				<a-row :gutter="8">
					<a-row>
						<a-col :span="3">
							<q-label required :label="l('Type')"> </q-label>
						</a-col>
						<a-col :span="9">
							<a-select
								show-search
								:placeholder="l('Type')"
								option-filter-prop="children"
								v-model="entity.noticeTypeCode"
								@change="TypeChange"
								allowClear
								style="width: 100%"
							>
								<a-select-option v-for="item in NoticeTypeList" :key="item.itemDetailCode">
									{{ item.itemDetailName }}
								</a-select-option>
							</a-select>
						</a-col>
						<a-col :span="3">
							<q-label required :label="l('AuthorName')"> </q-label>
						</a-col>
						<a-col :span="9">
							<a-input :placeholder="l('AuthorName')" v-model="entity.authorName" />
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="3">
							<q-label required :label="l('FullHead')"> </q-label>
						</a-col>
						<a-col :span="21">
							<a-input :placeholder="l('FullHead')" v-model="entity.fullHead" />
						</a-col>
					</a-row>
					<a-row>
						<a-col :span="3">
							<q-label :label="l('Subtitle')"> </q-label>
						</a-col>
						<a-col :span="21">
							<a-input :placeholder="l('Subtitle')" v-model="entity.subtitle" />
						</a-col>
					</a-row>
					<a-row>
						<!-- <a-col :span="3">
							<q-label :label="l('SendDeptIds')"> </q-label>
						</a-col>
						<a-col :span="9">
							<div class="group-input" @click="selectDep">
								<div class="group-text">
									<span class="ellipsis">
										{{ depNames ? depNames : '' }}
									</span>
								</div>
								<div class="group-icon">
									<a>
										<a-icon type="plus" />
									</a>
								</div>
							</div>
						</a-col> -->
						<a-col :span="3">
							<q-label :label="l('sendUserIds')"> </q-label>
						</a-col>
						<a-col :span="9">
							<div class="group-input" @click="selectUser">
								<div class="group-text">
									<span class="ellipsis">
										{{ userNames ? userNames : '' }}
									</span>
								</div>
								<div class="group-icon">
									<a>
										<a-icon slot="addonAfter" type="user" />
									</a>
								</div>
							</div>
						</a-col>
						<a-col :span="3">
							<q-label required :label="l('NoticeType')"> </q-label>
						</a-col>
						<a-col :span="9">
							<a-select
								show-search
								allowClear
								:placeholder="l('NoticeType')"
								option-filter-prop="children"
								v-model="entity.categoryCode"
								style="width: 100%"
							>
								<a-select-option v-for="item in CategoryType" :key="item.itemDetailCode">
									{{ item.itemDetailName }}
								</a-select-option>
							</a-select>
						</a-col>
					</a-row>
				</a-row>
				<a-row>
					<a-col :span="3">
						<q-label required :label="l('ReleaseTime')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-date-picker
							:show-time="timeOp"
							v-model="releaseTime"
							:open="IsOpen"
							@focus="onfocus"
							:placeholder="l('ReleaseTime')"
							@change="onChange"
							@ok="onOk"
							format="YYYY-MM-DD HH:mm"
							style="width: 100%"
						>
						</a-date-picker>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="3">
						<q-label :label="l('NewsContent')"> </q-label>
					</a-col>
					<a-col :span="21" class="editor">
						<!--                    <a-textarea :placeholder="l('NewsContent')" :rows="4"/>-->
						<editor @onChange="editorOnChang" :value="entity.newsContent"></editor>
					</a-col>
				</a-row>
				<a-row v-if="Id">
					<a-col :span="24">
						<div class="tag-text">
							<div class="tag-lable">已读 {{ readUserList.length }}</div>
							<div class="tag-btn">
								<a-tag :color="readbtn ? '' : '#108ee9'" class="tag" @click="readbtn = !readbtn">
									{{ readbtn ? '隐藏' : '显示' }}
								</a-tag>
							</div>
						</div>
						<div class="data-row">
							<div style="flex: 2"></div>
							<div style="flex: 21" v-if="readbtn">
								<a-tag color="#2db7f5" v-for="(item, index) in readUserList" :key="index">
									{{ item }}
								</a-tag>
							</div>
						</div>
					</a-col>
				</a-row>
				<a-row v-if="Id && noticeType == '通知'">
					<a-col :span="24">
						<div class="tag-text">
							<div class="tag-lable">未读 {{ noreadUserList.length }}</div>
							<div class="tag-btn">
								<a-tag :color="noreadbtn ? '' : '#108ee9'" class="tag" @click="noreadbtn = !noreadbtn">
									{{ noreadbtn ? '隐藏' : '显示' }}
								</a-tag>
							</div>
						</div>
						<div class="data-row">
							<div style="flex: 2"></div>
							<div style="flex: 21" v-if="noreadbtn">
								<a-tag color="#2db7f5" v-for="(item, index) in noreadUserList" :key="index">
									{{ item }}
								</a-tag>
							</div>
						</div>
					</a-col>
					<!--                    <a-col :span="3">-->
					<!--                        <q-label :label="l('未读')">-->
					<!--                        </q-label>-->
					<!--                    </a-col>-->
					<!--                    <a-col :span="21">-->
					<!--                        <a-tag :color="noreadbtn?'#108ee9':''" class="tag" @click="noreadbtn=!noreadbtn">-->
					<!--                            {{noreadbtn?"显示":"隐藏"}}-->
					<!--                        </a-tag>-->
					<!--                    </a-col>-->
				</a-row>
			</div>
		</a-config-provider>

		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import {
	CreateOrUpdateNewsInput,
	DepartmentServiceProxy,
	NewsEditDto,
	NewsServiceProxy,
} from '../../../../shared/service-proxies';
import moment from 'moment';
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import Dic from '../../../../shared/utils/dic';
import { ModalHelper } from '@/shared/helpers';
import SelectDepTag from '../../notice/select-dep-tag/select-dep-tag';
import SelectUserTag from '@/components/select-user-tag/select-user-tag.vue';
import Editor from '../../../../components/editor/editor';
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';

export default {
	name: 'create-edit-notice',
	mixins: [ModalComponentBase],
	components: {
		SelectDepTag,
		SelectUserTag,
		Editor,
	},
	data() {
		return {
			spinning: false,
			form: this.$form.createForm(this),
			Id: undefined,
			entity: new NewsEditDto(),
			NoticeTypeList: [],
			CategoryType: [],
			DepTreeData: [],
			//选择的部门Id
			depList: [],
			depNames: '',
			userIds: '',
			userNames: '',
			releaseTime: undefined,
			zhCN,
			IsOpen: false,
			timeOp: {
				format: 'HH:mm',
			},
			noticeType: undefined,
			readbtn: false,
			noreadbtn: false,
			//未读人员
			noreadUserList: [],
			//已读人员
			readUserList: [],
			UserData: [],
		};
	},
	async created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._NewsServiceProxy = new NewsServiceProxy(this.$apiUrl, this.$api);
		this.releaseTime = moment(moment().format('YYYY-MM-DD HH:mm'));
		this.entity.releaseTime = this.releaseTime;
		this.NoticeTypeList = await Dic.getInstance().getDicAsync('NoticeType');
		this.CategoryType = await Dic.getInstance().getDicAsync('CategoryByNotice');
		if (this.Id) {
			this.getData();
		}
	},
	methods: {
		getData() {
			this.spinning = true;
			this._NewsServiceProxy
				.getForEditByNotice(this.Id, 1)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.news;
					this.releaseTime = this.entity.releaseTime;
					// this.depNames = res.depNames;
					this.depList = res.depList;
					if (this.depList.length > 0) {
						this.depNames = _.map(this.depList, 'displayName').join(',');
					}
					this.userNames = res.userNames;
					this.userIds = res.userIds.map((u) => u.id).toString();
					this.noreadUserList = res.noReadUserList;
					this.readUserList = res.readUserList;
					this.UserData = res.userIds;
					this.TypeChange(this.entity.noticeTypeCode);
				});
		},
		TypeChange(record) {
			this.noticeType = record ? this.NoticeTypeList.find((item) => item.id == record).itemDetailName : undefined;
		},
		/**
		 * 推送人员
		 */
		selectUser() {
			console.log('this.UserData', this.UserData);
			ModalHelper.create(
				SelectUserTag,
				{
					_selectedRowKeys: this.userIds.split(','),
					_selectedRows: this.UserData,
					isMultiple: true,
				},
				{
					width: '900px',
					closable: false,
				}
			).subscribe((res) => {
				if (res) {
					if (res.length > 0) {
						this.UserData = res;
						for (let i = 0; i < res.length; i++) {
							for (let i = 0; i < res.length; i++) {
								if (i == 0) {
									this.userIds = res[i].id;
									this.userNames = res[i].realName;
									continue;
								}
								this.userIds = this.userIds + ',' + res[i].id;
								this.userNames = this.userNames + ',' + res[i].realName;
							}
						}
						this.entity.sendUserIds = this.userIds ? this.userIds.toString() : undefined;
					} else {
						this.entity.sendUserIds = undefined;
						this.UserData = [];
						this.userIds = '';
						this.userNames = '';
					}
				}
			});
		},
		/**
		 * 推送部门
		 */
		selectDep() {
			let ids = [];
			if (this.depList.length > 0) {
				ids = _.map(this.depList, 'id');
			}
			ModalHelper.create(
				SelectDepTag,
				{
					_selectedRowKeys: ids,
					_selectedRows: this.depList,
				},
				{
					width: '900px',
					closable: false,
				}
			).subscribe((res) => {
				if (res) {
					if (res.length > 0) {
						this.depNames = _.map(res, 'displayName').join(',');
						this.entity.sendDeptIds = _.map(res, 'id').join(',');
						this.depList = res;
					} else {
						this.depList = [];
						this.depNames = '';
						this.entity.sendDeptIds = undefined;
					}
				}
			});
		},
		editorOnChang(v) {
			//this.newsContent=v;
			this.entity.newsContent = v;
		},
		onChange(e) {
			this.entity.releaseTime = e;
			this.IsOpen = false;
		},
		onOk(value) {
			this.entity.releaseTime = value;
			this.IsOpen = false;
		},
		onfocus(e) {
			this.IsOpen = true;
		},
		save() {
			if (!this.entity.noticeTypeCode) {
				return abp.message.warn('请选择类型');
			}
			if (!this.entity.fullHead) {
				return abp.message.warn('请输入标题');
			}
			if (!this.entity.categoryCode) {
				return abp.message.warn('请选择公告类型');
			}
			if (!this.entity.releaseTime) {
				return abp.message.warn('请指定发布时间');
			}
			let input = new CreateOrUpdateNewsInput();
			input.news = NewsEditDto.fromJS(this.entity);
			input.departmentIds = this.entity.sendDeptIds ? this.entity.sendDeptIds : undefined;
			this.spinning = true;
			this._NewsServiceProxy
				.createOrUpdateNotic(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped>
.tag-text {
	width: 100%;
	height: 30px;
	display: flex;
}

.tag-lable {
	flex: 2;
	width: 100%;
	height: 35px;
	color: black;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.tag-btn {
	flex: 21;
	width: 100%;
	height: 35px;
	display: flex;
	align-items: flex-start;
	justify-content: left;
}

.data-row {
	width: 100%;
	margin: auto;
	display: flex;
	padding: 15px 0px 10px 0px;
}

.editor {
	line-height: 22px;
}

.group-input {
	width: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	height: 32px;
	margin-top: 3px;
	display: flex;
}

.group-text {
	flex: 5;
	text-align: left;
	padding-left: 9px;
	line-height: 32px;
	cursor: pointer;
}

.ellipsis {
	max-width: 250px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
}

.group-icon {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.group-icon i {
	color: #808080;
	background-color: #f6f4f4;
	padding: 8px 20px;
	border-radius: 0px 4px 4px 0px;
	border-left: 1px solid #d9d9d9;
}

.tag {
	margin-top: 9px;
	margin-left: 10px;
}
</style>
