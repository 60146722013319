<template>
        <a-row :gutter="8">
            <a-col :span="6" class="userDepPluralism-tree-panel">
                <userDepPluralism-tree-panel @selectedNode="selectedNodeFunc"></userDepPluralism-tree-panel>

            </a-col>
            <a-col :span="17" class="userDepPluralism-tree-panel" style="margin-left: 6px;">
                <a-row :gutter="8" class="units-header">
                    <a-col :span="24" class="title">
                        <a-icon type="team" />
                        <span v-if="selectedTree && selectedTree.hasOwnProperty('id')">{{ selectedTree.displayName }}</span>
                    </a-col>
                </a-row>
                <a-row class="form">
                    <!-- 暂无数据 -->
                    <div class="no-data" v-if="!selectedTree || !selectedTree.hasOwnProperty('id')">
                        <p>{{ l('SelectAnOrganizationUnitToSeeMembers') }}</p>
                    </div>
                    <userDepPluralism-members-panel></userDepPluralism-members-panel>
                </a-row>
            </a-col>
        </a-row>
</template>

<script>
import { ModalComponentBase } from "@/shared/component-base";
import UserDepPluralismTreePanel from "./userDepPluralism-tree-panel/userDepPluralism-tree-panel";
import UserDepPluralismMembersPanel from "./userDepPluralism-members-panel/userDepPluralism-members-panel";

export default {
    name: "organization-units",
    mixins: [ModalComponentBase],
    components: {
        UserDepPluralismTreePanel,
        UserDepPluralismMembersPanel
    },
    data() {
        return {
            // 选择的树结构
            selectedTree: {}
        };
    },
    mounted() {
        this.selectedTree = {};
    },
    methods: {
        /**
         * 选择树结构
         */
        selectedNodeFunc(data) {
            this.selectedTree = data;
        }
    }
};
</script>

<style scoped lang="less">
    .header {
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #e8e8e8;
        p {
            line-height: 50px;
            &.left {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.85);
                font-weight: 500;
                margin-left: 20px;
            }
            a {
                margin-left: 10px;
            }
        }
    }
.userDepPluralism-tree-panel {
    background-color: #fff;
    border: 1px solid #e8e8e8;
}
.units-header {
    height: 50px;
    border-bottom: 1px solid #e8e8e8;
    > .title {
        line-height: 50px;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        margin-left: 20px;
    }
    p {
        line-height: 50px;
        &.left {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            margin-left: 20px;
        }
        a {
            margin-left: 10px;
        }
    }
}
/deep/.ant-tabs-bar {
    margin: 0 20px;
}
// 暂无数据
.no-data {
    border: 1px solid #e8e8e8;
    margin: 20px;
    p {
        text-align: center;
        margin-bottom: 0;
        line-height: 120px;
    }
}
</style>
