<template>
	<!-- usePagination开启分页 -->
	<!-- loading绑定引入页面的蒙层加载变量 -->
	<q-container usePagination :loading="loading">
		<!-- 使用具名插槽 #toolbar -->
		<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
		<template #toolbar>
			<a-row :gutter="8">
				<a-col :span="24">
					<a-button :type="'primary'" @click="createOrEdit()" v-if="isGranted('manage_add')">
						<a-icon type="plus" />
						<span>{{ l('Create') }}</span>
					</a-button>
					<a-button :type="'primary'" @click="EnabledMarkToTrue()" v-if="isGranted('manage_enable')">
						<span>{{ l('EnabledMarkToTrue') }}</span>
					</a-button>
					<a-button :type="'primary'" @click="EnabledMarkToFalse()" v-if="isGranted('manage_unEnable')">
						<span>{{ l('EnabledMarkToFalse') }}</span>
					</a-button>
					<a-input-search
						name="filterText"
						style="width: 200px; margin: 0 10px"
						:placeholder="l('SearchWithThreeDot')"
						@search="search"
						enterButton
						v-model="filterText"
						v-decorator="['filterText']"
					/>
				</a-col>
			</a-row>
		</template>
		<!-- 使用具名插槽slot="table" -->
		<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
		<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

		<!-- **************行选择*************** -->
		<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
		<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
		<template slot="table" slot-scope="scopeParam">
			<a-table
				ref="table"
				size="small"
				:pagination="false"
				:columns="columns"
				:rowKey="(tableDatas) => tableDatas.id"
				:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
				:dataSource="tableData"
				:customRow="customRow"
				:rowClassName="rowClassName"
			>
				<!--                    图片-->
				<span slot="fileName" slot-scope="text, record" style="height: 50px">
					<img :src="appDTImgPath + record.fileName" style="height: 50px" />
				</span>
				<!--                    有效标识-->
				<span slot="enabledMark" slot-scope="text, record">
					<span class="true" v-if="record.enabledMark">
						启用
					</span>
					<span class="false" v-else>
						禁用
					</span>
				</span>
				<span slot="actions" slot-scope="text, record">
					<a-space>
						<!-- 修改 -->
						<a class="table-edit" @click="createOrEdit(record.id)" v-if="isGranted('manage_edit')">
							<a-icon type="edit" />{{ l('Edit') }}</a
						>
						<!-- 删除 -->
						<a-popconfirm
							placement="top"
							:okText="l('Ok')"
							:cancelText="l('Cancel')"
							@confirm="deleteManage(record)"
							v-if="isGranted('manage_delete')"
						>
							<template slot="title">
								{{ l('ConfirmDeleteWarningMessage') }}
							</template>
							<a class="table-delete"> <a-icon type="delete" />{{ l('Delete') }}</a>
						</a-popconfirm>
					</a-space>
				</span>
			</a-table>
		</template>
		<!-- 使用具名插槽 #page -->
		<template #page>
			<!-- 分页组件只需复制以下代码 -->
			<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
			<a-pagination
				v-model="pageNumber"
				:total="totalItems"
				showSizeChanger
				size="small"
				:defaultPageSize="request.maxResultCount"
				:pageSizeOptions="pageSizeOptions"
				:showTotal="() => showTotal"
				@change="onPageChange"
				@showSizeChange="showPageSizeChange"
			/>
		</template>
	</q-container>
</template>

<script>
import { AppComponentBase } from '@/shared/component-base';
import { AppDTImgServiceProxy } from '../../../shared/service-proxies';
import CreateEditManage from '../../appmanage/manage/create-edit-manage/create-edit-manage';
import { ModalHelper } from '@/shared/helpers';
import { AppConsts } from '../../../abpPro/AppConsts';

export default {
	mixins: [AppComponentBase],
	components: {
		CreateEditManage,
	},
	name: 'manage',
	data() {
		return {
			appDTImgPath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/App/${abp.session.tenantId}/`,
			// 表格
			columns: [
				{
					title: this.l('ImgName'),
					dataIndex: 'imgName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'imgName' },
				},
				{
					title: this.l('ImgFileName'),
					dataIndex: 'fileName',
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'fileName' },
				},
				{
					title: this.l('EnabledMark'),
					dataIndex: 'enabledMark',
					align: 'center',
					scopedSlots: { customRender: 'enabledMark' },
				},
				{
					title: this.l('SortCode'),
					dataIndex: 'sortCode',
					align: 'center',
					scopedSlots: { customRender: 'sortCode' },
				},
				{
					title: this.l('Actions'),
					dataIndex: 'actions',
					align: 'center',
					scopedSlots: { customRender: 'actions' },
					width: '200px',
				},
			],
			tableData: [],
		};
	},
	mounted() {
		this._AppDTImgServiceProxy = new AppDTImgServiceProxy(this.$apiUrl, this.$api);
		this.getData();
	},
	methods: {
		getData() {
			this.loading = true;
			this._AppDTImgServiceProxy
				.getPaged(this.filterText, this.request.sorting, this.request.maxResultCount, this.request.skipCount)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
					this.rowSelect(this.tableData[0], 0);
				});
		},

		createOrEdit(id) {
			ModalHelper.create(
				CreateEditManage,
				{ id },
				{
					width: '600px',
				}
			).subscribe((res) => {
				if (res) {
					this.search();
				}
			});
		},
		deleteManage(record) {
			this.loading = true;
			this._AppDTImgServiceProxy
				.delete(record.id)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SuccessfullyDeleted'),
					});
					this.search();
				});
		},
		/**
		 * 启用
		 * @constructor
		 */
		EnabledMarkToTrue() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请选择一项');
			}
			this.$confirm({
				title: this.l('ConfirmInformation'),
				content: this.l('ConfirmOperationWarningMessage'),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					this.loading = true;
					this._AppDTImgServiceProxy
						.updateEnabledMarkToTrue(this.selectedRowKeys[0])
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: this.l('SavedSuccessfully'),
							});
							this.search();
						});
				},
			});
		},
		/**
		 * 禁用
		 * @constructor
		 */
		EnabledMarkToFalse() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请选择一项');
			}
			this.$confirm({
				title: this.l('ConfirmInformation'),
				content: this.l('ConfirmOperationWarningMessage'),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					this.loading = true;
					this._AppDTImgServiceProxy
						.updateEnabledMarkToFalse(this.selectedRowKeys[0])
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: this.l('SavedSuccessfully'),
							});
							this.search();
						});
				},
			});
		},
	},
};
</script>

<style>
.true {
	margin: auto;
	width: 35px;
	height: 20px;
	font-weight: bold;
	border-radius: 0.25em;
	padding: 5px;
	font-size: 10px;
	color: white;
	background-color: #5cb85c;
	display: flex;
	align-items: center;
	justify-content: center;
}

.false {
	margin: auto;
	width: 35px;
	height: 20px;
	font-weight: bold;
	border-radius: 0.25em;
	padding: 5px;
	font-size: 10px;
	color: white;
	background-color: #777777;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
