<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <!-- 标题 -->
            <div class="modal-header">
                <div class="modal-title">
                    <span>{{ l('导入全年计划') }}</span>
                </div>
            </div>
            <div class="my-md">
                <a-row class="text">
                    1.不要修改模板，按照模板要求填写数据
                </a-row>
                <a-row class="text">
                    2.导入文件必须是xls,xlsx格式
                </a-row>
                <a-row class="text">
                    当前年:<b>{{ year }}</b>
                </a-row>
                <a-row class="text">
                    <a-col :span="5">
                        <a-button @click="ExportTemplate()">
                            <a-icon type="file-excel" />
                            <span>{{ l('下载模板') }}</span>
                        </a-button>
                    </a-col>
                    <a-col :span="19">
                        <a-upload
                            :action="uploadPictureUrl"
                            :beforeUpload="beforeUpload"
                            :headers="uploadHeaders"
                            @change="uploadPictureChange($event)"
                        >
                            <a-button type="primary"> <a-icon type="import" /> 导入 </a-button>
                        </a-upload>
                    </a-col>
                </a-row>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { AppConsts } from '../../../../abpPro/AppConsts';
import { fileDownloadService } from '../../../../shared/utils';
import moment from 'moment';
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import { LaborcostYearPlanServiceProxy, LCExportTemplateInput } from '../../../../shared/service-proxies';

export default {
    name: 'import-excel-model',
    mixins: [ModalComponentBase],
    data() {
        return {
            zh_CN,
            uploadPictureUrl: AppConsts.remoteServiceBaseUrl + '/Profile/UploadExcelFile',
            uploadHeaders: {
                Authorization: 'Bearer ' + abp.auth.getToken(),
            },
            year: undefined,
            comPanyId: undefined,
            departmentId: undefined,
        };
    },
    created() {
        this.fullData();

        this._fileDownloadService = fileDownloadService;
        this._laborcostYearPlanServiceProxy = new LaborcostYearPlanServiceProxy(this.$apiUrl, this.$api);
    },
    mounted() {},
    methods: {
        beforeUpload(file) {
            let filetype;
            if (file.name) {
                filetype = file.name.substring(file.name.lastIndexOf('.'));
            }
            let isExcel = filetype === '.xlsx' || filetype === '.xls';
            if (!isExcel) {
                return abp.message.warn('只能上传xlsx格式和xls的文件');
            }
        },
        uploadPictureChange({ file, fileList }) {
            if (file.status === 'done') {
                this.spinning = true;
                let input = new LCExportTemplateInput();
                input.comId = this.comPanyId;
                input.depId = this.departmentId;
                input.year = this.year;
                input.fileName = file.response.result.fileName;
                this._laborcostYearPlanServiceProxy
                    .importLoborcost(input)
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then((res) => {
                        if (!res.isSuccess && res.failedCount > 0) {
                            this.$notification['warn']({
                                message: '总条数:' + res.totalCount + '  失败条数:' + res.failedCount,
                            });
                            this._fileDownloadService.downloadTempFile(res.file);
                        } else {
                            this.$notification['success']({
                                message: this.l('导入成功'),
                            });
                        }
                    });
            }
        },
        ExportTemplate() {
            this.spinning = true;
            let input = new LCExportTemplateInput();
            input.comId = this.comPanyId;
            input.depId = this.departmentId;

            this._laborcostYearPlanServiceProxy
                .exportTemplate(input)
                .finally(() => {
                    this.spinning = false;
                })
                .then((res) => {
                    this._fileDownloadService.downloadTempFile(res);
                });
        },
    },
};
</script>

<style scoped>
.text {
    padding: 5px 0px;
}
</style>
