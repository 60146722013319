
    import {ModalComponentBase} from "@/shared/component-base";
    import {AppConsts} from "@/abpPro/AppConsts";
    import {
        DataItemDetailServiceProxy,
        DepartmentServiceProxy, DepartmentTreeDto, OrgPostServiceProxy,
        ProfileServiceProxy,
        UserServiceProxy
    } from "@/shared/service-proxies";
    import OrganizationUnitTree from "../../shared/organization-unit-tree/organization-unit-tree.vue";
    import ImageUtils from "@/shared/utils/image-utils";
    import Reg from "@/shared/utils/reg";

    export default {
        name: "create-or-edit-user",
        mixins: [ModalComponentBase],
        components: {
            OrganizationUnitTree
        },
        data() {
            return {
                _DepartmentServiceProxy: null,
                _profileServiceProxy: null,
                _userServiceProxy: null,
                _dataitemDetailServiceProxy: null,
                //主页面传过来的ID
                id: "", //用户ID
                did:undefined,//部门
                companyId: null,//公司ID

                // 表单
                formLayout: "horizontal",
                form: this.$form.createForm(this, {name: "coordinated"}),
                // 用户
                user: {
                    //用户id
                    id: undefined,
                    //公司id
                    companyId: undefined,
                    //部门id
                    departmentId: undefined,
                    //性别字典id
                    genderId: undefined,
                    //员工类型字典id
                    empTypeId: undefined,
                    //员工状态字典id
                    empStateId: undefined,
                    //用户名
                    userName: "",
                    //密码
                    password: '',
                    //核对密码
                    passwordRepeat: '',
                    //工号
                    jobNumber:'',
                    //邮箱地址
                    emailAddress: '',
                    //姓名
                    realName: '',
                    //手机号码
                    mobile: '',
                    //生日
                    birthday: '',
                    //婚姻状况字典ID
                    maritalStatusId: undefined,
                    //政治面貌字典id
                    politicsStatusId: undefined,
                    //入职日期
                    hireDate: '',
                    //试用期 月
                    probationMonth: 0,
                    //转正日期
                    regularDate: '',
                    //离职日期
                    termDate: '',
                    //是否激活
                    isActive: true,
                    //是否锁定用户
                    isLockoutEnabled: false,
                    //下次登录是否修改密码
                    needToChangeThePassword: false,
                    //使用随机密码
                    randomPassword: false,
                    //头像图片ID
                    profilePictureId: undefined,
                },
                previewVisible: false,
                previewImage: "",
                userPicturePath:`${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/${abp.session.tenantId}/`,
                // 上传图片
                fileList: [],
                uploadPictureUrl:
                    AppConsts.remoteServiceBaseUrl +
                    "/Profile/UploadProfilePictureReturnFileId",
                maxProfilPictureBytesValue: AppConsts.maxProfilPictureMb,
                uploadHeaders: {
                    Authorization: "Bearer " + abp.auth.getToken(),
                    type:"head"
                },

                // 是否设置随机密码
                isSetRandomPassword: false,
                // 角色list
                roleList: [],
                checkedRoles: [],
                // 选择组织单源数据
                organizationList: {},
                selectedUnit: [],
                userName: undefined,

                //部门树
                DepTreeData:[],
                DepTree: [],

                //角色
                role:[],
                CheckRoles:[],
            };
        },
        async created() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this._profileServiceProxy = new ProfileServiceProxy(this.$apiUrl, this.$api);
            this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
            this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
            this._dataitemDetailServiceProxy = new DataItemDetailServiceProxy(this.$apiUrl, this.$api);


        },
        mounted() {
            //获取部门
            this.getDepTreeData();
            this.user.departmentId = this.did;
            if(this.id){
                this.init();
            }

        },
        methods: {
            /**
             * 初始化
             */
            init() {
                this.CheckRoles=[];
                this._userServiceProxy
                    .getForEditTree(this.id)
                    .finally(() => (this.loading = false))
                    .then(result => {
                        // this.roleList = result.roles.map(
                        //     item => item.roleDisplayName
                        // );
                        this.role=result.roles;
                        this.role.forEach(item=>{
                            if(item.isAssigned){
                                this.CheckRoles.push(item.roleDisplayName);
                            }
                        })
                        this.organizationList = Object.assign(result, {
                            selectedOrganizationUnits: this.selectedUnit
                        });
                        this.user = {...result.user};
                        this.userName = result.user.userName;
                        // 设置头像
                        if (result.user.profilePictureId&&result.user.userPictureType) {
                            this.fileList = [
                                {
                                    uid: -1,
                                    name: result.user.profilePictureId,
                                    status: "done",
                                    url: this.userPicturePath+result.user.profilePictureId+result.user.userPictureType
                                }
                            ];
                            // this.getProfilePicture(result.user.profilePictureId);
                        }

                    });

            },
            /**
             * 提交表单
             */
            async handleSubmit() {
                //检查表单
                let isChecked = await this.checkSubmit();
                if(isChecked){
                    this.saving = true;
                    this.loading = true;
                    try{
                        let parmas = {
                            assignedRoleNames: [],
                            organizationUnits: this.selectedUnit,
                            user: {}
                        };
                        this.user.companyId = this.companyId;
                        this.user.id = this.id;
                        parmas.user = this.user;
                        // 处理角色
                        // parmas.assignedRoleNames = this.organizationList.roles
                        //     .filter(obj =>
                        //         this.checkedRoles.some(
                        //             item => item === obj.roleDisplayName
                        //         )
                        //     )
                        //     .map(item => item.roleName);
                        parmas.assignedRoleNames=this.CheckRoles;
                        this._userServiceProxy
                            .createOrUpdate(parmas)
                            .finally(() => {
                                this.loading = false;
                                this.saving = false;
                            })
                            .then(() => {
                                this.$notification["success"]({
                                    message: this.l("SavedSuccessfully")
                                });
                                this.success(true);
                            });
                    }catch (e) {
                        this.saving = false;
                        this.loading = false;
                        console.error(e)
                    }
                }
            },
            roleonChange(enent){
                this.CheckRoles=enent;
            },
            /**
             * 提交时检查表单
             */
            checkSubmit(){
                return new Promise((resolve, reject)=>{
                    if(!this.user.userName){
                        abp.message.warn("请输入账户")
                        reject(false);
                    }else if(!this.user.realName){
                        abp.message.warn("请输入姓名")
                        reject(false);
                    }else if(!this.user.departmentId){
                        abp.message.warn("请选择部门")
                        reject(false);
                    }
                    // else if(!this.user.emailAddress){
                    //     abp.message.warn("请输入邮箱")
                    //     reject(false);
                    // }
                    // else if(!Reg.getInstance().RegEmail(this.user.emailAddress)){
                    //
                    //     abp.message.warn("请输入正确的邮箱")
                    //     reject(false);
                    // }
                    else if(!this.user.randomPassword){
                        if(!this.id){
                            if(!this.user.password){
                                abp.message.warn("请输入密码")
                                reject(false);
                            }else if(!this.user.passwordRepeat){
                                abp.message.warn("请输入核对密码")
                                reject(false);
                            }else if(this.user.password !== this.user.passwordRepeat){
                                abp.message.warn("两次输入的密码不一致")
                                reject(false);
                            }else{
                                resolve(true);
                            }
                        }else{
                            resolve(true);
                        }
                    }else{
                        resolve(true);
                    }

                })
            },
            /**
             * 上传图片
             */
            beforeUpload(file) {
                const isJPG =
                    file.type === "image/jpeg" ||
                    file.type === "image/png" ||
                    file.type === "image/gif";
                if (!isJPG) {
                    abp.message.error(this.l("OnlySupportPictureFile"));
                }
                const isLtXM = file.size / 1024 / 1024 < this.maxProfilPictureBytesValue;
                if (!isLtXM) {
                    abp.message.error(
                        this.l(
                            "ProfilePicture_Warn_SizeLimit",
                            this.maxProfilPictureBytesValue
                        )
                    );
                }
                return isJPG && isLtXM;
            },
            async handlePreview(file) {
                if (!file.url && !file.preview) {
                    file.preview = await ImageUtils.getBase64(file.originFileObj);
                }
                this.previewImage = file.url || file.preview;
                this.previewVisible = true;
            },
            uploadPictureChange({file, fileList}) {
                if(file.status === 'done'){
                    this.user.userPictureType=file.response.result.fileType;
                    this.user.profilePictureId = file.response.result.profilePictureId;
                }
                this.fileList = fileList;
            },
            /**
             * 通过头像Id获取头像
             * @param profilePictureId 头像Id
             */
            getProfilePicture(profilePictureId) {
                if (profilePictureId) {
                    this.loading = true;
                    this._profileServiceProxy
                        .getProfilePictureById(profilePictureId)
                        .finally(() => (this.loading = false))
                        .then(result => {
                            if (result && result.profilePicture) {
                                let profilePreviewImage =
                                    "data:image/jpeg;base64," +
                                    result.profilePicture;
                                // 把图像加到头像列表 显示
                                this.fileList = [
                                    {
                                        uid: -1,
                                        name: profilePictureId,
                                        status: "done",
                                        url: profilePreviewImage
                                    }
                                ];
                            }
                        });
                }
            },
            /**
             * 选择完权限过滤
             */
            refreshGoFirstPage(data) {
                this.selectedUnit = data;
            },

            /**
             * 获取部门
             */
            getDepTreeData(){
                this.DepTreeData = [];
                this._DepartmentServiceProxy.getDepTreeData(this.companyId).then(res=>{
                    this.DepTree=res;
                    this.DepTree.forEach(item => {
                        if (!item.parentId) {
                            let obj = {
                                key: item.key,
                                value: item.key.toString(),
                                title: item.title
                            };
                            this.DepTreeData.push(obj);
                        }
                    });
                    this.initDepTree(this.DepTreeData);

                }).finally(()=>{
                    this.loading = false;
                })
            },
            /**
             * 初始化树形结构数据格式
             */
            initDepTree(list) {
                list.forEach((v) => {
                    //遍历父级数据，加载子集=
                    v.children = []; //创建空的子集
                    this.DepTree.forEach((m) => {
                        if (m.parentId == v.key) {
                            //根据主键查子集数据
                            var obj = {
                                key: m.key,
                                value: m.key.toString(),
                                title: m.title
                            };
                            v.children.push(obj); //放进父级的集合中
                            this.initDepTree(v.children); //递归查询子集的集合
                        }
                    });
                    if (v.children.length == 0) {
                        //校验如何数据没有子集，删除子集参数
                        delete v.children;
                    }
                });
            },
            /**
             * 选择部门
             */
            SelectDepartmentTree(e){
                this.user.departmentId = e;
            },
            /**
             * 使用随机密码switch改变
             */
            onRandomPasswordChange(isChecked){
                this.user.randomPassword = isChecked;
                this.user.needToChangeThePassword = isChecked;
            },
            /**
             * 根据字典code获取字典列表
             * @param code 字典code
             * @returns {Promise<unknown>} 异步，可等待
             */
            getDicAsync(code) {
                return new Promise((resolve, reject) => {
                    this._dataitemDetailServiceProxy.getListByDataItmeCode(code).then(res => {
                        resolve(res);
                    }).catch(err=>{
                        console.error(err);
                        reject();
                    })
                })
            }
        }
    };
