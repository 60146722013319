<template>
	<a-spin :spinning="spinning">
		<!-- 标题 -->
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="medicine-box" />
				<span v-if="id">{{ l('EditDepartment') }}</span>
				<span v-else>{{ l('CreateDepartment') }}</span>
			</div>
		</div>
		<a-form :form="form" @submit="save()" autocomplete="off">
			<a-row :gutter="8">
				<a-row>
					<a-col :span="3">
						<q-label required :label="l('Name')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input :placeholder="l('DepartmentFullNameInputDesc')" v-model="entity.displayName" />
					</a-col>
					<a-col :span="3">
						<q-label required :label="l('Code')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input :placeholder="l('DepartmentEnCodeInputDesc')" v-model="entity.code" />
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="3">
						<q-label :label="l('ShortName')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input :placeholder="l('DepartmentShortNameInputDesc')" v-model="entity.shortName" />
					</a-col>
					<a-col :span="3">
						<q-label :label="l('Type')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-select
							show-search
							:placeholder="l('Nature')"
							option-filter-prop="children"
							v-model="entity.natureCode"
						>
							<a-select-option v-for="item in departmenttype" :key="item.itemDetailCode">
								{{ item.itemDetailName }}
							</a-select-option>
						</a-select>
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="3">
						<q-label :label="l('ParentDepartment')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-tree-select
							v-model="parentId"
							allowClear
							style="width: 100%"
							show-search
							:filterTreeNode="searchTree"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="DepTreeData"
							:placeholder="l('ParentDepartment')"
							tree-default-expand-all
							@select="SelectTree"
						>
						</a-tree-select>
					</a-col>
					<a-col :span="3">
						<q-label :label="l('Manager')"> </q-label>
					</a-col>
					<a-col :span="9" id="userName">
						<div class="group-input" @click="selectUser">
							<div class="group-text">
								{{ userName ? userName : '' }}
							</div>
							<div class="group-icon">
								<a><a-icon slot="addonAfter" type="user"/></a>
							</div>
						</div>
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="3">
						<q-label :label="l('DepartmentOuterPhone')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input :placeholder="l('DepartmentOuterPhoneInputDesc')" v-model="entity.outerPhone" />
					</a-col>
					<a-col :span="3">
						<q-label :label="l('DepartmentInnerPhone')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input :placeholder="l('DepartmentInnerPhoneInputDesc')" v-model="entity.innerPhone" />
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="3">
						<q-label :label="l('DepartmentEmail')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input :placeholder="l('DepartmentEmailInputDesc')" v-model="entity.email" />
					</a-col>
					<a-col :span="3">
						<q-label :label="l('DepartmentFax')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input :placeholder="l('DepartmentFaxInputDesc')" v-model="entity.fax" />
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="3">
						<q-label :label="l('排序码')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-input-number v-model="entity.sortCode" :min="0" style="width: 100%" />
					</a-col>
					<a-col :span="3">
						<q-label :label="l('DepartmentDescription')"> </q-label>
					</a-col>
					<a-col :span="9">
						<textarea
							class="ant-input"
							:Placeholder="l('DepartmentDescriptionInputDesc')"
							style="width: 100%"
							AllowClear="true"
							v-model="entity.description"
						/>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="3">
						<q-label :label="l('虚拟部门')"> </q-label>
					</a-col>
					<a-col :span="9">
						<a-switch v-model="entity.isVirtual" checked-children="是" un-checked-children="否" />
					</a-col>
				</a-row>
			</a-row>
		</a-form>
		<!-- 按钮 -->
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="save()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</a-spin>
</template>

<script>
import { ModalComponentBase } from '../../../../shared/component-base';
import {
	DataItemDetailServiceProxy,
	UserServiceProxy,
	DepartmentServiceProxy,
	CreateOrUpdateDepartmentInput,
	AbpOrganizationunitsExtendEditDto,
} from '../../../../shared/service-proxies';
import SelectUserTag from '@/components/select-user-tag/select-user-tag';
import { ModalHelper } from '@/shared/helpers';

export default {
	name: 'create-edit-department',
	mixins: [ModalComponentBase],
	components: { SelectUserTag },
	data() {
		return {
			form: this.$form.createForm(this),
			id: null,
			entity: new AbpOrganizationunitsExtendEditDto(),
			spinning: false,
			userlist: [],
			departmenttype: [],
			departmentList: [],
			DepTreeData: [],
			tree: [],
			parentId: undefined,
			userName: undefined,
			companyId: undefined,
			userid: undefined,
			pid: undefined,
		};
	},
	mounted() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._dataitemDetailServiceProxy = new DataItemDetailServiceProxy(this.$apiUrl, this.$api);
		this._UserServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
		this._departmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this.gettreedata();
		this.getDepartmentType();
		this.parentId = this.pid;
		if (this.id) {
			this.getData();
		}
	},

	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		getData() {
			this.spinning = true;
			this._departmentServiceProxy
				.getForEdit(this.id)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.entity = res.department;
					this.userid = res.department.managerId;
					this.userName = res.department.managerName;
					this.parentId = !res.department.parentId ? undefined : res.department.parentId.toString();
				});
		},
		getDepartmentUserList() {
			this._UserServiceProxy.getUserList().then((res) => {
				this.userlist = res;
			});
		},
		getDepartmentType() {
			this._dataitemDetailServiceProxy.getListByDataItmeCode('DepartmentType').then((res) => {
				this.departmenttype = res;
			});
		},
		gettreedata() {
			this._departmentServiceProxy
				.getPaged(this.companyId, true, undefined, undefined, 9999, undefined)
				.then((res) => {
					this.tree = res.items;
					this.tree.forEach((item) => {
						if (!item.parentId) {
							var obj = {
								key: item.id,
								value: item.id.toString(),
								title: item.displayName,
							};
							this.DepTreeData.push(obj);
						}
					});
					this.initTree(this.DepTreeData);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.tree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id.toString(),
							title: m.displayName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		SelectTree(value, node, extra) {},
		selectUser() {
			ModalHelper.create(
				SelectUserTag,
				{
					companyId: this.companyId,
					_selectedRowKeys: [this.userid],
				},
				{ closable: false }
			).subscribe((res) => {
				if (res) {
					this.userid = res.id;
					this.userName = res.realName;
				}
			});
		},
		save() {
			if (!this.entity.code) {
				return abp.message.warn('请输入部门代码');
			}
			if (!this.entity.displayName) {
				return abp.message.warn('请输入部门名称');
			}
			// if(!this.entity.natureCode){
			//     return abp.message.warn("请选择部门类型");
			// }
			// if(!this.userid){
			//     return abp.message.warn("请选择负责人");
			// }
			if (!this.parentId) {
				this.parentId = undefined;
			}
			const input = new CreateOrUpdateDepartmentInput();
			this.entity.parentId = this.parentId != undefined ? parseFloat(this.parentId) : undefined;
			this.entity.companyId = this.companyId;
			this.entity.managerId = this.userid;
			input.department = AbpOrganizationunitsExtendEditDto.fromJS(this.entity);
			this.spinning = true;
			this._departmentServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style lang="less" scoped>
#username {
	background-color: white;
	color: #808080;
}
.group-input {
	width: 100%;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	height: 32px;
	margin-top: 3px;
	display: flex;
}
.group-text {
	flex: 5;
	text-align: left;
	padding-left: 9px;
	line-height: 32px;
	cursor: pointer;
}
.group-icon {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}
.group-icon i {
	color: #808080;
	background-color: #f6f4f4;
	padding: 8px 20px;
	border-radius: 0px 4px 4px 0px;
	border-left: 1px solid #d9d9d9;
}
</style>
