<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="spinning">
            <a-card>
                <a-tabs default-active-key="1" @change="callback">
                    <a-tab-pane key="1" tab="工号规则" force-render>
                        <a-row :gutter="[8, 32]">
                            <a-col class="center">
                                <span>是否启用自动工号：</span>
                                <a-switch v-model="jobNumberRule.isEnableAutoJobNum"></a-switch>
                            </a-col>
                            <a-col class="center">
                                <span>固定前缀：</span>
                                <a-input style="width: 100px;" v-model="jobNumberRule.fixedPrefix"/>
                            </a-col>
                            <a-col class="center">
                                <span>前缀+公司编码，是否启用：</span>
                                <a-switch v-model="jobNumberRule.isEnableCompanyCode"></a-switch>
                            </a-col>
                            <a-col class="center">
                                <span>序号位数(不包含前缀以及公司编码)：</span>
                                <a-input-number step="1" v-model="jobNumberRule.serialNumber" :min="1" :max="7"/>
                            </a-col>
                        </a-row>
                    </a-tab-pane>
                    <a-button :type="'primary'" @click="handleSubmit" slot="tabBarExtraContent">
                        <a-icon type="save"/>
                        <span>{{l("SaveAll")}}</span>
                    </a-button>
                </a-tabs>
            </a-card>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {AppComponentBase} from "../../../shared/component-base";
    import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
    import {
        PasswordComplexitySetting,
        SecuritySettingsEditDto,
        TenantSettingsEditDto, TenantSettingsServiceProxy, UserLockOutSettingsEditDto
    } from "../../../shared/service-proxies";
    import moment from "moment";

    export default {
        name: "account",
        mixins:[AppComponentBase],
        data(){
            return{
                zh_CN,
                spinning:false,
                //用户所有设置
                tenantSetting: new TenantSettingsEditDto(),
                //安全
                security: new SecuritySettingsEditDto(),
                //密码复杂程度
                passwordComplexitySetting: new PasswordComplexitySetting(),
                //锁定用户
                userLockOut: new UserLockOutSettingsEditDto(),
                //工号规则
                jobNumberRule: {
                    isEnableAutoJobNum: true, //是否启用自动工号
                    fixedPrefix: '', //前缀
                    isEnableCompanyCode: false, //是否启用公司编码
                    serialNumber: 5 //序号位数
                },
                //工号规则的提交参数
                fixedRule: [{
                    "itemType": "0",
                    "formatStr": "是否启用自动工号(忽略)",
                    "stepValue": "",
                    "initValue": "",
                    "description": "true",
                    "itemTypeName": "自定义"
                }, {
                    "itemType": "0",
                    "formatStr": "",
                    "stepValue": "",
                    "initValue": "",
                    "description": "",
                    "itemTypeName": "自定义"
                }, {
                    "itemType": "3",
                    "formatStr": "code",
                    "stepValue": "",
                    "initValue": "",
                    "description": "false",
                    "itemTypeName": "公司"
                }, {
                    "itemType": "2",
                    "formatStr": "000000",
                    "stepValue": "",
                    "initValue": "",
                    "description": "",
                    "itemTypeName": "流水号"
                }],
            }
        },
        created() {
            this._tenantSettingServiceProxy = new TenantSettingsServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.getData();
        },
        methods:{
            getData() {
                this.spinning = true;
                this._tenantSettingServiceProxy.getAllSettings()
                    .finally(() => {
                        this.spinning = false;
                    })
                    .then((res) => {
                        this.security = res.security;
                        this.passwordComplexitySetting = res.security.passwordComplexity;
                        this.userLockOut = res.security.userLockOut;
                        //赋值工号规则
                        if(res.jobNumberRule){
                            const jobNumberRule = JSON.parse(res.jobNumberRule);
                            this.jobNumberRule.isEnableAutoJobNum = jobNumberRule[0].description !== 'false';
                            this.jobNumberRule.fixedPrefix = jobNumberRule[1].formatStr;
                            this.jobNumberRule.isEnableCompanyCode = jobNumberRule[2].description !== 'false';
                            this.jobNumberRule.serialNumber = jobNumberRule[3].formatStr.length;
                        }
                    })
            },
            handleSubmit(){
                    if (!this.security.useDefaultPasswordComplexitySettings) {
                        this.security.passwordComplexity = this.passwordComplexitySetting;
                    }
                    this.security.userLockOut = this.userLockOut;
                    this.tenantSetting.security = this.security;
                    try{
                        //赋值工号规则
                        //是否启用自动工号
                        this.fixedRule[0].description = this.jobNumberRule.isEnableAutoJobNum.toString();
                        //固定前缀
                        this.fixedRule[1].formatStr = this.jobNumberRule.fixedPrefix;
                        //是否启用公司编码
                        this.fixedRule[2].description = this.jobNumberRule.isEnableCompanyCode.toString();
                        //序号位数
                        this.fixedRule[3].formatStr = new Array(this.jobNumberRule.serialNumber).fill(0).toString().replace(RegExp(",", "g"), '');
                        //序列化
                        this.tenantSetting.jobNumberRule = JSON.stringify(this.fixedRule);
                    }catch (e) {
                        abp.message.error('工号规则保存异常'+JSON.stringify(e));
                    }

                    this.spinning = true;
                    this._tenantSettingServiceProxy
                        .updateAllSettings(this.tenantSetting)
                        .finally(() => {
                            this.spinning = false;
                        })
                        .then(res => {
                            this.$notification.success({
                                description: undefined,
                                message: this.l("SavedSuccessfully")
                            })
                        })
            },
            callback(v){
                this.getData();
            }
        }
    }
</script>

<style scoped>

</style>
