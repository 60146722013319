<template>
    <a-config-provider :locale="zh_CN">
        <a-spin :spinning="loading">
            <div class="modal-header">
                <div class="modal-title">
                    <a-icon type="medicine-box"/>
                    <span>数据权限({{headertitle}})</span>
                </div>
            </div>
            <div class="my-md">
                <a-row>
                    <a-col :span="5" class="scroll">
<!--                        <a-list :data-source="listData" bordered  size="small" >-->
<!--                            <div slot="header">-->
<!--                                <b>接口目录</b>-->
<!--                            </div>-->
<!--                            <a-list-item slot="renderItem" slot-scope="item, index"-->
<!--                                         class="list-item"-->
<!--                                         :style="{'background-color':item.color}"-->
<!--                                         @click="selectList(item)">-->
<!--                                <a-list-item-meta>-->
<!--                                    <a slot="title" >{{ item.name }}</a>-->
<!--                                </a-list-item-meta>-->

<!--                            </a-list-item>-->
<!--                        </a-list>-->
                        <a-tree :treeData="listData" showIcon @select="onSelect">
                            <a-icon type="folder" slot="folder"/>
                            <a-icon type="file" slot="file"/>
                            <a-icon type="folder-open" slot="folder-open"/>
                            <template slot="custom" slot-scope="{ expanded }">
                                <a-icon :type="expanded ? 'folder-open' : 'folder'"/>
                            </template>
                        </a-tree>
                    </a-col>
                    <a-col :span="18" style="margin-left: 10px;">
                        <a-row>
                            <a-col :span="18">
                                <a-button
                                        :type="'primary'"
                                        @click="create()"
                                >
                                    <a-icon type="plus"/>
                                    <span>{{ l("Create") }}</span>
                                </a-button>
                                <a-button
                                        :type="'primary'"
                                        @click="edit()"
                                >
                                    <a-icon type="edit"/>
                                    <span>{{ l("Edit") }}</span>
                                </a-button>
                                <a-button
                                        :type="'danger'"
                                        @click="batchDelete()"
                                >
                                    <a-icon type="delete"/>
                                    <span>{{ l("Delete") }}</span>
                                </a-button>
                            </a-col>
                            <a-col :span="6">
                                <a-input-search style="width: 200px" name="filterText"
                                                :placeholder="l('SearchWithThreeDot')" @search="refresh" enterButton
                                                />
                            </a-col>
                        </a-row>
                        <a-row style="padding-top: 10px">
                            <a-col>
                                <a-table
                                        size="middle"
                                        @change="sorterChange"
                                        :customRow="customRow"
                                        class="list-table"
                                        :pagination="false"
                                        :scroll="{y:450}"
                                        :columns="columns"
                                        :rowKey="tableDatas => tableDatas.id"
                                        :dataSource="tableData">
                                    <p class="subscriptionEndUtc" slot="subscriptionEndUtc" slot-scope="record">{{ record }}</p>
                                    <a-checkbox slot="checkbox"
                                                slot-scope="text, record"
                                                :checked="record.checked">
                                    </a-checkbox>
<!--                                    <span slot="objectType" slot-scope="record">-->
<!--                                        {{record.objectType==1?'角色':'用户'}}-->
<!--                                    </span>-->
                                    <a class="isActive" slot="isActive" slot-scope="record">
                                        <a-icon v-if="record" type="check"/>
                                        <a-icon v-if="!record" type="close"/>
                                    </a>
                                </a-table>
                                <a-pagination
                                        class="pagination"
                                        :total="totalItems"
                                        v-model="pageNumber"
                                        showSizeChanger
                                        showQuickJumper
                                        :showTotal="showTotalFun"
                                        @change="onChange"
                                        @showSizeChange="showSizeChange"/>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </div>
        </a-spin>
    </a-config-provider>
</template>

<script>
    import {ModalComponentBase} from "../../../../shared/component-base";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import {DataRelationServiceProxy, InterfaceServiceProxy} from "../../../../shared/service-proxies";
    import modalHelper from "../../../../shared/helpers/modal/modal-helper";
    import CreateOrEditInterfaceAuthorize from './create-or-edit-interface-authorize/create-or-edit-interface-authorize';
    import moment from 'moment'

    let _this;
    export default {
        name: "interface-authorize",
        mixins: [ModalComponentBase],
        components:{CreateOrEditInterfaceAuthorize},
        data() {
            return {
                zh_CN,
                filterText: undefined,
                headertitle:undefined,
                // 表格
                columns: [
                    {
                        title: '',
                        dataIndex: "checkbox",
                        sorter: false,
                        fixed: 'left',
                        width: 50,
                        align: "center",
                        scopedSlots: {customRender: "checkbox"}
                    },
                    {
                        title: this.l("名称"),
                        dataIndex: "name",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "name"}
                    },
                    // {
                    //     title: this.l("用户/角色"),
                    //     dataIndex: "objectType",
                    //     sorter: true,
                    //     ellipsis: true,
                    //     align: "center",
                    //     scopedSlots: {customRender: "objectType"}
                    // },
                    {
                        title: this.l("公式"),
                        dataIndex: "formula",
                        sorter: true,
                        ellipsis: true,
                        align: "center",
                        scopedSlots: {customRender: "formula"}
                    },
                    {
                        title: this.l("创建人"),
                        dataIndex: "creatorUserName",
                        align: "center",
                        sorter: false,
                        ellipsis: true,
                        scopedSlots: {customRender: "creatorUserName"}
                    },
                    {
                        title: this.l("创建时间"),
                        dataIndex: "creationTime",
                        align: "center",
                        sorter: true,
                        ellipsis: true,
                        scopedSlots: {customRender: "creationTime"}
                    },
                ],
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                sorting: "",
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40", "50"],
                request: {sorting: "", maxResultCount: 10, skipCount: 0},
                tableData:[],
                selectRows:[],
                listData: [],
                interfaceId:undefined,
                interfaceName:undefined,
                //类型1、角色 2、用户
                type:undefined,
                //对应的角色或用户Id
                ObjectId:undefined,
            }
        },
        created() {
            this.fullData();
            this._interfaceServiceProxy=new InterfaceServiceProxy(this.$apiUrl, this.$api);
            this._dataRelationServiceProxy=new DataRelationServiceProxy(this.$apiUrl, this.$api);
        },
        async mounted() {
            _this=this;
            this.listData=await this.getInterfaceList();
        },
        methods: {
            refresh(){
                this.pageNumber=1;
                this.selectRows=[];
                this.request.skipCount=0;
                this.getData();

            },
            getData(){
                this.loading=true;
                this._dataRelationServiceProxy.getPaged(
                    this.interfaceId,
                    this.type,
                    this.ObjectId,
                    this.filterText,
                    this.request.sorting,
                    this.request.maxResultCount,
                    this.request.skipCount
                )
                .finally(()=>{this.loading=false;})
                .then(res=>{
                    this.tableData = res.items;
                    this.tableData.map(item=>{
                        item.checked=false;
                        item.creationTime=item.creationTime?moment(item.creationTime).format("YYYY-MM-DD"):"";
                    });
                    this.totalItems = res.totalCount;
                    this.totalPages = Math.ceil(
                        res.totalCount / this.request.maxResultCount
                    );
                    this.pagerange = [
                        (this.pageNumber - 1) * this.request.maxResultCount + 1,
                        this.pageNumber * this.request.maxResultCount,
                    ];
                })
            },
            /**
             * 接口选择事件
             */
            selectList(record){
                this.interfaceName=record.name;
                this.interfaceId=record.id;
                this.listData.map(item=>{
                    if(item.id!=record.id){
                        item.color="";
                    }
                    else {
                        item.color="#e5e5e5";
                    }
                });
                this.refresh();
            },
            /**
             * 选中item
             */
            onSelect(selectedKeys, info) {

                if(selectedKeys.length>0){
                    if(parseInt(selectedKeys[0])){
                        this.interfaceName=info.selectedNodes[0].data.props.title;
                        this.interfaceId=parseInt(selectedKeys[0]);
                        this.refresh();
                    }
                    else {
                        this.interfaceId=0;
                        this.refresh();
                    }
                }
                else {
                    this.interfaceId=0;
                    this.refresh();
                }
            },
            /**
             * 获取接口集合
             */
            getInterfaceList(){
                return new Promise(resolve => {
                   this.loading=true;
                   this._interfaceServiceProxy.getInterfaceTree()
                    .finally(()=>{this.loading=false;})
                    .then(res=>{
                       resolve(res);
                    });
                });
            },
            /**
             * 添加接口权限
             */
            create(){
                if(!this.interfaceId){
                    return abp.message.warn("请选择接口");
                }
                modalHelper.create(
                    CreateOrEditInterfaceAuthorize,
                    {
                        interfaceId:this.interfaceId,
                        type:this.type,
                        ObjectId:this.ObjectId,
                        interfaceName:this.interfaceName
                    },
                    {width:"600px"}
                )
                .subscribe(res=>{
                    if(res){
                        this.refresh();
                    }
                })
            },
            /**
             * 编辑接口权限
             */
            edit(){
                if(this.selectRows.length<=0){
                    return abp.message.warn("请至少勾选一项进行操作");
                }
                if(this.selectRows.length>1){
                    return abp.message.warn("只能勾选一项进行操作");
                }
                modalHelper.create(
                    CreateOrEditInterfaceAuthorize,
                    {
                        interfaceId:this.interfaceId,
                        type:this.type,
                        ObjectId:this.ObjectId,
                        id:this.selectRows[0].id,
                        interfaceName:this.interfaceName
                    },
                    {width:"600px"}
                )
                    .subscribe(res=>{
                        if(res){
                            this.refresh();
                        }
                    })
            },
            /**
             * 批量删除接口权限
             */
            batchDelete(){
                if(this.selectRows.length<=0){
                    return abp.message.warn("请至少勾选一项进行操作");
                }
                this.$confirm({
                    title:"确认操作",
                    content:"您确认要删除这"+this.selectRows.length+"项数据吗？",
                    okText:"确认",
                    cancelText:"取消",
                    onOk:()=>{
                        let ids=_.map(this.selectRows,"id");
                        this.loading=true;
                        this._dataRelationServiceProxy.batchDelete(ids)
                        .finally(()=>{this.loading=false;})
                        .then(res=>{
                            this.refresh();
                        });
                    }
                });
            },
            showSizeChange(current, size) {
                this.selectedRowKeys = [];
                this.selectedRows = [];
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            },
            customRow: (record) => {
                return {
                    on: {
                        click: () => {
                            record.checked = !record.checked;
                            if (record.checked) {
                                //判断是否存在
                                let u = _this.selectRows.find(item => item.id == record.id);
                                if (!u) {
                                    _this.selectRows.push(record);
                                }
                            } else {
                                _this.selectRows = [..._this.selectRows.filter(item => item.id != record.id)];
                            }
                            _this.$forceUpdate();
                        }
                    }
                }
            },
            /**
             * 分页事件
             */
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            /**
             * 排序
             * @param pagination
             * @param filters
             * @param sorter
             */
            sorterChange(pagination, filters, sorter){
                if(sorter.order){
                    this.request.sorting=sorter.columnKey+" "+sorter.order.replace('end','');
                }
                else {
                    this.request.sorting=undefined;
                }
                this.getData();

            },
            /**
             * 分页
             */
            onChange(page, pageSize) {
                this.selectedRowKeys = [];
                this.selectedRows = [];
                this.pageNumber = page;
                this.request.skipCount = (page - 1) * this.request.maxResultCount;
                this.getData();
            },
        }
    }
</script>

<style scoped >
    .pagination {
        margin: 10px auto;
        text-align: right;
    }
    .list-item{
        cursor: pointer;
    }
    .list-item:hover{
        background-color: #C0DDF8;
    }
    .scroll{
        max-height: 500px;
        min-height: 280px;
        overflow: auto;
    }
</style>
