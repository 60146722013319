<template>
	<div>
		<!-- usePagination开启分页 -->
		<!-- loading绑定引入页面的蒙层加载变量 -->
		<q-container usePagination :loading="loading">
			<!-- 使用具名插槽 #toolbar -->
			<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
			<template #toolbar>
				<a-row :gutter="[8, 8]">
					<a-col :span="24" class="q-flex q-row-between">
						<div class="q-flex">
							<a-input-search
								style="width: 220px;margin-right: 10px;"
								name="filterText"
								@search="search"
								:placeholder="l('SearchWithThreeDot')"
								enterButton
								v-model="filterText"
							/>
							<a @click="advancedFiltersVisible = !advancedFiltersVisible">
								<span v-if="!advancedFiltersVisible">
									展开
									<a-icon type="down"></a-icon>
								</span>
								<span v-else>
									收起
									<a-icon type="up"></a-icon>
								</span>
							</a>
						</div>
						<div>
							<a-checkbox v-model="isWillQuit" @change="search">
								只看预离职
							</a-checkbox>
							<a-checkbox v-model="isChildrenOrg" @change="search">
								包含子部门
							</a-checkbox>
						</div>
					</a-col>
					<a-col :span="24" v-if="advancedFiltersVisible">
						<a-row :gutter="[8, 8]">
							<a-col :sm="24" :md="12" :lg="6">
								<a-range-picker
									style="width: 200px;"
									@change="onHireDateChange"
									:placeholder="['入职开始时间', '入职结束时间']"
								/>
							</a-col>
							<a-col :sm="24" :md="12" :lg="6">
								<a-range-picker
									style="width: 200px;"
									@change="onTermDateChange"
									:placeholder="['离职开始时间', '离职结束时间']"
								/>
							</a-col>
							<a-col :sm="24" :md="12" :lg="6">
								<a-tree-select
									style="width: 200px;"
									treeCheckable
									:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
									:tree-data="DepTreeData"
									placeholder="请选择部门"
									tree-default-expand-all
									@change="DepTreeChange"
								>
								</a-tree-select>
							</a-col>
							<a-col :sm="24" :md="12" :lg="6">
								<a-select
									style="width: 200px;"
									allowClear
									v-model="MaritalStatusId"
									@change="getData"
									defaultActiveFirstOption
									placeholder="请选择婚姻状态"
								>
									<a-select-option v-for="item in dicMaritalStatusList" :key="item.itemDetailCode">
										{{ item.itemDetailName }}
									</a-select-option>
								</a-select>
							</a-col>
						</a-row>
					</a-col>
					<a-col :span="24">
						<!-- 添加用户 -->
						<a-button :type="'primary'" v-if="isGranted('btn_user_add')" @click="createOrEdit()">
							<a-icon type="plus" />
							<span>{{ l('Create') }}</span>
						</a-button>
						<!-- 查看档案 -->
						<a-button :type="'primary'" v-if="isGranted('btn_user_archives')" @click="userArchives()">
							<a-icon type="idcard" />
							<span>{{ l('查看档案') }}</span>
						</a-button>
						<!-- excel操作 -->
						<a-dropdown
							:placement="'bottomRight'"
							v-if="
								isGranted('btn_user_export_excel') ||
									isGranted('btn_user_import_excel') ||
									isGranted('btn_user_download_templete')
							"
						>
							<a-button
								>{{ l('操作') }}
								<a-icon type="down" />
							</a-button>
							<a-menu slot="overlay">
								<!-- 导出excel -->
								<a-menu-item v-if="isGranted('btn_user_export_excel')" @click="exportToExcel">
									<a-icon type="download" />
									<span>{{ l('Export') }}</span>
								</a-menu-item>
								<!-- 下载excel -->
								<a-menu-item
									v-if="isGranted('btn_user_download_templete')"
									@click="importUsersSampleFile"
								>
									<a-icon type="file-excel" />
									<span>{{ l('ImportTemplate') }}</span>
								</a-menu-item>
								<!-- 导入excel -->
								<a-menu-item>
									<a-upload
										v-if="isGranted('btn_user_import_excel')"
										:action="importFromExcelUrl"
										:beforeUpload="beforeUpload"
										:headers="uploadHeaders"
										:file-list="fileList"
										@change="uploadChange($event)"
									>
										<a-icon type="file-excel" />
										<span style="margin-left: 5px">
											{{ l('Import') }}
										</span>
									</a-upload>
								</a-menu-item>
							</a-menu>
						</a-dropdown>
						<a-button :type="'primary'" v-if="isGranted('btn_user_exe')">
							<a href="QYBClient:123-MonacoGP-19279-Senna">{{ l('证件入职制卡') }}</a>
						</a-button>
					</a-col>
				</a-row>
			</template>
			<!-- 使用具名插槽slot="table" -->
			<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
			<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->
			<template slot="table" slot-scope="scopeParam">
				<a-table
					:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
					:customRow="customRow"
					:rowClassName="rowClassName"
					:columns="columns"
					size="small"
					:pagination="false"
					:rowKey="(data) => data.id"
					:dataSource="tableData"
				>
					<a-checkbox slot="checkbox" slot-scope="text, record" :checked="record.checked"> </a-checkbox>
					<!-- 姓名 -->
					<a @click="viewUser(record)" slot="jobNumber" slot-scope="text, record">
						{{ text }}
					</a>
				</a-table>
			</template>
			<!-- 使用具名插槽 #page -->
			<template #page>
				<!-- 分页组件只需复制以下代码 -->
				<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
				<a-pagination
					v-model="pageNumber"
					:total="totalItems"
					showSizeChanger
					size="small"
					:defaultPageSize="request.maxResultCount"
					:pageSizeOptions="pageSizeOptions"
					:showTotal="() => showTotal"
					@change="onPageChange"
					@showSizeChange="showPageSizeChange"
				/>
			</template>
		</q-container>
	</div>
</template>

<script>
import AppComponentBase from '../../../../shared/component-base/app-component-base.js';
import { ModalHelper } from '@/shared/helpers';
import Bus from '@/shared/bus/bus';
import {
	DepartmentServiceProxy,
	EmployeeServiceProxy,
	UserServiceProxy,
	OrganizationUnitServiceProxy,
} from '@/shared/service-proxies';
import { Dic, fileDownloadService } from '@/shared/utils';
import UserArchives from '../../users/user-archives/user-archives';
import CreateOrEditUserComponent from '../../users/create-or-edit-user/create-or-edit-user';
import ViewUserComponent from '../../users/view-user/view-user';
import { AppConsts } from '@/abpPro/AppConsts';
import { appSessionService } from '@/shared/abp';

import moment from 'moment';
import DepTreeUntils from '../../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'organization-unit-members-panel',
	mixins: [AppComponentBase],
	props: {
		treeData: {
			type: Object,
			default() {
				return {};
			},
		},
		companyId: {
			type: Number,
			default: undefined,
		},
		isNotChoices: {
			type: Boolean,
			default() {
				return true;
			},
		},
	},
	components: {
		UserArchives,
		CreateOrEditUserComponent,
		ViewUserComponent,
	},
	data() {
		return {
			_organizationUnitServiceProxy: null,
			// 用户表格
			columns: [
				{
					title: '工号',
					dataIndex: 'jobNumber',
					ellipsis: true,
					sorter: false,
					align: 'center',
					fixed: 'left',
					width: 100,
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: '姓名',
					dataIndex: 'realName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					fixed: 'left',
					width: 100,
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: '账号',
					dataIndex: 'userName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'userName' },
				},
				{
					title: '性别',
					dataIndex: 'genderName',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'genderName' },
				},
				{
					title: '手机',
					dataIndex: 'mobile',
					ellipsis: true,
					sorter: false,
					align: 'center',
					scopedSlots: { customRender: 'mobile' },
				},
				{
					title: '部门',
					dataIndex: 'displayName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'displayName' },
				},
				{
					title: '岗位',
					dataIndex: 'orgName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'orgName' },
				},
				{
					title: '生日',
					dataIndex: 'birthday',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'birthday' },
				},
				{
					title: '年龄',
					dataIndex: 'age',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'age' },
				},
				{
					title: '司龄',
					dataIndex: 'companyAge',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'companyAge' },
					width: 150,
				},
				{
					title: '邮箱',
					dataIndex: 'personalEmail',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'personalEmail' },
					width: 250,
				},
				{
					title: '入职日期',
					dataIndex: 'hireDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'hireDate' },
				},
				{
					title: '试用期',
					dataIndex: 'probationMonth',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'probationMonth' },
				},
				{
					title: '试用期职等',
					dataIndex: 'probationGradeName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'probationGradeName' },
				},
				{
					title: '转正后职等',
					dataIndex: 'regularGradeName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'regularGradeName' },
				},
				{
					title: '转正日期',
					dataIndex: 'regularDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'regularDate' },
				},
				{
					title: '首次工作日期',
					dataIndex: 'firstWorkDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'firstWorkDate' },
				},
				{
					title: '民族',
					dataIndex: 'nationName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'nationName' },
				},
				{
					title: '籍贯',
					dataIndex: 'nativePlace',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'nativePlace' },
				},
				{
					title: '户口性质',
					dataIndex: 'residenceName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'residenceName' },
				},
				{
					title: '户籍地址',
					dataIndex: 'identityAddress',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'identityAddress' },
				},
				{
					title: '常住地址',
					dataIndex: 'presentAddress',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'presentAddress' },
				},
				{
					title: '常住地址邮编',
					dataIndex: 'postalCode',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'postalCode' },
				},
				{
					title: '身份证号',
					dataIndex: 'identityCard',
					ellipsis: true,
					align: 'center',
					width: 250,
					scopedSlots: { customRender: 'identityCard' },
				},
				{
					title: '证件开始日期',
					dataIndex: 'identityStartDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'identityStartDate' },
				},
				{
					title: '身份证有效期',
					dataIndex: 'identityValidity',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'identityValidity' },
				},
				{
					title: '员工种类一',
					dataIndex: 'empKindOne',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empKindOne' },
				},
				{
					title: '员工种类二',
					dataIndex: 'empKindTwo',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empKindTwo' },
				},
				{
					title: '员工种类三',
					dataIndex: 'empKindThree',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empKindThree' },
				},
				{
					title: '员工类型',
					dataIndex: 'empTypeName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empTypeName' },
				},
				{
					title: '员工状态',
					dataIndex: 'empStateName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'empStateName' },
				},
				{
					title: '婚姻状况',
					dataIndex: 'maritalStatusName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'maritalStatusName' },
				},
				{
					title: '政治面貌',
					dataIndex: 'politicsStatusName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'politicsStatusName' },
				},
				{
					title: '学历',
					dataIndex: 'educationsName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'educationsName' },
				},
				{
					title: '招聘来源',
					dataIndex: 'recruitmentSourceName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'recruitmentSourceName' },
				},
				{
					title: '劳务公司',
					dataIndex: 'personalServiceName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'personalServiceName' },
				},
				{
					title: '银行卡号',
					dataIndex: 'cardNo',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'cardNo' },
				},
				{
					title: '开户行名称',
					dataIndex: 'bankName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'bankName' },
				},
				{
					title: '社保编号',
					dataIndex: 'socialSecurity',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'socialSecurity' },
				},
				{
					title: '公积金编号',
					dataIndex: 'reservedFunds',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'reservedFunds' },
				},
				{
					title: '离职日期',
					dataIndex: 'termDate',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'termDate' },
				},
			],
			// 用户数据
			tableData: [],
			importFromExcelUrl: AppConsts.remoteServiceBaseUrl + '/Profile/UploadExcelFile',
			// 上传excel
			fileList: [],
			uploadHeaders: {
				Authorization: 'Bearer ' + abp.auth.getToken(),
			},
			_DepartmentServiceProxy: null,
			// 是否多条件查询
			advancedFiltersVisible: false,
			modalSpinning: false,
			userid: undefined,
			//婚姻状况
			MaritalStatusId: undefined,

			//员工类型
			EmpTypeId: undefined,
			//员工类型
			dicEmpTypeList: [],
			//员工状态
			EmpStateId: undefined,
			//员工状态
			dicEmpStateList: [],
			//婚姻状态
			dicMaritalStatusList: [],
			//部门树
			DepTreeData: [],
			_appSessionService: '',
			_fileDownloadService: '',
			//入职开始时间
			HiredateStart: undefined,
			//入职结束时间
			HiredateEnd: undefined,
			//离职开始日期
			TermDateStart: undefined,
			//离职结束日期
			TermDateEnd: undefined,
			//部门Id集合
			DepartmentIds: [],

			_userServiceProxy: null,
			isChildrenOrg: true,
			isWillQuit: false,
		};
	},
	async mounted() {
		this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(this.$apiUrl, this.$api);
		this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
		this._employeeServiceProxy = new EmployeeServiceProxy(this.$apiUrl, this.$api);
		this._appSessionService = appSessionService;
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this.columns = this.getColumn('员工', this.columns, 150);

		// let list = await Dic.getInstance().getDicAsync('EmpState');
		// this.dicEmpStateList = list.filter((item) => item.itemDetailCode === '01' || item.itemDetailCode === '03');
		// this.EmpStateId = '01';
		this.getDepTreeData();
		this.dicEmpTypeList = await Dic.getInstance().getDicAsync('EmpType');
		this.dicMaritalStatusList = await Dic.getInstance().getDicAsync('MaritalStatus');
	},
	methods: {
		/**
		 * 选中树结构
		 */
		getTree(data) {
			this.selectTree = data;
			this.getData();
		},
		/**
		 * 拉取数据
		 */
		getData() {
			// if (!this.EmpStateId) {
			// 	this.EmpStateId = '01';
			// }
			this.loading = true;
			this._employeeServiceProxy
				.getPaged(
					this.companyId != undefined ? this.companyId : undefined,
					this.treeData ? this.treeData.id : undefined,
					this.HiredateStart != undefined ? this.HiredateStart : undefined,
					this.HiredateEnd != undefined ? this.HiredateEnd : undefined,
					this.TermDateStart != undefined ? this.TermDateStart : undefined,
					this.TermDateEnd != undefined ? this.TermDateEnd : undefined,
					this.DepartmentIds.length == 0 ? undefined : this.DepartmentIds.join(','),
					this.EmpTypeId != undefined ? this.EmpTypeId : undefined,
					this.isWillQuit,
					this.MaritalStatusId != undefined ? this.MaritalStatusId : undefined,
					!this.isChildrenOrg,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					if (res.items.length < 1) {
						this.tableData = [];
						this.$emit('GetUserCount', res.totalCount);
						return;
					}

					this.tableData = res.items;
					//设置行选择，默认选择第一个
					this.rowSelect(this.tableData[0], 0);

					//格式化时间
					this.tableData.map((item) => {
						item.birthday = item.birthday ? moment(item.birthday).format('YYYY-MM-DD') : '';
						item.creationTime = item.creationTime ? moment(item.creationTime).format('YYYY-MM-DD') : '';
						item.hireDate = item.hireDate ? moment(item.hireDate).format('YYYY-MM-DD') : '';
						item.regularDate = item.regularDate ? moment(item.regularDate).format('YYYY-MM-DD') : '';
						item.termDate = item.termDate ? moment(item.termDate).format('YYYY-MM-DD') : '';
						item.firstWorkDate = item.firstWorkDate ? moment(item.firstWorkDate).format('YYYY-MM-DD') : '';
						item.identityValidity = item.identityValidity
							? moment(item.identityValidity).format('YYYY-MM-DD')
							: '';
						item.identityStartDate = item.identityStartDate
							? moment(item.identityStartDate).format('YYYY-MM-DD')
							: '';
					});
					this.totalItems = res.totalCount;
					this.$emit('GetUserCount', res.totalCount);
				});
		},
		/**
		 * 移除用户
		 * @param user 当前用户实体
		 */
		removeMember(user) {
			const _ouId = parseInt(this.treeData.id);
			this._organizationUnitServiceProxy.removeUser(user.id, _ouId).then(() => {
				this.$notification['success']({
					message: this.l('SuccessfullyRemoved'),
				});
				//this.clearFilterAndRefresh();
				Bus.$emit('reloadOrganizationUnitTree', {
					depId: this.treeData ? this.treeData.id : undefined,
					companyId: this.companyId,
				});
				// this.refreshGoFirstPage();
				// this.memberRemoved.emit([user.id]);
			});
		},
		/**
		 * 批量删除
		 */
		batchDelete() {
			const selectCount = this.selectedRowKeys.length;
			if (selectCount <= 0) {
				abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
				return;
			}
			this.$confirm({
				content: this.l('ConfirmDeleteXItemsWarningMessage', selectCount),
				cancelText: this.l('Cancel'),
				okText: this.l('Ok'),
				onOk: () => {
					const ids = this.selectedRowKeys;
					this._employeeServiceProxy.batchDelete(ids).then(() => {
						this.selectedRowKeys = [];
						this.request.skipCount = 0;
						this.pageNumber = 1;
						this.$notification['success']({
							message: this.l('SuccessfullyDeleted'),
						});

						Bus.$emit('reloadOrganizationUnitTree', {
							depId: this.treeData ? this.treeData.id : undefined,
							companyId: this.companyId,
						});
					});
				},
			});
		},
		/**
		 * @deprecated 废弃
		 * 显示条件搜索框并初始化字典
		 */
		async ShowModel() {
			this.advancedFiltersVisible = !this.advancedFiltersVisible;
			this.modalSpinning = true;
			try {
				//初始化部门树数据
				this.getDepTreeData();
				//this.dicPeopleAge = await Dic.getInstance().getDicAsync('PeopleAge');
				//this.dicWorkYears = await Dic.getInstance().getDicAsync('WorkYears');
				//this.dicInsuranceType = await Dic.getInstance().getDicAsync('InsuranceType');
				this.dicEmpTypeList = await Dic.getInstance().getDicAsync('EmpType');
				//this.dicEmpTypeKind1List = await Dic.getInstance().getDicAsync('EmpTypeKind1');
				//this.dicEmpTypeKind2List = await Dic.getInstance().getDicAsync('EmpTypeKind2');
				//this.dicEmpTypeKind3List = await Dic.getInstance().getDicAsync('EmpTypeKind3');

				this.dicMaritalStatusList = await Dic.getInstance().getDicAsync('MaritalStatus');
				//this.dicEducationList = await Dic.getInstance().getDicAsync('Education');
			} catch (e) {
				console.error(e);
			} finally {
				this.modalSpinning = false;
			}
		},
		/**
		 * 查看档案
		 */
		userArchives() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项');
			}
			ModalHelper.create(
				UserArchives,
				{ id: this.selectedRowKeys[0] },
				{
					width: '1200px',
				}
			).subscribe((res) => {});
		},
		/**
		 * 添加用户  修改用户
		 */
		createOrEdit() {
			ModalHelper.create(
				ViewUserComponent,
				{
					EmpId: undefined,
					companyId: this.companyId,
					depId: this.treeData ? this.treeData.id : undefined,
					type: 0,
				},
				{
					width: '800px',
				}
			).subscribe((res) => {
				if (res) {
					Bus.$emit('reloadOrganizationUnitTree', {
						depId: this.treeData ? this.treeData.id : undefined,
						companyId: this.companyId,
						//添加和编辑
						type: 1,
					});
				}
			});
		},
		/**
		 * 导出为excel
		 */
		exportToExcel() {
			this._employeeServiceProxy
				.getEmployeeToExcel(
					this.companyId != undefined ? this.companyId : undefined,
					this.treeData ? this.treeData.id : undefined,
					this.HiredateStart != undefined ? this.HiredateStart : undefined,
					this.HiredateEnd != undefined ? this.HiredateEnd : undefined,
					this.TermDateStart != undefined ? this.TermDateStart : undefined,
					this.TermDateEnd != undefined ? this.TermDateEnd : undefined,
					this.DepartmentIds.length == 0 ? undefined : this.DepartmentIds.join(','),
					this.EmpTypeId != undefined ? this.EmpTypeId : undefined,
					this.EmpStateId != undefined ? this.EmpStateId : undefined,
					this.MaritalStatusId != undefined ? this.MaritalStatusId : undefined,
					!this.isChildrenOrg,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((res) => {
					this._fileDownloadService.downloadTempFile(res);
				});
		},
		/**
		 * 下载excel模版
		 */
		importUsersSampleFile() {
			this._employeeServiceProxy.downloadTemplateFile().then((res) => {
				this._fileDownloadService.downloadTempFile(res);
			});
		},
		beforeUpload(file) {
			let filetype;
			if (file.name) {
				filetype = file.name.substring(file.name.lastIndexOf('.'));
			}
			let isExcel = filetype === '.xlsx' || filetype === '.xsl';
			if (!isExcel) {
				return abp.message.warn('只能上传xlsx格式和xsl的文件');
			}
		},
		/**
		 * 用户导入
		 */
		uploadChange({ file, fileList }) {
			this.fileList = fileList;
			if (file.status === 'done') {
				let filename = file.response.result.fileName;
				this.loading = true;
				this._employeeServiceProxy
					.importExcel(filename)
					.finally(() => {
						this.loading = false;
					})
					.then((res) => {
						this.fileList = [];

						if (!res.isSuccess && res.failedCount > 0) {
							this.$notification['warn']({
								message: '总条数:' + res.totalCount + '  失败条数:' + res.failedCount,
							});
							this._fileDownloadService.downloadTempFile(res.file);
						} else {
							this.$notification['success']({
								message: this.l('导入成功'),
							});
						}
						this.refresh();
					});
			}
		},
		/**
		 * 是否是管理员
		 */
		isAdmin(item) {
			return item.userName === AppConsts.userManagement.defaultAdminUserName;
		},
		/**
		 * 设置部门树数据
		 */
		getDepTreeData() {
			this.DepTreeData = [];
			this._DepartmentServiceProxy.getDepTreeData(this.companyId).then((res) => {
				this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
				this.DepTree.forEach((item) => {
					if (!item.parentId) {
						this.DepTreeData.push(item);
					}
				});
				this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
			});
		},
		DepTreeChange(value, label, extra) {
			this.DepartmentIds = value;
			this.getData();
		},
		/**
		 * 初始化部门树形结构
		 */
		initDepTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.DepTree.forEach((m) => {
					if (m.parentId == v.key) {
						//根据主键查子集数据
						var obj = {
							key: m.key,
							value: m.key,
							title: m.title,
						};
						v.children.push(obj); //放进父级的集合中
						this.initDepTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		/**
		 * 入职时间筛选
		 * @param e
		 */
		onHireDateChange(e) {
			this.HiredateStart = e.length > 0 ? e[0] : undefined;
			this.HiredateEnd = e.length > 0 ? e[1] : undefined;
			this.getData();
		},
		/**
		 * 离职时间筛选
		 * @param e
		 */
		onTermDateChange(e) {
			this.TermDateStart = e.length > 0 ? e[0] : undefined;
			this.TermDateEnd = e.length > 0 ? e[1] : undefined;
			this.getData();
		},
		/**
		 * 右键事件
		 */
		onRightClick({ event, node }) {
			this.dataItemId = node.eventKey;
			const x = event.currentTarget.offsetLeft + event.currentTarget.clientWidth;
			const y = event.currentTarget.offsetTop;
			this.NodeTreeItem = true;
			this.tmpStyle = {
				position: 'absolute',
				maxHeight: 40,
				textAlign: 'center',
				left: `${x + 10 - 0}px`,
				top: `${y + 6 - 0}px`,
				display: 'flex',
				flexDirection: 'row',
			};
		},
		/**
		 * 查看用户详情
		 */
		viewUser(record) {
			// if (!this.companyId) {
			//     abp.message.warn("请选择公司");
			//     return;
			// }
			ModalHelper.create(
				ViewUserComponent,
				{ EmpId: record.id, companyId: this.companyId, depId: this.treeData ? this.treeData.id : undefined },
				{
					width: '1000px',
				}
			).subscribe((res) => {
				if (res) {
					// this.getData(this.companyId);
					Bus.$emit('reloadOrganizationUnitTree', {
						depId: this.treeData ? this.treeData.id : undefined,
						companyId: this.companyId,
						//添加和编辑
						type: 1,
					});
				}
			});
		},
	},
};
</script>

<style scoped lang="less"></style>
