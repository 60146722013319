<template>
	<div>
		<div class="modal-header">
			<div class="modal-title">
				<a-icon type="team" />
				{{ l('SelectUsers') }}
			</div>
		</div>
		<!-- usePagination开启分页 -->
		<!-- loading绑定引入页面的蒙层加载变量 -->
		<q-container usePagination :loading="loading" useModal>
			<!-- 使用具名插槽 #toolbar -->
			<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
			<template #toolbar>
				<!-- 搜索框 -->
				<a-input-search
					name="filterText"
					style="margin: 10px 0"
					@search="getData"
					:placeholder="l('SearchWithThreeDot')"
					enterButton
					v-model="filterText"
				/>
			</template>
			<!-- 使用具名插槽slot="table" -->
			<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
			<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

			<!-- **************行选择*************** -->
			<!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
			<!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
			<template slot="table" slot-scope="scopeParam">
				<a-table
					ref="table"
					size="small"
					:pagination="false"
					:columns="columns"
					:rowKey="(tableDatas) => tableDatas.id"
					:scroll="{ x: scroll_x, y: scopeParam.scroll.y }"
					:dataSource="TableData"
					:customRow="customRow"
					:rowClassName="rowClassName"
				>
				</a-table>
			</template>
			<!-- 使用具名插槽 #page -->
			<template #page>
				<!-- 分页组件只需复制以下代码 -->
				<!-- 处理逻辑和变量都在 app-component-base混入文件 -->
				<a-pagination
					v-model="pageNumber"
					:total="totalItems"
					showSizeChanger
					size="small"
					:defaultPageSize="request.maxResultCount"
					:pageSizeOptions="pageSizeOptions"
					:showTotal="() => showTotal"
					@change="onPageChange"
					@showSizeChange="showPageSizeChange"
				/>
			</template>
		</q-container>
		<div class="modal-footer">
			<a-button :disabled="saving" @click="close()" type="button">
				<a-icon type="close-circle" />
				{{ l('Cancel') }}
			</a-button>
			<a-button :loading="saving" :type="'primary'" @click="handleSubmit()">
				<a-icon type="save" />
				{{ l('Save') }}
			</a-button>
		</div>
	</div>
</template>

<script>
import { ModalComponentBase } from '@/shared/component-base';
import { OrganizationUnitServiceProxy } from '@/shared/service-proxies';
import { UserServiceProxy } from '@/shared';
import * as _ from 'lodash';

export default {
	name: 'select-user-success-user',
	mixins: [ModalComponentBase],
	data() {
		return {
			_organizationUnitServiceProxy: null,
			// 用户表格
			columns: [
				{
					title: this.l('Department'),
					dataIndex: 'departmentName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'departmentName' },
				},
				{
					title: this.l('OrgPost'),
					dataIndex: 'orgName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'orgName' },
				},
				{
					title: this.l('JobNumber'),
					dataIndex: 'jobNumber',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: this.l('Name'),
					dataIndex: 'realName',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'realName' },
				},
			],
			// 用户数据
			TableData: [],
			companyId: undefined,
			organizationUnitId: undefined,
			isMultiple: true,
		};
	},
	created() {
		this.fullData(); // 模态框必须,填充数据到data字段
		this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(this.$apiUrl, this.$api);
		this._UserServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
		this.getData();
	},
	methods: {
		/**
		 * 获取数据
		 */
		getData() {
			this.loading = true;
			this.selectRowObj = {};
			this._UserServiceProxy
				.getUserPageListByDep(
					this.companyId,
					this.organizationUnitId,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.TableData = res.items;
					this.TableData.map((item) => (item.checked = false));
					this.totalItems = res.totalCount;
				});
		},
		/**
		 * 提交表单
		 */
		handleSubmit() {
			const selectCount = this.selectedRows.length;
			if (selectCount <= 0) {
				abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
				return;
			}
			// this.$notification["success"]({
			//     message: this.l("SavedSuccessfully")
			// });
			this.success(this.selectedRows);
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
