<template>
	<a-row :gutter="[8, 0]">
		<a-col :span="5">
			<q-tree-container>
				<template #select>
					<a-tree-select
						class="left"
						@change="handleChange"
						:placeholder="l('Company')"
						tree-default-expand-all
						style="width: 100%"
						:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
						:tree-data="companyTreeList"
						v-model="comPanyId"
					/>
				</template>

				<template #tree>
					<a-tree
						:treeData="treeData"
						showIcon
						@select="onSelect"
						:expanded-keys="expandedKeys"
						@expand="onExpand"
						:selectedKeys="SelectKey"
					>
						<a-icon type="folder" slot="folder" />
						<a-icon type="file" slot="file" />
						<a-icon type="folder-open" slot="folder-open" />
						<template slot="custom" slot-scope="{ expanded }">
							<a-icon :type="expanded ? 'folder-open' : 'folder'" />
						</template>
					</a-tree>
				</template>
			</q-tree-container>
		</a-col>
		<a-col :span="19">
			<!-- usePagination开启分页 -->
			<!-- loading绑定引入页面的蒙层加载变量 -->
			<q-container :loading="loading">
				<!-- 使用具名插槽 #toolbar -->
				<!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
				<template #toolbar>
					<a-row :gutter="[8, 8]">
						<a-col :span="24">
							<!-- 添加部门 -->
							<a-button :type="'primary'" v-if="isGranted('add_post')" @click="addPost">
								<a-icon type="plus" />
								<span>{{ l('Create') }}</span>
							</a-button>
							<!-- 编辑部门 -->
							<a-button :type="'primary'" v-if="isGranted('edit_post')" @click="editPost">
								<a-icon type="edit" />
								<span>{{ l('Edit') }}</span>
							</a-button>
							<!-- 批量删除岗位 -->
							<a-button :type="'danger'" v-if="isGranted('delete_post')" @click="deletedPost">
								<a-icon type="delete" />
								<span>{{ l('Delete') }}</span>
							</a-button>
						</a-col>
					</a-row>
				</template>
				<!-- 使用具名插槽slot="table" -->
				<!-- 插槽作用域的变量 slot-scope="scopeParam" -->
				<!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->
				<template slot="table" slot-scope="scopeParam">
					<a-table
						size="small"
						:scroll="{ y: scopeParam.scroll.y }"
						:pagination="false"
						:columns="columns"
						:rowKey="(tableDatas) => tableDatas.id"
						:dataSource="tabletree"
						:customRow="customRow"
						:rowClassName="rowClassName"
					>
					</a-table>
				</template>
			</q-container>
		</a-col>
	</a-row>
</template>

<script>
import { AppComponentBase } from '../../../shared/component-base';
import * as _ from 'lodash';
import {
	CompanyServiceProxy,
	OrganizationUnitServiceProxy,
	OrgPostServiceProxy,
	UserServiceProxy,
} from '../../../shared/service-proxies';
import AddPost from './add-Post/add-Post';
import { ModalHelper } from '../../../shared/helpers';
import { abpService } from '../../../shared/abp';
import DepTreeUntils from '../department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'grade',
	mixins: [AppComponentBase],
	components: { AddPost },
	data() {
		return {
			comPanyId: undefined,
			companyList: [],
			SelectKey: [],
			expandedKeys: [],
			treeData: [],
			companyTreeList: [],
			_ouData: {},
			depId: undefined,
			// 表格
			columns: [
				{
					title: this.l('名称'),
					dataIndex: 'name',
					align: 'left',
					ellipsis: true,
					scopedSlots: { customRender: 'name' },
					width: '150px',
				},
				{
					title: this.l('员工种类一'),
					dataIndex: 'empKindOne',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'empKindOne' },
					width: '150px',
				},
				{
					title: this.l('员工种类二'),
					dataIndex: 'empKindTwo',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'empKindTwo' },
					width: '150px',
				},
				{
					title: this.l('员工种类三'),
					dataIndex: 'empKindThree',
					align: 'center',
					ellipsis: true,
					scopedSlots: { customRender: 'empKindThree' },
					width: '150px',
				},
			],
			tableData: [],
			postId: undefined,
			tabletree: [],
		};
	},
	created() {
		this._organizationUnitServiceProxy = new OrganizationUnitServiceProxy(this.$apiUrl, this.$api);
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._UserServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
		this._OrgPostServiceProxy = new OrgPostServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.comPanyId = abpService.abp.userInfo.companyId;
			this.handleChange(this.comPanyId);
		}
	},
	methods: {
		companyTreeInit() {
			this.loading = true;
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					if (this.companyTreeList.length > 0 && !this.comPanyId) {
						this.handleChange(this.companyTreeList[0].key);
					}
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		handleChange(value) {
			this.SelectKey = [];
			this.comPanyId = value;
			this.depId = undefined;
			this.getPostData();
			this.getData();
		},
		getData() {
			this.left = true;
			this._organizationUnitServiceProxy
				.getAllOrganizationUnitList(this.comPanyId, false)
				.finally(() => {
					this.loading = false;
				})
				.then((result) => {
					this.treeData = [];
					this._ouData = DepTreeUntils.getInstance().InitTreeData(result.items);
					this._ouData.forEach((item) => {
						if (!item.parentId) {
							this.treeData.push(item);
						}
					});
					this.treeData = DepTreeUntils.getInstance().ArrMapTree(this._ouData, this.treeData, false);
					if (result.items.length > 0) {
						// this.onSelect([result.items[0].id.toString()], undefined);
					} else {
						this.data = [];
					}
				});
		},

		/**
		 * 选中item
		 */
		onSelect(selectedKeys, info) {
			this.SelectKey = selectedKeys;
			this.depId = selectedKeys.length > 0 ? parseInt(selectedKeys[0]) : undefined;

			this.getPostData();

			// this.activedNode = this._ouData.find(
			//     item => parseInt(item.id) == parseInt(selectedKeys[0])
			// );
		},
		/**
		 * 展开
		 */
		onExpand(expandedKeys, record) {
			this.expandedKeys = expandedKeys;
		},
		getPostData() {
			this.loading = true;
			this._OrgPostServiceProxy
				.getPagedDepPosts(this.comPanyId, this.depId, '', undefined, 999, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tabletree = [];
					this.tableData = res.items;

					this.tableData.forEach((item) => {
						item.checked = false;
						if (item.parentId == 0) {
							this.tabletree.push(item);
						}
					});
					this.selectedRowObj = {};
					this.initTree(this.tabletree);
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.tableData.forEach((m) => {
					if (m.parentId == v.id) {
						v.children.push(m); //放进父级的集合中
						this.initTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},

		addPost() {
			let pId = undefined;
			if (this.selectedRowKeys.length == 1) {
				pId = this.selectedRowKeys[0];
			}
			ModalHelper.create(
				AddPost,
				{
					companyId: this.comPanyId,
					pId: pId,
					depId: this.depId,
				},
				{ width: '900px' }
			).subscribe((res) => {
				if (res) {
					this.getPostData();
				}
			});
		},
		editPost() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			if (this.selectedRowKeys.length > 1) {
				return abp.message.warn('只能选择一项进行操作');
			}
			ModalHelper.create(
				AddPost,
				{
					companyId: this.comPanyId,
					id: this.selectedRowKeys[0],
				},
				{ width: '900px' }
			).subscribe((res) => {
				if (res) {
					this.getPostData();
				}
			});
		},
		deletedPost() {
			if (this.selectedRowKeys.length <= 0) {
				return abp.message.warn('请至少选择一项进行操作');
			}
			this.$confirm({
				title: '操作确认',
				content: '你确认要删除这' + this.selectedRowKeys.length + '项吗?',
				cancelText: '取消',
				okText: '确认',
				onOk: () => {
					this.loading = true;
					this._OrgPostServiceProxy
						.batchDelete(this.selectedRowKeys)
						.finally(() => {
							this.loading = false;
						})
						.then((res) => {
							this.$notification['success']({
								message: this.l('SuccessfullyDeleted'),
							});
							this.getPostData();
						});
				},
			});
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .ant-table-thead > tr > th .ant-table-header-column .ant-checkbox {
	display: none;
}
</style>
